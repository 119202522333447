import React from 'react';
import classNames from 'classnames/bind';

const File = ({
    classStyles,
    label="Label",
    hasErrors,
    ...props
}) => {
    let styles = classNames({
        'form-control': true,
        'is-invalid': hasErrors,
        ...classStyles
    });
    return (
        <React.Fragment>
            { props.label ? <label>{props.label}</label> : '' }
            <input type="file" className={styles} {...props}/>
        </React.Fragment>
    );
};

export default File;

