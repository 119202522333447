import reducer from './reducers';
import * as dashboardOperations from './operations';
import api from './api';
import * as dashboardMiddleware from './middleware';
import * as dashboardSelectors from "./selectors";
import * as dashboardUtils from "./utils";

export {
    dashboardOperations,
    api,
    dashboardMiddleware,
    dashboardSelectors,
    dashboardUtils
};

export default reducer;
