

import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from 'Routes/PrivateRoute';
import { Loader } from 'Components';

import ListSepa from './ListSepa';

export default function SagaPage() {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          <Redirect
            exact={true}
            from="/sepa"
            to="/sepa/list"
          />
        }
        <PrivateRoute path="/sepa/list" component={ListSepa} />

      </Switch>
    </Suspense>
  );
}

