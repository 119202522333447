

import { default as HttpClient } from './HttpClient';

const  ApigprocAuth =  {

    forgotPassword: (data) => {     
        return HttpClient.post('/auth/forgotPassword', data);
    },
    passwordReset: (data) => {     
        return HttpClient.post('/auth/passwordReset', data);
    },
}

export default ApigprocAuth;


