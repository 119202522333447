/*import { middleware as mdOffer } from "./offer";
import { middleware as mdUser } from "./user";
import { middleware as mdCategory } from "./category";*/

import { constants } from "./user/types";

/*export {
    mdOffer,
    mdUser,
    mdCategory
}*/


//middleware.js
const Middleware = store => next => action => {

    const user = store.getState().user;
    if((!user)) {
        const {pathname} = window.location;
        if(pathname.includes('/auth') == 0){
            window.location = `/auth/login`;
        }
    }


    /*if(action.type != constants.SET_INITIAL_DATA_USER && action.type != constants.SET_CURRENT_GEOLOCATION)
    {
        if(! store.getState().user.status)
        {
            window.location = `/`;

            return next(action(false));
        }
    }*/

    /*if(action.type != constants.SET_INITIAL_DATA_USER && action.type != constants.SET_CURRENT_GEOLOCATION)
    {
        if(! store.getState().user.status)
        {
            window.location = `/`;

            return next(action(false));
        }
    }*/

    /*alert(action.type);
    switch(action.type) {
      
        case constants.SET_INITIAL_DATA_USER:
            //alert("set initial data user");
            //alert(JSON.stringify(store.getState().user.status));
            //fetch('http://example.com/request1')
            //.then(response => response.json())
            //.then(json => store.dispatch(receiveValue1(json));
        break;
  
        // when we receive value 1, we can request value 2
        case constants.VALIDATE_USER_IS_TUENTI:
            alert("validate user is tuenti");
            //fetch('http://example.com/request2/?val='+action.val)
            //.then(response => response.json())
            //.then(json => store.dispatch(receiveValue2(json));
        break;
  
    }*/
    return next(action);
  };

  export default Middleware;