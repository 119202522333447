import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Button from "../../components/Button";
import Alert from "../../components/Alert";
import Input from "../../components/Input";
import CustomButton from "../../components/CustomButton";
import Divider from "../../components/Divider";

const Entregables = ({
  amount,
  deliverables,
  onAddDeliverable,
  onRemoveDeliverable,
  onHandleChangeDeliverables
}) => {
  const noExisteMontoContrato = amount === "" || amount === 0;
  let porcentajeTotal = 0;

  if (deliverables.length > 0) {
    porcentajeTotal = deliverables.reduce((acc, {percentaje_amount}) => acc + (+percentaje_amount), 0);
  }

  return (
    <div>
      {porcentajeTotal > 100 && <Alert color="solid-warning" message="La suma de porcentaje excede el 100%" icon="fa fa-exclamation" />}
      {deliverables.map((deliverable, index) => {
        return (
          <div className="row mb-4" key={index}>
            <div className="col-lg-12">
              <Alert message={`${deliverable.name}`} color="dark"/>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label>Número</label>
                <Input
                  type="number"
                  min="1"
                  name={`order.${index}`}
                  value={deliverable.order}
                  onChange={onHandleChangeDeliverables}
                />
              </div>
              <label>Descripción</label>
              <textarea
                name={`description.${index}`}
                className="form-control"
                rows="6"
                value={deliverable.description}
                onChange={onHandleChangeDeliverables}
              ></textarea>
              <Button
                name="Remover Entregable"
                classStyles={{
                  "btn-danger": true,
                  "text-white": true,
                  "mt-4": true,
                }}
                onClick={() => onRemoveDeliverable(index)}
              />
            </div>
            <div className="col-lg-8">
              <div className="form-group">
                <label>Días de entrega a partir de la fecha de inicio</label>
                <div class="input-group">
                  <Input
                    type="number"
                    min="1"
                    name={`delivery_day.${index}`}
                    value={deliverable.delivery_day}
                    onChange={onHandleChangeDeliverables}
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">
                      {moment(deliverable.delivery_date, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Porcentaje (%)</label>
                <div class="input-group">
                  <Input
                    type="number"
                    disabled={noExisteMontoContrato}
                    max="100"
                    name={`percentaje_amount.${index}`}
                    value={deliverable.percentaje_amount}
                    onChange={onHandleChangeDeliverables}
                  />
                  <Input type="hidden" name={`amount.${index}`} value={amount * (deliverable.percentaje_amount / 100)} />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">
                      {noExisteMontoContrato
                        ? "No hay monto en el contrato"
                        : `Monto: ${deliverable.amount}`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Penalidad </label>
                <div class="input-group">
                <Input
                    type="number"
                    disabled={noExisteMontoContrato}
                    min="0"
                    name={`penalty_amount.${index}`}
                    value={deliverable.penalty_amount}
                    onChange={onHandleChangeDeliverables}
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">
                      {noExisteMontoContrato
                        ? "No hay monto en el entregable"
                        : `Monto Final: ${deliverable.amount - deliverable.penalty_amount}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {deliverables.length > 0 ? (
        <div className="mb-4">
          <Divider />
        </div>
      ) : (
        ""
      )}
      <div className="row mt-2">
        <div className="col-lg-12">
          <Button
            name="Agregar Entregable"
            classStyles={{ "btn-success": true, "text-white": true }}
            onClick={onAddDeliverable}
          />
        </div>
      </div>
    </div>
  );
};

Entregables.propTypes = {};

export default Entregables;
