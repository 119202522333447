import { constants } from "./types";
import createReducer  from "Utils/createReducer";

const initialState = {
    institutions : []
};

const institutionsReducer = createReducer( initialState )( {
    [ constants.SET_INSTITUTIONS ]: (state, action) => {
        return  { ...state, institutions : action.institutions };
    }
} );

export default institutionsReducer;
