import { combineReducers } from "redux";
import * as reducers from "Ducks";

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') 
  {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
