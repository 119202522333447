import { constants } from "./types";

export const requestStartedAction = (id, cancelTokenSource) => ({
    type: constants.API_REQUEST_STARTED,
    payload: { id, cancelTokenSource }
  });

export const requestSucceededAction = id => ({
    type: constants.API_REQUEST_SUCCEEDED,
    payload: { id }
});

export const requestFailedAction = (id, error) => ({
    type: constants.API_REQUEST_FAILED,
    payload: { id, error }
});

export const cancelRequestAction = id => ({
    type: constants.API_REQUEST_CANCEL_REQUEST,
    payload: { id }
});