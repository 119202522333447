import React from 'react';
import Autosuggest from 'react-autosuggest';
  
  // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
  function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
function renderSuggestion(suggestion) {
    return (
      <span>{suggestion.name}</span>
    );
}

class InputAutocomplete extends React.Component {
    constructor() {
      super();
  
      this.state = {
        value: '',
        suggestions: []
      };    
    }

    componentDidMount()
    {
      if(this.props.value) this.setState({ value : this.props.value })
    }

    onChange = (event, { newValue, method }) => {
      this.setState({
        value: newValue
      });
    };
    
    onSuggestionsFetchRequested = async ({ value }) => {
      this.setState({
        suggestions: await this.getSuggestions(value)
      });
    };
  
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
    };

    renderSuggestion = (suggestion) => {
        if(this.props.renderSuggestion){
          return this.props.renderSuggestion(suggestion)
        }
        return (
          <span>{suggestion.name}</span>
        );
    }

    getSuggestions = async (value) => {
        const escapedValue = escapeRegexCharacters(value.trim());
        
        if (escapedValue === '') {
          return [];
        }

        const regex = new RegExp('^' + escapedValue, 'i');
        
        const result = await this.props.onCallback(escapedValue);

        return result;
    }

    clear = () => this.setState({ suggestions : [], value : '' });

    getSuggestionValue = (suggestion) => 
    {
        this.props.onResult(suggestion);
        return suggestion.name;
    }

    componentDidUpdate(oldProps) {
      const newProps = this.props;

      if(oldProps.onClear !== newProps.onClear) 
      {
        this.clear();
      }

      if(oldProps.value !== newProps.value)
      {
        this.setState({ value : newProps.value });
      }
  }
  
    render() {
      const { value, suggestions } = this.state;
      const inputProps = {
        placeholder: "",
        value,
        onChange: this.onChange
      };
  
      return (
        <Autosuggest 
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps} />
      );
    }
  }

  export default InputAutocomplete;