import {  constants } from "./types";
import { createRequest } from "Utils/createRequest";
import history from 'Utils/history';
import { routes } from 'Utils/constants';
import { settings } from 'Utils/settings';
import _ from 'lodash';

export const setProducts = (products) => 
({
    type: constants.SET_PRODUCTS,
    products
});

export const setActivities = (activities) => 
({
    type: constants.SET_ALL_ACTIVITIES,
    activities
});

export function getProducts(numberPage = 1, parameters) {
  return async dispatch => {
    try {
      let queryString = "";

      queryString = `&page=${numberPage}
        ${_.has(parameters, 'name') ? `&name=${parameters.name}` : ``}
        ${_.has(parameters, 'provider') ? `&provider_name=${parameters.provider}` : ``}
      `;

      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contract_products?with=event,contract,activity,proyect,provider${queryString}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_PRODUCTS
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        //Redirect to Dashboard
        dispatch(setProducts(data));
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function createProduct(product) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'POST',
          url: `/proyects/${settings.PROJECT_ID}/contract_products`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : product
          //params: query
        },
        requestId: constants.CREATE_PRODUCT
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        alert("GUARDADO EXITOSAMENTE");
        history.push(routes.PRODUCTS);
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function editProduct(product) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'PUT',
          url: `/proyects/${settings.PROJECT_ID}/contract_products`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : product
          //params: query
        },
        requestId: constants.EDIT_PRODUCT
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        alert("GUARDADO EXITOSAMENTE");
        history.push(routes.PRODUCTS);
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function getAllActivities() {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/activities`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_ALL_ACTIVITIES
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        //Redirect to Dashboard
        dispatch(setActivities(data.data));
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};