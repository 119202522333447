import React, { Component, useState } from 'react';
import { Input, SubHeader, Portlet, Section } from 'Components';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as UserActionsCreators from 'Ducks/user/operations';
import classNames from 'classnames/bind';
import 'react-notifications/lib/notifications.css';
import { default as LayoutLogin } from 'Views/Layout/types/Login';
import { configureStore } from 'Utils/createStore';
import _ from 'lodash';
import ApigprocRecruitment from 'Utils/apigproc/ApigprocRecruitment';
const { store, persistor } = configureStore();
const queryString = require('query-string');
// import queryString from 'query-string';

class WorkJobs extends Component {



	constructor(props) {
		super(props);
		this.state = {
			workOffers: [],
			queryString: {
				search: ''
			}
		};	
		
	}

	/** FUCIONES QUERY STRING REDIRECT */
	initQueryString(){
		this.setState({ queryString: this.getQueryString() });
	}
	getQueryString(){
		 return  queryString.parse(window.location.search)
	}
	setQueryString(name, value){
		this.setState({ queryString: _.assign(queryString, { [name]: value }) });
	}
	goQueryString(){
		const {history} = this.props		
		let params = {}		
		if(this.state.queryString.search)  
			params.search = this.state.queryString.search
		
		history.push(window.location.pathname+"?"+queryString.stringify(params));
		history.go();
	}
	handleQueryString = (e) => {
		const { name, value } = e.target;
		this.setQueryString(name, value);
	}
	/** FUCIONES QUERY STRING REDIRECT */


	componentDidMount() {

	
	}
	
	componentWillUnmount() {
		
	}
	
	keyPressed = (event) => {
		if (event.key === "Enter" || event.keyCode == 13) {		
			this.goQueryString()
		}
	};

	

	render() {

		return (
			<div class="row">

				<SubHeader title="CONTRATACIÓNES" />

				<div class="col-12 text-center">
					<h1>CONTRATACIÓNES</h1>
					<p>El programa para la mejora de la calidad y pertinencia de los servicios de Educación Superior Universitaria y Tecnología a nivel nacional – PMESUT, te invita a formar parte de nuestra red de consultores y evaluadores.</p>

					<div class="mb-4 d-flex justify-content-center" >
						<Input
							style={{ maxWidth: "320px" }}
							classStyles={{}}
							label="Buscar"
							placeholder="Buscar"
							type="text"
							name="search"
							required={true}
							value={this.state.queryString.search}
							onKeyPress={(e) => this.keyPressed(e)}
							onChange={this.handleQueryString}
						/>
					</div>

				</div>

				{
					_.map(this.state.workOffers, (workOffer) => (
						<div class="col-md-6">
							<Portlet
								bodyContent={<ItemListWorkOffer workOffer={workOffer} {...this.props} />}
							/>
						</div>
					))
				}
				
			</div>
		)
	}
}

const ItemListWorkOffer = (props) => {
	const { workOffer, history } = props

	const handleSubmit = () => {
		history.push("/puestos-de-tabajo/" + workOffer.id)
	}

	return (
		<div class="kt-widget kt-widget--user-profile-3">
			<div class="kt-widget__top">
				<div class="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-boldest kt-font-light kt-hidden">
					JM
						</div>
				<div class="kt-widget__content">
					<div class="kt-widget__head">
						<div href="" onClick={handleSubmit} class="kt-widget__username cursor-pointer">
							{workOffer.name}
							<i class="flaticon2-correct kt-font-success"></i>
						</div>
						<div class="kt-widget__action">

						</div>
					</div>
					<div class="kt-widget__info">
						<div class="kt-widget__desc">
							{workOffer.summary}
							<br />
							{workOffer.start_date}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.user,
	api: state.api
});
export default connect(mapStateToProps, null)(WorkJobs);
