import KeyMirror from 'keymirror';

export const constants = KeyMirror({
    TEST: null,
    SET_TOKEN: null,
    SET_USER_INFORMATION : null,
    GET_USER_INFORMATION : null,
    SET_ROLE : null,
    LOGOUT : null,
    SET_ISAUTHENTICATED : null,
    REGISTER : null,
    RESET_PASSWORD : null,
    FORGOT_PASSWORD: null,
    PROFILE_SET_CONTRACTS: null,
    //API
    AUTH : null
});
