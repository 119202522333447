import React from 'react';
import { connect } from 'react-redux';

class ListSepa extends React.Component {
    state = {
        data: [],
        page: 1,
        loading: true,
        loadingMore: false,
        refreshing: false,
        error: null,
    };

    render() {
        return "HOLAS =) ...";
    }

}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = {
    
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListSepa);