import React, { useState } from "react";
import _ from "lodash";
import "./ResumableUploadFile/index.css";
import { settings } from 'Utils/settings';
import { default as HttpClient } from '../../utils/apigproc/HttpClient';

const token = HttpClient.getToken();

const TypeFile = ({ id="", value="" }) => {

  let val = _.find(settings.TYPE_INFORME, (v, i) => v.id == id);

  if(!val && !value){
    return '';
  }
  return <small className="type">{val ? val.name : value}</small>
}

const FileObject = ({ object, type="", object_type_id="" }) => {

  if(!object){
    return '';
  }
  const { size, _url, created_at, identifier, id } = object;
  const kilobyte = 1024;
  const megabyte = (kilobyte * 1024);
  const gigabyte = (megabyte * 1024);

  let sizetext = `0 KB`;

  if (size && size > 0) {
    sizetext = `${((size / kilobyte)).toFixed(0)} KB`;
    if ((size / megabyte) >= 1) {
      sizetext = `${(size / megabyte).toFixed(0)} MB`;
      if ((size / gigabyte) >= 1) {
        sizetext = `${(size / gigabyte).toFixed(0)} GB`;
      }
    }
  }

  const link = `${settings.BASE_URL}/download/${identifier}`;
  return object && <div className="file-success" created_at={object.created_at} >
    <div className="data-file">
        <a href={link} target={"_blank"}>
          <strong>{object.name} </strong>         
        </a>
        <strong>({sizetext})</strong> 
        <div><small>{created_at}</small> <TypeFile id={object_type_id} value={type} /></div>
    </div>
  </div>
}

export default FileObject;