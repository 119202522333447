import React, { Component } from 'react';
import Datatable from 'Components/Datatable';
import Activity from 'Views/Contracts/Activity';
import Select from 'Components/Select';
import File from 'Components/File';
import Button from 'Components/Button';
import { FileObject } from 'Components';
import * as EventsActionsCreators from 'Ducks/events/operations';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import * as ContractsActionsCreators from 'Ducks/contracts/operations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import Router, { Link } from 'react-router-dom';
import { settings } from 'Utils/settings';
import { Input } from 'Components';
import EditButton from 'Components/EditButton';
import Badge from 'Components/Badge';
import InformeCreateForm from '../informes/InformeCreateForm';


const TypeFile = ({ id }) => {
    if (!id) {
        return '';
    }
    let val = _.find(settings.TYPE_INFORME, (v, i) => v.id == id);
    return val ? val.name : 'N.D.';
}

class EditDeliverableProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deliverable: {}
        };

        const { dispatch } = props;

        this.eventsActionsCreators = bindActionCreators(EventsActionsCreators, dispatch);

        this.providersActionsCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

        this.contractsActionsCreators = bindActionCreators(ContractsActionsCreators, dispatch);
    }

    componentWillMount() {
        const { pathname, search, hash, state } = this.props.location;
        //this.setState({ ...state.deliverable });
        this.setState({ deliverable: { ...state.deliverable } });
    }

    componentDidMount() {
        const { deliverable } = this.state;
        this.providersActionsCreators.getInformes(deliverable.id);
    }

    render() {
        const { informes, deliverable, listFormInforme } = this.props;
    
        
        if (!informes || !deliverable || !listFormInforme) {
            return '';
        }
        
        const formInforme = _.find(listFormInforme, v => v.contract_deliverable_id === deliverable.id);

        //const { deliverable } = this.state;

        const columns = [
            {
                label: 'Fecha de creación',
                field: 'created_at',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Fecha de actualización',
                field: 'updated_at',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Estado',
                field: 'status_review',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Editar',
                field: 'edit',
                sort: 'asc',
                width: 150
            }
        ];


        let informesHtml = _.map(informes, (informe, k) => {
            let data = _.pick(informe, ['created_at', 'updated_at', 'status_review']);

            return <div key={k} className="kt-portlet">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            <div style={{ marginBottom: '.5rem' }}>
                                PRODUCTO <span className="kt-font-primary">N°{deliverable.order}</span>
                                    - CONTRATO <span className="kt-font-primary">{deliverable.contract.contract_number}</span>
                                    - PROVEEDOR <span className="kt-font-primary">{deliverable.provider.name}</span>
                            </div>

                            <div>

                                <span style={{ fontWeight: '500' }} >{informe?.created_at}</span>
                            </div>
                        </h3>
                    </div>
                </div>
                <div className="kt-form kt-form--fit kt-form--label-right">
                    <div className="kt-portlet__body">

                        <div style={{ marginBottom: '1rem' }} dangerouslySetInnerHTML={{ __html: informe?.summary }} />

                        <div className="form-group">
                            {_.map(informe.verifications, (v, i) => {
                                return v.object && <FileObject key={i} object={v.object} type={"ANEXO"} object_type_id={v.object_type_id} />
                            })}
                        </div>

                        {informe.reviews.length > 0 && <h5>Revisión del informe</h5>}
                        <div className="form-group">
                            {_.map(informe.reviews, (v, i) => {
                                return v.summary && <div key={i} className="card mb-1" >
                                    <div className="card-body">
                                        <div>
                                            <strong>{v.user?.name}</strong> {<Badge label={settings.STATUS_INFORME[v.status_review].label} name={settings.STATUS_INFORME[v.status_review].name} />}
                                        </div>
                                        <div style={{ marginBottom: '.5rem' }}><small>{informe?.created_at}</small></div>

                                        <div style={{ marginBottom: '.5rem' }} dangerouslySetInnerHTML={{ __html: v?.summary }} />

                                        {v.document_review && <FileObject object={v.document_review} type={"Documento adjunto"} />}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>

        });


        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <h3 className="kt-widget1__title_">
                    CONTRATO <span className="kt-font-primary">{deliverable.contract.contract_number}</span>
                                    &nbsp; PROVEEDOR <span className="kt-font-primary">{deliverable.provider.name}</span>
                </h3>
                <div >
                    <FileObject object={deliverable.contract.document_contract} type="CONTRATO / OS" />
                    <FileObject object={deliverable.contract.document_tdr} type="TDR / ET" />
                </div>

                <div className="kt-portlet">
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div className="row row-no-padding row-col-separator-xl">
                            <div className="col-md-12">
                                <div className={`kt-widget1 card-custom-${deliverable.status_review}`} style={{ paddingBottom: '1rem', paddingTop: '1rem' }} >
                                    <div className="kt-widget1__item">
                                        <div className="kt-widget1__info">
                                            <h3 className="kt-widget1__title_" style={{ verticalAlign: 'middle' }}>
                                                PRODUCTO <span className="kt-font-primary">N°{deliverable.order}</span> <Badge label={settings.STATUS_INFORME[deliverable.status_review].label} name={settings.STATUS_INFORME[deliverable.status_review].name} />
                                            </h3>
                                            {deliverable.description && <div style={{ marginBottom: '.5rem' }}>
                                                {deliverable.description}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-lg-12">
                        {(deliverable.status_review == 1 || deliverable.status_review == 4) && <InformeCreateForm deliverable={deliverable} formInforme={formInforme} maxFiles={20} onChange={(formInforme) => {                           
                            this.providersActionsCreators.saveFormInforme({formInforme});
                        }} onSuccess={(response) => {
                            this.providersActionsCreators.removeFormInforme({formInforme});
                            setTimeout(() => {
                                this.props.history.push('/provider/deliverables');
                            }, 1000);
                            /*
                            setTimeout(() => {
                                this.providersActionsCreators.removeFormInforme({formInforme});
                            }, 300);  
                            this.providersActionsCreators.getInformes(deliverable.id);           
                            */                                                                                     
                        }} />}
                    </div>

                    <div className="col-lg-12">
                        {informesHtml}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    informes: state.providers.informes,
    deliverable: state.providers.deliverable,
    listFormInforme: state.providers.listFormInforme,
    api: state.api
});

export default connect(mapStateToProps, null)(EditDeliverableProvider);