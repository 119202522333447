import React, { Component } from 'react';
import Datatable from 'Components/Datatable';
import Badge from 'Components/Badge';
import * as InstitutionsActionsCreators from 'Ducks/institutions/operations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import EditButton from 'Components/EditButton';

class Institutions extends Component
{
    constructor(props)
    {
        super(props);

        const { dispatch } = props;

		this.institutionsActionsCreators = bindActionCreators(InstitutionsActionsCreators, dispatch);
    }

    componentDidMount()
    {
        this.institutionsActionsCreators.getInstitutions();
    }

    render()
    {
        const { institutions } = this.props; 

        const columns = [
            {
                label: 'id',
                field: 'id',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Nombre',
                field: 'name',
                sort: 'asc',
                width: 400
            },
            {
                label: 'Editar',
                field: 'edit',
                sort: 'asc',
                width: 100
            }
          ];

          let rows = _.map(institutions, institution => {
            let data = _.pick(institution, ['id', 'name']);

            return _.assign(data, { edit : <Link to={{
                pathname:'/edit-institution',
                search:`?id=${institution.id}`,
                //hash:'#hash',
                state: { institution }
                    }}><EditButton /></Link>});
          });

        return(
            <div class="kt-portlet kt-portlet--mobile">
	            <div class="kt-portlet__head kt-portlet__head--lg">
                <div class="kt-portlet__head-label">
                    <span class="kt-portlet__head-icon">
                        <i class="kt-font-success flaticon2-line-chart"></i>
                    </span>
                    <h3 class="kt-portlet__head-title">
                        Instituciones
                    </h3>
                </div>
		        <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
                <div class="kt-portlet__head-actions">
                            <div class="dropdown dropdown-inline">
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul class="kt-nav">
                                        <li class="kt-nav__section kt-nav__section--first">
                                            <span class="kt-nav__section-text">Choose an option</span>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-print"></i>
                                                <span class="kt-nav__link-text">Print</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-copy"></i>
                                                <span class="kt-nav__link-text">Copy</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-file-excel-o"></i>
                                                <span class="kt-nav__link-text">Excel</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-file-text-o"></i>
                                                <span class="kt-nav__link-text">CSV</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-file-pdf-o"></i>
                                                <span class="kt-nav__link-text">PDF</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                    &nbsp;
                    <Link to="/new-product">
                        <a href="javascript:void(0)" class="btn btn-success btn-elevate btn-icon-sm">
                            <i class="la la-plus"></i>Nueva Institución
                        </a>
                    </Link>
                </div>	
            </div>		
        </div>
	</div>
                <div class="kt-portlet__body">
                    <Datatable columns={columns} rows={rows} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    institutions : state.institutions.institutions
});

export default connect(mapStateToProps, null)(Institutions);