import reducer from './reducers';
import * as institutionsOperations from './operations';
import api from './api';
import * as institutionsMiddleware from './middleware';
import * as institutionsSelectors from "./selectors";
import * as institutionsUtils from "./utils";
import * as institutionsAsyncComponent from './asyncComponent';

export {
    institutionsOperations,
    api,
    institutionsMiddleware,
    institutionsSelectors,
    institutionsUtils,
    institutionsAsyncComponent
};

export default reducer;
