import React, { useState } from "react";
import PropTypes from "prop-types";

import Input from "../../components/Input";
import Button from "../../components/Button";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import { useSelector } from "react-redux";
import AgregarProveedor from "./AgregarProveedor";

const InformacionGeneral = ({
  order,
  contract_type_id,
  event_id,
  concept,
  provider_id,
  onHandleChange,
  onHandleChangeProviders,
  updateNewProvider,
  inputHasErrors
}) => {
  const [showModal, setShowModal] = useState(false);
  const providers = useSelector((state) => state.providers.providers);
  const events = useSelector((state) => state.events.events);

  const onAfterSubmitModal = (provider_id) => {
    updateNewProvider(provider_id);
    setShowModal(false);
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-3">
          <div className="form-group">
            <label>Tipo</label>
            <div>
              <label class="kt-option">
                <span class="kt-option__control">
                  <span
                    class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold"
                    checked=""
                  >
                    <input
                      type="radio"
                      name="contract_type_id"
                      value="1"
                      checked={contract_type_id == '1'}
                      onClick={onHandleChange}
                    />
                    <span></span>
                  </span>
                </span>
                <span class="kt-option__label">
                  <span class="kt-option__head">
                    <span class="kt-option__title">Contrato</span>
                  </span>
                </span>
              </label>
              <label class="kt-option">
                <span class="kt-option__control">
                  <span
                    class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold"
                    checked=""
                  >
                    <input
                      type="radio"
                      name="contract_type_id"
                      value="2"
                      checked={contract_type_id == '2'}
                      onClick={onHandleChange}
                    />
                    <span></span>
                  </span>
                </span>
                <span class="kt-option__label">
                  <span class="kt-option__head">
                    <span class="kt-option__title">Orden de Servicio</span>
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="form-group">
            <label>Concepto</label>
            <textarea
              name="concept"
              className={`form-control ${
                inputHasErrors("concept") ? "is-invalid" : ""
              }`}
              data-provide="concept"
              rows="6"
              value={concept}
              onChange={onHandleChange}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3">
          <div className="form-group">
            <label>Nro Orden / Contrato</label>
            <Input
              hasErrors={inputHasErrors('order')}
              name="order"
              type="number"
              value={order}
              onChange={onHandleChange}
            />
          </div>
        </div>
        <div className="col-lg-9">
          <div className="form-group">
            <label>Proveedor</label>
            <div className="d-flex">
              <ReactSelect
                hasErrors={inputHasErrors("provider_id")}
                value={provider_id}
                options={providers.map((provider) => ({
                  value: provider.id,
                  label: provider.name,
                }))}
                onChange={onHandleChangeProviders}
                classStyles={{ "w-100": true }}
                placeholder="Seleccione un proveedor"
              />
              <Button
                classStyles={{ "btn-default": true, "ml-2": true }}
                name="Agregar"
                onClick={() => setShowModal(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <label>Evento</label>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                gridGap: "10px 20px",
              }}
            >
              {events.map((event) => {
                return (
                  <div key={event.id}>
                    <label class="kt-option">
                      <span class="kt-option__control">
                        <span
                          class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold"
                          checked=""
                        >
                          <input
                            type="radio"
                            name="event_id"
                            value={event.id}
                            checked={event.id === +event_id}
                            onClick={onHandleChange}
                          />
                          <span></span>
                        </span>
                      </span>
                      <span class="kt-option__label">
                        <span class="kt-option__head">
                          <span class="kt-option__title">{event.name}</span>
                        </span>
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <AgregarProveedor
        show={showModal}
        onCloseModal={() => setShowModal(false)}   
        onAfterSubmit={onAfterSubmitModal}     
      />
    </div>
  );
};

InformacionGeneral.propTypes = {};

export default InformacionGeneral;
