import React, { Component, useState } from 'react';
import 'react-notifications/lib/notifications.css';
import _ from 'lodash';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';


class ListCheckedFact extends Component {
	
	constructor(props){
		super(props)  // onClose, title, open, options, checkeds, keyoption, keychecked, block
		const { keyoption, keychecked } = props;
		this.state = {
			listoptions: [],
			primerykey: keyoption?keyoption:'id',
			foreingkey: keychecked?keychecked:'id',
			isFormAdd: false,
			isFormEdit: false,
		}	
	}

	componentWillReceiveProps(nextProps) {
		//this.setState({});
		if(nextProps.open === true){
			this.initOptions()
		}		
    }
    
	renderItem = ({data}) => {		
		if(data){
			return this.props.item(data)
		}
	}

	renderItem = ({data}) => {		
		if(data){
			return this.props.renderItem(data)
		}
	}

	initOptions = () => {
		const {options, checkeds} = this.props

		console.log("ListCheckedFact::initOptions 1 ", checkeds);
		

		if(Array.isArray(options)){
			let listTemp = []

			options.map((item, index) => {	
				let itemList = {
					index: index,
					data: item,
					selected: false,
					block: false
				}		
				if(Array.isArray(checkeds)){
					const {primerykey, foreingkey} = this.state
					const {block} = this.props
					console.log("INEX", checkeds.findIndex(val => val[primerykey] == item[foreingkey]))
					if(checkeds.findIndex(val => val[primerykey] == item[foreingkey]) != -1){
						itemList.block = block
						itemList.selected = true
					}
				}	
				listTemp.push(itemList)		
			})

			
			this.setState(_.assign(this.state, { listoptions: listTemp }))

			console.log("ListCheckedFact::initOptions", this.props, this.state, listTemp);
		}
	}
	
	handleToggle = (index) => {
        console.log(index)
		let {listoptions} = this.state
		if(Array.isArray(listoptions)){			
			if(listoptions[index] && listoptions[index].block !== true){
				listoptions[index].selected = !listoptions[index].selected
				this.setState(_.assign(this.state.listoptions, listoptions))
			}
		}		
	}

	handleIsChecked = (index) => {
		return this.state.listoptions[index] && this.state.listoptions[index].selected === true
	}

	handleAdd = () => {
		if(this.props.onAdd) {
			this.props.onAdd()
		}
	}


	handleClose = (title) => {
		const {onClose} = this.props
		if(onClose){			
	  		onClose(title);
		}
	}

	handleCancel = () => {
		if(this.props.onCancel) {
			const result = this.state.listoptions.filter(item => item.selected)
			this.props.onCancel(result.map(item => item.data))
		}
		this.handleClose()
	}

	handleConfirm = () => {
		if(this.props.onConfirm) {
			const result = this.state.listoptions.filter(item => item.selected && item.block !== true)
			this.props.onConfirm(result.map(item => item.data))
		}
		this.handleClose()
	}
	
	handleStyleItem = (item) => {
		if(item.block === true)
			return {opacity: 0.5}
		return {}
	}

	render(){
		const { title, open, onAdd} = this.props;
		return (
			<Dialog onClose={this.handleClose} 
			  aria-labelledby="alert-dialog-title"
			  aria-describedby="alert-dialog-description" 
			  open={open}
			>
			  <DialogTitle id="simple-dialog-title" onClose={this.handleClose} >{title}</DialogTitle>
			  <DialogContent dividers style={{padding:0}} >
				  <List  component="div" disablePadding >
						{_.map(this.state.listoptions, (item, index) => (
							item.block !== true && <ListItem key={index} button onClick={(e) => this.handleToggle(index)} style={this.handleStyleItem(item)} >
								<ListItemIcon>
									<Checkbox
										//edge="end"
										//onChange={(e) => this.handleToggle(index)}
										checked={this.handleIsChecked(index)}									
									/>
								</ListItemIcon>
								{this.renderItem(item)}
								
							</ListItem>
						))}
						{onAdd && <ListItem key={'000'} button onClick={this.handleAdd}  >
							<ListItemIcon>
								<AddIcon />
							</ListItemIcon>
							<ListItemText primary={'NUEVO ITEM'} />
						</ListItem>}
				  </List>				
			  </DialogContent>
			  	<MuiDialogActions>			
					<Button onClick={this.handleCancel} color="primary">
						SALIR
					</Button>
					<Button onClick={this.handleConfirm} color="primary" autoFocus>
						ACEPTAR
					</Button>					
				</MuiDialogActions>
			</Dialog>
	  )
	}
}


export default ListCheckedFact
