import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { HorizontalBar, Pie, Doughnut, Bar } from "react-chartjs-2";
import "./newdashboard.css";
import * as SupervisorsActionsCreators from "Ducks/supervisors/operations";
import Portlet from "../../components/Portlet/portlet";
import Divider from "../../components/Divider";
import Loader from "../../components/Loader";

class NewDashboard extends Component {
  colors = ["#FD397A", "#ffb822", "#0ABB87"];

  state = { isLoaded: false };

  constructor(props) {
    super(props);

    const { dispatch } = this.props;

    this.supervisorsActionsCreators = bindActionCreators(
      SupervisorsActionsCreators,
      dispatch
    );
  }

  componentDidMount() {
    this.supervisorsActionsCreators.getIndicadores();
    this.supervisorsActionsCreators.getActividadesRecientes();
    this.supervisorsActionsCreators.getIndicadoresPorConvocatoria();
    this.supervisorsActionsCreators.getEstadosEntregables();
    this.supervisorsActionsCreators.getProgresoContratos();
    this.supervisorsActionsCreators.getConsultoresSexo();
    this.supervisorsActionsCreators.getTopIdiomas();
    this.supervisorsActionsCreators.getTopEspecialidades();
    this.supervisorsActionsCreators.getProgresoCv();
    this.supervisorsActionsCreators.getConsultoresGradoAcademico();
    this.supervisorsActionsCreators.getContratosResponsable();
    setTimeout(() => {
      this.setState({ isLoaded: true });
    }, 2000);
  }

  indicadores() {
    const { indicadores } = this.props;

    return indicadores.map((indicador, index) => {
      return (
        <div class="kt-widget17__item" key={index}>
          <div className="d-flex align-items-center flex-wrap">
            <span>
              <i
                className={`fa fa-2x fa-${indicador.icon} ${indicador.color}`}
              ></i>
            </span>
            <h4 className="ml-3 m-0">{indicador.total}</h4>
          </div>
          <span class="kt-widget17__subtitle">{indicador.name}</span>
          <span class="kt-widget17__desc">
            {indicador.totalThisWeek} nuevos esta semana
          </span>
        </div>
      );
    });
  }

  actividadesRecientes() {
    const { actividadesRecientes } = this.props;

    return actividadesRecientes.map((actividad, index) => {
      return (
        <div key={index} className="actividades-recientes__item mt-3">
          <div className="actividades-recientes__imagen">
            <img
              className="kt-widget3__img"
              src="/assets/img/default.jpg"
              alt=""
            />
          </div>
          <div className="actividades-recientes__nombre ml-3">
            <h6>{actividad.name}</h6>
          </div>
          <div className="actividades-recientes__fecha">
            <p>
              <em>{actividad.date}</em>
            </p>
          </div>
        </div>
      );
    });
  }

  indicadoresPorConvocatoria() {
    const { indicadoresPorConvocatoria } = this.props;

    const {
      convocatorias,
      contratos,
      entregables,
      proveedores,
    } = indicadoresPorConvocatoria;

    let data = {
      labels: convocatorias,
      datasets: [
        {
          label: "Contratos",
          data: contratos,
          backgroundColor: "#5867DD",
        },
        {
          label: "Entregables",
          data: entregables,
          backgroundColor: "#0ABB87",
        },
        {
          label: "Proveedores",
          data: proveedores,
          backgroundColor: "#FD397A",
        },
      ],
    };

    return (
      <HorizontalBar
        data={data}
        width={100}
        height={50}
        options={{
          scales: {
            xAxes: [
              {
                stacked: true,
                ticks: {
                  fontFamily: "Poppins",
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  fontSize: 12,
                  fontFamily: "Poppins",
                  callback: function (label) {
                    if (/\s/.test(label)) {
                      return label.match(/.{1,70}/g);
                    } else {
                      return label;
                    }
                  },
                },
              },
            ],
          },
          legend: {
            position: "bottom",
            labels: {
              fontFamily: "Poppins",
            },
          },
        }}
      />
    );
  }

  estadosEntregables() {
    const { estadosEntregables } = this.props;

    const { estados, cantidad } = estadosEntregables;

    const data = {
      labels: estados,
      datasets: [
        {
          data: cantidad,
          backgroundColor: ["#0ABB87", "#ffb822", "#5867DD", "#FD397A"],
        },
      ],
    };

    return (
      <Pie
        width={100}
        height={70}
        data={data}
        options={{
          legend: {
            position: "bottom",
            margin: 0,
            labels: {
              fontFamily: "Poppins",
            },
          },
        }}
      />
    );
  }

  progresoContratos() {
    const { progresoContratos } = this.props;

    return (
      <div className="contratos">
        {Object.values(progresoContratos).map((progresoContrato, index) => {
          return (
            <div className="contrato" key={index}>
              <div className="contrato-detail">
                {index > 0 ? (
                  <div class="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                ) : (
                  ""
                )}
                <p className="kt-font-bold">{progresoContrato.contractName}</p>
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column">
                    <small>Entregable pendiente</small>
                    <div class="kt-widget__label mt-1">
                      <span class="btn btn-label-primary btn-sm btn-bold btn-upper">
                        {progresoContrato.deliverableName}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <small>Fecha de vencimiento:</small>
                    <span>
                      <div class="kt-widget__label mt-1">
                        <span class="btn btn-label-danger btn-sm btn-bold btn-upper">
                          {progresoContrato.deliveryDate}
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="mt-4">
                  <small>Progreso Total:</small>
                  <div class="contrato-progreso progress progress-lg w-100 kt-font-bold">
                    <div
                      class="progress-bar kt-bg-warning font-weight-normal"
                      role="progressbar"
                      style={{ width: `${progresoContrato.progress}%` }}
                      aria-valuenow={progresoContrato.progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {progresoContrato.progress}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  consultoresSexo() {
    const { consultoresSexo } = this.props;

    const { genero, cantidad } = consultoresSexo;

    const data = {
      labels: genero,
      datasets: [
        {
          data: cantidad,
          backgroundColor: ["#5867DD", "#FD397A"],
        },
      ],
    };

    return (
      <Doughnut
        width={400}
        height={300}
        data={data}
        options={{
          legend: {
            position: "bottom",
            margin: 0,
            labels: {
              fontFamily: "Poppins",
            },
          },
        }}
      />
    );
  }

  topIdiomas() {
    const { topIdiomas } = this.props;

    return Object.values(topIdiomas).map((topIdioma, key) => {
      return (
        <div
          key={key}
          className="text-white p-5 mb-2 font-weight-bolder text-center"
          style={{ backgroundColor: this.colors[key] }}
        >
          <h4>{topIdioma.name}</h4>
          <h3>{topIdioma.count}</h3>
        </div>
      );
    });
  }

  topEspecialidades() {
    const { topEspecialidades } = this.props;

    return Object.values(topEspecialidades).map((topEspecialidad, key) => {
      return (
        <div
          key={key}
          className="text-white p-5 mb-2 font-weight-bolder text-center"
          style={{ backgroundColor: this.colors[key] }}
        >
          <h4>{topEspecialidad.name}</h4>
          <h3>{topEspecialidad.count}</h3>
        </div>
      );
    });
  }

  progresoCV() {
    const { progresoCv } = this.props;

    const { rangos, cantidad } = progresoCv;

    let data = {
      labels: rangos,
      datasets: [
        {
          label: "Cantidad",
          data: cantidad,
          backgroundColor: "#0ABB87",
        },
      ],
    };

    return (
      <Bar
        width={100}
        height={100}
        data={data}
        options={{
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontSize: 12,
                  fontFamily: "Poppins",
                  callback: function (label) {
                    if (/\s/.test(label)) {
                      return label.match(/.{1,70}/g);
                    } else {
                      return label;
                    }
                  },
                },
              },
            ],
            yAxes: [
              {
                display: false,
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        }}
      />
    );
  }

  consultoresGradoAcademico() {
    const { consultoresGradoAcademico } = this.props;

    const { grades, total } = consultoresGradoAcademico;

    let data = {
      labels: grades,
      datasets: [
        {
          label: "Cantidad",
          data: total,
          backgroundColor: "#5867DD",
        },
      ],
    };

    let options = {
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontSize: 12,
              fontFamily: "Poppins",
              callback: function (label) {
                if (/\s/.test(label)) {
                  return label.match(/.{1,70}/g);
                } else {
                  return label;
                }
              },
            },
          },
        ],
      },
    };

    return (
      <HorizontalBar width={80} height={50} data={data} options={options} />
    );
  }

  contratosResponsable() {
    const { contratosResponsable } = this.props;

    const { responsable, total } = contratosResponsable;

    let data = {
      labels: responsable,
      datasets: [
        {
          label: "Cantidad",
          data: total,
          backgroundColor: "#5867DD",
        },
      ],
    };

    let options = {
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontSize: 12,
              fontFamily: "Poppins",
              callback: function (label) {
                if (/\s/.test(label)) {
                  return label.match(/.{1,70}/g);
                } else {
                  return label;
                }
              },
            },
          },
        ],
      },
    };

    return (
      <HorizontalBar width={50} height={30} data={data} options={options} />
    );
  }

  render() {
    return (
      <div>
        {this.state.isLoaded ? (
          <div>
            <h4>Dashboard</h4>
            <div className="b1 flex-lg-row">
              <div className="c1">
                <div className="indicadores">
                  <div className="kt-widget17">
                    <div className="kt-widget17__stats">
                      <div className="kt-widget17__items d-flex flex-column justify-content-sm-around flex-sm-row flex-sm-wrap">
                        {this.indicadores()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detalle-convocatoria">
                  <Portlet
                    title="Indicadores por Convocatoria"
                    bodyContent={this.indicadoresPorConvocatoria()}
                  />
                </div>
              </div>
              <div className="c2">
                <div className="actividad-reciente">
                  <Portlet
                    title="Actividad Reciente"
                    bodyContent={
                      <div className="actividades-recientes">
                        {this.actividadesRecientes()}
                      </div>
                    }
                  />
                </div>
                <div className="estado-entregables">
                  <Portlet
                    title="Estado de los Entregables"
                    bodyContent={this.estadosEntregables()}
                  />
                </div>
              </div>
            </div>
            <div className="b2 flex-lg-row">
              <div className="c3 flex-lg-row">
                <div className="contrato-avance">
                  <Portlet
                    title="Progreso por Contrato"
                    bodyContent={this.progresoContratos()}
                  />
                </div>
                <div className="c3__c2">
                  <div className="consultores-sexo">
                    <Portlet
                      title="Consultores - Sexo"
                      bodyContent={this.consultoresSexo()}
                    />
                  </div>
                  <div className="progreso-cv">
                    <Portlet
                      title="Progreso - CV"
                      bodyContent={this.progresoCV()}
                    />
                  </div>
                </div>
              </div>
              <div className="c4">
                <div className="top-idiomas-card">
                  <Portlet
                    title="Top 3 Idiomas"
                    bodyContent={this.topIdiomas()}
                  />
                </div>
                <div className="top-especialidad-card">
                  <Portlet
                    title="Top 3 Especialidad"
                    bodyContent={this.topEspecialidades()}
                  />
                </div>
              </div>
            </div>
            <div className="b3 flex-lg-row">
              <div className="consultores-grado">
                <Portlet
                  title="Consultores por Grado Académico"
                  bodyContent={this.consultoresGradoAcademico()}
                />
              </div>
              <div className="contrato-responsable">
                <Portlet
                  title="Contratos por Responsable"
                  bodyContent={this.contratosResponsable()}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <h3>Cargando</h3>
            <div className="ml-4">
              <Loader></Loader>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  indicadores: state.supervisors.dashboard.indicadores,
  actividadesRecientes: state.supervisors.dashboard.actividadesRecientes,
  indicadoresPorConvocatoria:
    state.supervisors.dashboard.indicadoresPorConvocatoria,
  estadosEntregables: state.supervisors.dashboard.estadosEntregables,
  progresoContratos: state.supervisors.dashboard.progresoContratos,
  consultoresSexo: state.supervisors.dashboard.consultoresSexo,
  topIdiomas: state.supervisors.dashboard.topIdiomas,
  topEspecialidades: state.supervisors.dashboard.topEspecialidades,
  progresoCv: state.supervisors.dashboard.progresoCv,
  consultoresGradoAcademico:
    state.supervisors.dashboard.consultoresGradoAcademico,
  contratosResponsable: state.supervisors.dashboard.contratosResponsable,
});

export default connect(mapStateToProps, null)(NewDashboard);
