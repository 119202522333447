import React, { useState, useEffect } from 'react';
import { Button, Input, CustomButton, Checkbox, Textarea } from 'Components';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import _ from 'lodash';
import moment from 'moment';
import { scrollTo } from 'Utils/animateScrollTo';

const Profiles = ({ cv_profiles, cv_experiences }) => {
    const dispatch = useDispatch();

    const [profiles, setProfiles] = useState([]);

    useEffect(() => {
        getProfiles();
        
    }, [cv_profiles]);

    const getProfiles = async () =>
    {
        let profiles = await dispatch(ProvidersActionsCreators.getProfiles());

        setProfiles(profiles);
    }


    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <img src="/assets/img/red-de-consultores.jpg" />
                        <h3 className="kt-section__content" style={{ width : '80%', margin : '1.5em auto 0' }}>
                            El programa para la mejora de la calidad y pertinencia de los servicios de Educación Superior Universitaria y Tecnología a nivel nacional – PMESUT, te invita a formar parte de nuestra red de consultores y evaluadores.
                        </h3>
                    </div>
                </div>
            </div>
            <div className="kt-separator kt-separator--space-md kt-separator--border-dashed"></div>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    {
                        _.map(profiles, (profile, index) => <Profile key={index} profile={profile} cv_experiences={cv_experiences} cv_profiles={cv_profiles} />)
                    }
                </div>
            </div>
        </>
    )
}

const Profile = ({ profile, cv_experiences, cv_profiles }) =>
{
    const dispatch = useDispatch();

    console.log("PROFILE profile: ", profile);
    console.log("PROFILE cv_experiences: ", cv_experiences);
    console.log("PROFILE cv_profiles: ", cv_profiles);

    const [specialities, setSpeciality] = useState([]);
    const [tab, setTab] = useState('');
    
    /*useEffect(() => {
        setEnabled();
        
    }, [cv_profiles]);

    const setEnabled = () =>
    {
        _.map(cv_profiles, prof => {
            let value = _.filter(profile.specialities, spec => spec.id == prof.speciality_id);

            console.log("VALUE PROFILE: ", value);
            console.log("VALUE PROFILE ID: ", value.length  ? _.toString(_.head(value).id) : 'NAA');

            value.length ? onAddSpeciality(_.toString(_.head(value).id)) : '';
        })
    }*/

    const onAddSpeciality = (e) =>
    {
        let values = _.includes(specialities, e) ? _.filter(specialities, speciality => speciality.id != e) : specialities;

        //REMOVE
        if(_.includes(specialities, e)) 
        {
            let values = _.filter(specialities, speciality => speciality != e);
            setSpeciality(values);
        }
        else //ADD
        {
            setSpeciality([...values, e]);
        }

        setTab(e);
    };

    return (
        <div className="col-lg-4">
            <div className="kt-portlet">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <span className="kt-portlet__head-icon">
                            <i className="flaticon2-calendar-2"></i>
                        </span>
                        <h3 className="kt-portlet__head-title" style={{ padding : '1em' }}>
                            { profile.name } - {JSON.stringify(specialities)}
                        </h3>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    { profile.description }

                    
                    <div className="kt-separator kt-separator--space-md kt-separator--border-dashed"></div>

                    <div className="kt-widget2">

                        { _.map(profile.specialities, (speciality, index) => <Speciality key={index} speciality={speciality} onAddSpeciality={onAddSpeciality} cv_profiles={cv_profiles} />) }
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-xl-12">
                            <div className="kt-portlet kt-portlet--tabs">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-toolbar">
                                        <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand" role="tablist">
                                            { _.map(specialities, speciality => {
                                                return (<li className="nav-item">
                                                    <a onClick={() => setTab(speciality)} class={`nav-link ${tab === speciality ? 'active' : ''}`}  data-toggle="tab" href={`#${speciality}`} role="tab">
                                                        <i className="flaticon2-calendar-3"></i> {  _.head(_.filter(profile.specialities, spec => spec.id == speciality)) ? _.head(_.filter(profile.specialities, spec => spec.id == speciality)).name : '' }
                                                    </a>
                                                </li>)
                                            }) }
                                        </ul>
                                    </div>
                                </div>
                                <div className="kt-portlet__body">
                                    <div className="tab-content kt-margin-t-10">
                                        { _.map(specialities, speciality => {
                                            return (
                                                <div class={`tab-pane ${tab === speciality ? 'active' : ''}`} id={speciality} role="tabpanel">
                                                    <Title speciality={speciality} cv_experiences={cv_experiences} />
                                                </div>
                                            )
                                        }) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>	
                </div>
            </div>
        </div>
    )
};

const Title = ({ cv_experiences, speciality }) => 
{
    const dispatch = useDispatch();

    const [cvExperiences, setCvExperiences] = useState(cv_experiences);
    const [selected, setSelected] = useState('');
    const [description, setDescription] = useState('');

    console.log("SPECIALITY: ", speciality);

    const onSubmit = async (e) =>
    {
        e.preventDefault();

        console.log("SPECIALTY: ", speciality);
        console.log("EXPERIENCE: ", selected);
        console.log("DESCIRPTION: ", description);
        await dispatch(ProvidersActionsCreators.setProfiles({
            "speciality_id": speciality,
            "cv_experience_id": selected,
            "description": description
        }));
        
    }

    return (
        <>
            <form method="POST" onSubmit={onSubmit}>
                <div className="form-group">
                    <label className="col-xl-3 col-lg-3 col-form-label">Cargo</label>
                    <select 
                        className="form-control kt-selectpicker" 
                        required={true}
                        onChange={(e) => setSelected(e.target.value)} >
                        <option value="">Seleccionar una experiencia..</option>
                        { _.map(cvExperiences, experience => <option value={experience.id}>{ experience.company } - { experience.position }</option>) }
                    </select>
                </div>
                {selected &&
                    <div className="form-group">
                        <label className="col-xl-3 col-lg-3 col-form-label">Descripción...</label>
                        <Textarea 
                            classStyles={ {} } 
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            processHtml={false}
                        /> 
                    </div>
                }
                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                <div className="row">
                    <div className="col text-center">
                        <Button 
                            classStyles={ { 'btn' : true, 'btn-success' : true } }
                            type="submit"
                            name="AÑADIR"
                        />
                    </div>
                </div>
            </form>
        </>
    )
};

const Speciality = ({ speciality, onAddSpeciality, cv_profiles }) =>
{
    console.log("CV_PROFILES: ", cv_profiles);
    console.log("SPECIALITY: ", speciality);
    
    return (
        <div className="kt-widget2__item kt-widget2__item--primary">
            {/*{ ! _.filter(cv_profiles, profile => profile.id == speciality.id).length && */}
            <Checkbox 
                value={speciality.id} 
                onChange={(e) => onAddSpeciality(e.target.value)}/>
            {/*}*/}
            <div className="kt-widget2__info">
                <a href="#" className="kt-widget2__title">
                    { speciality.name }
                </a>							 
            </div>
            <div className="kt-widget2__actions">
                { speciality.document_detail &&
                    <CustomButton 
                        classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-warning' : true,'btn-icon' : true }}
                        icon="fa fa-cloud-download-alt"
                        style={{ marginLeft : '1em' }}
                        href={speciality.document_detail._url}
                        target="_blank"
                        //onClick={() => console.log("CLICK CUSTOMBUTTOM")} 
                /   >
                }
            </div>
        </div>
    )
};

export default Profiles;