import React, {useEffect, useState, Component, useMemo, Fragment} from 'react';
import { useDropzone } from 'react-dropzone';
import { getIn } from "formik";
import sha256 from 'crypto-js/sha256';
import UploadFileTemp from "./ResumableUploadFile/UploadFileTemp";
import FileObject from "./ResumableUploadFile/FileObject";
import _ from "lodash";
import "Assets/css/style.css";
import UploadFile from '../UploadFile';

const ACCEPT = `.doc,.docx,.xml,.xls,.xlsx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.zip,.rar`;

function FileUpload(props) {

  const {placeholder="Adjuntar archivo", color='default', size=""} = props;
  const [files, setFiles] = useState([]);
  const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject
    } = useDropzone({
    accept: props.accept ? props.accept : ACCEPT,
    onDrop: acceptedFiles => {
      if(!props.isValidate)
      {
        alert("Elegir el tipo de archivo a subir..");

        return false;
      }

      //console.log("acceptedFiles", acceptedFiles);

      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

      /*
      acceptedFiles.map(file => {
        
        var reader = new FileReader();
        if (!file) {
            alert("no file selected");
            return;
        } 
        //check if user selected a file              
        reader.onload = function (f) {
            var file_result = this.result; // this == reader, get the loaded file "result"
            const hash = sha256(file_result);
            console.log(hash.toString(), file_result);
        };
        //reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
      });
      */

      props.onProcessFiles(acceptedFiles);
    },
    onDragOver: (data) => {
      console.log("ONDRAGOVER");
    },
    onDragLeave: () => {
      alert("ONDRAGLEAVE")
    }
  });

  const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive,
      isDragReject
    ]);
  
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
        {/*<img
          src={file.preview}
          style={img}
        />*/}
        <div class="kt-demo-icon__preview" style={{ width : '100%', textAlign : 'center' }}>
            <i style={{ fontSize : '4em' }} class="flaticon-upload"></i>
        </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const ref = React.createRef();

  const handlerUploadFileBtn = (e) => {


    var reader = new FileReader();
    var file = e.target.files[0]; //get the File object 
        if (!file) {
            alert("no file selected");
            return;
        } //check if user selected a file
      

    reader.onload = function (f) {
        var file_result = this.result; // this == reader, get the loaded file "result"
        const hash = sha256(file_result);
        console.log(hash.toString(), file_result);
    };
    //reader.readAsArrayBuffer(file);
    reader.readAsDataURL(file);

  };

  return (
    <section className="">
      <a className={`btn btn-${color} btn-${size}`} {...getRootProps({style})}>
        <div><i className="fas fa-paperclip"></i> {placeholder}</div>
        <input {...getInputProps()} />
      </a>

      {/*<input type={'file'} ref={ref}  onChange={handlerUploadFileBtn}/>
      <button type={'button'} className="FancyButton" onClick={() => {
        console.log(ref.current.click());
        
        }} >
        UPLOAD
      </button>*/}

    </section>
  );
}


const baseStyle = {
  /*flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#b3b3b3',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  fontWeight: '600',
  outline: 'none',
  transition: 'border .24s ease-in-out'*/
};

const activeStyle = {
borderColor: '#2196f3'
};

const acceptStyle = {
borderColor: '#00e676'
};

const rejectStyle = {
borderColor: '#ff1744'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};



class InputFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      filesUploaded: 0,
      maxFiles: 20,
    };

    this.files = [];
  }

  componentDidMount() {
    const { value, maxFiles } = this.props;
    if (value) {
      let filereader = [];
      _.each(value, (f) => {
        filereader.push({ file: null, fileServer: f });
      });
      this.setState({ files: filereader, maxFiles: maxFiles });
    }
  }

  handlerFileSuccess = (file, fileServer) => {
    let { files, filesUploaded } = this.state;
    const { objects, onChange } = this.props;

    let docs = [...objects];
    docs.push(fileServer);
    if (onChange) {
      onChange(docs);
    }

    this.setState({ filesUploaded: filesUploaded + 1 },
      () => {
        if (this.state.filesUploaded === files.length) {
          this.setState({ files: [], filesUploaded: 0 });
        }
      }
    );
  };

  processFile = (data) => {
    let { files } = this.state;
    _.each(data, (f) => {
      files.push({ file: f, fileServer: null });
    });
    this.setState({ files: files });
  };

  onRemoveFile = (index) => {
    let { files } = this.state;
    files.splice(index, 1);
    this.setState({ files: files });
  };

  handlerRemoveFile(i) {
    const { objects, onChange } = this.props;
    let docs = [...objects];
    docs.splice(i, 1);
    if (onChange) {
      onChange(docs);
    }
  }

  render() {
    const { maxFiles = 50, objects, name } = this.props;
    const { files } = this.state;

    if (!Array.isArray(objects)) {
      return <div>Ingresar Array de objetos</div>;
    }

    return (
      <Fragment>
        {objects.length < maxFiles && (
          <div className={"mb-2"}>
          <FileUpload
            isValidate={4}
            hideFiles={true}
            onProcessFiles={this.processFile}
            
          />
          </div>
        )}

        <div className="d-flex align-items-center">
          {_.map(objects, (o, i) => (
            <FileObject
              key={i}
              object={o}
              onRemoveFile={() => this.handlerRemoveFile(i)}
            />
          ))}
        </div>

        {files.length > 0 && <br />}
        {_.map(files, (f, i) => {
          return (
            <UploadFileTemp
              key={i}
              id={name}
              onFileSuccess={this.handlerFileSuccess}
              file={f?.file}
              onRemoveFile={() => {
                this.onRemoveFile(i);
              }}
            />
          );
        })}
      </Fragment>
    );
  }
}

export default InputFile;
