import React from 'react';
import classNames from 'classnames/bind';

const Button = ({
    classStyles,
    label="Label",
    type="button",
    loading=false,
    ...props
}) => {
    let styles = classNames('btn', { ...classStyles }, loading ? 'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light' : '');

    return (
        <React.Fragment>
            <button className={styles} {...props} type={type}>{ props.name }</button>
        </React.Fragment>
    );
};

export default Button;
