

import { default as HttpClient } from './HttpClient';

const  ApigprocProfile =  {

    profesional: () => {     
        return HttpClient.get('/profile/profesional');
    },

}

export default ApigprocProfile;


