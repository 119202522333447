import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import _ from "lodash";

import Input from "../../components/Input";
import Modal from "../../components/Modal/Modal";
import Radio from "../../components/Radio";
import Checkbox from "../../components/Checkbox";
import FormError from "../../Layout/errors/FormError";
import ErrorMessage from "../../Layout/errors/ErrorMessage";
import * as ProvidersActionsCreators from "Ducks/providers/operations";

const getProviderInitialState = () => ({
  name: "",
  ruc: "",
  giro_general: "",
  position: "",
  doc_identidad: "",
  mobilephone: "",
  email: "",
  address: "",
  flag_factura: false,
  flag_boleta: false,
  flag_recibo: false,
  flag_consorcio: false,
  flag_no_domiciliario: false,
  flag_discapacidad: false,
  type_person: "",
  errors: {},
});

const AgregarProveedor = ({
  name,
  ruc,
  giro_general,
  position,
  doc_identidad,
  mobilephone,
  email,
  address,
  type_person,
  flag_factura,
  flag_boleta,
  flag_recibo,
  flag_consorcio,
  flag_no_domiciliario,
  flag_discapacidad,
  show,
  onCloseModal,
  onAfterSubmit
}) => {

  const [provider, setProvider] = useState(getProviderInitialState);
  const dispatch = useDispatch();

  const onHandleChange = (e, checkbox = false) => {
    const { name, value } = e.target;

    if (checkbox) {
      setProvider({ ...provider, [name]: !provider[name] });
    } else {
      setProvider({ ...provider, [name]: value });
    }
    
  };
  
  const inputHasErrors = (name) => {
    const errors = provider.errors;
    return Object.keys(errors).includes(name);
  };

  const formHasErrors = () => {
    const errors = provider.errors;
    return Object.values(errors).length > 0;
  };

  const errorMessage = (name) => {
    if (!inputHasErrors(name)) return;
    const errors = provider.errors;
    return errors[name][0];
  };

  const onHandleSubmit = async () => {
    const response = await dispatch(
      ProvidersActionsCreators.createProvider(_.omit(provider, ["errors"]))
    );

    if (response.data.errors) {
      setProvider({ ...provider, errors: response.data.errors });
    } else {
      dispatch(ProvidersActionsCreators.getProviders(false));
      onAfterSubmit(response.data.id);
      setProvider(getProviderInitialState)  
    }
  };

  return (
    <Modal
      show={show}
      handleClose={onCloseModal}
      submitButtonMessage="Guardar"
      handleSubmit={onHandleSubmit}
      title="Agregar Proveedor"
    >
      <FormError show={formHasErrors()} errors={Object.values(provider.errors)} />
      <div class="row">
        <div class="col-lg-6">
          <div className="form-group">
            <label>*Nombre:</label>
            <Input
              hasErrors={inputHasErrors("name")}
              classStyles={{}}
              label="Nombre"
              placeholder="Nombre"
              type="text"
              name="name"
              value={name}
              onChange={onHandleChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>*RUC</label>
            <Input
              hasErrors={inputHasErrors("ruc")}
              classStyles={{}}
              label="RUC"
              placeholder="RUC"
              type="text"
              name="ruc"
              value={ruc}
              onChange={onHandleChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label>*Giro General</label>
            <Input
              hasErrors={inputHasErrors("giro_general")}
              classStyles={{}}
              label="Giro General"
              placeholder="Giro General"
              type="text"
              name="giro_general"
              value={giro_general}
              onChange={onHandleChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Posición</label>
            <Input
              hasErrors={inputHasErrors("position")}
              classStyles={{}}
              label="Posición"
              placeholder="Posición"
              type="text"
              name="position"
              value={position}
              onChange={onHandleChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label>Número de Documento</label>
            <Input
              hasErrors={inputHasErrors("doc_identidad")}
              classStyles={{}}
              label="Número de Documento"
              placeholder="Número de Documento"
              type="text"
              name="doc_identidad"
              value={doc_identidad}
              onChange={onHandleChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Celular</label>
            <Input
              hasErrors={inputHasErrors("mobilephone")}
              classStyles={{}}
              label="Celular"
              placeholder="Celular"
              type="text"
              name="mobilephone"
              value={mobilephone}
              onChange={onHandleChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label>Email</label>
            <Input
              hasErrors={inputHasErrors("email")}
              classStyles={{}}
              label="Email"
              placeholder="Email"
              type="text"
              name="email"
              value={email}
              onChange={onHandleChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Dirección</label>
            <Input
              hasErrors={inputHasErrors("address")}
              classStyles={{}}
              label="Dirección"
              placeholder="Dirección"
              type="text"
              name="address"
              value={address}
              onChange={onHandleChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div
          className={`form-group ${
            inputHasErrors("type_person") ? "is-invalid" : ""
          }`}
          >
            <label>*Tipo de Persona</label>
            <div className="kt-radio-inline">
              <Radio
                id="1"
                name="type_person"
                changed={onHandleChange}
                isSelected={type_person === "N"}
                label="Natural"
                value="N"
              />
              <Radio
                id="2"
                name="type_person"
                changed={onHandleChange}
                isSelected={type_person === "J"}
                label="Jurídica"
                value="J"
              />
            </div>
            {
              <ErrorMessage
                message={errorMessage("type_person")}
              />
            }
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Marcar</label>
            <div className="d-flex">
              <div className="kt-checkbox-inline">
                <Checkbox
                  label="Factura"
                  name="flag_factura"
                  onChange={(e) => onHandleChange(e, true)}
                  checked={flag_factura}
                />
                <Checkbox
                  label="Boleta"
                  name="flag_boleta"
                  onChange={(e) => onHandleChange(e, true)}
                  checked={flag_boleta}
                />
                <Checkbox
                  label="Recibo"
                  name="flag_recibo"
                  onChange={(e) => onHandleChange(e, true)}
                  checked={flag_recibo}
                />
              </div>
              <div
                className="kt-checkbox-inline"
                style={{ marginLeft: 5 + "rem" }}
              >
                <Checkbox
                  label="Consorcio"
                  name="flag_consorcio"
                  onChange={(e) => onHandleChange(e, true)}
                  checked={flag_consorcio}
                />
                <Checkbox
                  label="No Domiciliario"
                  name="flag_no_domiciliario"
                  onChange={(e) => onHandleChange(e, true)}
                  checked={flag_no_domiciliario}
                />
                <Checkbox
                  label="Discapacidad"
                  name="flag_discapacidad"
                  onChange={(e) => onHandleChange(e, true)}
                  checked={flag_discapacidad}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AgregarProveedor.propTypes = {
  show: PropTypes.bool
};

export default AgregarProveedor;
