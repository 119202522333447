

import { default as HttpClient } from './HttpClient';

const  ApigprocInformeReview =  {

    create: (data) => {     
        return HttpClient.post('proyects/1/contract_informe_reviews', data);
    },
}

export default ApigprocInformeReview;


