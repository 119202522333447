import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Input from "../../components/Input";
import File from "../../components/File";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import Radio from "../../components/Radio";
import { settings } from "../../../utils/settings";
import ErrorMessage from "../../Layout/errors/ErrorMessage";
import FileIcon from "../../components/FileIcon/FileIcon";

const Detalle = ({
  amount,
  signature_date,
  evaluators,
  funding_source,
  document_contract,
  document_tdr,
  onHandleChange,
  onHandleChangeEvaluators,
  onHandleChangeFile,
  inputHasErrors,
  errorMessageFormByInput,
}) => {
  const allEvaluators = useSelector((state) => state.supervisors.evaluators);
  const foundingSources = settings.FOUNDING_SOURCE;

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label>Monto</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">S/.</span>
              </div>
              <Input
                hasErrors={inputHasErrors("amount")}
                name="amount"
                type="number"
                value={amount}
                onChange={onHandleChange}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Fecha de Firma</label>
            <Input
              hasErrors={inputHasErrors("signature_date")}
              name="signature_date"
              type="date"
              value={signature_date}
              onChange={onHandleChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label>Repsonsables</label>
            <ReactSelect
              hasErrors={inputHasErrors("evaluators")}
              value={evaluators}
              isMulti
              options={allEvaluators.map((evaluator) => ({
                label: evaluator.name,
                value: evaluator.id,
              }))}
              onChange={onHandleChangeEvaluators}
              placeholder="Seleccione los responsables"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div
            className={`form-group ${
              inputHasErrors("funding_source") ? "is-invalid" : ""
            }`}
          >
            <label>Tipo de Financimiento</label>
            <div className="kt-radio-inline mt-2">
              {foundingSources.map((foundingSource, index) => {
                return (
                  <Radio
                    hasErrors={inputHasErrors("funding_source")}
                    key={index}
                    id={index}
                    name="funding_source"
                    changed={onHandleChange}
                    isSelected={+funding_source === foundingSource.id}
                    label={foundingSource.name}
                    value={foundingSource.id}
                  />
                );
              })}
            </div>
            {
              <ErrorMessage
                message={errorMessageFormByInput("funding_source")}
              />
            }
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <label>Archivos</label>
            <div className="d-flex">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    {document_tdr ? (
                      <FileIcon href={document_tdr._url} text="TDR" icon="pdf" />
                    ) : (
                      "TDR"
                    )}
                  </span>
                </div>
                <File
                  hasErrors={inputHasErrors("_filename_tdr")}
                  name="_filename_tdr"
                  onChange={onHandleChangeFile}
                />
              </div>
              <div class="input-group ml-4">
                <div class="input-group-prepend">
                  <span class="input-group-text">{document_contract ? (
                      <FileIcon href={document_contract._url} text="Contrato" icon="pdf" />
                    ) : (
                      "Contrato"
                    )}</span>
                </div>
                <File
                  hasErrors={inputHasErrors("_filename_contract")}
                  name="_filename_contract"
                  onChange={onHandleChangeFile}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Detalle.propTypes = {};

export default Detalle;
