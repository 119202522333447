import React from 'react';
import classNames from 'classnames/bind';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Textarea = ({
    classStyles,
    processHtml = false,
    ...props
}) => {
    let styles = classNames({
        'form-control': true,
        ...classStyles
    });

    if(processHtml)
    {
        return (
            <CKEditor
                editor={ ClassicEditor }
                data={ props.value }
                {...props}
            />
        );
    }
    else
    {
        return (
            <React.Fragment>
                <textarea 
                    className={styles}
                    rows="6" 
                    {...props}>
                    { props.value }
                </textarea>
            </React.Fragment>
        );
    }
};

export default Textarea;
