import reducer from './reducers';
import * as productsOperations from './operations';
import api from './api';
import * as productsMiddleware from './middleware';
import * as productsSelectors from "./selectors";
import * as productsUtils from "./utils";
import * as productsAsyncComponent from './asyncComponent';

export {
    productsOperations,
    api,
    productsMiddleware,
    productsSelectors,
    productsUtils,
    productsAsyncComponent
};

export default reducer;
