import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Input from "../components/Input";
import Select from "../components/Select";
import { settings } from "../../utils/settings";
import Button from "../components/Button";
import { createUser } from "../../ducks/users/actions";
import FormError from "../Layout/errors/FormError";
import Checkbox from "../components/Checkbox";
import { property } from "lodash";
import Switch from "../components/Switch";

const NewUser = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    role_id: "",
    document_type_id: "",
    document_number: "",
    is_consultor: false,
  });

  const [errors, setErrors] = useState({});

  const formHasErrors = useMemo(() => Object.values(errors).length > 0, [
    errors,
  ]);

  const api = useSelector((state) => state.api);

  const dispatch = useDispatch();

  const inputHasErrors = (name) => {
    return Object.keys(errors).includes(name);
  };

  const onHandleFormChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const roles = Object.values(settings.ROLES).map((rol) => {
    return {
      ...rol,
      name: rol.label,
    };
  });

  const onHandleSelectChange = (property, value) => {
    setForm({ ...form, [property]: value });
  };

  const onHandleCheckClick = (property) => {
    setForm({ ...form, [property]: !form[property] });
  };

  const handleSubmit = async () => {
    const response = await dispatch(createUser(form));
    if (response) {
      setErrors(response.data.errors);
    }
  };

  return (
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="kt-portlet">
            <div class="kt-portlet__head">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Información General</h3>
              </div>
            </div>
            <form class="kt-form kt-form--label-right">
              <div class="kt-portlet__body">
                {formHasErrors && <FormError errors={Object.values(errors)} />}
                <div class="row">
                  <div class="col-lg-6">
                    <div className="form-group">
                      <label>Nombre:</label>
                      <Input
                        hasErrors={inputHasErrors("name")}
                        label="Nombre"
                        placeholder="Escriba el nombre"
                        type="text"
                        name="name"
                        value={form.name}
                        onChange={onHandleFormChange}
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div className="form-group">
                      <label>Email:</label>
                      <Input
                        hasErrors={inputHasErrors("email")}
                        label="Email"
                        placeholder="Escriba el email"
                        type="email"
                        name="email"
                        value={form.email}
                        onChange={onHandleFormChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div className="form-group">
                      <label>Rol:</label>
                      <Select
                        hasErrors={inputHasErrors("role_id")}
                        text="Tipo de rol"
                        name="role_id"
                        value={roles}
                        onChange={(value) =>
                          onHandleSelectChange("role_id", value)
                        }
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div className="form-group">
                      <label>Tipo de documento:</label>
                      <Select
                        hasErrors={inputHasErrors("document_type_id")}
                        text="Tipo de documento"
                        name="document_type_id"
                        value={settings.DOCUMENT_TYPE}
                        onChange={(value) =>
                          onHandleSelectChange("document_type_id", value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div className="form-group">
                      <label>Número de documento:</label>
                      <Input
                        hasErrors={inputHasErrors("document_number")}
                        label="Número de Documento"
                        placeholder="Escriba el número de Documento"
                        type="text"
                        name="document_number"
                        value={form.document_number}
                        onChange={onHandleFormChange}
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div className="form-group">
                      <label>¿Es Consultor?:</label>
                      <div>
                        <Switch
                          name="is_consultor"
                          checked={form.is_consultor}
                          onChange={() => onHandleCheckClick("is_consultor")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="kt-portlet__foot kt-portlet__foot--fit-x">
              <div class="kt-form__actions">
                <div class="row">
                  <div class="col-lg-12 justify-content-md-center row">
                    <Button
                      classStyles={{ "btn-success": true }}
                      name="Guardar"
                      loading={api.loading}
                      onClick={handleSubmit}
                    />
                    <Link to="/users">
                      <button type="button" class="btn btn-secondary ml-3">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
