import React, { Component } from 'react';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import { Loader, Input, Button } from 'Components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import _ from 'lodash';

class FinalReports extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            finalReports : [],
            total: null,
            to : null,
            per_page: null,
            current_page: 1,
            last_page : 1,
            loading : true,
            numberPage : 1,
            statusReview : null,
            institution_name : '',
            provider_name : '',
            deliverable_number : '',
            event_id : ''
        };

        const { dispatch } = props;

        this.providersActionsCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidMount()
    {
        this.providersActionsCreators.getFinalReports();
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;

        if(oldProps.finalReports !== newProps.finalReports) 
        {
            this.setState({ 
                finalReports : newProps.finalReports.data, 
                total : newProps.finalReports.total,
                to : newProps.finalReports.to,
                per_page : newProps.finalReports.per_page,
                current_page : newProps.finalReports.current_page,
                last_page : newProps.finalReports.last_page,
                loading : false
            });
        }
    }

    handlePageClick = numberPage => 
    {
        this.setState({ loading : true, numberPage : numberPage + 1 });
        
        this.providersActionsCreators.getFinalReports(numberPage + 1);
    }

    search = () =>
    {
        const { numberPage } = this.state;

        this.providersActionsCreators.getFinalReports(numberPage, this.state);
    }

    handleChange = (e) =>
    {
        const { name, value } = e.target;

        this.setState({ [ name ] : value });
    };

    render()
    {
        const { 
            finalReports, 
            to, 
            total, 
            last_page,
            loading,
            institution_name,
            provider_name,
            event_id,
            deliverable_number } = this.state;
        
        const { api } = this.props;

        console.log("FINAL REPORTS: ", finalReports);
        return (
            <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div class="row">
                    <div class="col-xl-12">    
                        <div class="kt-portlet">
                            <div class="kt-portlet__body">
                                <div class="kt-pagination kt-pagination--brand">
                                    <ReactPaginate
                                        previousLabel={'Atrás'}
                                        nextLabel={'Adelante'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={last_page}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />

                                    <div class="kt-pagination__toolbar">
                                        {/*<select class="form-control kt-font-brand" style={{ width: '60px' }}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>*/}
                                        <span class="pagination__desc">
                                            Mostrando { to } de { total } registros
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>           
                </div> 
                <div class="row">
                    <div class="kt-portlet">
                    <div class="kt-portlet__head kt-portlet__head--lg d-block kt-margin-t-25">
                        <div class="kt-section__content w-100">
                            <div class="form-group row">
                                <div class="col-lg-6 form-group-sub">
                                    <label class="form-control-label">Institución</label>
                                    { <Input 
                                        classStyles={ {} } 
                                        label="Institución"
                                        placeholder=""
                                        type="text"
                                        name="institution_name"
                                        value={institution_name}
                                        onChange={this.handleChange}
                                    /> }
                                </div>
                                <div class="col-lg-6 form-group-sub">
                                    <label class="form-control-label">Autor</label>
                                    { <Input 
                                        classStyles={ {} } 
                                        label="Autor"
                                        placeholder=""
                                        type="text"
                                        name="provider_name"
                                        value={provider_name}
                                        onChange={this.handleChange}
                                    /> }
                                </div>
                            </div>
                            <div class="form-group-last row">
                                <div class="col-lg-6 form-group-sub">
                                    <label class="form-control-label">N Entregable</label>
                                    { <Input 
                                        classStyles={ {} } 
                                        label="N Entregable"
                                        placeholder=""
                                        type="text"
                                        name="deliverable_number"
                                        value={deliverable_number}
                                        onChange={this.handleChange}
                                    /> }
                                </div>
                                <div class="col-lg-6 form-group-sub">
                                    <label class="form-control-label">Convocatoria</label>
                                    { <Input 
                                        classStyles={ {} } 
                                        label="Convocatoria"
                                        placeholder=""
                                        type="text"
                                        name="event_id"
                                        value={event_id}
                                        onChange={this.handleChange}
                                    /> }
                                </div>
                            </div>
                        </div>
                        <div class="kt-section kt-margin-t-20">
                            <div class="kt-section__content kt-grid__item--middle kt-align-center">
                                <Button 
                                    classStyles={ { 'btn-success' : true, 'btn-font-lg' : true } }
                                    name="BUSCAR"
                                    loading={api.loading}
                                    onClick={this.search}
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row">
                    { _.map(finalReports, (informe) => <Informe informe={informe} />) }
                    { loading ? <Loader /> : ''}
                </div>

                <div class="row">
                    <div class="col-xl-12">       
<div class="kt-portlet">
    <div class="kt-portlet__body">
        <div class="kt-pagination kt-pagination--brand">
        <ReactPaginate
            previousLabel={'Atrás'}
            nextLabel={'Adelante'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={last_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
        />

            <div class="kt-pagination__toolbar">
                {/*<select class="form-control kt-font-brand" style={{ width: '60px' }}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>*/}
                <span class="pagination__desc">
                    Mostrando { to } de { total } registros
                </span>
            </div>
        </div>
    </div>
</div>
    </div>           
                </div>  
                </div>
        )
    }
}

const Informe = ({ informe }) =>
{
    return (<div class="col-xl-3">
        <div class="kt-portlet kt-portlet--height-fluid">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">

                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <a href="#" class="btn btn-clean btn-icon" data-toggle="dropdown">
                        <i class="flaticon-more-1"></i>
                    </a>
                    
                    <div class="dropdown-menu dropdown-menu-right">
                        <ul class="kt-nav">
                            <li class="kt-nav__item">
                                <a href="#" class="kt-nav__link">
                                    <i class="kt-nav__link-icon flaticon2-line-chart"></i>
                                    <span class="kt-nav__link-text">Reports</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a href="#" class="kt-nav__link">
                                    <i class="kt-nav__link-icon flaticon2-send"></i>
                                    <span class="kt-nav__link-text">Messages</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a href="#" class="kt-nav__link">
                                    <i class="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                    <span class="kt-nav__link-text">Charts</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a href="#" class="kt-nav__link">
                                    <i class="kt-nav__link-icon flaticon2-avatar"></i>
                                    <span class="kt-nav__link-text">Members</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a href="#" class="kt-nav__link">
                                    <i class="kt-nav__link-icon flaticon2-settings"></i>
                                    <span class="kt-nav__link-text">Settings</span>
                                </a>
                            </li>
                        </ul>                    
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fit-y">
                <div class="kt-widget kt-widget--user-profile-4">
                    <div class="kt-widget__head">
                        <div class="kt-widget__media">
                            <a href={ informe.object._url } target="_blank">
                                <img class="kt-hidden-" style={{ width : '5rem' }} src="/assets/img/pdf.svg" alt="image" />
                            </a>
                            <div class="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-boldest kt-hidden">
                                JB
                            </div>
                        </div>
                        <div class="kt-widget__content kt-padding-t-40">
                            <div class="kt-widget__section">
                                <div class="kt-widget__button">
                                    <span  class="btn btn-label-warning btn-sm">{ `INFORME ${informe.deliverable.order}` }</span>
                                </div>
                                <a href="#" class="kt-widget__username">
                                    { informe.provider.name }
                                </a>
                                <p><strong>Email: </strong> { informe.provider.email ? informe.provider.email : '-' }</p>
                                <p><strong>Teléfono: </strong> { informe.provider.phones ? informe.provider.phones: '-' }</p>
                                <p><strong>Dirección: </strong> { informe.provider.address ? informe.provider.address : '-' }</p>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget__body">
                        <div class="kt-widget__section">
                            <strong>Evento: </strong> { informe.event ? informe.event.name : '-' }
                        </div>
                        <div class="kt-widget__section">
                            <strong>Instituciones: </strong> 
                            <ul>
                                { _.map(informe.institutions, (institution) => {
                                    return (
                                        <ul>- { institution.name }</ul>
                                    )
                                })}
                            </ul>
                        </div>          
                    </div>       
                </div>
            </div>
        </div>
    </div>)
};

const mapStateToProps = (state) => ({
    api: state.api,
    finalReports : state.providers.finalReports
});

export default connect(mapStateToProps, null)(FinalReports);
