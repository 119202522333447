import React, { Component } from 'react';
import { Datatable, Loader, Input, Select, Button, Divider } from 'Components';
import PageInformation from 'Components/Datatable/PageInformation';
import * as UsersActionsCreators from 'Ducks/users/operations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { settings } from 'Utils/settings';
import { Link } from 'react-router-dom';

class Users extends Component
{
    constructor(props)
    {
      super(props);

      const { dispatch } = props;

      this.state = this.getInitialState();

      this.usersActionsCreators = bindActionCreators(UsersActionsCreators, dispatch);
  
      this.handlePageClick = this.handlePageClick.bind(this);
    }

    getInitialState = () =>
    ({
      users : [],
      total: "",
      per_page: null,
      current_page: 1,
      last_page : 1,
      loading : true,
      numberPage : 1,
      name : "",
      email : "",
      grade : "",
      source : "",
      especiality : "",
      is_consultor : ""
    });

    getfilter = () => {
      return {
        page: this.state.numberPage,
        name: this.state.name,
        email: this.state.email,
        grade: this.state.grade,
        especiality: this.state.especiality,
        is_consultor: this.state.is_consultor,
        source: this.state.source,
      }
    }

    componentDidMount()
    {
        this.usersActionsCreators.getUsers(1, this.getfilter());
    }

    handleChange = (e) =>
    {
        const { name, value } = e.target;

        this.setState({ [ name ] : value });
    };

    handlePageClick = numberPage => 
    {
        this.setState({ loading : true, numberPage : numberPage + 1 });
        this.usersActionsCreators.getUsers(numberPage + 1, this.getfilter());
    }

    componentDidUpdate(oldProps) {
      const newProps = this.props;

      if(oldProps.users !== newProps.users) 
      {
          this.setState({ 
              users : newProps.users.data, 
              total : newProps.users.total,
              per_page : newProps.users.per_page,
              current_page : newProps.users.current_page,
              last_page : newProps.users.last_page,
              loading : false
          });
      }
  }

  search = () =>
  {
      const { numberPage } = this.state;

      this.usersActionsCreators.getUsers(numberPage, this.getfilter());
  }

    render()
    {
        const { api } = this.props; 

        console.log('this.props USER', this.props)

        const {
          users,
          total,
          last_page,
          current_page,
          loading,
          numberPage,
          name,
          email,
          grade,
          especiality,
          source,
          is_consultor
        } = this.state;

          const rows = _.map(users, obj => {
            let data = _.pick(obj, ['id', 'name', 'phone', 'address', 'birthdate', 'document_type_id', 'cv_titles', 'created_at', 'cv'])
            
            return _.assign(data, {
              name: <Name name={obj.name} email={obj.email} />,           
              document_type_id : obj.document_type_id ? settings.DOCUMENT_TYPE[obj.document_type_id].name : '-',
              cv_titles : obj.cv_titles.length ?                
                _.map(obj.cv_titles, (title, index) => {
                  return (
                    <div> 
                      {index > 0 ? <Divider /> : ''}
                      <ProfessionalTitles title={title} />
                    </div>
                  );                
                }) : '-',
              cv: <DownloadCvButton id={data.id} />      
            });
          });

        return(
          <div class="kt-portlet kt-portlet--mobile">
	          <div class="kt-portlet__head kt-portlet__head--lg">
              <div class="kt-portlet__head-label">
                  <span class="kt-portlet__head-icon">
                      <i class="kt-font-success flaticon-users-1"></i>
                  </span>
                  <h3 class="kt-portlet__head-title">
                      Usuarios
                  </h3>                  
              </div>
              
              <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-wrapper">
                  <div class="kt-portlet__head-actions">
                    <div class="dropdown dropdown-inline">
                      <div class="dropdown-menu dropdown-menu-right">
                        <ul class="kt-nav">
                          <li class="kt-nav__section kt-nav__section--first">
                            <span class="kt-nav__section-text">Choose an option</span>
                          </li>
                          <li class="kt-nav__item">
                            <a href="#" class="kt-nav__link">
                              <i class="kt-nav__link-icon la la-print"></i>
                              <span class="kt-nav__link-text">Print</span>
                            </a>
                          </li>
                          <li class="kt-nav__item">
                            <a href="#" class="kt-nav__link">
                              <i class="kt-nav__link-icon la la-copy"></i>
                              <span class="kt-nav__link-text">Copy</span>
                            </a>
                          </li>
                          <li class="kt-nav__item">
                            <a href="#" class="kt-nav__link">
                              <i class="kt-nav__link-icon la la-file-excel-o"></i>
                              <span class="kt-nav__link-text">Excel</span>
                            </a>
                          </li>
                          <li class="kt-nav__item">
                            <a href="#" class="kt-nav__link">
                              <i class="kt-nav__link-icon la la-file-text-o"></i>
                              <span class="kt-nav__link-text">CSV</span>
                            </a>
                          </li>
                          <li class="kt-nav__item">
                            <a href="#" class="kt-nav__link">
                              <i class="kt-nav__link-icon la la-file-pdf-o"></i>
                              <span class="kt-nav__link-text">PDF</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    &nbsp;
                    <Link to="/new-user">
                        <a href="javascript:void(0)" class="btn btn-success btn-elevate btn-icon-sm">
                            <i class="la la-plus"></i>Nuevo Usuario
                        </a>
                    </Link>
                  </div>	
                </div>		
              </div>
	          </div>

            <div class="kt-portlet__head kt-portlet__head--lg d-block kt-margin-t-25">
                    <div class="kt-section__content w-100">
                        <div class="form-group row">
                            <div class="col-lg-3">
                              <div className="input-group form-group">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Nombres</div>
                                </div>
                                { <Input 
                                    classStyles={ {} } 
                                    label="Nombre"
                                    placeholder=""
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={this.handleChange}
							                    /> }
                              </div>                             
                            </div>
                            <div class="col-lg-3">
                              <div className="input-group form-group">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Email</div>
                                </div>
                                { <Input 
                                    classStyles={ {} } 
                                    label="Email"
                                    placeholder=""
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={this.handleChange}
							                    /> }
                              </div>   
                            </div>

                            <div class="col-lg-3">
                              <div className="input-group form-group">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Grado</div>
                                </div>
                                { <Input 
                                    classStyles={ {} } 
                                    label="Email"
                                    placeholder=""
                                    type="text"
                                    name="grade"
                                    value={grade}
                                    onChange={this.handleChange}
							                    /> }
                              </div>   
                            </div>

                            <div class="col-lg-3">
                              <div className="input-group form-group">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Especialidad</div>
                                </div>
                                { <Input 
                                    classStyles={ {} } 
                                    label="Especialidad"
                                    placeholder=""
                                    type="text"
                                    name="especiality"
                                    value={especiality}
                                    onChange={this.handleChange}
							                    /> }
                              </div>   
                            </div>

                            <div class="col-lg-3">
                              <div className="input-group form-group">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">¿Es Consultor?</div>
                                </div>
                                <Select 
                                  text="Seleccionar tipo de:"
                                  value={[
                                    {
                                        id : "2",
                                        name : "CONSULTOR",
                                    },
                                    {
                                        id : "1",
                                        name : "FUENTE DE BASE DE DATOS",
                                    }
                                  ]}
                                  //selected={this.state.currency}
                                  onChange={(e) => this.setState({ is_consultor : e })}
                                />
                              </div>   
                            </div>
                            
                            <div class="col-lg-3">
                              <div className="input-group form-group">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Base de datos</div>
                                </div>
                                <Select 
                                  text="Seleccionar tipo de:"
                                  value={[
                                    {
                                        id : "DB_CALENA",
                                        name : "DB CALENA",
                                    },
                                    {
                                      id : "DB_COMITE_EJECUTIVO",
                                      name : "DB COMITE EJECUTIVO",
                                    },
                                    {
                                      id : "DB_CONSULTORES",
                                      name : "DB CONSULTORES",
                                    },
                                    {
                                      id : "DB_ILIANA",
                                      name : "DB ILIANA",
                                    },
                                    {
                                      id : "DB_LEONCIO",
                                      name : "DB LEONCIO",
                                    },
                                    {
                                        id : "DB_RONY",
                                        name : "DB RONY",
                                    }
                                  ]}
                                  //selected={this.state.currency}
                                  onChange={(e) => this.setState({ source : e })}
                                />
                              </div>   
                            </div>

                            <div class="col-lg-3 form-group">
                              <Button 
                                classStyles={ { 'btn-success' : true } }
                                name="BUSCAR"
                                loading={api.loading}
                                onClick={this.search}
                              />
                            </div>

                        </div>
                        
                    </div>
                    
				          </div>
                  { <PageInformation total={total} current_page={current_page} last_page={last_page} /> }
                  <div class="">
                    <Datatable 
                      columns={columns} 
                      rows={rows}
                      handlePageClick={(e) => this.handlePageClick(e.selected)}
                      last_page={last_page} 
                      current_page={current_page - 1} 
                    />
                  </div>
            { loading ? <Loader /> : ''}
          </div>
        )
    }
}

const Name = ({ name, email }) => 
(
  <div>
    <p>
      <div class="kt-demo-icon__preview">
        <i class="flaticon2-user"> </i>
        <span className="kt-font-boldest">{name}</span>
      </div>
    </p>
    <p>
      <div class="kt-demo-icon__preview">
        <i class="flaticon-multimedia"> </i>
        <span className="kt-font-bold"> {email}</span>
      </div>
    </p>
  </div>
);

const ProfessionalTitles = ({ title }) =>
(
  <div className="mt-2">
    <p>
      <div class="kt-demo-icon__preview">
				<i class="flaticon2-correct"> </i>
        Grado: <span className="kt-font-boldest"> {title.grade}</span>
			</div>
    </p>
    <p>
      <div class="kt-demo-icon__preview">
				<i class="flaticon-layer"> </i>
        Especialidad: <span className="kt-font-boldest"> {title.especiality}</span>
			</div>
    </p>    
  </div>
);

const DownloadCvButton = ({ id }) => (
  <a 
    className="btn btn-info btn-icon text-center"
    href={`${settings.BASE_URL}/users/${id}/downloadCv?token=${localStorage.getItem('token')}`} target="_blank"
    title="Descargar CV"
  >   
  <i class="flaticon-doc"></i>    
  </a>  
);

const columns = [
  {
    label: 'ID',
    field: 'id',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Nombre',
    field: 'name',
    sort: 'asc',
    width: 270
  },
  {
    label: 'Teléfono',
    field: 'phone',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Dirección',
    field: 'address',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Fecha de Nacimiento',
    field: 'birthdate',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Tipo Documento',
    field: 'document_type_id',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Títulos',
    field: 'cv_titles',
    sort: 'asc',
    width: 200
  },
  {
    label: 'Creado',
    field: 'created_at',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Curriculum',
    field: 'cv',
    width: 100,
    attributes: {
      'class': 'text-center'
    }
  }
];

const mapStateToProps = (state) => ({
    api : state.api,
    users : state.users.users
});

export default connect(mapStateToProps, null)(Users);
