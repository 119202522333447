

import { default as HttpClient } from './HttpClient';

const  ApigprocRecruitment =  {

    finWorkOffers: (query) => {     
        return HttpClient.get('/recruitment/work_offers', {params: query});
    },
    getWorkOffer: (id, query) => {
        return HttpClient.get('/recruitment/work_offers/'+id, {params: query});
    },
    findPostulation: (id, query) => {
        return HttpClient.get('/recruitment/work_offers/'+id+'/postulation', {params: query});
    },
    savePostulation: (id, data) => {
        return HttpClient.post('/recruitment/work_offers/'+id+'/postulation', data);
    }
    
}

export default ApigprocRecruitment;


