import React, { Fragment } from 'react';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';

const DatatableExpanded = ({ 
    title, 
    columns, 
    data, 
    style, 
    last_page, 
    current_page, 
    ExpandableRowsComponent,
    onSelectedRowsChange,
    handlePageClick }) => {

    const handlePage = (e) => handlePageClick(e);

    const customTheme = {
      header: {
        fontColor: '#fff',
        backgroundColor: '#343a40',
        borderColor: '#454d55',
        fontColorActive: '#fff'
      }
    }

  return (
    <Fragment>
      {/*<ReactPaginate
        previousLabel={'Atrás'}
        nextLabel={'Adelante'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={last_page}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePage}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        forcePage={current_page}
      />*/}
      <DataTable
        title={title}
        columns={columns}
        data={data}
        //selectableRowsComponent={<div>aa</div>}
        //selectableRowsComponentProps={{ inkDisabled: true }}
        //sortIcon={"dix"}
        onSelectedRowsChange={onSelectedRowsChange}
        expandableRows
        style={style}
        expandableRowsComponent={<ExpandableRowsComponent />}
        highlightOnHover={true}
        customTheme={customTheme}
      />
      <ReactPaginate
        previousLabel={'Atrás'}
        nextLabel={'Adelante'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={last_page}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePage}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        forcePage={current_page}
      />
    </Fragment>
  );
}

export default DatatableExpanded;
