import { constants } from "./types";
import createReducer  from "Utils/createReducer";

const initialState = {
    contracts : [],
    processes: []
};

const contractsReducer = createReducer( initialState )( {
    [ constants.SET_CONTRACTS ]: (state, action) => {
        return  { ...state, contracts : action.contracts };
    },
    [ constants.SET_PROCESSES ]: (state, action) => {
        return  { ...state, processes : action.processes };
    },
} );

export default contractsReducer;
