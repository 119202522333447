import { constants } from "./types";
import createReducer  from "Utils/createReducer";

const initialState = {
    "cancelSource": null,
    "cancelled": false,
    "error": false,
    "loading": false,
    "requestId": ""
};

const apiReducer = createReducer( initialState )( {
    [ constants.API_REQUEST_STARTED ]: (state, action) => {
         return{ 
            ...state, 
            requestId : action.payload.id, 
            loading : true,
            cancelTokenSource: action.payload.cancelTokenSource
        };
    },
    [ constants.API_REQUEST_SUCCEEDED ]: (state, action) => {
        return{ 
            ...state, 
            requestId : action.payload.id, 
            loading : false,
            cancelTokenSource: null
        };
    },
    [ constants.API_REQUEST_FAILED ]: (state, action) => {
        return{ 
            ...state, 
            requestId : action.payload.id, 
            loading : false,
            cancelTokenSource: null,
            error: action.payload.error.status
        };
    },
    [ constants.API_REQUEST_CANCEL_REQUEST ]: (state, action) => {
        return{ 
            ...state, 
            requestId : action.payload.id, 
            loading : false,
            cancelled : true,
            cancelTokenSource: null
        };
    }
} );

export default apiReducer;
