import React from 'react';

const Progress = ({ valuemax = 50, progress = 20, color = "success" ,styles = { height: '10px', width: '100px' } }) =>
(
    <div className="kt-widget__progress d-flex  align-items-center">
        <div className="progress" style={styles}>
            <div className={`progress-bar kt-bg-${color}`} role="progressbar" style={{ width: `${(100 / valuemax) * progress}%`}} aria-valuenow={valuemax} aria-valuemin="0" aria-valuemax={valuemax}></div>
        </div>
        <span className="kt-widget__stat" style={{ paddingLeft : '10px' }}>
             {((progress * 100) / valuemax).toFixed(2)}% ({progress}/{valuemax})
        </span>
    </div>
);

export default Progress;