import { NotificationManager } from 'react-notifications';

const buildNotification = ( type, message = null, title = null, timeOut = 5000, callback = null ) => {
      switch (type) {
        case 'info':
            return NotificationManager.info(message, title, timeOut, callback);
        break;
        case 'success':
            return NotificationManager.success(message, title, timeOut, callback);
        break;
        case 'warning':
            return NotificationManager.warning(message, title, timeOut, callback);
        break;
        case 'error':
            return NotificationManager.error(message, title, timeOut, callback);
        break;
      }
    
};

export default buildNotification;