import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import classes from "./ReactSelect.module.css";

const ReactSelect = ({ value, options, isMulti, hasErrors, ...props }) => {
  const objectValue = options.filter((option) => {
    if (isMulti) {
      return value.includes(option.value);
    }
    return option.value === value;
  });

  const selectClassNames = ["w-100", hasErrors ? classes.Error : ""].join(
    " "
  );

  return (
    <Select
      className={selectClassNames}
      value={objectValue}
      options={options}
      isMulti={isMulti}
      {...props}
    />
  );
};

ReactSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  isMulti: PropTypes.bool
};

export default ReactSelect;
