import React, { useState } from "react";
import PropTypes from "prop-types";

import UploadChunkFile from "./UploadChunkFile";
import "./index.css";
import { settings } from "../../../utils/settings";


// Doc: http://resumablejs.com/
// Doc: https://github.com/Artear/ReactResumableJS

const CancelButton = ({onClick}) => {
  return <span  onClick={onClick} style={{borderRadius: '1rem', borderColor: '#000', padding: '0 5px', cursor: 'pointer', fontWeight: 800, borderWidth: '1px', borderStyle: 'solid'}} >X</span>
}

const UploadFileTemp = ({
  maxFileSizeGB = 1,
  chunkSizeMB = 1,
  simultaneousUploads = 5,
  apiURL = `${settings.BASE_URL}/files/uploadChunk`,
  id = "file",
  textLabel="Subir archivo",
  onFileSuccess,
  onRemoveFile,
  fileServer=null,
  file,
}) => {
  const maxFileSizeMB = maxFileSizeGB * 1024 + "mb";
  const chunkSizeBytes = chunkSizeMB * 1024 * 1024;

  return (
    <div className="d-flex align-items-center">

      {fileServer && <div  className="file-success"> {fileServer.name} <CancelButton  onClick={onRemoveFile} /></div>}
      {!fileServer && <UploadChunkFile
        onRemoveFile={onRemoveFile}
        chunkSize={chunkSizeBytes}
        simultaneousUploads={simultaneousUploads}
        testChunks={false}
        service={apiURL}
        headerObject={{
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }}
        file={file}
        fileServer={fileServer}
        uploaderID={id}
        maxFileSize={maxFileSizeMB}
        fileAddedMessage="Started!"
        completedMessage="Complete!"
        onFileAdded={(file, e) => {
          console.log("ADDED", file);
        }}
        maxFiles={1}
        startButton={true}
        pauseButton={true}
        cancelButton={true}
        onStartUpload={() => {}}
        onCancelUpload={() => {}}
        onPauseUpload={() => {}}
        onResumeUpload={() => {}}
        onFileSuccess={onFileSuccess}
        label={textLabel}
      />}

    </div>
  );
};

/*UploadFileTemp.propTypes = {
  label: PropTypes.string.isRequired,
};*/

export default UploadFileTemp;
