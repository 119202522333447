import KeyMirror from 'keymirror';

export const constants = KeyMirror({
    TEST: null,
    GET_DELIVERABLES : null,
    CREATE_DELIVERABLE : null,
    GET_DELIVERABLES_FOR_PROVIDER : null,
    //API
    SET_DELIVERABLES : null
});
