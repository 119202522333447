import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "../../components/Button";
import Modal from "../../components/Modal/Modal";
import AddDeliverable from "./AddDeliverable";
import { Datatable } from "Components";

const DeliverablesList = ({
  contractDeliverables,
  setContractDeliverables,
  contract
}) => {
  const [openAddDeliverableModal, setOpenAddDeliverableModal] = useState(false);

  const removeDeliverable = (key) => {
    let _contractDeliverables = contractDeliverables;
    _contractDeliverables.splice(key, 1);
    setContractDeliverables([..._contractDeliverables]);
  };

  const Deliverables = () => {
    let rows = contractDeliverables.map((deliverable, key) => {
      return {
        ...deliverable,
        action_buttons: (
          <Button
            name="Eliminar"
            classStyles={{ "btn-danger": true }}
            onClick={() => removeDeliverable(key)}
          />
        ),
      };
    });

    return contractDeliverables.length > 0 ? (
      <div>
        <Datatable
          columns={columns}
          rows={rows}
          paginate={false}
          // handlePageClick={(e) => this.handlePageClick(e.selected)}
          // last_page={1}
          // current_page={1}
        />
      </div>
    ) : (
      <div>No hay entregables</div>
    );
  };

  const addDeliverable = (deliverable) => {
    setContractDeliverables([...contractDeliverables, { ...deliverable }]);
    setOpenAddDeliverableModal(false);
  };

  return (
    <div>
      <h5 className="mb-4">Entregables</h5>
      <Button
        name="Agregar"
        classStyles={{ "btn-success": true, "mb-4": true }}
        onClick={() => setOpenAddDeliverableModal(true)}
      />
      <Deliverables />
      {openAddDeliverableModal && (
        <AddDeliverable
          contract={contract}
          handleClose={() => setOpenAddDeliverableModal(false)}
          handleSubmit={addDeliverable}
        />
      )}
    </div>
  );
};

const columns = [
  {
    label: "Hito N°",
    field: "order",
    sort: "asc",
    width: 150,
  },
  {
    label: "Producto o Entregable",
    field: "description",
    width: 300,
  },
  {
    label: "Entrega Producto Plazo",
    field: "delivery_day",
    width: 150,
  },
  {
    label: "Entrega Producto Fecha",
    field: "delivery_date",
    width: 300,
  },
  {
    label: "Monto Previsto",
    field: "amount",
    width: 150,
  },
  {
    label: "F. Entrega Producto",
    field: "modified_date",
    width: 150,
  },
  {
    label: "F. Solicita Conformidad",
    field: "compliace_date_request",
    width: 150,
  },
  {
    label: "F. de Conformidad",
    field: "compliace_date",
    width: 150,
  },
  {
    label: "Monto Facturado",
    field: "amount_invoiced",
    width: 150,
  },
  {
    label: "Penalidad",
    field: "penalty_amount",
    width: 150,
  },
  {
    label: "Monto Pagado",
    field: "amount_paid",
    width: 150,
  },
  {
    label: "Fecha de Pago",
    field: "amount_paid_date",
    width: 150,
  },
  {
    label: "N° RH",
    field: "rrhh_number",
    width: 150,
  },
  {
    label: "Acción",
    field: "action_buttons",
    width: 150,
  },
];

DeliverablesList.propTypes = {};

export default DeliverablesList;
