import ApigprocRecruitment from './ApigprocRecruitment'
import ApigprocProfile from './ApigprocProfile'
import ApigprocSepa from './ApigprocSepa'
import ApigprocInforme from './ApigprocInforme';
import ApigprocInformeReview from './ApigprocInformeReview';
import ApigprocAuth from './ApigprocAuth';

const  Apigproc = {    
    auth: ApigprocAuth,
    recruitment: ApigprocRecruitment,
    profile: ApigprocProfile,   
    sepa: ApigprocSepa,
    informe: ApigprocInforme,
    informeReview: ApigprocInformeReview,
}

export default Apigproc



