import React, { useState, useEffect } from 'react';
import { Button, Input, CustomButton, DateRange, File, ButtonFile, Textarea, CustomDatePicker } from 'Components';
import InputFile from "../../components/InputFile";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import _ from 'lodash';
import moment from 'moment';
import { scrollTo } from 'Utils/animateScrollTo';

const Experiences = ({ cv_experiences }) =>
{
    console.log("cv_experiences: ", cv_experiences);
    const dispatch = useDispatch();

    const [experiences , setExperiences] = useState([]);
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [file, setFile] = useState('');
    const [files, setFiles] = useState([]);
    const [id, setId] = useState('');
    const [edit, setEdit] = useState(false);

    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        setExperiences(cv_experiences);
    }, [cv_experiences]);

    const dateRange = (e, type) =>
    {
        let dateFormat = moment(e).format('YYYY-MM-DD');

        if(type == "start_date")
        {
            setStartDate(dateFormat);
        }
        else
        {
            setEndDate(dateFormat);
        }
    };

    const onSubmit = async (e) =>
    {
        e.preventDefault();

        e.target.reset();

        let request = { company, position, description, start_date : startDate, end_date : endDate, _filename_certificate : files.length > 0 ? files[0]: null};

        await dispatch(ProvidersActionsCreators.setExperience(edit ? _.assign(request, { id }) : request ));

        setShowForm(false);
        setEdit(false);
    }

    const onEdit = (experience) => 
    {
        setShowForm(true)
        if(_.isEmpty(experience))
        {
            setCompany('');
            setPosition('');
            setDescription('');
            setStartDate('');
            setEndDate('');
            setFile('');
            setFiles([]);
            setId('');
            setEdit(false);
        }
        else
        {
            setCompany(experience.company);
            setPosition(experience.position);
            setDescription(experience.description);
            setStartDate(experience.start_date);
            setEndDate(experience.end_date);
            if(experience.document_certificate) setFiles([experience.document_certificate]);
            setFile('');
            setId(experience.id);
            setEdit(true);
        }
    }

    const handleChangeFile = async (e) =>
    {
        const { name, files } = e.target;

        let urlFile = await dispatch(ProvidersActionsCreators.submitFiles(files[0]));

        setFile(urlFile.filename);
    };

    return (
        <>

            {!showForm && <div>
                <h2>EXPERIENCIA LABORAL</h2>
                <p>
                En la presente sección deberá detallar en cada uno de los cuadros siguientes, SOLO LAS FUNCIONES/TAREAS CUMPLIDAS EN CADA UNA DE LAS AREAS QUE SERÁN CALIFICADAS. En el caso de haber ocupado varios cargos en una entidad, mencionar cuales y completar los datos respectivos.
                </p>
                <p>
                En el caso de haber realizado trabajos en forma paralela, sólo se considerará el período cronológico total de dichos trabajos; es decir, no se contabilizarán las duplicaciones en el tiempo.
                </p>
                <p>
                De ser seleccionado, la información proporcionada en los cuadros deberá ser respaldada con las respectivas certificaciones antes de suscribir el contrato.
                </p>
                <button onClick={() => { onEdit(null) }} className="btn btn-success btn-sm" >REGISTRAR EXPERIENCIA LABORAL</button>                

                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true">
                    <div className="kt-notes__items">
                        {
                            _.map(experiences, (experience) => (
                                <Experience experience={experience} onEdit={onEdit} />
                            ))
                        }
                    </div>
                </div>
            </div>} 

            {showForm && <form method="POST" onSubmit={onSubmit}>

                {!edit && <h2>NUEVO EXPERIENCIA LABORAL</h2>}
                {edit && <h2>EDITAR EXPERIENCIA LABORAL</h2>}

                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>

                <div className="row">
                <div className="form-group col-md-6 col-lg-3">
                    <label>Empresa o Institución</label>
                    <Input 
                        classStyles={ {} } 
                        label="Compañía"
                        placeholder=""
                        type="text"
                        name="company"
                        required={true}
                        value={company}
                        maxlength="100"
                        required={true}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                </div>
                <div className="form-group col-md-6 col-lg-3">
                    <label>Cargo</label>
                    <Input 
                        classStyles={ {} } 
                        label="Posición"
                        placeholder=""
                        type="text"
                        name="position"
                        required={true}
                        value={position}
                        maxlength="60"
                        required={true}
                        onChange={(e) => setPosition(e.target.value)}
                    />
                </div>
                <div className="form-group col-md-6 col-lg-3">
                    <label>Constancia (máx 10 mb)</label>
                    <InputFile name={"_filename_certificate"} objects={files} maxFiles={1} onChange={(objects) => {
                        setFiles(objects);
                    }} />

                    {/*<File 
                        name="_filename_certificate"
                        onChange={handleChangeFile}
                    />*/}
                </div>


                <div className="form-group col-md-6 col-lg-3">
                    <label>Fecha de inicio - Fecha de fin</label>
                    <div>
                        <CustomDatePicker
                            selected={ startDate? moment(startDate, "YYYY-MM-DD").toDate(): null}
                            onChange={date => { setStartDate(moment(date).format("YYYY-MM-DD")) }}
                            maxDate={moment().toDate()}                           
                        />  
                        &nbsp;
                        <CustomDatePicker
                            selected={ endDate? moment(endDate, "YYYY-MM-DD").toDate(): null}
                            onChange={date => { setEndDate(moment(date).format("YYYY-MM-DD")) }}
                            maxDate={moment().toDate()}                           
                        />  
                        &nbsp;
                        
                        <Button 
                            classStyles={ { 'btn' : true, 'btn-default' : endDate != moment().format("YYYY-MM-DD"), 'btn-primary' : endDate == moment().format("YYYY-MM-DD") } }
                            onClick={() => {setEndDate(moment().format("YYYY-MM-DD"))}}
                            type="button"
                            name="HOY"
                        />
                    </div>
                </div>

                <div className="form-group col-md-12">
                    <label>Descripción de la función desempeñada</label>
                    { <Textarea 
                        classStyles={ {} } 
                        name="summary"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        processHtml={false}
                    /> }
                </div>
                
                
                
                </div>

                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                <div className="row">
                    <div className="col">
                        <Button 
                            classStyles={ { 'btn' : true, 'btn-default' : true } }
                            onClick={() => {setShowForm(false)}}
                            type="button"
                            name="CANCELAR"
                        />&nbsp;
                        <Button 
                            classStyles={ { 'btn' : true, 'btn-success' : true } }
                            type="submit"
                            name="GUARDAR"
                        />
                    </div>
                </div>
            </form>}
            
        </>
    )
}

const Experience = ({ experience, onEdit }) =>
{
    const dispatch = useDispatch();

    const [edit, setEdit] = useState(false);

    const deleteExperience = async () =>
    {
        let providersActionCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

        let result = await providersActionCreators.deleteExperience(experience.id);
    };

    useEffect( () => {
        setEdit(false);
      }, [ experience ]
    );

    return (
        <div className="kt-notes__item"> 
            <div className="kt-notes__media">
            <span className="kt-notes__icon">
                <i className="fas fa-flask kt-font-success"></i>
            </span>                              
            </div>    
            <div className="kt-notes__content"> 
                <div className="kt-notes__section">     
                    <div className="kt-notes__info">
                        <a href="#" className="kt-notes__title kt-font-bolder">
                            EXPERIENCIA LABORAL:                       
                        </a>
                        <span className="kt-notes__desc">
                        { experience.start_date } - { experience.end_date }
                        </span>
                    </div>
                    <div className="kt-notes__dropdown"> 
                        {edit &&
                            <CustomButton 
                                classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-warning' : true,'btn-icon' : true }}
                                icon="fa fa-window-close"
                                style={{ marginRight : '.7em' }}
                                onClick={() => { onEdit({}); setEdit(false) }} />
                        }
                        {! edit &&
                            <CustomButton 
                                classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-success' : true,'btn-icon' : true }}
                                icon="fa fa-edit"
                                style={{ marginRight : '.7em' }}
                                onClick={() => { onEdit(experience); setEdit(true); scrollTo('.frm-cv') }} />
                        }
                        <CustomButton 
                            classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-danger' : true,'btn-icon' : true }}
                            icon="fa fa-trash-alt"
                            onClick={() => { if(window.confirm("¿Estas seguro de eliminar?")) deleteExperience() } } />
                    </div>
                </div>
                <span className="kt-notes__body">                                        
                    <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-building" /> { experience.company }</p>
                    <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-money-check-alt" />{ experience.position }</p>
                    <p>{ experience.description }</p>
                    { experience.document_certificate &&
                        <ButtonFile classStyles={{ 'btn-outline-warning' : true }} href={experience.document_certificate ? experience.document_certificate._url : 'javascript_void(0)'} />
                    }
                </span>  
            </div>                                             
        </div>
    )
}

export default Experiences;