import { default as sysadmin } from './Sysadmin';
import { default as admin } from './Admin';
import { default as provider } from './Provider';
import { default as supervisor } from './Supervisor';
import { default as cordinator } from './Cordinator';

export default {
    sysadmin,
    admin,
    provider,
    supervisor,
    cordinator
};