import ApigprocRecruitment from './ApigprocRecruitment'
import ApigprocProfile from './ApigprocProfile'
import ApigprocSepa from './ApigprocSepa'


const  Apigproc = {    
    recruitment: ApigprocRecruitment,
    profile: ApigprocProfile,   
    sepa: ApigprocSepa,
}

export default Apigproc



