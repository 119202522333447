import React, { Component, useEffect, Fragment } from 'react';
import { Loader, EditButton, Input, Select, Button, ButtonFile, Badge, Progress, CustomButton, Expiration, FlatIcon, ListPagination } from 'Components';
import DatatableExpanded from 'Components/DatatableExpanded';
import PageInformation from 'Components/Datatable/PageInformation';
import * as cordinatorActionsCreators from 'Ducks/cordinator/operations';
import * as userActionsCreators from 'Ducks/user/operations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { settings } from 'Utils/settings';
import { Link } from 'react-router-dom';
import { constants } from 'crypto';
import { routes } from 'Utils/constants';
import { getIn } from 'formik';

const EntregableCargd = ({ deliverable }) => {

    return (<div className={`card card-custom-${deliverable.status_review} card-buttom`}>
        <div className="card-body">
                <Link to={{
                    pathname:'/provider/edit-deliverables-provider',
                    search:`?id=${deliverable.id}`,
                    //hash:'#hash',
                    state: { deliverable }
                    }}
                    >
                        <h5 className="card-title" >
                Entregable N°{deliverable.order} {settings.STATUS_INFORME[deliverable.status_review].name}
            </h5>
                    </Link>
            
            <div>
                Vence el {deliverable.delivery_date}
            </div>
        </div>
    </div>);

}

const ContractCard = ({ contract }) => {

    const deliverables = getIn(contract, 'deliverables', []);

    return <div class="card">
        <div class="card-body">
            <div className="row">
                <div className="col-8">
                    <h4>
                        {getIn(contract, 'contract_type.name', '').toUpperCase()} <span className="kt-font-primary">{contract.contract_number}</span>
                    - PROVEEDOR <span className="kt-font-primary">{contract.provider.name}</span>
                    </h4>
                    <h6 class="card-subtitle mb-2 text-muted">FECHA INICIO {getIn(contract, 'enddate')} </h6>
                </div>
                <div className="col-4">
                    <h5 class="card-title pull-right">{getIn(contract, 'currency.symbol')} {getIn(contract, 'amount')}</h5>
                </div>
            </div>


            <p class="card-text">{getIn(contract, 'concept')}</p>


            <div className="row">
                {_.map(deliverables, (d, i) => <div key={i} className="col-sm-6 col-md-4" >
                    <EntregableCargd deliverable={d} />
                </div>)}
            </div>
        </div>
    </div>;
}

class ContractsProvider extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();

        const { dispatch } = props;

        this.cordinatorActionsCreators = bindActionCreators(cordinatorActionsCreators, dispatch);
        this.userActionsCreators = bindActionCreators(userActionsCreators, dispatch);

        this.handlePageClick = this.handlePageClick.bind(this);

        this.handleChange = this.handleChange.bind(this);

        this.search = this.search.bind(this);
    };

    getInitialState = () =>
        ({
            contractsList: {},
            contracts: [],
            total: "",
            per_page: null,
            current_page: 1,
            last_page: 1,
            loading: true,
            numberPage: 1,
            responsable: "",
            provider: "",
            type_contract: "",
            order: "",
            contract_number: "",
            event_id: '',
            evaluador_id: '',
            proyect_activity_id: '',
            status_review: ''
        });

    componentDidMount() {
        this.userActionsCreators.getContracts();
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    };

    handlePageClick = numberPage => {
        this.setState({ loading: true, numberPage: numberPage + 1 });
        this.cordinatorActionsCreators.getContracts(numberPage + 1, this.state);
    }

    search = () => {
        const { numberPage } = this.state;
        this.cordinatorActionsCreators.getContracts(numberPage, this.state);
    }

    clean = () => {
        this.setState({ ...this.getInitialState() }, () => {
            this.search();
        });
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;

        if (oldProps.contracts !== newProps.contracts) {
            this.setState({
                contractsList: newProps.contracts,
                contracts: newProps.contracts.data,
                total: newProps.contracts.total,
                per_page: newProps.contracts.per_page,
                current_page: newProps.contracts.current_page,
                last_page: newProps.contracts.last_page,
                loading: false
            });
        }
    }

    onEvent = (e) => {
        this.setState({ event_id: e });
        this.cordinatorActionsCreators.getActivitiesForEvent(e);
    };

    render() {
        const {
            contractsList,
            loading
        } = this.state;

        const { api, activities } = this.props;

        
        return (
            <div>
                <h4>Mis Contratos</h4>
                <ListPagination
                    data={contractsList}
                    render={(item) => <div className="form-group">
                        <ContractCard contract={item} />
                    </div>}
                    changePage={(page) => { console.log(page) }}
                />
                {loading ? <Loader /> : ''}

            </div>
        )
    }
}

const progressCell = ({ deliverables }) => {
    console.log("deliverables", deliverables);
    return (
        <Progress
            progress={_.filter(deliverables, (deliverable) => deliverable.status_review == 3).length}
            valuemax={deliverables.length}
        />
    );
};

const providerCell = ({ provider, document_contract, contract_number, document_tdr }) => {
    return (
        <div className="d-flex flex-column">
            <div className="provider-name">
                <FlatIcon icon="flaticon-businesswoman" />
                <span className="kt-font-bold ml-1">{provider ? provider.name : '-'}</span>
            </div>
            <div className="provider-files d-flex flex-row  mt-3">
                <div className="provider-files-contrato">
                    <div className="d-flex flex-column">
                        <Badge label="info" name={`Contratos`} />
                        {ContratoButton(document_contract, contract_number)}
                    </div>
                </div>
            </div>
        </div>
    );
};

const ContratoButton = (document_contract, contract_number) => {
    return (
        document_contract ?
            <div style={{ textAlign: 'center' }} className="mt-2">
                <ButtonFile classStyles={{ 'btn-outline-info': true }} href={document_contract._url} />
                <p style={{ marginTop: '1em' }} className="kt-font-info"><em>{contract_number}</em></p>
            </div>
            : '-'
    );
};

const TdrButton = (document_tdr) => {
    return (
        document_tdr ?
            <ButtonFile classStyles={{ 'btn-outline-warning': true, 'mt-2': true }} href={document_tdr._url} />
            : '-'
    )
}

const responsablesCell = ({ responsable }) => {
    return responsable ? <div className={'mt-3'}>
        <FlatIcon icon="flaticon2-user" />
        {responsable.name}
    </div> : '-';
};

const ExpanableComponent = ({ data }) => {
    return (
        <table style={{ border: '2px solid #ebedf2', width: '100%' }}>
            <tbody>
                <tr>
                    <th className="text-center" style={{ padding: '1.2em 0', borderBottom: '1px solid #ebedf2' }}>Ver Entregables</th>
                    <th class="text-center" style={{ padding: '1.2em 0', borderBottom: '1px solid #ebedf2' }}>N Entregable</th>
                    <th class="text-center" style={{ padding: '1.2em 0', borderBottom: '1px solid #ebedf2' }}>Monto programado</th>
                    <th class="text-center" style={{ padding: '1.2em 0', borderBottom: '1px solid #ebedf2' }}>Monto Pagado</th>
                    <th class="text-center" style={{ padding: '1.2em 0', borderBottom: '1px solid #ebedf2' }}>Estado</th>
                    <th class="text-center" style={{ padding: '1.2em 0', borderBottom: '1px solid #ebedf2' }}>F. Entrega</th>
                    <th class="text-center" style={{ padding: '1.2em 0', borderBottom: '1px solid #ebedf2' }}>F. Aprobación</th>
                    <th class="text-center" style={{ padding: '1.2em 0', borderBottom: '1px solid #ebedf2' }}>Conformidad </th>
                </tr>
                {
                    _.map(data.deliverable, (deliverable, index) => (
                        <tr key={index} style={{ height: '50px', borderBottom: '1px solid #ebedf2' }}>
                            <td class="text-center" style={{ padding: '1.2em 0', borderBottom: '1px solid #ebedf2' }}>
                                <Link to={{
                                    pathname: routes.CORDINATOR.DETAIL_DELIVERABLE,
                                    search: `?id=${deliverable.id}`,
                                    //hash:'#hash',
                                    state: { deliverable, deliverables: data.deliverable }
                                }}>
                                    <CustomButton
                                        classStyles={{ 'btn': true, 'btn-elevate': true, 'btn-warning': true, 'btn-icon': true }}
                                        icon="fa fa-eye"
                                        style={{ marginLeft: '1em' }}
                                    />
                                </Link>
                            </td>
                            <td class="text-center">{deliverable.order}</td>
                            <td class="text-center">{deliverable.amount}</td>
                            <td class="text-center">{deliverable.payment ? deliverable.payment : ''}</td>
                            <td class="text-center">
                                <Badge label={`${settings.STATUS_INFORME[deliverable.status_review].label}`} name={settings.STATUS_INFORME[deliverable.status_review].name} />
                            </td>
                            <td class="text-center">{deliverable.delivery_date}</td>
                            <td class="text-center">{deliverable.approved_at || '-'}</td>
                            <td class="text-center">{deliverable.document_compliance ?
                                <ButtonFile classStyles={{ 'btn-outline-success': true }} href={deliverable.document_compliance._url} />
                                : '-'}
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

const mapStateToProps = (state) => ({
    api: state.api,
    contracts: state.user.contracts,
    activities: state.cordinator.activities
});

export default connect(mapStateToProps, null)(ContractsProvider);
