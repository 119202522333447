import { constants } from "./types";
import createReducer  from "Utils/createReducer";

/* State shape
{
    redirectRoute: {},
    currentGeolocation: { lat, lng, status }
}
*/

const initialState = {
    test : false,
    token : null,
    isAuthenticated : false,
    userInformation : {},
    authorities : [],
    contracts: [],
    role : ''
};

const userReducer = createReducer( initialState )( {
    [ constants.TEST ]: (state, action) => {
        return  { ...state, test : true };
    },
    [ constants.SET_TOKEN ]: (state, action) => {
        return  { ...state, token : action.token };
    },
    [ constants.SET_ISAUTHENTICATED ]: (state, action) => {
        return  { ...state, isAuthenticated : action.isAuthenticated };
    },
    [ constants.SET_ROLE ]: (state, action) => {
        return  { ...state, role : action.role, authorities : [action.role] };
    },
    [ constants.SET_USER_INFORMATION ]: (state, action) => {
        return  { ...state, userInformation : {...action.userInformation}, isAuthenticated : true };
    },
    [ constants.PROFILE_SET_CONTRACTS ]: (state, action) => {
        return  { ...state, contracts : action.contracts };
    },
} );

export default userReducer;
