import Actions from "./types";

const initialState = {
    sepa_list: {
        data: [],
    },
    sepa_list_loading: false,
    sepa_list_error: null,
    error: null,
    loading: false,
    sepa: {

    },
    sepa_loading: false,
    sepa_error: false,
};

function sepaReducer(state = initialState, { type, payload, error }) {
    switch (type) {
        case Actions.GET_SEPA:
            return state.set('sepa_list_loading', true);
        case Actions.GET_SEPA_LIST_SUCCESS:
            return state.set('sepa_list', payload).set('sepa_list_loading', false);
        case Actions.GET_SEPA_LIST_ERROR:
            return state.set('sepa_list_error', error).set('sepa_list_loading', false);
        case Actions.ADD_SEPA:
            return state.set('sepa_loading', true);
        case Actions.ADD_SEPA_SUCCESS:
            return state.set('sepa', payload).set('sepa_loading', false);
        case Actions.ADD_SEPA_ERROR:
            return state.set('sepa_error', error).set('sepa_loading', false);
        case Actions.UPDATE_SEPA:
            return state.set('sepa_loading', true);
        case Actions.UPDATE_SEPA_SUCCESS:
            return state.set('sepa', payload).set('sepa_loading', false);
        case Actions.UPDATE_SEPA_ERROR:
            return state.set('sepa_error', error).set('sepa_loading', false);
        default:
            return state;

    }
}

export default sepaReducer;
