import React, { Fragment, useState, useEffect, Component } from 'react';
import { getIn } from "formik";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FileUpload, Button, Textarea, Select, Alert, buildNotification, ConfirmationDialog, Loader, SplashScreenLoading } from 'Components';
import UploadFileTemp from '../../components/ResumableUploadFile/UploadFileTemp';
import FileUploadSection from "../../components/FileUploadSection";
import InputFile from "../../components/InputFile";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import _ from 'lodash';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import { settings } from 'Utils/settings';
import 'Assets/css/style.css';
import Resumablejs from "resumablejs";
import Apigproc from "../../../services/apigproc";

class InformeCreateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contract_deliverable_id: null,
            summary: '',
            observation: '',
            action: '',
            type_informe: '',
            informes: [],
            anexos: [],
            anexos_edit: [],
            anexos_rrhh: [],
            sending: false,
            informe: null,
            openConfirm: false
        }
    }

    resetState() {
        this.setState({
            contract_deliverable_id: null,
            summary: '',
            observation: '',
            action: '',
            type_informe: '',
            informes: [],
            anexos: [],
            anexos_edit: [],
            anexos_rrhh: [],
            sending: false,
            informe: null,
        });
    }

    componentWillReceiveProps(nextProps) {
        const { formInforme, deliverable, informe } = nextProps;
        if (formInforme) {
            this.setState(formInforme);
        }
        if (informe !== this.props.informe) {
            this.setState(informe);
        }
        if (deliverable) {
            if (!formInforme) {
                this.setState({
                    contract_deliverable_id: deliverable.id,
                    summary: '',
                    observation: '',
                    action: '',
                    type_informe: '',
                    informes: [],
                    anexos: [],
                    anexos_edit: [],
                    anexos_rrhh: [],
                    sending: false,
                });
            }
        }
    }

    componentDidMount() {

    }

    handleChange = (e) => {
        const { onChange } = this.props;
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            if (onChange) {
                onChange(this.state);
            }
        });
    };

    handleChangeFile = (name, value) => {
        const { onChange } = this.props;
        this.setState({ [name]: value }, () => {
            if (onChange) {
                onChange(this.state);
            }
        });
    };

    initUploader(files) {

    }

    processFile2 = async (data) => {
        console.log(data);
    }

    processFile = async (data) => {
        console.log(data);
        let { files } = this.state;
        _.each(data, f => {
            files.push(f);
        });
        this.setState({ files: files });
    }

    handleSubmit = async () => {

        //e.preventDefault();

        // eslint-disable-next-line no-restricted-globals
        /*if (confirm('Estas Seguro(a) de enviar Informe') == false) {
            return false;
        }*/

        this.setState({ sending: true });


        const { deliverable } = this.props;
        let dataSend = {
            contract_deliverable_id: deliverable.id,
            summary: this.state.summary,
            status_review: 2,
            attachments: [],
        };
        _.map(this.state.informes, f => {
            f.object_type_id = 3;
            dataSend.attachments.push(f);
        });

        _.map(this.state.anexos_edit, f => {
            f.object_type_id = 9;
            dataSend.attachments.push(f);
        });

        _.map(this.state.anexos_rrhh, f => {
            f.object_type_id = 1;
            dataSend.attachments.push(f);
        });

        _.map(this.state.anexos, f => {
            f.object_type_id = 7;
            dataSend.attachments.push(f);
        });

        Apigproc.informe.create(dataSend).then(response => {
            this.setState({ sending: false });
            if (this.props.onSuccess) {
                this.props.onSuccess(response.data);
                this.resetState();
            }
        }).catch(e => {
            this.setState({ sending: false });
        });

    }

    getAttachementsFilter() {

    }

    render() {

        const { deliverable } = this.props;
        const { summary, observation, type_informe, files, informes, anexos, anexos_edit, anexos_rrhh } = this.state;

        return <Fragment>

            <div class="kt-portlet">

                

                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">Formulario para envío de Informe</h3>
                    </div>
                </div>
                <form class="kt-form kt-form--fit kt-form--label-right" method="POST"  >



                    <div class="kt-portlet__body">


                        <div className="form-group">
                            <label><strong>Escribir mensaje</strong> (obligatorio)</label>
                            {/*<Textarea
                                classStyles={{}}
                                name="summary"
                                value={summary}
                                processHtml={true}
                                onInit={editor => {
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    console.log("OBJ: ", { target: { name: 'summary', data } });
                                    this.handleChange({ target: { name: 'summary', value: data } });
                                }}
                            />*/}

                            <textarea
                                name="summary"
                                class="form-control"
                                data-provide="markdown"
                                rows="6"
                                onChange={this.handleChange}
                                value={summary}
                                required></textarea>
                        </div>

                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label><strong>Informe Visado</strong> (obligatorio)</label>
                                    <InputFile name={"informes"} maxFiles={1} objects={informes} onChange={(objects) => this.handleChangeFile('informes', objects)} />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label><strong>Informe Editable </strong> (recomendado para revision)</label>
                                    <InputFile name={"anexos_edit"} maxFiles={1} objects={anexos_edit} onChange={(objects) => this.handleChangeFile('anexos_edit', objects)} />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label><strong>Comprobante de Pago</strong></label>
                                    <InputFile name={"anexos_rrhh"} maxFiles={1} objects={anexos_rrhh} onChange={(objects) => this.handleChangeFile('anexos_rrhh', objects)} />
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label><strong>Adjuntar Anexos</strong></label>
                                    <FileUploadSection name={"anexos"} help={"Subir archivos PDF"} objects={anexos} onChange={(objects) => this.handleChangeFile('anexos', objects)} />
                                </div>
                            </div>

                        </div>



                        <div className="form-group">
                            <ConfirmationDialog open={this.state.openConfirm} message={"Estas Seguro(a) de enviar Informe"} onConfirm={(result) => {
                                this.setState({ openConfirm: false }, () => {
                                    if(result){
                                        this.handleSubmit();
                                    }                                    
                                });
                            }} />

                            <Button
                                classStyles={{ 'btn-brand': true, 'btn-success': true }}
                                name="ENVIAR INFORME"
                                loading={this.state.sending}
                                type="button"
                                disabled={this.state.sending || this.state.summary === '' || this.state.informes.length === 0}
                                onClick={() => this.setState({ openConfirm: true })}
                            />
                        </div>
                    </div>


                </form>
            </div>
        </Fragment>
    }
}

export default InformeCreateForm;