

import { default as HttpClient } from './HttpClient';

const  ApigprocProfile =  {

    profesional: () => {     
        return HttpClient.get('/profile/profesional');
    },

    getContracts: (params) => {     
        return HttpClient.get('/profile/contracts', {params});
    },
}

export default ApigprocProfile;


