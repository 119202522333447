import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";

const Tabs = ({ children }) => {

  const [tabActive, setTabActive] = useState(0);

  return (
    <div>
      <ul class="nav nav-pills nav-fill">
        {children.map((tab, index) => {

          const { name } = tab.props;

          return (
            <li key={index} class="nav-item" onClick={() => setTabActive(index)}>
              <a
                class={`nav-link ${tabActive === index ? "active" : null}`}
                href="#"
              >
                <i class={``}></i> {name}
              </a>
            </li>
          );
        })}
      </ul>
      <div class="tab-content">
        <div class="tab-pane active">
          <div className="p-2">{children[tabActive]}</div>
        </div>
      </div>
    </div>
  );
};

Tabs.propTypes = {};

export default Tabs;
