import React from 'react';

const Portlet = (props) => {

    const { title, toolbarContent, bodyContent, classTitle, classStyles = '', submitButton = '' } = props

    return (
        <React.Fragment>
            <div className={`kt-portlet kt-portlet--height-fluid ${classStyles}`}>
                {(title || toolbarContent) && <div class="kt-portlet__head kt-portlet__head--noborder">
                    {title && <div class="kt-portlet__head-label d-flex justify-content-between w-100">
                        <h3 class="kt-portlet__head-title" className={classTitle} >
                            {title}
                        </h3>
                        {submitButton}
                    </div>}
                    <div class="kt-portlet__head-toolbar">
                        {toolbarContent}
                    </div>
                </div>}
                <div class="kt-portlet__body">
                    {bodyContent}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Portlet;
