import React from 'react';
import classNames from 'classnames/bind';

const Checkbox = ({
    classStyles,
    label="Label",
    required=false,
    disabled=false,
    ...props
}) => {
    let styles = classNames({
        'form-control': true,
        ...classStyles
    });
    return (
        <React.Fragment>
            <div className="kt-widget2__checkbox">
                <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                    <input 
                        type="checkbox" 
                        className={styles} 
                        required={required ? required : false} 
                        value={props.value ? props.value : 'on'}
                        disabled={props.disabled ? props.disabled : false}
                        {...props}  />
                    {label}
                    <span></span>
                </label>
            </div>
        </React.Fragment>
    );
};

export default Checkbox;

