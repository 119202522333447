

import { default as HttpClient } from './HttpClient';

const  ApigprocInforme =  {

    findAll: (query) => {     
        return HttpClient.get('/sepas', {params: query});
    },

    create: (data) => {     
        return HttpClient.post('proyects/1/contract_informes', data);
    },

    update: (data) => {     
        return HttpClient.put('proyects/1/contract_informes', data);
    },

}

export default ApigprocInforme;


