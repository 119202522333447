import _ from 'lodash';
import {  constants } from "./types";
import { createRequest } from "Utils/createRequest";
import history from 'Utils/history';
import { routes } from 'Utils/constants';
import { settings } from 'Utils/settings';
import buildNotification from 'Components/Notification';

import apigproc from '../../utils/apigproc';

export const setToken = (token) => 
({
    type: constants.SET_TOKEN,
    token
});

export const setIsAuthenticated = (isAuthenticated) => 
({
    type: constants.SET_ISAUTHENTICATED,
    isAuthenticated
});

export const setRole = (role) =>
({
  type : constants.SET_ROLE,
  role
});

export const resetLogout = () => ({ type : constants.LOGOUT });

export const setInformationUser = (userInformation) =>
({
  type : constants.SET_USER_INFORMATION,
  userInformation
});

  export const queryAuth = query => ({
    requestParams: {
      method: 'post',
      url: `/auth/login`,
      params: query
    },
    requestId: constants.AUTH
  });


  export function auth(params, query) {
    return async dispatch => {
      try {
        const res = await dispatch(createRequest(queryAuth(params)));
        
        const response = res.data;

        const { status, data } = response;

        if(status === 'Success')
        {
          buildNotification('success', 'Usuario logueado correctamente..', 'Éxito');

          dispatch(setToken(data.access_token));

          localStorage.setItem("token", data.access_token);

          let informationUser =  await dispatch(getInformationUser());

          let slug = _.head(informationUser.roles).slug;
          await dispatch(setRole(slug));

          decideRouteApplication(slug, query);
        }
      } catch (error) {
        console.log("ERROR: ", error);
        buildNotification('error', 'Error de credenciales, intentar nuevamente..', 'Error');
      }
    };
  }

  /*export function validatetoken(access_token) {
    return async dispatch => {
      try {
          dispatch(setToken(access_token));
          localStorage.setItem("token", access_token);
          let informationUser =  await dispatch(getInformationUser());
          let slug = _.head(informationUser.roles).slug;
          await dispatch(setRole(slug));
          decideRouteApplication(slug, query);
      } catch (error) {
        console.log("ERROR: ", error);
        buildNotification('error', 'Error de credenciales, intentar nuevamente..', 'Error');
      }
    };
  }*/

  export function  decideRouteApplication(slug, query) {
      if(query["page"]){
        history.push(query["page"]);
      }else{
        if(slug == settings.ROLES['PROYECT::ADMIN'].name)
        {
          history.push(routes.DASHBOARD);
        }
        else if(slug == settings.ROLES["PROYECT::SUPERVISOR"].name)
        {
          history.push(routes.SUPERVISOR.DASHBOARD);
        }
        else if(slug == settings.ROLES["PROYECT::CORDINATOR"].name)
        {
          history.push(routes.CORDINATOR.CONTRACTS);
        }else{ // slug == settings.ROLES["PROYECT::PROVEEDOR"].name
          history.push(routes.PROVIDER.DELIVERABLES);
        }
      }
  }

  export function register(obj) {
    return async dispatch => {
      try {
        const params = {
          requestParams: {  
            method: 'POST',
            url: `/register`,
            data: obj
          },
          requestId: constants.REGISTER
        };

        const response = await dispatch(createRequest(params));

        const { status, data } = response;

        if(data.status === "Success")
        {
          history.push(routes.LOGIN);

          buildNotification('success', 'Usuario registrado correctamente, revisar correo electrónico para confirmar el registro..', 'Éxito');
        }
      }
      catch (error) 
      {
        const { response } = error.request;

        if(response.errors && response.errors.email)
        {
          buildNotification('error', response.errors.email[0], 'Error');
        }

        console.log("ERROR REGISTER: ", error.request);
      }
    }
  };

  export function logout() {
    return async dispatch => {
      try {
        window.localStorage.clear();
        await dispatch(resetLogout());
        /*
        const params = {
          requestParams: {  
            method: 'POST',
            url: `/auth/logout`,
            headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          },
          requestId: constants.LOGOUT
        };

        const response = await dispatch(createRequest(params));

        const { status, data } = response;

        if(data.status === "Success")
        {
          localStorage.removeItem('token');

          await dispatch(resetLogout());
        }
        */
      }
      catch (error) 
      {
        console.log("ERROR LOGOUT: ", error);
      }
    }
  };

  export function getInformationUser() {
    return async dispatch => {
      try {
        const params = {
          requestParams: {  
            method: 'POST',
            url: `/auth/me?with=cvTitles,cvCourses,cvLanguages,cvSpecialities,cvReferences,cvExperiences,cvSalaries,cvProfiles,avatar,roles,events`,
            headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          },
          requestId: constants.GET_USER_INFORMATION
        };

        const response = await dispatch(createRequest(params));

        const { status, data } = response;

        dispatch(setInformationUser(data.data));

        return data.data;
      }
      catch (error) 
      {
        console.log("ERROR GETUSERINFORMATION: ", error);
      }
    }
  };

  export function resetPassword(obj) {
    return async dispatch => {
      try {
        const params = {
          requestParams: {  
            method: 'PUT',
            url: `/auth/password`,
            data: obj,
            headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          },
          requestId: constants.RESET_PASSWORD
        };

        const response = await dispatch(createRequest(params));

        const { status, data } = response;

        if(data.status === "Success")
        {
          buildNotification('success', 'Password cambiado con éxito..', 'Éxito');
        }
      }
      catch (error) 
      {
        console.log("ERROR REGISTER: ", error.request);
        buildNotification('error', 'Error al cambiar contraseña..', 'Error');
      }
    }
  };


  export function forgotPassword(obj) {
    return async dispatch => {
      try {
        const params = {
          requestParams: {  
            method: 'POST',
            url: `/forgotPassword`,
            data: obj,
          },
          requestId: constants.FORGOT_PASSWORD
        };

        const response = await dispatch(createRequest(params));

        const { status, data } = response;

        if(data.status === "Success")
        {
          buildNotification('success', 'Solicitud de recuperación de contraseña enviado', 'Éxito');
        }
      }
      catch (error) 
      {
        console.log("ERROR REGISTER: ", error.request);
        buildNotification('error', 'Error al cambiar contraseña..', 'Error');
      }
    }
  };


export const setContracts = (contracts) => 
({
    type: constants.PROFILE_SET_CONTRACTS,
    contracts
});

export function getContracts(params){
  return async dispatch => {
    try {
      params = {...params, with: 'deliverables,provider,contractType,documentContract,responsable'};
      const {data: {status, data}} = await apigproc.profile.getContracts(params);
      if(status === "Success")
      {
        //buildNotification('success', 'Mi lista de contratos', 'Éxito');
        dispatch(setContracts(data));
      }
    }
    catch (error) 
    {
      console.log("ERROR REGISTER: ", error);
      //buildNotification('error', 'Mi lista de contratos', 'Error');
    }
  }
}