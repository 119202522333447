import React from 'react';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = (props) =>
{    
    const monthsBG = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const daysBG = ['Dom','Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];    
    registerLocale('bg', {
        localize: {
            month: n => monthsBG[n],
            day: n => daysBG[n]
        }, 
        formatLong:{} 
    });
    //setDefaultLocale("es");

    if(props.selected){

    }
    
    const ExampleCustomInput = ({ value, onClick }) => (
        <button className="btn btn-default" type="button" onClick={onClick}>
          <i class="flaticon2-calendar-9"></i> {value ? value : '-- / -- / ----'}
        </button>
    );

    //return <DatePicker {...props} />    
    return <DatePicker
        locale={"bg"}
        dateFormat = "dd/MM/yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown   
        dropdownMode = "select"
        customInput={<ExampleCustomInput />}
        {...props}
    />   
};

export default CustomDatePicker;