import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as moment from 'moment';
import  * as utils from 'Utils/utils';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import Date from 'Components/Date';
import { settings } from 'Utils/settings';
import Badge from 'Components/Badge';

const MeansOfVerification = ({ verification, providerOperations, informe }) =>
(
    <div class="col-md-12 kt-margin-t-10">
        <div class="d-flex align-items-center">
            <div class="col-md-2">
                <a href={`${verification.object._url}`} target="_blank">
                    <button title="Descargar medio de verificación" type="button" class="btn btn-outline-success btn-elevate btn-icon"><i class="fa fa-cloud-download-alt"></i></button>
                </a>
            </div>
            <div class="col-md-8">
                <span class="kt-padding-0 d-block">
                    <strong>Nombre:</strong> { verification.name }
                </span>
                {(verification.informe_type_id != 0) &&
                    <span class="kt-padding-0 d-block">
                        <strong>Tipo:</strong> { verification.informe_type_id ? settings.TYPE_INFORME[verification.informe_type_id].name : '-' }
                    </span>
                }
                <span class="kt-padding-0 d-block">
                    <strong>Status :</strong> { <Badge label={`${settings.STATUS_INFORME[verification.status_review].label}`} name={settings.STATUS_INFORME[verification.status_review].name} /> }
                </span>
                { verification.status_review == settings.STATUS_INFORME[4].id &&
                    <span class="kt-padding-0 d-block">
                        <strong>Observación :</strong> { verification.reviews.length ? _.head(verification.reviews).observation : '-'}
                    </span>
                }
                <span class="kt-margin-t-10 d-flex align-items-center">
                    <strong>F. Creación :</strong> 
                    <div class="kt-widget__label kt-margin-l-10">
                        <Date 
                            alert='success'
                            date={verification.created_at}
                            outputFormat='DD-MM-YYYY h:mm:ss a'
                        />
                    </div>
                </span>
                <span class="kt-margin-t-10 d-flex align-items-center">
                    <strong>F. Actualización :</strong> 
                    <div class="kt-widget__label kt-margin-l-10">
                        <Date 
                            alert='warning'
                            date={verification.updated_at}
                            outputFormat='DD-MM-YYYY h:mm:ss a'
                        />
                    </div>
                </span>
            </div>
            <div class="col-md-2">
                <button 
                    type="button"
                    title="Eliminar Medio de verificación" class="btn btn-danger btn-elevate btn-icon"
                    onClick={() => { if(window.confirm("¿Estás seguro que quieres eliminar?")) providerOperations.deleteFile(verification.id, informe.id) } }>
                        <i class="fa fa-window-close"></i>
                </button>
            </div>
        </div>
    </div>
);

const mapDispatchToProps = (dispatch) => ({
    providerOperations: bindActionCreators(ProvidersActionsCreators, dispatch)
});


export default connect(null, mapDispatchToProps)(MeansOfVerification);