import React, { Component } from 'react';
import Datatable from 'Components/Datatable';
import Activity from 'Views/Contracts/Activity';
import Select from 'Components/Select';
import File from 'Components/File';
import Button from 'Components/Button';
import { FileObject } from 'Components';
import * as SupervisorsActionsCreators from 'Ducks/supervisors/operations';
import * as EventsActionsCreators from 'Ducks/events/operations';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import * as ContractsActionsCreators from 'Ducks/contracts/operations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { settings } from 'Utils/settings';
import { Input } from 'Components';
import EditButton from 'Components/EditButton';
import Badge from 'Components/Badge';
import InformeReviewForm from "./InformeReviewForm";


const TypeFile = ({ id }) => {
    if (!id) {
        return '';
    }
    let val = _.find(settings.TYPE_INFORME, (v, i) => v.id == id);
    return val ? val.name : 'N.D.';
}

const InformeReviewItem = ({ deliverable, informe, renderForm }) => {
    return <div className="kt-portlet">
        <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">
                    <div style={{ marginBottom: '.5rem' }}>
                        PRODUCTO <span className="kt-font-primary">N°{deliverable.order}</span>
                                    - CONTRATO <span className="kt-font-primary">{deliverable.contract.contract_number}</span>
                                    - PROVEEDOR <span className="kt-font-primary">{deliverable.provider.name}</span>
                    </div>

                    <div>
                        <span style={{ fontWeight: '500' }} >{informe?.created_at}</span>
                    </div>
                </h3>
            </div>
        </div>
        <div className="kt-form kt-form--fit kt-form--label-right">
            <div className="kt-portlet__body">

                <div style={{ marginBottom: '1rem' }} dangerouslySetInnerHTML={{ __html: informe?.summary }} />

                <div className="form-group">
                    {_.map(informe?.verifications, (v, i) => {
                        return v.object && <FileObject key={i} object={v.object} type={"ANEXO"} object_type_id={v.object_type_id} />
                    })}
                </div>


                {informe.reviews.length > 0 && <h5>Revisión del producto</h5>}
                <div className="form-group">


                    {_.map(informe.reviews, (v, i) => {
                        return <div className="card mb-1" >
                            <div className="card-body">
                                <div>
                                    <strong>{v.user?.name}</strong> {<Badge label={settings.STATUS_INFORME[v.status_review].label} name={settings.STATUS_INFORME[v.status_review].name} />}
                                </div>
                                <div style={{ marginBottom: '.5rem', fontWeight: '500' }}><span>{v?.created_at}</span></div>

                                <div style={{ marginBottom: '1rem' }} dangerouslySetInnerHTML={{ __html: v?.summary }} />

                                {v.document_review && <FileObject object={v.document_review} type={"Documento adjunto"} />}
                            </div>
                        </div>
                    })}
                </div>


                {renderForm}


            </div>


        </div>
    </div>

}

class EditDeliverableSupervisor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deliverable: {},
            sendReview: false,
        };

        const { dispatch } = props;

        this.eventsActionsCreators = bindActionCreators(EventsActionsCreators, dispatch);

        this.providersActionsCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

        this.contractsActionsCreators = bindActionCreators(ContractsActionsCreators, dispatch);

        this.supervisorsActionsCreators = bindActionCreators(SupervisorsActionsCreators, dispatch);
    }

    componentWillMount() {
        const { pathname, search, hash, state } = this.props.location;
        //this.setState({ ...state.deliverable });
        this.setState({ deliverable: { ...state.deliverable } });
    }

    dataInit() {
        const { deliverable } = this.state;
        //this.supervisorsActionsCreators.getInformesByDeliverable(deliverable.id);
        this.supervisorsActionsCreators.getDeliverable(deliverable.id);
        //this.providersActionsCreators.getInformes(deliverable.id);
    }

    componentDidMount() {
        this.dataInit();
    }

    handlerCancel() {
        // eslint-disable-next-line no-restricted-globals
        /*if (confirm("Seguro que desea cancelar")) {
            this.setState({ action: null });
        }*/
        this.setState({ action: null });
    }

    render() {

        const { deliverable, listFormInformeReview } = this.props;

        //const { deliverable } = this.state;

        if (!deliverable || !listFormInformeReview) {
            return '';
        }

        const informe = deliverable.informes.length > 0 ? deliverable.informes[0] : null;
        const formInformeReview = _.find(listFormInformeReview, v => v.contract_informe_id === informe?.id);

        const columns = [
            {
                label: 'Fecha de creación',
                field: 'created_at',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Fecha de actualización',
                field: 'updated_at',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Estado',
                field: 'status_review',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Editar',
                field: 'edit',
                sort: 'asc',
                width: 150
            }
        ];




        let informesHtml = _.map(deliverable.informes, (informe, index) => {
            return <InformeReviewItem deliverable={deliverable} informe={informe} 
            renderForm={index == 0 && deliverable.status_review == 2 && <InformeReviewForm informe={informe} deliverable={deliverable} formInformeReview={formInformeReview}
            onChange={(formInformeReview) => {
                this.supervisorsActionsCreators.saveFormInformeReview({ formInformeReview });
            }}
            onSuccess={(result) => {   

                this.supervisorsActionsCreators.removeFormInformeReview({ formInformeReview }); 
                setTimeout(() => {
                    this.props.history.push('/supervisor/deliverables');
                }, 1000);
                /*
                setTimeout(() => {
                    this.supervisorsActionsCreators.removeFormInformeReview({ formInformeReview }); 
                }, 300)
                this.supervisorsActionsCreators.getDeliverable(deliverable.id);  
                */                                                   
            }}

        />} />
        });


        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <h3 className="kt-widget1__title_">
                    CONTRATO <span className="kt-font-primary">{deliverable.contract.contract_number}</span>
                                    &nbsp; PROVEEDOR <span className="kt-font-primary">{deliverable.provider.name}</span>
                </h3>
                <div >
                    <FileObject object={deliverable.contract.document_contract} type="CONTRATO / OS" />
                    <FileObject object={deliverable.contract.document_tdr} type="TDR / ET" />
                </div>

                <div className="kt-portlet">
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div className="row row-no-padding row-col-separator-xl">
                            <div className="col-md-12 col-lg-12">
                                <div className={`kt-widget1 card-custom-${deliverable.status_review}`} style={{ paddingBottom: '1rem', paddingTop: '1rem' }} >
                                    <div className="kt-widget1__item">
                                        <div className="kt-widget1__info">
                                            <h3 className="kt-widget1__title_" style={{ verticalAlign: 'middle' }}>
                                                PRODUCTO <span className="kt-font-primary">N°{deliverable.order}</span> <Badge label={settings.STATUS_INFORME[deliverable.status_review].label} name={settings.STATUS_INFORME[deliverable.status_review].name} />
                                            </h3>

                                            {deliverable.description && <div style={{ marginBottom: '.5rem' }}>
                                                {deliverable.description}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        {informesHtml}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    informes: state.supervisors.informes,
    deliverable: state.supervisors.deliverable,
    listFormInformeReview: state.supervisors.listFormInformeReview,
    api: state.api
});

export default connect(mapStateToProps, null)(EditDeliverableSupervisor);