import React from "react";
import PropTypes from "prop-types";

const ErrorMessage = ({ message }) => {
  if (!message) return null;
  return <div class="error invalid-feedback">{message}</div>;
};

ErrorMessage.propTypes = {
};

export default ErrorMessage;
