import KeyMirror from 'keymirror';

export const constants = KeyMirror({
    TEST: null,
    GET_PROVIDERS : null,
    GET_PROVIDERS_BY_USER : null,
    GET_INSTITUTIONS : null,
    SET_INSTITUTIONS : null,
    SET_PROVIDERS_BY_USER : null,
    SET_COUNTRIES : null,
    SET_FINAL_REPORTS : null,
    SET_COURSE : null,
    UPDATE_COURSE : null,
    SET_LANGUAGE : null,
    DELETE_LANGUAGE : null,
    UPDATE_PERSONAL_INFORMATION : null,
    SET_SALARY : null,
    DELETE_SALARY : null,
    UPDATE_SALARY : null,
    DELETE_REFERENCE : null,
    SET_REFERENCE : null,
    UPDATE_REFERENCE : null,
    SET_EXPERIENCE : null,
    DELETE_EXPERIENCE : null,
    UPDATE_EXPERIENCE : null,
    //API
    SET_PROVIDERS : null,
    GET_INFORMES : null,
    SET_INFORMES : null,
    GET_PRODUCTS_BY_INFORME : null,
    SET_PRODUCTS_BY_INFORME : null,
    SET_DELIVERABLE_BY_INFORME: null,
    SET_CONTRACT_BY_INFORME: null,
    SUBMIT_MEANS_OF_VERIFICATIONS : null,
    DELETE_MEAN_OF_VERIFICATION : null,
    CREATE_MEAN_OF_VERIFICATION : null,
    SET_VERIFICATIONS_OF_INFORME : null,
    CREATE_MEAN_OF_VERIFICATION_INFORME : null,
    CHANGE_STATUS_INFORME : null,
    GET_COUNTRIES : null,
    GET_FINAL_REPORTS : null,
    GET_PROFILES : null,
    SET_PROFILES : null,
    DELETE_ACADEMIC_TITLE : null,
    CREATE_PROVIDER: null,
    SAVE_FORM_INFORME: null,
    REMOVE_FORM_INFORME: null,
});
