import React, { Component } from 'react';
import Login from 'Views/Layout/types/Login';
import System from 'Views/Layout/types/System';
import { routes } from 'Utils/constants';

class Layout extends Component
{
    constructor(props)
    {
        super(props);

        this.layoutComponent = this.layoutComponent.bind(this);
    }

    layoutComponent = (pathname) => 
    {
        const { children } = this.props;

        switch (pathname) {
          case routes.LOGIN:
            return <Login children={children} />

          default: return <System children={children} />
        }
    }

    render()
    {
        const { location, children } = this.props;

        return this.layoutComponent(location.pathname)
    }
}

export default Layout; 