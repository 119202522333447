import { constants } from "./types";
import createReducer  from "Utils/createReducer";

/* State shape
{
    redirectRoute: {},
    currentGeolocation: { lat, lng, status }
}
*/

const initialState = {
    test : false,
    users : []
};

const usersReducer = createReducer( initialState )( {
    [ constants.TEST ]: (state, action) => {
        return  { ...state, test : true };
    },
    [ constants.SET_USERS ]: (state, action) => {
        return  { ...state, users : action.users };
    }
} );

export default usersReducer;
