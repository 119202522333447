import React from "react";
import classNames from "classnames/bind";
import _ from "lodash";

const Radio = (props) => {
  let styles = classNames({
    "is-invalid": props.hasErrors,
    ...props.classStyles,
  });
  return (
    <React.Fragment>
      <label className="kt-radio">
        <input
          id={props.id}
          name={props.name}
          required={props.required ? props.required : false}
          onChange={props.changed}
          value={props.value}
          type="radio"
          checked={props.isSelected}
          className={styles}
        />
        {props.label}
        <span></span>
      </label>
    </React.Fragment>
  );
};

export default Radio;
