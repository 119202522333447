import {  constants } from "./types";
import { createRequest } from "Utils/createRequest";
import history from 'Utils/history';
import { routes } from 'Utils/constants';
import _ from 'lodash';
import buildNotification from "../../views/components/Notification";

export const setUsers = (users) => 
({
    type: constants.SET_USERS,
    users
});

  export const queryAuth = query => ({
    requestParams: {
      method: 'post',
      url: `/auth`,
      params: query
    },
    requestId: constants.AUTH
  });

  export function getUsers(numberPage = 1, parameters) {
    return async dispatch => {

      try {
        const params = {
          requestParams: {
            method: 'GET',
            url: `/acl/users?with=cvTitles`,
            headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
            params: parameters
          },
          requestId: constants.GET_USERS
        };

        const res = await dispatch(createRequest(params));
        
        const response = res.data;

        const { status, data } = response;

        if(status === 'Success')
        {
          //Redirect to Dashboard
          dispatch(setUsers(data));
        }
      } catch (error) 
      {
        console.log("ERROR: ", error);
      }
    };
  }

  export function createUser(user) {
    return async dispatch => {
      try {
        const params = {
          requestParams: {
            method: 'POST',
            url: `/auth/registerUser`,
            headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
            data: user
          }
        }

        const res = await dispatch(createRequest(params));

        const response = res.data;

        const { status, data } = response;

        console.log('response', response)

        if (status === 'Success') {
          history.push(routes.USERS);
          buildNotification('success', response.message)
        }
      } catch ({ response }) {
        return response;
      }
    }
  }