// async component
import {
   AsyncContractsComponent,
   AsyncNewContractComponent,
   AsyncEditContractComponent
} from './asyncComponent';

export default [
   {
      path: '/',
      component: AsyncContractsComponent
   },
   {
      path : '/new-contract',
      component : AsyncNewContractComponent
   },
   {
      path : '/edit-contract',
      component : AsyncEditContractComponent
   }
] 