import React from "react";

const FormError = ({ errors, show }) => {
  if (!show) {
    return null;
  }

  return (
    <div class="alert alert-solid-danger alert-bold flex-column" role="alert">
      <div class="alert-text align-self-auto">
        <ul style={{ margin: 0 }}>
          {errors.map((error, index) => {
            let listErrors = [];
            if (Array.isArray(error)) {
              listErrors = error.map((error, key) => (
                <li key={`${index}${key}`}>{error}</li>
              ));
            } else {
              listErrors.push(<li key={`${index}`}>{error}</li>);
            }
            return listErrors;
          })}
        </ul>
      </div>
    </div>
  );
};
export default FormError;
