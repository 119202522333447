import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ProviderStore } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { configureStore } from 'Redux/store';

import './index.css';
import './assets/css/login-3.css';
import './assets/css/perfect-scrollbar.css';
import './assets/css/style.bundle.css';
import './assets/css/base-light.css';
import './assets/css/menu-light.css';
import './assets/css/brand-dark.css';
import './assets/css/aside-dark.css';
import './assets/css/fontawesome-free/css/all.min.css';
import './assets/css/flaticon/flaticon.css';
import './assets/css/flaticon2/flaticon.css';

import Routes from 'Routes/';
import { Loader } from 'Components';

const { store, persistor } = configureStore();

ReactDOM.render(
  <ProviderStore store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <Routes />
    </PersistGate>
  </ProviderStore>,
  document.getElementById('root')
);