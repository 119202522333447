import React, { useState, useEffect } from 'react';
import { Button, Input, CustomButton } from 'Components';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import _ from 'lodash';
import animateScrollTo from 'animated-scroll-to';

const References = ({ cv_references }) =>
{
    const dispatch = useDispatch();

    const [references , setReferences] = useState([]);
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [name, setName] = useState('');
    const [year, setYear] = useState('');
    const [phone, setPhone] = useState('');
    const [id, setId] = useState('');
    const [edit, setEdit] = useState(false);

    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        console.log("USE EFFECT REFERENCES");
        setReferences(cv_references);
    }, [cv_references]);

    const onSubmit = async (e) =>
    {
        e.preventDefault();

        await dispatch(ProvidersActionsCreators.setReference(edit ? { company, position, name, year, phone, id } : { company, position, name, year, phone }));

        setShowForm(false);
        setEdit(false);
    }

    const onEdit = (reference) => 
    {
        setShowForm(true);
        if(_.isEmpty(reference))
        {
            setCompany('');
            setPosition('');
            setName('');
            setYear('');
            setPhone('');
            setId('');
            setEdit(false);
        }
        else
        {
            setCompany(reference.company);
            setPosition(reference.position);
            setName(reference.name);
            setYear(reference.year);
            setPhone(reference.phone);
            setId(reference.id);
            setEdit(true);
        }
    }

    console.log("REFERENCES: ", references);

    return (
        <>

            {!showForm && <div>
                <h2>REFERENCIAS LABORALES</h2>
                <p>En la presente sección deberá detallar como mínimo las referencias personales correspondientes a las dos últimas instituciones donde estuvo trabajando.</p>
                <button onClick={() => { onEdit(false) }} className="btn btn-success btn-sm" >REGISTRAR REFERENCIA LABORAL</button>                

                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true">
                    <div className="kt-notes__items">
                        {
                            _.map(references, (reference) => (
                                <ReferencesLaboral reference={reference} onEdit={onEdit} />
                            ))
                        }
                    </div>
                </div>
            </div>} 

            {showForm && <form method="POST" onSubmit={onSubmit}>

                {!edit && <h2>NUEVO REFERENCIA LABORAL</h2>}
                {edit && <h2>EDITAR REFERENCIA LABORAL</h2>}
                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>

                <div className="row">
                <div className="form-group col-md-6 col-lg-4">
                    <label>Nombre de la institución o empresa</label>
                    <Input 
                        classStyles={ {} } 
                        label="Compañía"
                        placeholder=""
                        type="text"
                        name="company"
                        required={true}
                        value={company}
                        maxlength="50"
                        required={true}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                </div>
                <div className="form-group col-md-6 col-lg-4">
                    <label>Cargo de la referencia</label>
                    <Input 
                        classStyles={ {} } 
                        label="Posición"
                        placeholder=""
                        type="text"
                        name="position"
                        required={true}
                        value={position}
                        maxlength="60"
                        required={true}
                        onChange={(e) => setPosition(e.target.value)}
                    />
                </div>
                <div className="form-group col-md-6 col-lg-4">
                    <label>Nombre de la persona</label>
                    <Input 
                        classStyles={ {} } 
                        label="Nombres"
                        placeholder=""
                        type="text"
                        name="name"
                        required={true}
                        value={name}
                        maxlength="50"
                        required={true}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="form-group col-md-6 col-lg-4">
                    <label>Año</label>
                    <Input 
                        classStyles={ {} } 
                        label="Año"
                        placeholder=""
                        type="text"
                        name="year"
                        required={true}
                        value={year}
                        title="Ingresar solo números"
                        maxlength="4"
                        pattern="[0-9]+"
                        required={true}
                        onChange={(e) => setYear(e.target.value)}
                    />
                </div>
                <div className="form-group col-md-6 col-lg-4">
                    <label>Teléfono de la referencia</label>
                    <Input 
                        classStyles={ {} } 
                        label="Teléfono"
                        placeholder=""
                        type="text"
                        name="phone"
                        required={true}
                        value={phone}
                        maxlength="15"
                        required={true}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                </div>

                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                <div className="row">
                    <div className="col">
                        <Button 
                            classStyles={ { 'btn' : true, 'btn-default' : true } }
                            onClick={() => {setShowForm(false)}}
                            type="button"
                            name="CANCELAR"
                        />&nbsp;
                        <Button 
                            classStyles={ { 'btn' : true, 'btn-success' : true } }
                            type="submit"
                            name="GUARDAR"
                        />
                    </div>
                </div>
            </form>}
            
        </>
    )
}

const ReferencesLaboral = ({ reference, onEdit }) =>
{
    const dispatch = useDispatch();

    const [edit, setEdit] = useState(false);

    const deleteReference = async () =>
    {
        let providersActionCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

        let result = await providersActionCreators.deleteReference(reference.id);
    };

    useEffect( () => {
        console.log("USEEFFECT");
        setEdit(false);
      }, [ reference ]
     );

    return (
        <div className="kt-notes__item"> 
            <div className="kt-notes__media">
            <span className="kt-notes__icon">
                <i className="fa fa-user kt-font-warning"></i>
            </span>                              
            </div>    
            <div className="kt-notes__content"> 
                <div className="kt-notes__section">     
                    <div className="kt-notes__info">
                        <a href="#" className="kt-notes__title kt-font-bolder">
                            REFERENCIA LABORAL:                       
                        </a>
                        <span className="kt-notes__desc">
                            
                        </span>
                    </div>
                    <div className="kt-notes__dropdown"> 
                        {edit &&
                            <CustomButton 
                                classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-warning' : true,'btn-icon' : true }}
                                icon="fa fa-window-close"
                                style={{ marginRight : '.7em' }}
                                onClick={() => { onEdit({}); setEdit(false) }} />
                        }
                        {! edit &&
                            <CustomButton 
                                classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-success' : true,'btn-icon' : true }}
                                icon="fa fa-edit"
                                style={{ marginRight : '.7em' }}
                                onClick={() => { onEdit(reference); setEdit(true); animateScrollTo(document.querySelector('.frm-cv')); }} />
                        }
                        <CustomButton 
                            classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-danger' : true,'btn-icon' : true }}
                            icon="fa fa-trash-alt"
                            onClick={() => { if(window.confirm("¿Estas seguro de eliminar?")) deleteReference() } } />
                    </div>
                </div>
                <span className="kt-notes__body">                                        
                    <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-building" /> { reference.company }</p>
                    <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-money-check-alt" />{ reference.position }</p>
                    <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-user" />{ reference.name }</p>
                    <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-calendar-alt" />{ reference.year } </p>
                    <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-phone-alt" />{ reference.phone } </p>
                </span>  
            </div>                                             
        </div>
    )
}

export default References;