import {  constants } from "./types";
import { createRequest } from "Utils/createRequest";
import history from 'Utils/history';
import { routes } from 'Utils/constants';
import { settings } from 'Utils/settings';
import _ from 'lodash';

export const setContracts = (contracts) => 
({
    type: constants.SET_CONTRACTS,
    contracts
});

export const setActivitiesForEvent = (activities) =>
({
  type : constants.SET_ACTIVITIES_FOR_EVENT,
  activities
});

export function getContracts(page = 1, parameters) {
  return async dispatch => {
    try {
            
      let queryString = {...page};

      if(_.has(parameters, 'responsable_id')) queryString.responsable_id = parameters.responsable_id;
      if(_.has(parameters, 'responsable_name')) queryString.responsable_name = parameters.responsable_name;
      if(_.has(parameters, 'provider_name')) queryString.provider_name = parameters.provider_name;
      if(_.has(parameters, 'contract_number')) queryString.contract_number = parameters.contract_number;
      if(_.has(parameters, 'deliverable_order')) queryString.deliverable_order = parameters.deliverable_order;
      if(_.has(parameters, 'contract_number')) queryString.contract_number = parameters.contract_number;
      if(_.has(parameters, 'event_id')) queryString.event_id = parameters.event_id;
      if(_.has(parameters, 'status_review')) queryString.status_review = parameters.status_review;
      if(_.has(parameters, 'proyect_activity_id')) queryString.proyect_activity_id = parameters.proyect_activity_id;

      /*queryString = `&page=${page}
        ${_.has(parameters, 'responsable') ? `&responsable_name=${parameters.responsable_name}` : ``}
        ${_.has(parameters, 'provider') ? `&provider_name=${parameters.provider}` : ``}
        ${_.has(parameters, 'type_contract') ? `&provider_name=${parameters.provider}` : ``}
        ${_.has(parameters, 'order') ? `&order=${parameters.order}` : ``}
        ${_.has(parameters, 'contract_number') ? `&contract_number=${parameters.contract_number}` : ``}
        ${_.has(parameters, 'event_id') ? `&event_id=${parameters.event_id}` : ``}
        ${_.has(parameters, 'evaluador_id') ? `&evaluador_id=${parameters.evaluador_id}` : ``}
        ${_.has(parameters, 'status_review') ? `&status_review=${parameters.status_review}` : ``}
        ${_.has(parameters, 'proyect_activity_id') ? `&proyect_activity_id=${parameters.proyect_activity_id}` : ``}
      `;

      queryString += "";*/

      queryString.with = 'contractType,provider,documentTdr,documentContract,responsable,deliverables,deliverables.documentCompliance,event';

      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contracts`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          params: queryString
        },
        requestId: constants.GET_CONTRACTS
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setContracts(data));
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function getDetailDeliverable(deliverable_id) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contract_deliveries/${deliverable_id}?with=products,contract.documentContract,contract.documentTdr,tramites.documento.adjunto,tramites.documento.anexos`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_DETAIL_DELIVERABLE
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setContracts(data));
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
}

export function getActivitiesForEvent(event)
{
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/activities?event_id=${event}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_ACTIVITIES_FOR_EVENT
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setActivitiesForEvent(data));
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);
    }
  }
}