import { constants } from "./types";
import createReducer  from "Utils/createReducer";
import _ from "lodash";

const initialState = {
    providers : [],
    providersByUser : [],
    informes : [],
    products : [],
    verifications : [],
    institutions : [],
    finalReports : [],
    listFormInforme: [],
};

const providersReducer = createReducer( initialState )( {
    [ constants.SET_PROVIDERS ]: (state, action) => {
        return  { ...state, providers : action.providers };
    },
    [ constants.SET_PROVIDERS_BY_USER ]: (state, action) => {
        return  { ...state, providersByUser : action.providers };
    },
    [ constants.SET_INFORMES ]: (state, action) => {
        return  { ...state, informes : action.informes.informes };
    },
    [ constants.SET_PRODUCTS_BY_INFORME ]: (state, action) => {
        return  { ...state, products : action.products };
    },
    [ constants.SET_DELIVERABLE_BY_INFORME ]: (state, action) => {
        return  { ...state, deliverable : action.deliverable };
    },
    [ constants.SET_CONTRACT_BY_INFORME ]: (state, action) => {
        return  { ...state, contract : action.contract };
    },
    [ constants.SET_VERIFICATIONS_OF_INFORME ]: (state, action) => {
        return  { ...state, verifications : action.verifications };
    },
    [ constants.SET_INSTITUTIONS ]: (state, action) => {
        return  { ...state, institutions : action.institutions };
    },
    [ constants.SET_FINAL_REPORTS ]: (state, action) => {
        return  { ...state, finalReports : action.finalReports };
    },
    [ constants.SAVE_FORM_INFORME ]: (state, action) => {

        let {listFormInforme} = state;
        const {formInforme} = action;

        const index = _.findIndex(listFormInforme, i => i.contract_deliverable_id === formInforme?.contract_deliverable_id);
        if(index !== -1){
            listFormInforme[index] = formInforme;
        }else{
            listFormInforme.push(formInforme);            
        }

        return  { ...state, listFormInforme : listFormInforme };
    },
    [ constants.REMOVE_FORM_INFORME ]: (state, action) => {

        let {listFormInforme} = state;
        const {formInforme} = action;

        const index = _.findIndex(listFormInforme, i => i.contract_deliverable_id === formInforme?.contract_deliverable_id);
        if(index !== -1){
            listFormInforme.splice(index, 1);
        }

        return  { ...state, listFormInforme : listFormInforme };
    },
} );

export default providersReducer;
