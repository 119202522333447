import React from 'react';
import { NavLink } from 'react-router-dom';
import { FlatIcon } from '../../components';

const Admin = () =>
(
    <ul class="kt-menu__nav ">
			<li class="kt-menu__item  kt-menu__item" aria-haspopup="true" >
                <NavLink
                    to="/dashboard"
                    activeClassName="selectedLink"
                    className="kt-menu__link ">
                        <FlatIcon color="success" icon="flaticon2-chart" />                    
                        <span className="kt-menu__link-text"> Dashboard</span>                    
                </NavLink>  
            </li>

            <li class="kt-menu__section ">
                <h4 class="kt-menu__section-text">MÓDULOS</h4>
                <i class="kt-menu__section-icon flaticon-more-v2"></i>
            </li>

            <li class="kt-menu__item  kt-menu__item" aria-haspopup="true" >
                <NavLink
                    to="/users"
                    activeClassName="selectedLink"
                    className="kt-menu__link ">
                        <FlatIcon color="success" icon="flaticon-users-1" />                    
                        <span className="kt-menu__link-text"> Usuarios</span>                    
                </NavLink>
            </li>

            <li class="kt-menu__item  kt-menu__item" aria-haspopup="true" >
                <NavLink
                    to="/provider/curriculum"
                    activeClassName="selectedLink"
                    className="kt-menu__link ">
                        <FlatIcon color="success" icon="flaticon2-checking" />                    
                        <span className="kt-menu__link-text"> Hoja de Vida</span>                    
                </NavLink>
            </li>

            <li class="kt-menu__item  kt-menu__item" aria-haspopup="true" >
                <NavLink
                    to="/contracts"
                    activeClassName="selectedLink"
                    className="kt-menu__link ">
                        <FlatIcon color="success" icon="flaticon-price-tag" />                    
                        <span className="kt-menu__link-text"> Contratos</span>                    
                </NavLink>
            </li>

            <li class="kt-menu__item  kt-menu__item" aria-haspopup="true" >
                <NavLink
                    to="/deliverables"
                    activeClassName="selectedLink"
                    className="kt-menu__link ">
                        <FlatIcon color="success" icon="flaticon2-box-1" />                    
                        <span className="kt-menu__link-text"> Entregables</span>                    
                </NavLink>
            </li>
            <li class="kt-menu__item  kt-menu__item" aria-haspopup="true" >
                <NavLink
                    to="/products"
                    activeClassName="selectedLink"
                    className="kt-menu__link ">
                        <FlatIcon color="success" icon="flaticon2-box" />                    
                        <span className="kt-menu__link-text"> Productos</span>                    
                </NavLink>
            </li>
            <li class="kt-menu__item  kt-menu__item" aria-haspopup="true" >
                <NavLink
                    to="/upload-file"
                    activeClassName="selectedLink"
                    className="kt-menu__link ">
                        <FlatIcon color="success" icon="flaticon2-box" />                    
                        <span className="kt-menu__link-text"> Upload</span>                    
                </NavLink>
            </li>
        </ul>
);

export default Admin;
