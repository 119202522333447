import { constants } from "./types";
import createReducer  from "Utils/createReducer";

const initialState = {
    products : [],
    activities : []
};

const productsReducer = createReducer( initialState )( {
    [ constants.SET_PRODUCTS ]: (state, action) => {
        return  { ...state, products : action.products };
    },
    [ constants.SET_ALL_ACTIVITIES ]: (state, action) => {
        return  { ...state, activities : action.activities };
    }
} );

export default productsReducer;
