import React from 'react';
import classNames from 'classnames/bind';

const Alert = ({
    message="Alerta",
    color="warning",
    subStyle,
    ...props
}) =>
{
    return(
        <div class={`alert alert-${color} alert-bold`} role="alert" {...props}>
            {props.icon && <div class="alert-icon"><i class={props.icon}></i></div>}
            <div class="alert-text" style={ subStyle }>{message}</div>
        </div>
    )

};

export default Alert;