import React from 'react';

const Footer = () => (
    <div className="kt-footer  kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
        <div className="kt-container  kt-container--fluid ">
            <div className="kt-footer__copyright">
                2019&nbsp;&copy;&nbsp;<a href="http://pmesut.gob.pe" target="_blank" className="kt-link">PMESUT</a>
            </div>
            <div className="kt-footer__menu">
            </div>
        </div>
    </div>
);

export default Footer;