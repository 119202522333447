import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import rootReducer from './reducer';
import commonMiddleware from 'Ducks/middleware';

const persistConfig = {
    key: 'root',
    storage,
}

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer)

export function configureStore(initialState) {

    //const enhancer = compose(applyMiddleware(sagaMiddleware));
    
    const store = createStore(
        persistedReducer,
        initialState,
        compose(applyMiddleware(thunk, logger, commonMiddleware, sagaMiddleware))
    );    
    
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('Redux/reducer', () => {
            const nextRootReducer = require('Redux/reducer');
            store.replaceReducer(nextRootReducer);
        });
    }
    

   //const store = createStore(persistedReducer, enhancer);

    let persistor = persistStore(store);

    return { store, persistor }
}
