// async component
import {
   AsyncProductsComponent,
   AsyncNewProductComponent,
   AsyncEditProductComponent
} from './asyncComponent';

export default [
   {
      path: '/',
      component: AsyncProductsComponent
   },
   {
      path : '/new-product',
      component : AsyncNewProductComponent
   },
   {
      path : '/edit-product',
      component : AsyncEditProductComponent
   }
] 