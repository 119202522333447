import React, { Component } from 'react';
import { Input } from 'Components';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as UserActionsCreators from 'Ducks/user/operations';
import classNames from 'classnames/bind';
import 'react-notifications/lib/notifications.css';
import { default as LayoutLogin } from 'Views/Layout/types/Login';
import { configureStore } from 'Utils/createStore';
const {store, persistor } = configureStore();

class Register extends Component
{
	constructor(props) 
	{
		super(props);

		this.state = {
            name : '',
			email : '',
            password: '',
            c_password : ''
		};

		const { dispatch } = props;

		this.userActionsCreators = bindActionCreators(UserActionsCreators, dispatch);

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount()
	{
		const { dispatch } = this.props;
		
		persistor.purge();

		this.userActionsCreators.resetLogout();
	}

	handleChange = (e) =>
    {
        const { name, value } = e.target;
        this.setState({ [ name ] : value });
	}
	
	keyPressed = (event) => {
        if (event.key === "Enter") {
          this.handleSubmit();
        }
    };

    onSubmit = async (e) =>
    {
        e.preventDefault();
        await this.userActionsCreators.register(this.state);

        this.setState({
            name : '',
			email : '',
            password: '',
            c_password : ''
        });
    }

    render()
    {
        const bg = {
            backgroundImage: 'url(./assets/img/bg-3.jpg)',
		};

		const { api } = this.props;

		let styles = classNames(
			'btn', 'btn-brand', 'btn-success', 'btn-elevate', api.loading ? 'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light' : ''
		);
            
        return (
			<div class="kt-grid kt-grid--ver kt-grid--root" style={{ height : '100%' }}>
            <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login" style={bg}>
				<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"    >
					<div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
						<div class="kt-login__container">
							<div class="kt-login__logo">
								<a href="javascript:void(0)">
									<img src="./assets/img/logo.png" />  	
								</a>
							</div>
							<div class="kt-login__signin">
							<div class="kt-login__head">
								<h3 class="kt-login__title">REGÍSTRATE</h3>
							</div>
							<form class="kt-form" method="POST" onSubmit={this.onSubmit}>
                                <div class="input-group">
                                    { <Input 
                                        classStyles={ {} } 
                                        label="Nombres"
                                        placeholder="Nombres"
                                        type="text"
                                        name="name"
                                        maxlength="20"
                                        value={this.state.name}
                                        onKeyPress={this.keyPressed}
                                        onChange={this.handleChange}
                                        required={true}
                                    /> }
                                </div>
                                <div class="input-group">
                                    { <Input 
                                        classStyles={ {} } 
                                        label="DIXONET"
                                        placeholder="Email"
                                        type="text"
                                        name="email"
                                        maxlength="25"
                                        value={this.state.email}
                                        onKeyPress={this.keyPressed}
                                        onChange={this.handleChange}
                                        required={true}
                                    /> }
                                </div>
                                <div class="input-group">
                                    { <Input 
                                        classStyles={ { 'dixonet' : true } } 
                                        label="DIXONET"
                                        placeholder="Password"
                                        type="password"
                                        name="password"
                                        maxlength="20"
                                        onKeyPress={this.keyPressed}
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        required={true}
                                    /> }
                                </div>
                                <div class="input-group">
                                    { <Input 
                                        classStyles={ { } } 
                                        label="Confirmar password"
                                        placeholder="Confirmar password"
                                        type="password"
                                        name="c_password"
                                        maxlength="20"
                                        onKeyPress={this.keyPressed}
                                        value={this.state.c_password}
                                        onChange={this.handleChange}
                                        required={true}
                                    /> }
                                </div>
                                <div class="kt-login__actions">
                                    <a href="javascript:void(0)">
                                        <button onClick={this.handleSubmit} type="submit" className={styles}>Registrarte</button>&nbsp;
                                    </a>
                                </div>
						</form>
							</div>
							<div class="kt-login__forgot">
								<div class="kt-login__head">
									<h3 class="kt-login__title">¿ Olvidaste tu contraseña ?</h3>
									<div class="kt-login__desc">Ingresa tu email para reestablecer la contraseña..</div>
								</div>
								<form class="kt-form" action="">
									<div class="input-group">
										<input class="form-control" type="text" placeholder="Email" name="email" id="kt_email" autocomplete="off" />
									</div>
									<div class="kt-login__actions">
										<button id="kt_login_forgot_submit" class="btn btn-brand btn-elevate kt-login__btn-primary btn-success">Enviar</button>&nbsp;&nbsp;
										<button id="kt_login_forgot_cancel" class="btn btn-light btn-elevate kt-login__btn-secondary">Cancelar</button>
									</div>
								</form>
							</div>
						</div>	
					</div>
				</div>
				</div>
				</div>
        )
    }
}

const mapStateToProps = (state) => ({
    user : state.user,
    api : state.api
});


export default connect(mapStateToProps, null)(Register);