import { constants } from "./types";
import createReducer  from "Utils/createReducer";

const initialState = {
    events : []
};

const eventsReducer = createReducer( initialState )( {
    [ constants.SET_EVENTS ]: (state, action) => {
        return  { ...state, events : action.events };
    }
} );

export default eventsReducer;
