import axios from 'axios';
import _ from 'lodash';
import { settings } from 'Utils/settings';

function HttpClient() {
  this.client = axios.create({
    baseURL: `${settings.BASE_URL}`,
    responseType: 'json'    
  });
}

HttpClient.prototype.init = function init() {
  this.client = axios.create({
    baseURL: `${settings.BASE_URL}`,
    responseType: 'json'
  });
};

HttpClient.prototype.getCancelTokenSource = function getCancelTokenSource() {
  const { CancelToken } = axios;
  return CancelToken.source();
};

HttpClient.prototype.setBearerToken = function setBearerToken(token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

HttpClient.prototype.getToken = function getToken() {
  return localStorage.getItem('token');
};

HttpClient.prototype.addConfig = function addConfig(config) {
  let conf = {
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
  const token = this.getToken();
  if(token){
    conf.headers["Authorization"] = "Bearer " + token;
  }
  conf = _.assign(conf, config)
  return conf
}

HttpClient.prototype.get = function get(url, config) {
  return this.client.get(url, this.addConfig(config));
};

HttpClient.prototype.post = function post(url, data, config) {
  return this.client.post(url, data, this.addConfig(config));
};

HttpClient.prototype.put = function put(url, data, config) {
  return this.client.put(url, data, this.addConfig(config));
};

HttpClient.prototype.delete = function deleteRequest(url, config) {
  return this.client.delete(url, this.addConfig(config));
};

HttpClient.prototype.send = function send(options) {
  return this.client.request(options);
};

const Client = new HttpClient();

export default Client;