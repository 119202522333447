import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Input from "../../components/Input";
import Modal from "../../components/Modal/Modal";
import Divider from "../../components/Divider";

const AddDeliverable = ({ handleClose, handleSubmit, contract }) => {
  const [deliverable, setDeliverable] = useState({
    order: "",
    penalty_amount: "",
    description: "",
    amount_invoiced: "",
    amount: "",
    rrhh_number: "",
    delivery_day: "",
    delivery_date: "",
    modified_date: "",
    receveid_at: "",
    percentaje_amount: "",
    compliace_date_request: "",
    compliace_date: "",
    amount_paid: "",
    amount_paid_date: "",
  });

  const onHandleChange = (e) => {
    const { name, value } = e.target;

    setDeliverable({
      ...deliverable,
      [name]: value,
    });
  };

  useEffect(() => {
    if (deliverable.percentaje_amount > 0) {
      setDeliverable((deliverable) => ({
        ...deliverable,
        amount: (contract.amount * deliverable.percentaje_amount) / 100,
      }));
    } else {
      setDeliverable((deliverable) => ({
        ...deliverable,
        penalty_amount: "",
        amount: "",
      }));
    }
  }, [deliverable.percentaje_amount, deliverable.penalty_amount]);

  useEffect(() => {
    setDeliverable((deliverable) => ({
      ...deliverable,
      delivery_date: moment(contract.startdate, "YYYY-MM-DD")
        .add(deliverable.delivery_day, "days")
        .format("YYYY-MM-DD"),
    }));
  }, [deliverable.delivery_day]);

  const NotExistsContractAmount =
    contract.amount === "" || contract.amount === 0;

  const NotExistDeliverablePercentaje = deliverable.percentaje_amount === "";

  const NotExistsContractStartDate = contract.startdate === "";

  const RequiredFieldsNotFilled =
    deliverable.order === "" ||
    deliverable.description === "" ||
    deliverable.percentaje_amount === "" ||
    deliverable.delivery_day === "" ||
    deliverable.modified_date === "" ||
    deliverable.receveid_at === "" ||
    deliverable.amount === "";

  return (
    <Modal
      show
      title="Agregar Entregable"
      handleClose={handleClose}
      handleSubmit={() => handleSubmit(deliverable)}
      disabledSubmitButton={RequiredFieldsNotFilled}
    >
      <div>
        <div className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>Número</label>
              <Input
                type="number"
                min="1"
                name={`order`}
                value={deliverable.order}
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group">
              <label>Descripción</label>
              <textarea
                name={`description`}
                className="form-control"
                rows="6"
                value={deliverable.description}
                onChange={onHandleChange}
              ></textarea>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>Porcentaje (%)</label>
              <div class="input-group">
                <Input
                  type="number"
                  disabled={NotExistsContractAmount}
                  max="100"
                  name={`percentaje_amount`}
                  value={deliverable.percentaje_amount}
                  onChange={onHandleChange}
                />
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">
                    {NotExistsContractAmount
                      ? "No hay monto en el contrato"
                      : `Monto: ${
                          contract.amount *
                          (deliverable.percentaje_amount / 100)
                        }`}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Días de entrega a partir de la fecha de inicio</label>
              <div class="input-group">
                <Input
                  disabled={NotExistsContractStartDate}
                  type="number"
                  min="1"
                  name={`delivery_day`}
                  value={deliverable.delivery_day}
                  onChange={onHandleChange}
                />
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">
                    {NotExistsContractStartDate
                      ? "No hay fecha de inicio en el contrato"
                      : `Fecha: ${deliverable.delivery_date}`}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Fecha de vencimiento</label>
              <Input
                type="date"
                name={"modified_date"}
                value={deliverable.modified_date}
                onChange={onHandleChange}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>Fecha de entrega producto real</label>
              <Input
                type="date"
                name={"receveid_at"}
                value={deliverable.receveid_at}
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group">
              <label>Monto Previsto</label>
              <Input
                disabled={true}
                type="number"
                name="amount"
                value={deliverable.amount}
                onChange={onHandleChange}
              />
            </div>
          </div>
        </div>
        <Divider />
        <div className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>Monto Facturado</label>
              <Input
                type="number"
                name="amount_invoiced"
                value={deliverable.amount_invoiced}
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group">
              <label>Fecha Solicita Conformidad</label>
              <Input
                type="date"
                name="compliace_date_request"
                value={deliverable.compliace_date_request}
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group">
              <label>Fecha de Pago</label>
              <Input
                type="date"
                name="amount_paid_date"
                value={deliverable.amount_paid_date}
                onChange={onHandleChange}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>Fecha Conformidad</label>
              <Input
                type="date"
                name="compliace_date"
                value={deliverable.compliace_date}
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group">
              <label>Número de RH</label>
              <Input
                type="text"
                name="rrhh_number"
                value={deliverable.rrhh_number}
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group">
              <label>Penalidad </label>
              <div class="input-group">
                <Input
                  type="number"
                  disabled={NotExistDeliverablePercentaje}
                  min="0"
                  name={`penalty_amount`}
                  value={deliverable.penalty_amount}
                  onChange={onHandleChange}
                />
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">
                    {NotExistDeliverablePercentaje
                      ? "No hay porcentaje en el entregable"
                      : `Monto Final: ${
                          (contract.amount * deliverable.percentaje_amount) /
                            100 -
                          deliverable.penalty_amount
                        }`}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>Monto Pagado</label>
              <Input
                type="number"
                name="amount_paid"
                value={deliverable.amount_paid}
                onChange={onHandleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddDeliverable.propTypes = {};

export default AddDeliverable;
