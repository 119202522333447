// async component
import {
   AsyncDeliverablesComponent,
   AsyncNewDeliverableComponent,
   AsyncEditDeliverableComponent
} from './asyncComponent';

export default [
   {
      path: '/',
      component: AsyncDeliverablesComponent
   },
   {
      path : '/new-deliverable',
      component : AsyncNewDeliverableComponent
   },
   {
      path : '/edit-deliverable',
      component : AsyncEditDeliverableComponent
   }
] 