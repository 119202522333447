import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Doughnut, Pie } from 'react-chartjs-2';
import _ from 'lodash';
import * as SupervisorsActionsCreators from 'Ducks/supervisors/operations';

class DashboardSupervisor extends Component
{
    constructor(props)
    {
        super(props);

        const { dispatch } = this.props;

        this.supervisorsActionsCreators = bindActionCreators(SupervisorsActionsCreators, dispatch);
    }

    componentDidMount()
    {
        this.supervisorsActionsCreators.getChartStatusReview();

        this.supervisorsActionsCreators.getChartForExpiration();
    }

    render()
    {
        const { statusReview, statusExpiration } = this.props;

        const expiration = {
            labels: _.map(statusExpiration, review => `${_.upperCase(review.type)} (${review.count})`),
            datasets: [{
                data: _.map(statusExpiration, review => review.count),
                backgroundColor: _.chain(statusExpiration)
                .keyBy('color')
                .keys()
                .value(),
                hoverBackgroundColor: _.chain(statusExpiration)
                .keyBy('color')
                .keys()
                .value(),
            }]
        };

        const agroupedEvents = _.groupBy(statusReview ? statusReview.events : [] , 'event_id');

        const all = {
            labels: _.map(statusReview ? statusReview.all : [], review => `${review.status_name} (${review.status_count})`),
            datasets: [{
                data: _.map(statusReview ? statusReview.all : [], review => review.status_count),
                backgroundColor: [
                '#5578eb',
                '#ffb822',
                '#0abb87',
                '#FF6384'
                ],
                hoverBackgroundColor: [
                '#5578eb',
                '#ffb822',
                '#0abb87',
                '#FF6384'
                ]
            }]
        };

        return(
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                {/* CHART TRACKING ALL DELIVERABLES FOR EXPIRATION  */}
                <div className="row">                    
                    <div className="col-md-12 col-lg-12 col-xl-6">
                        <div>
                            <div className="alert alert-light alert-elevate fade show" role="alert">
                                <div className="alert-icon"><i className="flaticon-pie-chart kt-font-success"></i></div>
                                <div className="alert-text kt-font-bold">
                                    SEGUIMIENTO DE ENTREGABLES RELACIONADOS (VENCIMIENTO)
                                </div>
                            </div>
                            <div className="kt-portlet kt-portlet--height-fluid">
                                <div className="kt-widget14">
                                    <div className="kt-widget14__header">
                                        <h3 className="kt-widget14__title">
                                            Entregables (VENCIMIENTO)        
                                        </h3>
                                    </div>
                                    <div className="kt-portlet__body">
                                        <div className="kt-portlet__content">
                                        <Doughnut data={expiration} />
                                        </div>
                                    </div>                                        
                                </div>
                            </div>	
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-6">
                        <div>
                            <div className="alert alert-light alert-elevate fade show" role="alert">
                                <div className="alert-icon"><i className="flaticon-pie-chart kt-font-success"></i></div>
                                <div className="alert-text kt-font-bold">
                                    SEGUIMIENTO DE ENTREGABLES RELACIONADOS (ESTADOS)
                                </div>
                            </div>
                            <div className="kt-portlet kt-portlet--height-fluid">
                                <div className="kt-widget14">
                                    <div className="kt-widget14__header">
                                        <h3 className="kt-widget14__title">
                                            Todos los entregables        
                                        </h3>
                                    </div>
                                    <div className="kt-portlet__body">
                                        <div className="kt-portlet__content">
                                            <Doughnut data={all} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-12">
                        <div>
                            <div className="alert alert-light alert-elevate fade show" role="alert">
                                <div className="alert-icon"><i className="flaticon-pie-chart kt-font-success"></i></div>
                                <div className="alert-text kt-font-bold">
                                    SEGUIMIENTO DE ENTREGABLES POR CONVOCATORIA (ESTADOS)
                                </div>
                            </div>
                            { _.map(agroupedEvents, event => <GraphicPie event={event} />) }
                        </div>                        
                    </div>                        
                </div>

            </div>
        )
    }
}

const GraphicPie = ({ event }) =>
{
    const data = {
        labels: _.map(event, review => `${review.status_name} (${review.status_count})`),
        datasets: [{
            data: _.map(event, review => review.status_count),
            backgroundColor: [
            '#5578eb',
            '#ffb822',
            '#0abb87',
            '#FF6384'
            ],
            hoverBackgroundColor: [
            '#5578eb',
            '#ffb822',
            '#0abb87',
            '#FF6384'
            ]
        }]
    };

    return (
        <div class="kt-portlet kt-portlet--height-fluid">
            <div class="kt-widget14">
                <div class="kt-widget14__header">
                    <h3 class="kt-widget14__title">
                        
                    </h3>
                </div>
                <div class="kt-portlet__body">
                    <div class="kt-portlet__content">
                        <Pie data={data} />
                    </div>
                </div>                    
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    statusReview : state.supervisors.dashboard.statusReview,
    statusExpiration : state.supervisors.dashboard.statusExpiration
});

export default connect(mapStateToProps, null)(DashboardSupervisor);
