import React from 'react'

const FlatIcon = (props) => {
    return (
        <span className="kt-menu__link-icon">
            <span className="kt-portlet__head-icon">
                <i className={`kt-font-${props.color} ${props.icon}`}> </i>
            </span>
        </span> 
    );
};

FlatIcon.defaultProps = {
    color: 'dark'
};

export default FlatIcon;
