import KeyMirror from 'keymirror';

export const constants = KeyMirror({
    GET_SEPA_LIST:null,
    GET_SEPA_LIST_SUCCESS: null,
    GET_SEPA_LIST_ERROR: null,
    GET_SEPA: null,
    GET_SEPA_SUCCESS: null,
    GET_SEPA_ERROR: null,
    ADD_SEPA: null,
    ADD_SEPA_SUCCESS: null,
    ADD_SEPA_ERROR: null,
    UPDATE_SEPA: null,
    UPDATE_SEPA_SUCCESS: null,
    UPDATE_SEPA_ERROR: null,
});

export default constants;