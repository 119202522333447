import React from "react";
import PropTypes from "prop-types";

import "./index.css";

const BlockUI = () => {
  return (
    <div>
      <div className="blockUI blockOverlay blockoverlay"></div>
      <div
        className="blockUI blockMsg blockPage blockpage"        
      >
        <div className="blockui">
          <span>Procesando ...</span>
          <span>
            <div className="kt-spinner kt-spinner--v2 kt-spinner--primary "></div>
          </span>
        </div>
      </div>
    </div>
  );
};

BlockUI.propTypes = {};

export default BlockUI;
