import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { last, sumBy, max } from "lodash";

import Portlet from "../../components/Portlet/portlet";
import Input from "../../components/Input";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import { settings } from "../../../utils/settings";
import * as ProvidersActionsCreators from "Ducks/providers/operations";
import * as ContractsActionsCreators from "Ducks/contracts/operations";
import * as SupervisorActionsCreators from "Ducks/supervisors/operations";
import Divider from "../../components/Divider";
import Button from "../../components/Button";
import DeliverablesList from "./DeliverablesList";
import AdendasList from "./AdendasList";
import FormError from "../../Layout/errors/FormError";
import BlockUI from "../../components/BlockUI";
import FileIcon from "../../components/FileIcon/FileIcon";
import File from "../../components/File";

const BodyContent = (props) => {
  return (
    <React.Fragment>
      {props.errors}
      <FormContractMaster {...props} />
      <Divider />
      <FormContractDetail {...props} />
      <Divider />
      <DeliverablesList
        contract={props.contract}
        contractDeliverables={props.contractDeliverables}
        setContractDeliverables={props.setContractDeliverables}
      />
      <Divider />
      <AdendasList
        contract={props.contract}
        contractAdendas={props.contractAdendas}
        setContractAdendas={props.setContractAdendas}
      />
    </React.Fragment>
  );
};

const FormContractMaster = ({
  allProcessSelect,
  contractStatuses,
  allProviders,
  allEvaluators,
  contract,
  onHandleChange,
  onHandleChangeFile,
  inputHasErrors,
}) => {
  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="form-group">
          <label>N° Contrato</label>
          <Input
            hasErrors={inputHasErrors("contract_number")}
            name="contract_number"
            type="text"
            value={contract.contract_number}
            onChange={onHandleChange}
          />
        </div>
        <div className="form-group">
          <label>Acción</label>
          <textarea
            name="concept"
            rows="3"
            value={contract.concept}
            onChange={onHandleChange}
            className={`form-control ${
              inputHasErrors("concept") ? "is-invalid" : ""
            }`}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Proveedor</label>
          <ReactSelect
            hasErrors={inputHasErrors("provider_id")}
            options={allProviders}
            value={contract.provider_id}
            onChange={(e) => onHandleChange(e, "provider_id", true)}
          />
        </div>
        <div className="form-group">
          <label>TDR</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                {contract.document_tdr ? (
                  <FileIcon
                    href={contract.document_tdr._url}
                    text="TDR"
                    icon="pdf"
                  />
                ) : (
                  "TDR"
                )}
              </span>
            </div>
            <File
              hasErrors={inputHasErrors("_filename_tdr")}
              name="_filename_tdr"
              onChange={onHandleChangeFile}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="form-group">
          <label>Estado</label>
          <ReactSelect
            hasErrors={inputHasErrors("state_id")}
            options={contractStatuses}
            value={contract.state_id}
            onChange={(e) => onHandleChange(e, "state_id", true)}
          />
        </div>
        <div className="form-group">
          <label>Responsable</label>
          <ReactSelect
            hasErrors={inputHasErrors("evaluator_id")}
            options={allEvaluators}
            value={contract.evaluator_id}
            onChange={(e) => onHandleChange(e, "evaluator_id", true)}
          />
        </div>
        <div className="form-group">
          <label>Proceso</label>
          <ReactSelect
            hasErrors={inputHasErrors("process_id")}
            options={allProcessSelect}
            onChange={(e) => onHandleChange(e, "process_id", true)}
            value={contract.process_id}
          />
        </div>
        <div className="form-group">
          <label>Contrato</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                {contract.document_contract ? (
                  <FileIcon
                    href={contract.document_contract._url}
                    text="Contrato"
                    icon="pdf"
                  />
                ) : (
                  "Contrato"
                )}
              </span>
            </div>
            <File
              hasErrors={inputHasErrors("_filename_contract")}
              name="_filename_contract"
              onChange={onHandleChangeFile}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const FormContractDetail = ({
  contract,
  contractDeliverables,
  contractAdendas,
  onHandleChange,
  processSelected,
  inputHasErrors,
}) => {
  let lastAdenda = last(contractAdendas);

  let totalAmount = sumBy(contractAdendas, (o) => +o.amount);

  let accruedPayment = sumBy(contractDeliverables, (o) => +o.amount_paid);

  let maxAdendaByAccruedPaymentDate = max(contractDeliverables, (o) => new Date(o.amount_paid_date).getTime())

  let availableAmount = +contract.amount - accruedPayment;

  return (
    <div className="row">
      <div className="col-lg-3">
        <div className="form-group">
          <label>Monto Contratado</label>
          <Input
            hasErrors={inputHasErrors("amount")}
            name="amount"
            type="number"
            value={contract.amount}
            onChange={onHandleChange}
          />
        </div>
        <div className="form-group">
          <label>Fecha Firma de Contrato</label>
          <Input
            hasErrors={inputHasErrors("signature_date")}
            name="signature_date"
            type="date"
            value={contract.signature_date}
            onChange={onHandleChange}
          />
        </div>
        <div className="form-group">
          <label>Fecha Inicio de Contrato</label>
          <Input
            hasErrors={inputHasErrors("startdate")}
            name="startdate"
            type="date"
            value={contract.startdate}
            onChange={onHandleChange}
          />
        </div>
        <div className="form-group">
          <label>Fecha Final de Contrato</label>
          <Input
            hasErrors={inputHasErrors("enddate")}
            name="enddate"
            type="date"
            value={contract.enddate}
            onChange={onHandleChange}
          />
        </div>
        <div className="form-group">
          <label>Duración</label>
          <Input
            hasErrors={inputHasErrors("duration")}
            name="duration"
            type="number"
            value={contract.duration}
            onChange={onHandleChange}
          />
        </div>
      </div>
      <div className="col-lg-3">
        <div className="form-group">
          <label>N° Proceso</label>
          <Input type="text" value={processSelected?.process_number} disabled />
        </div>
        <div className="form-group">
          <label>Tipo de Proceso</label>
          <Input
            type="text"
            disabled
            value={processSelected?.process_type?.name}
          />
        </div>
        <div className="form-group">
          <label>Valor Referencial</label>
          <Input type="text" disabled value={processSelected?.amount_ref} />
        </div>
        <div className="form-group">
          <label>Área Usuaria</label>
          <Input type="text" disabled value={processSelected?.area?.name} />
        </div>
        <div className="form-group">
          <label>Origen</label>
          <Input type="text" disabled value={processSelected?.origin?.name} />
        </div>
      </div>
      <div className="col-lg-3">
        <div className="form-group">
          <label>Total de Adendas</label>
          <Input type="text" disabled value={contractAdendas.length} />
        </div>
        <div className="form-group">
          <label>Fecha de Última Adenda</label>
          <Input type="text" disabled value={lastAdenda?.suscription_date} />
        </div>
        <div className="form-group">
          <label>F. Final con Adenda</label>
          <Input type="text" disabled value={lastAdenda?.enddate} />
        </div>
        <div className="form-group">
          <label>Total de Días adicionales</label>
          <Input type="text" disabled />
        </div>
        <div className="form-group">
          <label>Total de Monto Adicional</label>
          <Input type="text" disabled value={totalAmount} />
        </div>
      </div>
      <div className="col-lg-3">
        <div className="form-group">
          <label>Pago Acumulado al</label>
          <Input
            disabled
            type="date"
            name="accrued_payment_date"
            value={maxAdendaByAccruedPaymentDate?.amount_paid_date}
          />
          <br></br>
          <Input
            disabled
            type="number"
            name="accrued_payment"
            value={accruedPayment}
          />
        </div>
        <div className="form-group">
          <label>Saldo Disponible</label>
          <Input disabled type="number" value={availableAmount} />
        </div>
        <div className="form-group">
          <label>CCP</label>
          <Input
            hasErrors={inputHasErrors("ccp")}
            type="text"
            name="ccp"
            value={contract.ccp}
            onChange={onHandleChange}
          />
        </div>
        <div className="form-group">
          <label>Orden de Servicio</label>
          <Input
            hasErrors={inputHasErrors("order")}
            type="text"
            name="order"
            value={contract.order}
            onChange={onHandleChange}
          />
        </div>
        <div className="form-group">
          <label>SIAF</label>
          <Input
            hasErrors={inputHasErrors("siaf")}
            type="text"
            name="siaf"
            value={contract.siaf}
            onChange={onHandleChange}
          />
        </div>
      </div>
    </div>
  );
};

const CreateContract = () => {
  const [contract, setContract] = useState({
    contract_number: "",
    concept: "",
    provider_id: "",
    state_id: "",
    evaluator_id: "",
    process_id: "",
    amount: "",
    signature_date: "",
    startdate: "",
    enddate: "",
    duration: "",
    order: "",
    siaf: "",
    ccp: "",
    _filename_tdr: "",
    _filename_contract: "",
  });
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [processSelected, setProcessSelected] = useState({});
  const [contractDeliverables, setContractDeliverables] = useState([]);
  const [contractAdendas, setContractAdendas] = useState([]);
  const [formErrorContract, setFormErrorContract] = useState({});

  const contractStatuses = settings.CONTRACT_STATUS.map((status) => ({
    value: status.id,
    label: status.name,
  }));

  const ALL_EVALUATORS = useSelector(
    (state) => state.supervisors.evaluators
  ).map((evaluator) => ({ value: evaluator.id, label: evaluator.name }));

  const ALL_PROVIDERS = useSelector(
    (state) => state.providers.providers
  ).map((provider) => ({ value: provider.id, label: provider.name }));

  const ALL_PROCCESSES = useSelector((state) => state.contracts.processes);

  const ALL_PROCESSES_FORMATTED = ALL_PROCCESSES.map((process) => ({
    value: process.process_id,
    label: process.name,
  }));

  const dispatch = useDispatch();

  // Render Inicial
  useEffect(() => {
    setIsPageLoaded(false);
    dispatch(ContractsActionsCreators.getProcesses());
    dispatch(ProvidersActionsCreators.getProviders(false));
    dispatch(SupervisorActionsCreators.getEvaluators());
    setIsPageLoaded(true);
  }, []);

  useEffect(() => {
    if (ALL_PROCCESSES.length > 0) {
      let processSelected = ALL_PROCCESSES.filter(
        (process) => process.process_id == contract.process_id
      );

      if (processSelected.length > 0) {
        setProcessSelected(processSelected[0]);
      }
    }
  }, [contract.process_id]);

  const onHandleChange = (e, selectName, reactSelect = false) => {
    let name, value;

    if (reactSelect) {
      name = selectName;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setContract({
      ...contract,
      [name]: value,
    });
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    setIsPageLoaded(false);
    const _contract = {
      ...contract,
      deliverables: contractDeliverables,
      adendas: contractAdendas,
    };
    const response = await dispatch(
      ContractsActionsCreators.createContract(_contract)
    );
    if (response) {
      setFormErrorContract(response.data.errors);
    }
    setIsPageLoaded(true);
  };

  const submitButton = () => {
    return (
      <Button
        type="submit"
        name="Guardar"
        classStyles={{ "btn-primary": true, "my-3": true }}
        onClick={onHandleSubmit}
      />
    );
  };

  const formHasErrors = () => {
    const errors = formErrorContract;
    return Object.values(errors).length > 0;
  };

  const inputHasErrors = (name) => {
    const errors = { ...formErrorContract };
    return Object.keys(errors).includes(name);
  };

  const onHandleChangeFile = async (e) => {
    const { name, files } = e.target;
    let urlFile = await dispatch(ContractsActionsCreators.submitFile(files[0]));
    setContract({ ...contract, [name]: urlFile });
  };

  let errors = null;

  if (formHasErrors()) {
    errors = <FormError show errors={Object.values(formErrorContract)} />;
  }

  return (
    <div>
      <Portlet
        title="Crear Contrato"
        bodyContent={
          <BodyContent
            inputHasErrors={inputHasErrors}
            errors={errors}
            contractDeliverables={contractDeliverables}
            setContractDeliverables={setContractDeliverables}
            contractAdendas={contractAdendas}
            setContractAdendas={setContractAdendas}
            processSelected={processSelected}
            allProcessSelect={ALL_PROCESSES_FORMATTED}
            contractStatuses={contractStatuses}
            allProviders={ALL_PROVIDERS}
            allEvaluators={ALL_EVALUATORS}
            contract={contract}
            onHandleChange={onHandleChange}
            onHandleChangeFile={onHandleChangeFile}
          />
        }
        submitButton={submitButton()}
      />
      {!isPageLoaded && <BlockUI />}
    </div>
  );
};

CreateContract.propTypes = {};

export default CreateContract;
