import KeyMirror from 'keymirror';

export const constants = KeyMirror({
    TEST: null,
    GET_PRODUCTS : null,
    CREATE_PRODUCT : null,
    //API
    SET_PRODUCTS : null,
    EDIT_PRODUCT : null,
    GET_ALL_ACTIVITIES : null,
    SET_ALL_ACTIVITIES : null
});
