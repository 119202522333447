import reducer from './reducers';
import * as supervisorsOperations from './operations';
import api from './api';
import * as supervisorsMiddleware from './middleware';
import * as supervisorsSelectors from "./selectors";
import * as supervisorsUtils from "./utils";
//import * as supervisorsAsyncComponent from './asyncComponent';

export {
    supervisorsOperations,
    api,
    supervisorsMiddleware,
    supervisorsSelectors,
    supervisorsUtils
    //supervisorsAsyncComponent
};

export default reducer;
