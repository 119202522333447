import React, { Component } from 'react';
import moment from 'moment';
import { Datatable, Loader, Expiration, Select, Input, Button, InputAutocomplete, FlatIcon } from 'Components';
import { settings } from 'Utils/settings';
import Badge from 'Components/Badge';
import Date from 'Components/Date';
import * as SupervisorsActionsCreators from 'Ducks/supervisors/operations';
import * as EventsActionsCreators from 'Ducks/events/operations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import PageInformation from 'Components/Datatable/PageInformation';
import { routes } from 'Utils/constants';
import EditButton from 'Components/EditButton';

class DeliverablesSupervisor extends Component
{
    constructor(props)
    {
        super(props);

        this.state = this.getInitialState();

        const { dispatch } = props;

        this.supervisorsActionsCreators = bindActionCreators(SupervisorsActionsCreators, dispatch);

        this.eventsActionsCreators = bindActionCreators(EventsActionsCreators, dispatch);
        
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    getInitialState = () => 
    ({
        deliverables : [],
        responsable_id: this.props.userInformation?.id,
        total: null,
        per_page: null,
        current_page: 1,
        last_page : 1,
        loading : true,
        numberPage : 1,
        status_review : "",
        provider : "",
        event_id : ""
    });

    componentDidMount()
    {
        this.eventsActionsCreators.getEvents();

        this.supervisorsActionsCreators.getDeliverablesForSupervisor(1, this.state);
    }

    handlePageClick = numberPage => 
    {
        this.setState({ loading : true, numberPage : numberPage + 1 });

        this.supervisorsActionsCreators.getDeliverablesForSupervisor(numberPage + 1, this.state);
    }

    handleChange = (e) =>
    {
        const { name, value } = e.target;

        this.setState({ [ name ] : value });
    };

    search = () =>
    {
        const { numberPage } = this.state;

        this.supervisorsActionsCreators.getDeliverablesForSupervisor(numberPage, this.state);
    };

    clean = () => 
    {
        this.setState({...this.getInitialState()}, () => {
            this.search();
        });        
    }

    keyPressed = (event) => {
        if (event.key === "Enter") {
          this.search();
        }
    };

    componentDidUpdate(oldProps) {
        const newProps = this.props;

        if(oldProps.deliverables !== newProps.deliverables) 
        {
            this.setState({ 
                deliverables : newProps.deliverables.data, 
                total : newProps.deliverables.total,
                per_page : newProps.deliverables.per_page,
                current_page : newProps.deliverables.current_page,
                last_page : newProps.deliverables.last_page,
                loading : false
            });
        }
    }

    onDownloadConfirmityAct = (id) => {
        window.open(`${settings.BASE_URL}/proyects/1/contract_deliveries/${id}/conformidad?token=${localStorage.getItem('token')}`, '_blank');
    }

    render()
    {
        const { 
            deliverables, 
            last_page, 
            current_page,
            loading,
            numberPage,
            status_review,
            provider_name,
            provider,
            total,
            event_id } = this.state; 

        const { api, events, userInformation} = this.props;

        const columns = [
            /*{
                label: 'id',
                field: 'id',
                sort: 'asc',
                width: 150
            },*/
            {
                label: 'Origen',
                field: 'event_id',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Contrato / Orden de Servicio',
                field: 'contract_id',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Proveedor/Consultor',
                field: 'provider_id',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Entregable',
                field: 'name',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Monto:',
                field: 'amount',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Status',
                field: 'status_review',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Fecha',
                field: 'expiration',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Fecha',
                field: 'delivery_date',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Conformidad',
                field: 'conformity_act_url',
                width: 100
            },
            {
                label: 'Editar',
                field: 'edit',
                sort: 'asc',
                width: 150
            }
          ];
          
          let rows = _.map(deliverables, deliverable => {
            let data = _.pick(deliverable, [/*'id',*/ 'event_id' ,'contract_id', 'provider_id',  'name', 'amount', 'status_review', 'delivery_date']);

            return _.assign(data, {
                name : deliverable.order ? deliverable.order : deliverable.name,
                event_id : deliverable.event ? deliverable.event.shortname : '', 
                contract_id : deliverable.contract.contract_number == 'S/C' ? `OS: ${deliverable.order}` : `CONTRATO: ${deliverable.contract.contract_number }`,
                provider_id : deliverable.provider ? deliverable.provider.name : '-',
                status_review : <Badge label={`${settings.STATUS_INFORME[deliverable.status_review].label}`} name={settings.STATUS_INFORME[deliverable.status_review].name} />,
                expiration : <Expiration label={deliverable._data_alert ? deliverable._data_alert.label : ''} value={deliverable._data_alert ? deliverable._data_alert.value : ''} />,
                delivery_date :
                        <Date 
                            alert='success'
                            date={deliverable.delivery_date}
                            outputFormat='DD-MM-YYYY'
                        />,
                conformity_act_url: (
                    deliverable.status_review === 3 
                        ? <Button name="Generar Conformidad" classStyles={{ 'btn-primary' : true }} onClick={() => this.onDownloadConfirmityAct(deliverable.id)} /> 
                        : <span class={`btn btn-label-warning btn-sm btn-bold btn-upper`}>No está aprobado</span>
                ),
                edit : <Link to={{
                    pathname : routes.SUPERVISOR.SHOW_INFORMES_AND_OBSERVATIONS,
                    search:`?id=${deliverable.id}`,
                    //hash:'#hash',
                    state: { deliverable }
                    }}><EditButton /></Link> });
          });

        return(
            <div class="kt-portlet kt-portlet--mobile">
	            <div class="kt-portlet__head kt-portlet__head--lg">
                    <div class="kt-portlet__head-label">
                        <FlatIcon color="success" icon="flaticon2-box-1" />
                        <h3 class="kt-portlet__head-title">
                            Entregables
                        </h3>
                    </div>
		        <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-wrapper">
                        <div class="kt-portlet__head-actions">
                            <div class="dropdown dropdown-inline">
                                {/*<button type="button" class="btn btn-default btn-icon-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="la la-download"></i> Exportar
                            </button>*/}                        
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul class="kt-nav">
                                        <li class="kt-nav__section kt-nav__section--first">
                                            <span class="kt-nav__section-text">Choose an option</span>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-print"></i>
                                                <span class="kt-nav__link-text">Print</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-copy"></i>
                                                <span class="kt-nav__link-text">Copy</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-file-excel-o"></i>
                                                <span class="kt-nav__link-text">Excel</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-file-text-o"></i>
                                                <span class="kt-nav__link-text">CSV</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-file-pdf-o"></i>
                                                <span class="kt-nav__link-text">PDF</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>	
                    </div>		
                </div>
	        </div>

            <div class="kt-portlet__head kt-portlet__head--lg d-block kt-margin-t-25">
                <div class="kt-section__content w-100">
                    <div class="form-group row">
                        <div class="col-lg-4 form-group-sub">
                            <label class="form-control-label">Estados</label>
                            <Select 
                                text="Todos"
                                value={settings.STATUS_INFORME}
                                selected={status_review}
                                onKeyPress={this.keyPressed}
                                onChange={(e) => this.setState({ status_review : e })}
                            />
                        </div>
                        <div class="col-lg-4 form-group-sub">
                            <label class="form-control-label">Proveedor</label>
                            { <Input 
                                classStyles={ {} } 
                                label="Proveedor"
                                placeholder=""
                                type="text"
                                name="provider_name"
                                onKeyPress={this.keyPressed}
                                value={provider_name}
                                onChange={this.handleChange}
                            /> }
                        </div>
                        <div class="col-lg-4 form-group-sub">
                            <label class="form-control-label">Convocatorias</label>
                            <Select 
                                text="Todas"
                                value={events}
                                selected={event_id}
                                onChange={(e) => this.setState({ event_id : e })}
                            />
                        </div>
                    </div>
                    <div class="form-group form-group-last row">
                       
                    </div>
                </div>
                <div class="kt-section kt-margin-t-0">
                    <div class="kt-section__content kt-grid__item--middle kt-align-right">
                        <Button 
                            classStyles={ { 'btn-success' : true} }
                            name="BUSCAR"
                            loading={api.loading}
                            onClick={this.search}
                        />
                        <Button 
                                classStyles={ { 'btn-secondary' : true, 'ml-1': true } }
                                name="LIMPIAR"
                                loading={api.loading}
                                onClick={this.clean}
                            />
                    </div>
                </div>
			</div>

            { <PageInformation total={total} current_page={current_page} last_page={last_page} /> }

            <div class="kt-portlet__body">
                <Datatable 
                    columns={columns} 
                    rows={rows}
                    handlePageClick={(e) => this.handlePageClick(e.selected)}
                    last_page={last_page} 
                    current_page={current_page - 1} />
            </div>
            { loading ? <Loader /> : ''}
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userInformation: state.user.userInformation,
    api : state.api,
    events : state.events.events,
    deliverables : state.supervisors.deliverables
});

export default connect(mapStateToProps, null)(DeliverablesSupervisor);
