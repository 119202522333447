import { constants } from "./types";
import createReducer  from "Utils/createReducer";

const initialState = {
    deliverables : []
};

const deliverablesReducer = createReducer( initialState )( {
    [ constants.SET_DELIVERABLES ]: (state, action) => {
        return  { ...state, deliverables : action.deliverables };
    }
} );

export default deliverablesReducer;
