import { createRequest } from "Utils/createRequest";
import {  constants } from "./types";
import { settings } from 'Utils/settings';
import buildNotification from 'Components/Notification';
import _ from 'lodash';

export const setDeliverables = (deliverables) => 
({
    type: constants.SET_DELIVERABLES,
    deliverables
});

export const setDaliverableDetail = (deliverable) => 
({
    type: constants.SET_DELIVERABLE_DETAIL,
    deliverable
});

export const setInformes = (informes) => 
({
    type: constants.SET_INFORMES,
    informes
});

export const setStatusReview = (data) => 
({
    type: constants.SET_STATUS_REVIEW,
    data
});

export const setStatusExpiration = (data) => 
({
    type: constants.SET_STATUS_EXPIRATION,
    data
});

export const setIndicadores = (data) => 
({
  type: constants.SET_INDICADORES,
  data
});

export const setActividadesRecientes = (data) => 
({
  type: constants.SET_ACTIVIDADES_RECIENTES,
  data
});

export const setIndicadoresPorConvocatoria = (data) => 
({
  type: constants.SET_INDICADORES_POR_CONVOCATORIA,
  data
});

export const setEstadosEntregables = (data) => 
({
  type: constants.SET_ESTADOS_ENTREGABLES,
  data
});

export const setProgresoContratos = (data) => 
({
  type: constants.SET_PROGRESO_CONTRATOS,
  data
});

export const setConsultoresSexo = (data) => 
({
  type: constants.SET_CONSULTORES_SEXO,
  data
});

export const setTopIdiomas = (data) => 
({
  type: constants.SET_TOP_IDIOMAS,
  data
});

export const setTopEspecialidades = (data) => 
({
  type: constants.SET_TOP_ESPECIALIDADES,
  data
});

export const setProgresoCv = (data) => 
({
  type: constants.SET_PROGRESO_CV,
  data
});

export const setConsultoresGradoAcademico = (data) => 
({
  type: constants.SET_CONSULTORES_GRADO_ACADEMICO,
  data
});

export const setContratosResponsable = (data) => 
({
  type: constants.SET_CONTRATOS_RESPONSABLE,
  data
});

export const setEvaluators = (data) => ({
  type: constants.SET_EVALUATORS,
  data,
});

export const saveFormInformeReview = ({formInformeReview}) =>
({
  type : constants.SAVE_FORM_INFORME_REVIEW,
  formInformeReview
});

export const removeFormInformeReview = ({formInformeReview}) =>
({
  type : constants.REMOVE_FORM_INFORME_REVIEW,
  formInformeReview
});

export function getDeliverablesForSupervisor(page = 1, parameters)
{
  return async (dispatch, getState) => {    
    try {
      let queryString = {...{page}};      
      if(_.has(parameters, 'responsable_id')) queryString.responsable_id = parameters.responsable_id;
      if(_.has(parameters, 'status_review')) queryString.status_review = parameters.status_review;
      if(_.has(parameters, 'provider_name')) queryString.provider_name = parameters.provider_name;
      if(_.has(parameters, 'event_id')) queryString.event_id = parameters.event_id;
      queryString.with = "documentCompliance,contract,contract.contractType,provider,event,contract.responsable,contract.documentContract,contract.documentTdr";
      console.log("QUERYSTRING: ", queryString);

      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contract_deliveries`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          params: queryString
        },
        requestId: constants.GET_DELIVERABLES_FOR_SUPERVISOR
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setDeliverables(data));
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);
    }
  }
}

export function getDeliverable(id)
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contract_deliveries/${id}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          params: {}
        },
        requestId: constants.SET_DELIVERABLE_DETAIL
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setDaliverableDetail(data));
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);
    }
  }
}

export function getInformesByDeliverable(informe_id)
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contract_deliveries/${informe_id}/informes`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_INFORMES_BY_DELIVERABLE
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setInformes(data));
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);
    }
  }
}

export function updateReviews(obj)
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'POST',
          url: `/proyects/${settings.PROJECT_ID}/contract_vetification_reviews`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : obj,
          //params: query
        },
        requestId: constants.CREATE_VERIFICATION_REVIEW
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(getInformesByDeliverable(data.contract_deliverable_id));

        localStorage.setItem("deliverable_id", data.contract_deliverable_id);

        buildNotification('success', 'Guardado satisfactoriamente..', 'Éxito');
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);
    }
  }
}

export function updateInformeReview(obj)
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'PUT',
          url: `/proyects/${settings.PROJECT_ID}/contract_informe_reviews`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          //params: query,
          data : obj
        },
        requestId: constants.UPDATE_INFORME_REVIEW
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(getInformesByDeliverable(data.contract_deliverable_id));

        buildNotification('success', 'Guardado satisfactoriamente..', 'Éxito');
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);

    }
  }
}

/* ALL DATA FOR CHARTS */
export function getChartStatusReview()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contract_deliveries/chartStatusReview`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query,
        },
        requestId: constants.GET_CHART_STATUS_REVIEW
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setStatusReview(data));
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);

    }
  }
}

export function getChartForExpiration()
{
  return async (dispatch, getState) => {
  try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contract_deliveries/chartStatusTime`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query,
        },
        requestId: constants.GET_CHART_STATUS_EXPIRATION
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setStatusExpiration(data));
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);

    }
  }
}

export function getIndicadores()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: '/dashboard/indicadores',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.INDICADORES
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === 'Success') {
        dispatch(setIndicadores(data));
      }

    } catch (error) {
      console.log('ERROR', error);
    }
  }
}

export function getActividadesRecientes()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: '/dashboard/actividadesRecientes',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.ACTIVIDADES_RECIENTES
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === 'Success') {
        dispatch(setActividadesRecientes(data));
      }

    } catch (error) {
      console.log('ERROR', error);
    }
  }
}

export function getIndicadoresPorConvocatoria()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: '/dashboard/indicadoresPorConvocatoria',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.INDICADORES_POR_CONVOCATORIA
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === 'Success') {
        dispatch(setIndicadoresPorConvocatoria(data));
      }

    } catch (error) {
      console.log('ERROR', error);
    }
  }
}

export function getEstadosEntregables()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: '/dashboard/estadosEntregables',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.ESTADOS_ENTREGABLES
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === 'Success') {
        dispatch(setEstadosEntregables(data));
      }

    } catch (error) {
      console.log('ERROR', error);
    }
  }
}

export function getProgresoContratos()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: '/dashboard/progresoContratos',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.PROGRESO_CONTRATOS
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === 'Success') {
        dispatch(setProgresoContratos(data));
      }

    } catch (error) {
      console.log('ERROR', error);
    }
  }
}

export function getConsultoresSexo()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: '/dashboard/consultoresSexo',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.CONSULTORES_SEXO
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === 'Success') {
        dispatch(setConsultoresSexo(data));
      }

    } catch (error) {
      console.log('ERROR', error);
    }
  }
}

export function getTopIdiomas()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: '/dashboard/topIdiomas',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.TOP_IDIOMAS
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === 'Success') {
        dispatch(setTopIdiomas(data));
      }

    } catch (error) {
      console.log('ERROR', error);
    }
  }
}

export function getTopEspecialidades()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: '/dashboard/topEspecialidades',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.TOP_ESPECIALIDADES
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === 'Success') {
        dispatch(setTopEspecialidades(data));
      }

    } catch (error) {
      console.log('ERROR', error);
    }
  }
}

export function getProgresoCv()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: '/dashboard/progresoCv',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.PROGRESO_CV
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === 'Success') {
        dispatch(setProgresoCv(data));
      }

    } catch (error) {
      console.log('ERROR', error);
    }
  }
}

export function getConsultoresGradoAcademico()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: '/dashboard/consultoresGradoAcademico',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.CONSULTORES_GRADO_ACADEMICO
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === 'Success') {
        dispatch(setConsultoresGradoAcademico(data));
      }

    } catch (error) {
      console.log('ERROR', error);
    }
  }
}

export function getContratosResponsable()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: '/dashboard/contratosResponsable',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.CONTRATOS_RESPONSABLE
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === 'Success') {
        dispatch(setContratosResponsable(data));
      }

    } catch (error) {
      console.log('ERROR', error);
    }
  }
}

export function getEvaluators() {
  return async (dispatch) => {
    try {
      const params = {
        requestParams: {
          method: "GET",
          url: `/acl/users/evaluators`,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        },
        requestId: constants.GET_EVALUATORS,
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if (status === "Success") {
        //Redirect to Dashboard
        dispatch(setEvaluators(data));
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };
}