import {  constants } from "./types";
import { createRequest } from "Utils/createRequest";
import history from 'Utils/history';
import { routes } from 'Utils/constants';
import { settings } from 'Utils/settings';
import _ from 'lodash';

export const setStatusReview = (data) => 
({
    type: constants.SET_STATUS_REVIEW,
    data
});

export const setStatusExpiration = (data) => 
({
    type: constants.SET_STATUS_EXPIRATION,
    data
});

export function getChartStatusReview()
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contract_deliveries/chartStatusReview`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query,
        },
        requestId: constants.GET_CHART_STATUS_REVIEW
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setStatusReview(data));
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);

    }
  }
}

export function getChartForExpiration()
{
  return async (dispatch, getState) => {
  try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contract_deliveries/chartStatusTime`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query,
        },
        requestId: constants.GET_CHART_STATUS_EXPIRATION
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setStatusExpiration(data));
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);

    }
  }
}