import React from "react";
import PropTypes from "prop-types";

import "./FileIcon.css";

const FileIcon = ({ icon = "alt", text, color = "bolder", href = "#" }) => {
  return (
    <a href={href}>
      <div className="kt-demo-icon">
        <div className="kt-demo-icon__preview">
          <i className={`fa fa-file-${icon} kt-font-${color}`}></i>
        </div>
        <div className="kt-demo-icon__class">{text}</div>
      </div>
    </a>
  );
};

FileIcon.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.oneOf([
    "alt",
    "pdf",
    "word",
    "excel",
    "csv",
    "image",
    "powerpoint",
  ]),
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "bold",
    "bolder",
    "danger",
    "success",
    "dark",
    "info",
    "primary",
    "warning",
  ]),
};

export default FileIcon;
