import {  constants } from "./types";
import { createRequest } from "Utils/createRequest";
import history from 'Utils/history';
import { routes } from 'Utils/constants';
import { settings } from 'Utils/settings';
import _ from 'lodash';
import buildNotification from "../../views/components/Notification";

export const setContracts = (contracts) => 
({
    type: constants.SET_CONTRACTS,
    contracts
});

export const setProcesses = (processes) => 
({
    type: constants.SET_PROCESSES,
    processes
});

export function getContracts(numberPage = 1, parameters) {
  return async dispatch => {
    try {
      let queryString = "";

      queryString = `&page=${numberPage}
        ${_.has(parameters, 'responsable') ? `&responsable_name=${parameters.responsable}` : ``}
        ${_.has(parameters, 'provider') ? `&provider_name=${parameters.provider}` : ``}
        ${_.has(parameters, 'type_contract') ? `&provider_name=${parameters.provider}` : ``}
        ${_.has(parameters, 'order') ? `&order=${parameters.order}` : ``}
        ${_.has(parameters, 'contract_number') ? `&contract_number=${parameters.contract_number}` : ``}
      `;

      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contracts?with=contractType,provider,documentTdr,deliverables,documentContract,evaluadores${queryString}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_CONTRACTS
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        //Redirect to Dashboard
        dispatch(setContracts(data));
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function createContract(contract) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'POST',
          url: `/proyects/${settings.PROJECT_ID}/contracts`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : contract
          //params: query
        },
        requestId: constants.CREATE_CONTRACT
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        history.push(routes.CONTRACTS);
        buildNotification('success', response.message)
      }
    } catch ({response}) 
    {
      return response;
    }
  }
};

export function editContract(contract) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'PUT',
          url: `/proyects/${settings.PROJECT_ID}/contracts`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : contract
          //params: query
        },
        requestId: constants.CREATE_CONTRACT
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        history.push(routes.CONTRACTS);
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
      return error.response;
    }
  }
};

export function submitFile(file) {
  return async dispatch => {
    try {
      let bodyFormData = new FormData();

      bodyFormData.set('file', file);

      const params = {
        requestParams: {
          method: 'POST',
          url: `/files`,
          data : bodyFormData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type':'multipart/form-data' 
          }
        },
        requestId: constants.SUBMIT_FILE
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        return data.filename;
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function nextContractNumber() {
  return async dispatch => {
    try {

      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contracts/nextOrderOrContract`,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        },
        requestId: constants.GET_CONTRACT_NUMBER
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        return data;
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }  
};

export function getProcesses() {
  return async dispatch => {
    try {

      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/process`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_PROCESSES
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setProcesses(data));
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
}