import React from 'react';

const ItemBreadcrumbs = (props) => {
    const {breadcrumbs} = props
    return (
        <div class="kt-subheader__breadcrumbs">
            <a href="#" class="kt-subheader__breadcrumbs-home">
                <i class="flaticon2-shelter"></i>
            </a>
            <span class="kt-subheader__breadcrumbs-separator"></span>
            <a href="" class="kt-subheader__breadcrumbs-link">Apps</a>
            <span class="kt-subheader__breadcrumbs-separator"></span>
            <a href="" class="kt-subheader__breadcrumbs-link">Contacts</a>
            <span class="kt-subheader__breadcrumbs-separator"></span>
            <a href="" class="kt-subheader__breadcrumbs-link">List - Columns</a>
        </div>
    )
}

const ListBreadcrumbs = (props) => {
    const {breadcrumbs, breadcrumbsContent} = props
    
    if(breadcrumbsContent){
        return <React.Fragment>
            <div class="kt-subheader__breadcrumbs">
                {breadcrumbsContent}
            </div>
        </React.Fragment>
    }

    if(!Array.isArray(breadcrumbs)){
        return ""
    }

    return (
        <div class="kt-subheader__breadcrumbs">

            {breadcrumbs.map((item, index) => (
                <a key={index} href={item.url | '#'} class="kt-subheader__breadcrumbs-link">
                    <i class={item.iconname | ''}> {item.label | ''}</i>
                </a>
            ))}

        </div>
    )
}

const SubHeader = (props) => {    

    
    return (
    <div class="kt-subheader  kt-grid__item" id="kt_subheader">
        <div class="kt-container  kt-container--fluid ">
            <div class="kt-subheader__main">                
                <h3 class="kt-subheader__title">{props.title}</h3>                
                
                {(props.breadcrumbs || props.breadcrumbsContent) &&<span class="kt-subheader__separator kt-subheader__separator--v"></span>}

                <ListBreadcrumbs breadcrumbs={props.breadcrumbs} />

            </div>
            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper">
                    {props.toolbarContent && props.toolbarContent}
                </div>
            </div>
        </div>
    </div>
)}

export default SubHeader;
