import React from 'react';

const PageInformation = ({ total, current_page, last_page }) =>
(
    <div class="kt-portlet kt-portlet__head--lg">
        <div class="kt-portlet__body kt-portlet__body--fit">
            <div class="row row-no-padding row-col-separator-xl">
                <div class="col-md-12 col-lg-12 col-xl-4">
                    <div class="kt-widget1">
                        <div class="kt-widget1__item">
                            <div class="kt-widget1__info">
                                <h3 class="kt-widget1__title">Total registros </h3>
                                {/*<span class="kt-widget1__desc">Awerage Weekly Profit</span>*/}
                            </div> 
                            <span class="kt-widget1__number kt-font-success">{total}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 col-xl-4">
                    <div class="kt-widget1">
                        <div class="kt-widget1__item">
                            <div class="kt-widget1__info">
                            <h3 class="kt-widget1__title">Página actual </h3>
                            </div> 
                            <span class="kt-widget1__number kt-font-success">{current_page}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 col-xl-4">
                    <div class="kt-widget1">
                        <div class="kt-widget1__item">
                            <div class="kt-widget1__info">
                                <h3 class="kt-widget1__title">Total Páginas</h3>
                            </div> 
                            <span class="kt-widget1__number kt-font-success">{last_page}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default PageInformation;