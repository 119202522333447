import _ from 'lodash';

export const getAmountMeansOfVerificationsPending = (products, verificationsInforme) =>
{
    let amountProductsPending = _.filter(products, product => {
        let result = _.filter(product.verifications, verification => {
                return _.includes([1, 2], verification.status_review )
        });
    });

    let amountInformeVerificationsPending = _.filter(verificationsInforme, verification => {
        return _.includes([1, 2], verification.status_review);
    });

    return amountProductsPending.length + amountInformeVerificationsPending.length;
}