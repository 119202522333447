import KeyMirror from 'keymirror';

export const constants = KeyMirror({
    SET_DELIVERABLE_DETAIL: null,
    GET_DELIVERABLES_FOR_SUPERVISOR: null,
    GET_INFORMES_BY_DELIVERABLE : null,
    GET_CHART_STATUS_REVIEW : null,
    GET_CHART_STATUS_EXPIRATION : null,
    INDICADORES: null,
    ACTIVIDADES_RECIENTES: null,
    INDICADORES_POR_CONVOCATORIA: null,
    ESTADOS_ENTREGABLES: null,
    PROGRESO_CONTRATOS: null,
    CONSULTORES_SEXO: null,
    TOP_IDIOMAS: null,
    TOP_ESPECIALIDADES: null,
    PROGRESO_CV: null,
    CONSULTORES_GRADO_ACADEMICO: null,
    CONTRATOS_RESPONSABLE: null,

    SET_DELIVERABLES : null,
    SET_INFORMES: null,
    SET_STATUS_REVIEW : null,
    SET_STATUS_EXPIRATION : null,
    SET_INDICADORES: null,
    SET_ACTIVIDADES_RECIENTES: null,
    SET_INDICADORES_POR_CONVOCATORIA: null,
    SET_ESTADOS_ENTREGABLES: null,
    SET_PROGRESO_CONTRATOS: null,
    SET_CONSULTORES_SEXO: null,
    SET_TOP_IDIOMAS: null,
    SET_TOP_ESPECIALIDADES: null,
    SET_PROGRESO_CV: null,
    SET_CONSULTORES_GRADO_ACADEMICO: null,
    SET_CONTRATOS_RESPONSABLE: null,
    CREATE_VERIFICATION_REVIEW: null,
    UPDATE_INFORME_REVIEW : null,
    GET_EVALUATORS: null,
    SET_EVALUATORS: null,
    SAVE_FORM_INFORME_REVIEW: null,
    REMOVE_FORM_INFORME_REVIEW:null,
});
