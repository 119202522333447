import React, { Fragment, useState, useEffect, Component } from "react";
import { getIn } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  FileUpload,
  Button,
  Textarea,
  Select,
  Alert,
  buildNotification,
} from "Components";
import UploadFileTemp from "./ResumableUploadFile/UploadFileTemp";
import FileObject from "./ResumableUploadFile/FileObject";
import _ from "lodash";
import "Assets/css/style.css";

const CancelButton = ({ onClick }) => {
  return (
    <span
      onClick={onClick}
      style={{
        borderRadius: "1rem",
        borderColor: "#000",
        padding: "0 5px",
        cursor: "pointer",
        fontWeight: 800,
        borderWidth: "1px",
        borderStyle: "solid",
      }}
    >
      X
    </span>
  );
};

class FileUploadSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      filesUploaded: 0,
      maxFiles: 20,
    };

    this.files = [];
  }

  componentDidMount() {
    const { value, maxFiles } = this.props;
    if (value) {
      let filereader = [];
      _.each(value, (f) => {
        filereader.push({ file: null, fileServer: f });
      });
      this.setState({ files: filereader, maxFiles: maxFiles });
    }
  }

  handlerFileSuccess = (file, fileServer) => {
    let { files, filesUploaded } = this.state;
    const { objects, onChange } = this.props;

    let docs = [...objects];
    docs.push(fileServer);
    if (onChange) {
      onChange(docs);
    }

    this.setState({ filesUploaded: filesUploaded + 1 },
      () => {
        if (this.state.filesUploaded === files.length) {
          this.setState({ files: [], filesUploaded: 0 });
        }
      }
    );
  };

  processFile = (data) => {
    let { files } = this.state;
    _.each(data, (f) => {
      files.push({ file: f, fileServer: null });
    });
    this.setState({ files: files });
  };

  onRemoveFile = (index) => {
    let { files } = this.state;
    files.splice(index, 1);
    this.setState({ files: files });
  };

  handlerRemoveFile(i) {
    const { objects, onChange } = this.props;
    let docs = [...objects];
    docs.splice(i, 1);
    if (onChange) {
      onChange(docs);
    }
  }

  render() {
    const { maxFiles = 50, objects, name } = this.props;
    const { files } = this.state;

    if (!Array.isArray(objects)) {
      return <div>Ingresar Array de objetos</div>;
    }

    return (
      <Fragment>
        {objects.length < maxFiles && (
          <div className={"mb-2"}>
          <FileUpload
            isValidate={4}
            hideFiles={true}
            onProcessFiles={this.processFile}
            
          >
            
            </FileUpload>
          </div>
        )}
        
        <div className="">
          {_.map(objects, (o, i) => (
            <FileObject
              key={i}
              object={o}
              onRemoveFile={() => this.handlerRemoveFile(i)}
            />
          ))}
        </div>
        

        {files.length > 0 && <br />}
        {_.map(files, (f, i) => {
          return (
            <UploadFileTemp
              key={i}
              id={name}
              onFileSuccess={this.handlerFileSuccess}
              file={f?.file}
              onRemoveFile={() => {
                this.onRemoveFile(i);
              }}
            />
          );
        })}
      </Fragment>
    );
  }
}

export default FileUploadSection;
