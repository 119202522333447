import React from 'react';
import { NavLink } from 'react-router-dom';
import { FlatIcon } from '../../components';

const Supervisor = () =>
(
    <ul className="kt-menu__nav ">
        <li className="kt-menu__section ">
            <h4 className="kt-menu__section-text">MÓDULOS</h4>
            <i className="kt-menu__section-icon flaticon-more-v2"></i>
        </li>

        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true" >            
            <NavLink
                to="/cordinator/users"
                activeClassName="selectedLink"
                className="kt-menu__link ">
                    <FlatIcon color="success" icon="flaticon-users-1" />                    
                    <span className="kt-menu__link-text"> Usuarios</span>                    
            </NavLink>                
        </li>

        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true" >
            <NavLink
                to="/supervisor/dashboard"
                activeClassName="selectedLink"
                className="kt-menu__link ">
                    <FlatIcon color="success" icon="flaticon2-chart" />                    
                    <span className="kt-menu__link-text"> Dashboard</span>                    
            </NavLink> 
        </li>

        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true" >
            <NavLink
                to="/supervisor/newdashboard"
                activeClassName="selectedLink"
                className="kt-menu__link ">
                    <FlatIcon color="success" icon="flaticon2-chart" />                    
                    <span className="kt-menu__link-text"> Panel de Control</span>                    
            </NavLink> 
        </li>

        <li className="kt-menu__item  kt-menu__item mt-1" aria-haspopup="true" >
            <NavLink
                to="/cordinator/contracts"
                activeClassName="selectedLink"
                className="kt-menu__link ">     
                    <FlatIcon color="success" icon="flaticon-price-tag" />  
                    <span className="kt-menu__link-text">Reporte Contratos</span>                
            </NavLink>
        </li>

        <li className="kt-menu__item  kt-menu__item mt-1" aria-haspopup="true" >
            <NavLink
                to="/supervisor/deliverables"
                activeClassName="selectedLink"
                className="kt-menu__link ">
                    <FlatIcon color="success" icon="flaticon2-box-1" />                    
                    <span className="kt-menu__link-text">Revision de Entregables</span>                    
            </NavLink> 
        </li>


        <li className="kt-menu__item  kt-menu__item mt-4" aria-haspopup="true" >
            <NavLink
                to="/provider/deliverables"
                activeClassName="selectedLink"
                className="kt-menu__link ">
                    <FlatIcon color="success" icon="flaticon2-box-1" />                    
                    <span className="kt-menu__link-text">Mis Entregables</span>                    
            </NavLink>  
        </li>

        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true" >
            <NavLink
                to="/provider/curriculum"
                activeClassName="selectedLink"
                className="kt-menu__link ">
                    <FlatIcon color="success" icon="flaticon2-checking" />                    
                    <span className="kt-menu__link-text"> Hoja de Vida</span>                    
            </NavLink>
        </li>
    </ul>
);

export default Supervisor;
