import React from 'react';
import classNames from 'classnames/bind';

const ButtonFile = ({
    classStyles,
    href="javascript:void(0)",
}) => {
    let styles = classNames('btn btn-outline-success btn-elevate btn-icon', { ...classStyles });

    return (
    <a href={href} className={styles}>
        <i class="fa fa-file-alt"></i>
    </a>
    );
};

export default ButtonFile;