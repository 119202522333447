import React from 'react';


export const ListPagination = ({ data, render, changePage }) => {

    const { current_page, last_page, total, from, to } = data;
    console.log("items", data);

    if (!Array.isArray(data.data)) {
        return '';
    }
    return <>

        <div className="row">
            <div className="col-lg-12 mb-2">
                {data.data.map((item, i) => {
                    return <div key={i}>{render(item, i)}</div>
                })}
            </div>

            <div className="col-lg-12 mb-2">

                <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">

                    {last_page > 1 || <div className="btn-group" role="group" aria-label="Basic example">
                        <button type="button" className="btn btn-sm btn-default" onClick={() => changePage(1)} >«</button>
                        {current_page > 1 && <button type="button" className="btn btn-sm btn-default" onClick={() => changePage(current_page - 1)} >‹</button>}
                        <button type="button" className="btn btn-sm btn-secondary">{current_page}/{last_page}</button>
                        {current_page < last_page && <button type="button" className="btn btn-sm btn-default" onClick={() => changePage(current_page + 1)} >›</button>}
                        <button type="button" className="btn btn-sm btn-default" onClick={() => changePage(last_page)} >»</button>
                    </div>}

                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text" id="btnGroupAddon2">{from} - {to} de total {total}</div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </>

}

export default ListPagination;