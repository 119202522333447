import React from 'react';

const Activity = (props) => (
    <div class="col-lg-3">
        <div class="kt-checkbox-list">
            <label class="kt-checkbox">
            <input type="checkbox" /> {props.activity.name}
            <span></span>
            </label>
        </div>
    </div>
);

export default Activity;