import React from 'react';

const status = (days) =>
{
    if(days < 5)
    {
        return {
            text : `Por vencer en ${Math.abs(days)} días`,
            label : 'success'
        }
    }
    else if(days <= 0)
    {
        return {
            text : `Por vencer en ${Math.abs(days)} días`,
            label : 'warning'
        }
    }
    else
    {
        return{
            text : `Venció hace ${days} días`,
            label : 'danger'
        }
    }
};

const Expiration = ({ label, value }) =>
(
    <div class="kt-demo-icon" style={{padding: 0 }}>
        <div class="kt-demo-icon__preview">
            <i class={`fa fa-clock text-${label}`}></i>
        </div>
        <div class="kt-demo-icon__class">
            {value}
        </div>
    </div>
);

export default Expiration;