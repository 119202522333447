import React, { Component } from 'react';
import Datatable from 'Components/Datatable';
import Activity from 'Views/Contracts/Activity';
import Select from 'Components/Select';
import File from 'Components/File';
import Button from 'Components/Button';
import * as EventsActionsCreators from 'Ducks/events/operations';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import * as ContractsActionsCreators from 'Ducks/contracts/operations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { settings } from 'Utils/settings';
import { Input } from 'Components';

class CreateMeansOfVerification extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            
        };

        const { dispatch } = props;

        this.eventsActionsCreators = bindActionCreators(EventsActionsCreators, dispatch);
        
        this.providersActionsCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

        this.contractsActionsCreators = bindActionCreators(ContractsActionsCreators, dispatch);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount()
    {
        this.eventsActionsCreators.getEvents();

        this.providersActionsCreators.getProviders();
    }

    handleChangeProviders = (e) => this.setState({ provider_id : e });

    handleChangeEvents = (e) => this.setState({ event_id : e });
    /*{
        console.log("HANDLE CHANGE EVENTS FROM NEWCONTRACTS", e);
        const { events } = this.props;

        console.log("EVENTS: ", events);

        let activities = _.head(_.filter(events, event => event.id == e)).activities;

        console.log("ACTIVITIES: ", activities);

        this.setState({ activities });
    };*/

    handleChangeFile = async (e) =>
    {
        const { name, files } = e.target;

        let urlFile = await this.contractsActionsCreators.submitFile(files[0]);

        this.setState({ [ name ] : urlFile });

        /*let result = await this.contractsActionsCreators.submitFile(file);

        console.log("RESULT HANDLECHANGEFILE: ", result);*/
    };

    handleChange = (e) =>
    {
        const { name, value } = e.target;
        this.setState({ [ name ] : value });
    };

    handleSubmit = () =>
    {
        this.contractsActionsCreators.editContract(this.state);
    };

    render()
    {
        const { events, providers, api } = this.props;

        const { activities } = this.state;

        return(
            <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="kt-portlet">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">
                                    MEDIO DE VERIFICACIÓN
                                </h3>
                            </div>
                        </div>
                     
                    </div>

                    <div class="row">
    <div class="col-xl-4">
        <div class="kt-portlet kt-portlet--height-fluid">            
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-widget kt-widget--project-1">
                    <div class="kt-widget__head d-flex">
                        <div class="kt-widget__label">
                        <div class="kt-demo-icon">
                                        <div class="kt-demo-icon__preview">
                                            <i class="fa fa-book-open"></i>
                                        </div>
                                    </div>
                            <div class="kt-widget__info kt-padding-0">
                                <a href="#" class="kt-widget__title">
                                    Nexa - Next generation SAAS                                                 
                                </a>
                                <span class="kt-widget__desc">
                                    Creates Limitless possibilities  
                                </span>
                            </div>
                        </div>
                        <div class="kt-widget__toolbar">
                            <a href="#" class="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                <i class="flaticon-more-1"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                <ul class="kt-nav">
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-line-chart"></i>
                                            <span class="kt-nav__link-text">Reports</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-send"></i>
                                            <span class="kt-nav__link-text">Messages</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                            <span class="kt-nav__link-text">Charts</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-avatar"></i>
                                            <span class="kt-nav__link-text">Members</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-settings"></i>
                                            <span class="kt-nav__link-text">Settings</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget__body">
                        <span class="kt-widget__text kt-margin-t-0 kt-padding-t-5">
                            I distinguish three main text objecttives.First
                            your objective could be merely                             
                        </span>
                        
                        <div class="kt-widget__stats kt-margin-t-20">
                            <div class="kt-widget__item d-flex align-items-center kt-margin-r-30">
                                <span class="kt-widget__date kt-padding-0 kt-margin-r-10">
                                    Start 
                                </span>
                                <div class="kt-widget__label">
                                    <span class="btn btn-label-brand btn-sm btn-bold btn-upper">07 may, 18</span>
                                </div>
                            </div>
                            <div class="kt-widget__item d-flex align-items-center kt-padding-l-0">
                                <span class="kt-widget__date kt-padding-0 kt-margin-r-10 ">
                                    Due
                                </span>
                                <div class="kt-widget__label">
                                    <span class="btn btn-label-danger btn-sm btn-bold btn-upper">07 0ct, 18</span>
                                </div>
                            </div>
                        </div>
                        <div class="kt-widget__container">
                            <span class="kt-widget__subtitel">Progress</span>
                            <div class="kt-widget__progress d-flex align-items-center flex-fill">
                                <div class="progress" style={{ height: '5px' ,width: '100%' }}>
                                    <div class="progress-bar kt-bg-success" role="progressbar" style={{ width: '78%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <span class="kt-widget__stat">
                                    78%
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget__footer">
                        <div class="kt-widget__wrapper">
                            <div class="kt-widget__section">
                                <div class="kt-widget__blog">
                                    <i class="flaticon2-list-1"></i>
                                    <a href="#" class="kt-widget__value kt-font-brand">64 Tasks</a>
                                </div>
                                <div class="kt-widget__blog">
                                    <i class="flaticon2-talk"></i>
                                    <a href="#" class="kt-widget__value kt-font-brand">654 Comments</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
    
    <div class="col-xl-4">
        <div class="kt-portlet kt-portlet--height-fluid">
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-widget kt-widget--project-1">
                    <div class="kt-widget__head d-flex">
                        <div class="kt-widget__label">
                            <div class="kt-widget__media kt-widget__media--m">
                                <span class="kt-media kt-media--md kt-media--circle kt-hidden-"> 
                                    <img src="./assets/img/4.png" alt="image"/>  
                                </span>
                                <span class="kt-media kt-media--md kt-media--circle kt-hidden"> 
                                    <img src="./assets/img/100_12.jpg" alt="image"/>  
                                </span>
                            </div>
                            <div class="kt-widget__info kt-padding-0 kt-margin-l-15">
                                <a href="#" class="kt-widget__title">
                                    B&Q - Food Company                                                 
                                </a>
                                <span class="kt-widget__desc">
                                    Tasty food for everyone 
                                </span>
                            </div>
                        </div>
                        <div class="kt-widget__toolbar">
                            <a href="#" class="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                <i class="flaticon-more-1"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                <ul class="kt-nav">
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-line-chart"></i>
                                            <span class="kt-nav__link-text">Reports</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-send"></i>
                                            <span class="kt-nav__link-text">Messages</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                            <span class="kt-nav__link-text">Charts</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-avatar"></i>
                                            <span class="kt-nav__link-text">Members</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-settings"></i>
                                            <span class="kt-nav__link-text">Settings</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget__body">
                        <span class="kt-widget__text kt-margin-t-0 kt-padding-t-5">
                            I distinguish three main text objecttives.First<br/> 
                            your objective could be merely                             
                        </span>
                        
                        <div class="kt-widget__stats kt-margin-t-20">
                            <div class="kt-widget__item d-flex align-items-center kt-margin-r-30">
                                <span class="kt-widget__date kt-padding-0 kt-margin-r-10">
                                    Start 
                                </span>
                                <div class="kt-widget__label">
                                    <span class="btn btn-label-brand btn-sm btn-bold btn-upper">20 aug, 18</span>
                                </div>
                            </div>
                            <div class="kt-widget__item d-flex align-items-center kt-padding-l-0">
                                <span class="kt-widget__date kt-padding-0 kt-margin-r-10 ">
                                    Due
                                </span>
                                <div class="kt-widget__label">
                                    <span class="btn btn-label-danger btn-sm btn-bold btn-upper">07 dec, 18</span>
                                </div>
                            </div>
                        </div>
                        <div class="kt-widget__container">
                            <span class="kt-widget__subtitel">Progress</span>
                            <div class="kt-widget__progress d-flex align-items-center flex-fill">
                                <div class="progress" style={{ height: '5px', width: '100%' }}>
                                    <div class="progress-bar kt-bg-success" role="progressbar" style={{ width: '92%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <span class="kt-widget__stat">
                                    92%
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget__footer">
                        <div class="kt-widget__wrapper">
                            <div class="kt-widget__section">
                                <div class="kt-widget__blog">
                                    <i class="flaticon2-list-1"></i>
                                    <a href="#" class="kt-widget__value kt-font-brand">32 Tasks</a>
                                </div>
                                <div class="kt-widget__blog">
                                    <i class="flaticon2-talk"></i>
                                    <a href="#" class="kt-widget__value kt-font-brand">534 Comments</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    
    <div class="col-xl-4">
        <div class="kt-portlet kt-portlet--height-fluid">
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-widget kt-widget--project-1">
                    <div class="kt-widget__head d-flex">
                        <div class="kt-widget__label">
                            <div class="kt-widget__media kt-widget__media--m">
                                <span class="kt-media kt-media--md kt-media--circle kt-hidden-"> 
                                    <img src="./assets/img/5.png" alt="image"/>  
                                </span>
                                <span class="kt-media kt-media--md kt-media--circle kt-hidden"> 
                                    <img src="./assets/img/100_12.jpg" alt="image"/>  
                                </span>
                            </div>
                            <div class="kt-widget__info kt-padding-0 kt-margin-l-15">
                                <a href="#" class="kt-widget__title">
                                    Citrix - CRM Application                                                 
                                </a>
                                <span class="kt-widget__desc">
                                    The Best CRM Salution  
                                </span>
                            </div>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <a href="#" class="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                <i class="flaticon-more-1"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                <ul class="kt-nav">
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-line-chart"></i>
                                            <span class="kt-nav__link-text">Reports</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-send"></i>
                                            <span class="kt-nav__link-text">Messages</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                            <span class="kt-nav__link-text">Charts</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-avatar"></i>
                                            <span class="kt-nav__link-text">Members</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-settings"></i>
                                            <span class="kt-nav__link-text">Settings</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget__body">
                        <span class="kt-widget__text kt-margin-t-0 kt-padding-t-5">
                            I distinguish three main text objecttives.First<br/> 
                            your objective could be merely                             
                        </span>
                        
                        <div class="kt-widget__stats kt-margin-t-20">
                            <div class="kt-widget__item d-flex align-items-center kt-margin-r-30">
                                <span class="kt-widget__date kt-padding-0 kt-margin-r-10">
                                    Start 
                                </span>
                                <div class="kt-widget__label">
                                    <span class="btn btn-label-brand btn-sm btn-bold btn-upper">16 jan, 19</span>
                                </div>
                            </div>
                            <div class="kt-widget__item d-flex align-items-center kt-padding-l-0">
                                <span class="kt-widget__date kt-padding-0 kt-margin-r-10 ">
                                    Due
                                </span>
                                <div class="kt-widget__label">
                                    <span class="btn btn-label-danger btn-sm btn-bold btn-upper">30 may, 19</span>
                                </div>
                            </div>
                        </div>
                        <div class="kt-widget__container">
                            <span class="kt-widget__subtitel">Progress</span>
                            <div class="kt-widget__progress d-flex align-items-center flex-fill">
                                <div class="progress" style={{ height: '5px' ,width: '100%' }}>
                                    <div class="progress-bar kt-bg-success" role="progressbar" style={{ width: '21%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <span class="kt-widget__stat">
                                    21%
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget__footer">
                        <div class="kt-widget__wrapper">
                            <div class="kt-widget__section">
                                <div class="kt-widget__blog">
                                    <i class="flaticon2-list-1"></i>
                                    <a href="#" class="kt-widget__value kt-font-brand">87 Tasks</a>
                                </div>
                                <div class="kt-widget__blog">
                                    <i class="flaticon2-talk"></i>
                                    <a href="#" class="kt-widget__value kt-font-brand">759 Comments</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>

                    <div class="kt-portlet">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">
                                    Producto 2
                                </h3>
                            </div>
                        </div>
                        <form class="kt-form kt-form--fit kt-form--label-right">
                            <div class="kt-portlet__body">
                            <div class="form-group row">
                                    <div class="col-lg-6">
                                    <a href="javascript:void(0)" class="btn btn-outline-success btn-elevate btn-icon"><i class="fa fa-file-alt"></i></a> Medio de verificación 1 - Producto 1
                                    </div>
                                </div>  
                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <File 
                                            name="_filename_tdr"
                                            //onChange={""}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    events : state.events.events,
    providers : state.providers.providers,
    api : state.api
});

export default connect(mapStateToProps, null)(CreateMeansOfVerification);