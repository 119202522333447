import React from "react";

const Switch = ({...props}) => {
  return (
    <span class="kt-switch">
      <label>
        <input type="checkbox" {...props} />
        <span></span>
      </label>
    </span>
  );
};

export default Switch;
