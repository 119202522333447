import React, { useState } from 'react';
import 'Assets/css/404.css';
import { Redirect } from 'react-router-dom';

const NotFound = () =>
{
    const [status, setRedirect] = useState(false);

    return (
        <div class="kt-grid kt-grid--ver kt-grid--root" style={{ height : '100%' }}>
            {status && <Redirect to="/" /> }
            <div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v1" style={{ backgroundImage : 'url(/assets/img/bg1.jpg)' }}>
                <div class="kt-error-v1__container">
                    <h1 class="kt-error-v1__number">404</h1>
                    <p class="kt-error-v1__desc">
                    OOPS! Algo salió mal aquí!!!
                    </p>
                        <button 
                            type="button" 
                            class="btn btn-lg btn-outline-secondary kt-error-v1__desc"
                            onClick={() => setRedirect(true)}>Volver</button>
                </div>	 
            </div>
        </div>
    )
};

export default NotFound;