import React, {useEffect, useState, Component, useMemo} from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';

const ACCEPT = `.doc,.docx,.xml,.xls,.xlsx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.zip,.rar`;

function FileUpload(props) {

    const {children, hideFiles, placeholder} = props;
    const [files, setFiles] = useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
      } = useDropzone({
      accept: props.accept ? props.accept : ACCEPT,
      onDrop: acceptedFiles => {
        if(!props.isValidate)
        {
          alert("Elegir el tipo de archivo a subir..");

          return false;
        }

        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));

        props.onProcessFiles(acceptedFiles);
      },
      onDragOver: (data) => {
        console.log("ONDRAGOVER");
      },
      onDragLeave: () => {
        alert("ONDRAGLEAVE")
      }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isDragActive,
        isDragReject
      ]);
    
    const thumbs = files.map(file => (
      <div style={thumb} key={file.name}>
          {/*<img
            src={file.preview}
            style={img}
          />*/}
          <div class="kt-demo-icon__preview" style={{ width : '100%', textAlign : 'center' }}>
							<i style={{ fontSize : '4em' }} class="flaticon-upload"></i>
					</div>
      </div>
    ));
  
    useEffect(() => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);
  
    

    return (
      <section className="">
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          <div>{ placeholder ? placeholder : 'Arrastrar y soltar, o dar click para seleccionar archivos..'}</div>
          {children && <div  style={{width: '100%', color:'#3e3e3e'}}>{children}</div>}
        </div>
        {!hideFiles && <aside style={thumbsContainer}>
          {thumbs}
        </aside>}
      </section>
    );
  }

  export default connect(null, null)(FileUpload);

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#b3b3b3',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    fontWeight: '600',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
borderColor: '#2196f3'
};

const acceptStyle = {
borderColor: '#00e676'
};

const rejectStyle = {
borderColor: '#ff1744'
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};
  
const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};
  
const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};
  
const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};