import React, { Component } from 'react';
import moment from 'moment';
import { 
    Datatable, 
    Expiration, 
    Loader,
    Badge,
    Date,
    EditButton,
    FlatIcon } from 'Components';
import * as DeliverablesActionsCreators from 'Ducks/deliverables/operations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { settings } from 'Utils/settings';

class DeliverablesProvider extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            deliverables : [],
            total: null,
            per_page: null,
            current_page: 1,
            last_page : 1,
            loading : true,
            numberPage : 1,
            statusReview : null
        };

        const { dispatch } = props;

        this.deliverablesActionsCreators = bindActionCreators(DeliverablesActionsCreators, dispatch);
        
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidMount()
    {
        this.deliverablesActionsCreators.getDeliverablesForProviders();
    }

    handlePageClick = numberPage => 
    {
        this.setState({ loading : true, numberPage : numberPage + 1 });
        this.supervisorsActionsCreators.getDeliverablesForProviders(numberPage + 1);
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;

        if(oldProps.deliverables !== newProps.deliverables) 
        {
            this.setState({ 
                deliverables : newProps.deliverables.data, 
                total : newProps.deliverables.total,
                per_page : newProps.deliverables.per_page,
                current_page : newProps.deliverables.current_page,
                last_page : newProps.deliverables.last_page,
                loading : false
            });
        }
    }

    /*alertDeliveryDay = (deliverable) => moment().diff(deliverable.delivery_date, 'days');*/

    render()
    {
        const { deliverables, 
            last_page, 
            current_page,
            loading,
            numberPage } = this.state; 

        const columns = [
            {
                label: 'Contrato / Orden de Servicio',
                field: 'contract_id',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Entregable',
                field: 'name',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Monto:',
                field: 'amount',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Penalidad',
                field: 'penalty_amount',
                sort: 'asc',
                width: 200
            },
            
            {
                label: 'Fecha de Vencimiento',
                field: 'delivery_date',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Estado del Entregable',
                field: 'status_review',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Editar',
                field: 'edit',
                sort: 'asc',
                width: 150
            }
          ];

          let rows = _.map(deliverables, deliverable => {
            let data = _.pick(deliverable, ['contract_id','name', 'amount', 'penalty_amount', 'delivery_date', 'expiration', 'status_review']);

            return _.assign(data, {
                contract_id : deliverable.contract.contract_number == 'S/C' ? `OS: ${deliverable.contract.order}` : `CONTRATO: ${deliverable.contract.contract_number }`,
                name: `ENTREGABLE N°${deliverable.order}`,
                delivery_date :
                        <Date 
                            alert='success'
                            date={deliverable.delivery_date}
                            outputFormat='DD-MM-YYYY'
                        />,
                expiration : <Expiration 
                    label={deliverable._data_alert ? deliverable._data_alert.label : ''} 
                    value={deliverable._data_alert ? deliverable._data_alert.value : ''} />,
                status_review:  <Badge label={settings.STATUS_INFORME[deliverable.status_review].label} name={settings.STATUS_INFORME[deliverable.status_review].name} />,
                edit : <Link to={{
                    pathname:'/provider/edit-deliverables-provider',
                    search:`?id=${deliverable.id}`,
                    //hash:'#hash',
                    state: { deliverable }
                    }}><EditButton /></Link> });
          });

        return(
            <div class="kt-portlet kt-portlet--mobile">
	            <div class="kt-portlet__head kt-portlet__head--lg">
                <div class="kt-portlet__head-label">
                    <FlatIcon color="success" icon="flaticon2-box-1" />
                    <h3 class="kt-portlet__head-title">
                        Mis Entregables
                    </h3>
                </div>
		        <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
                <div class="kt-portlet__head-actions">
                            <div class="dropdown dropdown-inline">
                                {/*<button type="button" class="btn btn-default btn-icon-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="la la-download"></i> Exportar
                            </button>*/}
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul class="kt-nav">
                                        <li class="kt-nav__section kt-nav__section--first">
                                            <span class="kt-nav__section-text">Choose an option</span>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-print"></i>
                                                <span class="kt-nav__link-text">Print</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-copy"></i>
                                                <span class="kt-nav__link-text">Copy</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-file-excel-o"></i>
                                                <span class="kt-nav__link-text">Excel</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-file-text-o"></i>
                                                <span class="kt-nav__link-text">CSV</span>
                                            </a>
                                        </li>
                                        <li class="kt-nav__item">
                                            <a href="#" class="kt-nav__link">
                                                <i class="kt-nav__link-icon la la-file-pdf-o"></i>
                                                <span class="kt-nav__link-text">PDF</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                </div>	
            </div>		
        </div>
	</div>
                <div class="kt-portlet__body">
                    <Datatable 
                        columns={columns} 
                        rows={rows}
                        handlePageClick={(e) => this.handlePageClick(e.selected)}
                        last_page={last_page} 
                        current_page={current_page} />
                </div>
                { loading ? <Loader /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    deliverables : state.deliverables.deliverables
});

export default connect(mapStateToProps, null)(DeliverablesProvider);
