import KeyMirror from 'keymirror';

export const constants = KeyMirror({
    TEST: null,
    GET_CONTRACTS : null,
    SUBMIT_FILE : null,
    CREATE_CONTRACT : null,
    //API
    SET_CONTRACTS : null,
    GET_CONTRACT_NUMBER: null,
    GET_PROCESSES: null,
    SET_PROCESSES: null
});
