import React, { Component, useEffect, Fragment } from 'react';
import { Loader, EditButton, Input, Select, Button, ButtonFile , Badge, Progress, CustomButton, FlatIcon} from 'Components';
import DatatableExpanded from 'Components/DatatableExpanded';
import PageInformation from 'Components/Datatable/PageInformation';
import * as cordinatorActionsCreators from 'Ducks/cordinator/operations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { settings } from 'Utils/settings';
import { Link } from 'react-router-dom';
import { constants } from 'crypto';
import { routes } from 'Utils/constants';

class ContractsCordinator extends Component
{   
    constructor(props)
    {
        super(props);

        this.state = this.getInitialState();
        
        const { dispatch } = props;

        this.cordinatorActionsCreators = bindActionCreators(cordinatorActionsCreators, dispatch);
        
        this.handlePageClick = this.handlePageClick.bind(this);

        this.handleChange = this.handleChange.bind(this);

        this.search = this.search.bind(this);
    };

    getInitialState = () =>
    ({
        contracts : [],
        total: "",
        per_page: null,
        current_page: 1,
        last_page : 1,
        loading : true,
        numberPage : 1,
        responsable : "",
        provider : "",
        provider_name: "",
        type_contract : "",
        order : "",
        contract_number : "",
        event_id : '',
        //evaluador_id : '',
        responsable_id : '',
        responsable_name: '',
        proyect_activity_id : '',
        status_review : ''
    });

    componentDidMount()
    {
        this.cordinatorActionsCreators.getContracts();
    };

    handleChange = (e) =>
    {
        const { name, value } = e.target;
        this.setState({ [ name ] : value });
    };

    handlePageClick = numberPage => 
    {
        this.setState({ loading : true, numberPage : numberPage + 1 });
        this.cordinatorActionsCreators.getContracts(numberPage + 1, this.state);
    }

    search = () =>
    {
        const { numberPage } = this.state;
        this.cordinatorActionsCreators.getContracts(numberPage, this.state);
    }   

    clean = () => 
    {
        this.setState({...this.getInitialState()}, () => {
            this.search();
        });        
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;

        if(oldProps.contracts !== newProps.contracts) 
        {
            this.setState({ 
                contracts : newProps.contracts.data, 
                total : newProps.contracts.total,
                per_page : newProps.contracts.per_page,
                current_page : newProps.contracts.current_page,
                last_page : newProps.contracts.last_page,
                loading : false
            });
        }
    }

    onEvent = (e) =>
    {
        this.setState({ event_id : e });

        this.cordinatorActionsCreators.getActivitiesForEvent(e);
    };

    render()
    {
        const { 
            contracts,
            last_page, 
            current_page,
            loading,
            numberPage,
            total,
            responsable,
            provider,
            type_contract,
            contract_number,
            responsable_name,
            provider_name,
            order,
            event_id,
            evaluador_id,
            status_review } = this.state; 

        const { api, activities } = this.props;

        let rows = _.map(contracts, contract => {
            let data = _.pick(contract, ['exercise', 'signature_date', 'responsable', 'concept', 'amount', 'duration']);

            return _.assign(data, { 
                concept : 
                    <div style={{ whiteSpace : 'unset' }}>
                        { contract.concept }
                    </div>,
                progress : progressCell(contract),
                provider : providerCell(contract),
                responsable : responsableCell(contract),
                event : contract.event ? contract.event.shortname : '-',
                deliverable : contract.deliverables,
                edit : <Link to={{
                    pathname:'/edit-contract',
                    search:`?id=${contract.id}`,
                    //hash:'#hash',
                    state: { contract }
                    }}><EditButton /></Link> });
        });

        const columns = [
            {
                name : 'Proveedor',
                selector : 'provider',
                sortable : true,
                left : true,
                width : "350px",
                style: {
                    paddingTop: '2rem',
                    paddingBottom: '2rem'
                }
            },
            {
                name : 'Concepto',
                selector : 'concept',
                sortable : true,
                right : true,
                center : true,
                width : "400px"
            },
            {
                name : 'Progreso',
                selector : 'progress',
                sortable : true,
                right : true,
                center : true,
                width : "100px"
            },            
            {
                name : 'Ejecutivo',
                selector : 'responsable',
                sortable : true,
                right : true,
                center : true,
                width : "180px"
            },
            {
                name : 'Origen',
                selector : 'event',
                sortable : true,
                center : true,
                right : true
            }
        ];

        return(
            <div class="kt-portlet kt-portlet--mobile">
	            <div class="kt-portlet__head kt-portlet__head--lg">
                    <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon">
                            <i class="kt-font-success flaticon-price-tag"></i>
                        </span>
                        <h3 class="kt-portlet__head-title">
                            Contratos
                        </h3>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <div class="kt-portlet__head-actions">
                                <div class="dropdown dropdown-inline">
                                </div>
                            </div>	
                        </div>
                    </div>
	            </div>
                <div class="kt-portlet__head kt-portlet__head--lg d-block kt-margin-t-25">
                    <div class="kt-section__content w-100">
                        <div class="row">
                            <div class="col-lg-4 form-group">
                                {/*<Select 
                                    text="Seleccionar Responsable"
                                    value={settings.RESPONSABLES}
                                    selected={evaluador_id}
                                    onChange={(e) => this.setState({ evaluador_id : e })}
                                />*/}
                                { <Input 
                                    classStyles={ {} } 
                                    label="Ejecutivo"
                                    placeholder="Nombre Ejecutivo..."
                                    type="text"
                                    name="responsable_name"
                                    value={responsable_name}
                                    onChange={this.handleChange}
							    /> }

                            </div>
                            <div class="col-lg-4 form-group">
                                { <Input 
                                    classStyles={ {} } 
                                    label="Proveedor"
                                    placeholder="Nombre Proveedor..."
                                    type="text"
                                    name="provider_name"
                                    value={provider_name}
                                    onChange={this.handleChange}
							    /> }
                            </div>
                            <div class="col-lg-4 form-group">
                                { <Input 
                                    classStyles={ {} } 
                                    label="N Contrato"
                                    placeholder="Contrato / Orden de Servicio"
                                    type="text"
                                    name="contract_number"
                                    value={contract_number}
                                    onChange={this.handleChange}
							    /> }
                            </div>
                            <div class="col-lg-4 form-group">                                
                                { <Select 
                                    text="Seleccionar Convocatoria"
                                    value={settings.EVENTS}
                                    selected={event_id}
                                    onChange={(e) => this.onEvent(e)}
                                /> }
                            </div>
                        
                            {event_id && <>
                                <div class="col-lg-4 form-group">
                                            { <Select 
                                                text="Elegir una actividad"
                                                value={activities.data}
                                                selected={event_id}
                                                onChange={(e) => this.setState({ proyect_activity_id : e })}
                                            /> }
                                        </div>
                                <div class="col-lg-4 form-group d-flex align-items-end">
                                            <label class="form-control-label">Ficha Convocatoria</label>
                                            <CustomButton 
                                                classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-warning' : true}}
                                                icon="fa fa-cloud-download-alt"
                                                title="PDF"
                                                style={{ marginLeft : '1em' }}
                                                href={`${settings.BASE_URL}/proyects/${settings.PROJECT_ID}/reports/events?event_id=${event_id}&token=${localStorage.getItem('token')}`}
                                                target="_blank"
                                            />
                                            <CustomButton 
                                                classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-warning' : true}}
                                                icon="fa fa-cloud-download-alt"
                                                title="EXCEL"
                                                style={{ marginLeft : '1em' }}
                                                href={`${settings.BASE_URL}/proyects/${settings.PROJECT_ID}/reports/events?download=excel&event_id=${event_id}&token=${localStorage.getItem('token')}`}
                                                target="_blank"
                                            />
                                        </div>
                            </>}
                        
                            <div class="col-lg-4 form-group">
                                { <Select 
                                    text="Seleccionar Estado Entregables"
                                    value={settings.STATUS_INFORME}
                                    selected={status_review}
                                    onChange={(e) => this.setState({ status_review : e })}
                                /> }
                            </div>

                            <div class="col-lg-4 form-group">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Button 
                                            classStyles={ { 'btn-success' : true, 'btn-font-lg' : true, 'w-100' : true } }
                                            name="BUSCAR"
                                            loading={api.loading}
                                            onClick={this.search}
                                        />                            
                                    </div>
                                    <div className="col-lg-6">
                                        <Button 
                                            classStyles={ { 'btn-secondary' : true, 'btn-font-lg' : true, 'w-100' : true, 'mt-1': true } }
                                            name="LIMPIAR"
                                            loading={api.loading}
                                            onClick={this.clean}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                    
				</div>

                <PageInformation total={total} current_page={current_page} last_page={last_page} />
               
                <div class="kt-portlet__body">
                    <DatatableExpanded 
                        columns={columns}
                        data={rows}
                        onSelectedRowsChange={(e) => console.log("SELECCIONADO: ", e)}
                        expandeRows
                        ExpandableRowsComponent={ExpanableComponent}
                        handlePageClick={(e) => this.handlePageClick(e.selected)}
                        last_page={last_page} 
                        current_page={current_page - 1}
                        style={{ border : '1px solid #ebedf2' }}
                    />
                </div>
                { loading ? <Loader /> : ''}
            </div>
        )
    }
}

const progressCell = ({ deliverables }) => {

    return deliverables.length ? _.filter(deliverables, (deliverable) => deliverable.status_review == 3).length +'/'+deliverables.length:'-';

    /*return (
        <Progress 
            progress={_.filter(deliverables, (deliverable) => deliverable.status_review == 3).length} 
            valuemax={deliverables.length}
        />
    );*/

};

const providerCell = ({ provider, document_contract, contract_number, document_tdr }) => {
    return (
        <div className="d-flex flex-column">
            <div className="provider-name">
                <FlatIcon icon="flaticon-businesswoman" />    
                <span className="kt-font-bold ml-1">{provider ? provider.name : '-'}</span>
            </div>           
            <div className="provider-files d-flex flex-row  mt-3">
                <div className="provider-files-contrato">
                    <div className="d-flex flex-column"> 
                        <Badge label="info" name={`Contratos`} />
                        {ContratoButton(document_contract, contract_number)}
                    </div>
                </div>
                <div className="provider-files-tdr ml-5">
                    <div className="d-flex flex-column"> 
                        <Badge label="warning" name="TDR"/>
                        {TdrButton(document_tdr)}
                    </div>                    
                </div>                
            </div>            
        </div>
    );
};

const ContratoButton = (document_contract, contract_number) => {
    return (
        document_contract ? 
        <div style={{ textAlign : 'center' }} className="mt-2">                         
            <ButtonFile classStyles={{ 'btn-outline-info' : true }} href={document_contract._url} />
            <p style={{ marginTop : '1em' }} className="kt-font-info"><em>{ contract_number }</em></p>                                     
        </div> 
        : '-'
    );
};

const TdrButton = (document_tdr) => {
    return (
        document_tdr ? 
        <ButtonFile classStyles={{ 'btn-outline-warning' : true, 'mt-2' : true }} href={document_tdr._url} /> 
        : '-'
    )
}

const responsablesCell = ({ evaluadores }) => {
    return evaluadores.length ? _.map(evaluadores, (evaluador, index) => {
        return (
            <div className={index > 0 ? 'mt-3' : ''}>
                <FlatIcon icon="flaticon2-user" />                
                {evaluador.name}
            </div>
        )}
    ) : '-';
};

const responsableCell = ({ responsable }) => {
    return  responsable ? responsable.name : '';
};

const ExpanableComponent = ({ data }) => 
{
    return (
        <table style={{ border : '2px solid #ebedf2', width : '100%'  }}>
            <tbody>
                <tr>
                    <th class="text-center" style={{ padding : '1.2em 0', borderBottom : '1px solid #ebedf2'}}>Ver Entregables</th>
                    <th class="text-center" style={{ padding : '1.2em 0', borderBottom : '1px solid #ebedf2'}}>N Entregable</th>
                    <th class="text-center" style={{ padding : '1.2em 0', borderBottom : '1px solid #ebedf2'}}>Monto programado</th>
                    <th class="text-center" style={{ padding : '1.2em 0', borderBottom : '1px solid #ebedf2'}}>Monto Pagado</th>
                    <th class="text-center" style={{ padding : '1.2em 0', borderBottom : '1px solid #ebedf2'}}>Estado</th>
                    <th class="text-center" style={{ padding : '1.2em 0', borderBottom : '1px solid #ebedf2'}}>F. Entrega</th>
                    <th class="text-center" style={{ padding : '1.2em 0', borderBottom : '1px solid #ebedf2'}}>F. Aprobación</th>
                    <th class="text-center" style={{ padding : '1.2em 0', borderBottom : '1px solid #ebedf2'}}>Conformidad
                    </th>
                    <th class="text-center" style={{ padding : '1.2em 0', borderBottom : '1px solid #ebedf2'}}>Ver Entregables</th>
                </tr>
                {
                    _.map(data.deliverable, (deliverable) => (
                            <tr style={{ height : '50px',borderBottom : '1px solid #ebedf2' }}>
                                <td class="text-center" style={{ padding : '1.2em 0', borderBottom : '1px solid #ebedf2'}}>
                                    <Link to={{
                                        pathname: routes.CORDINATOR.DETAIL_DELIVERABLE,
                                        search:`?id=${deliverable.id}`,
                                        //hash:'#hash',
                                        state: { deliverable, deliverables : data.deliverable}
                                    }}>
                                        <CustomButton 
                                            classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-warning' : true,'btn-icon' : true }}
                                            icon="fa fa-eye"
                                            style={{ marginLeft : '1em' }}
                                        />
                                    </Link>
                                </td>
                                <td class="text-center">{ deliverable.order }</td>
                                <td class="text-center">{ deliverable.amount }</td>
                                <td class="text-center">{ deliverable.payment ? deliverable.payment : '' }</td>
                                <td class="text-center">
                                    <Badge label={`${settings.STATUS_INFORME[deliverable.status_review].label}`} name={settings.STATUS_INFORME[deliverable.status_review].name} />
                                </td>
                                <td class="text-center">{ deliverable.delivery_date }</td>
                                <td class="text-center">{ deliverable.approved_at || '-' }</td>
                                <td class="text-center">{ deliverable.document_compliance ? 
                                    <ButtonFile classStyles={{ 'btn-outline-success' : true }} href={deliverable.document_compliance._url} />
                                        : '-' }
                                </td>
                                <td class="text-center" style={{ padding : '1.2em 0', borderBottom : '1px solid #ebedf2'}}>
                                    <Link to={{
                                        pathname: routes.CORDINATOR.DETAIL_DELIVERABLE,
                                        search:`?id=${deliverable.id}`,
                                        //hash:'#hash',
                                        state: { deliverable, deliverables : data.deliverable}
                                    }}>
                                        <CustomButton 
                                            classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-warning' : true,'btn-icon' : true }}
                                            icon="fa fa-eye"
                                            style={{ marginLeft : '1em' }}
                                        />
                                    </Link>
                                </td>
                            </tr>
                        ))
                }
            </tbody>
        </table>
    )
}

const mapStateToProps = (state) => ({
    api : state.api,
    contracts : state.cordinator.contracts,
    activities : state.cordinator.activities
});

export default connect(mapStateToProps, null)(ContractsCordinator);
