

import { default as HttpClient } from './HttpClient';

const  ApigprocSepa =  {

    findAll: (query) => {     
        return HttpClient.get('/sepas', {params: query});
    },

    create: (data) => {     
        return HttpClient.post('/sepas', data);
    },

    update: (data) => {     
        return HttpClient.put('/sepas', data);
    },
}

export default ApigprocSepa;


