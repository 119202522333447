import React, { useState } from "react";
import _ from "lodash";
import "./ResumableUploadFile/index.css";
import { settings } from 'Utils/settings';

const FileAdjunto = ({ adjunto }) => {

  if(!adjunto){
    return '';
  }
  const { tamano, created_at, nombre } = adjunto;
  const _url = `https://std.pmesut.gob.pe/?c=File&a=show&adjunto=${adjunto?.adjunto}`;
  const kilobyte = 1024;
  const megabyte = (kilobyte * 1024);
  const gigabyte = (megabyte * 1024);

  let tamanotext = `0 KB`;

  if (tamano && tamano > 0) {
    tamanotext = `${((tamano / kilobyte)).toFixed(0)} KB`;
    if ((tamano / megabyte) >= 1) {
      tamanotext = `${(tamano / megabyte).toFixed(0)} MB`;
      if ((tamano / gigabyte) >= 1) {
        tamanotext = `${(tamano / gigabyte).toFixed(0)} GB`;
      }
    }
  }

  return  <div className="file-success"  >
    <div className="data-file">
        <a href={_url} target={"_blank"}>
          <strong>{nombre} </strong>         
        </a>
        <strong>({tamanotext})</strong> 
        <div><small>{created_at}</small></div>
    </div>
  </div>
}

export default FileAdjunto;