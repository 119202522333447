import React, { useState, useEffect } from 'react';
import { Button, CustomButton, Select, Radio } from 'Components';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import _ from 'lodash';

const Languages = ({ cv_languages, languages }) =>
{
    const dispatch = useDispatch();
    const [lang , setLang] = useState('');
    const [selected , setSelected] = useState('');
    const [statusData , setStatusData] = useState(false);


    useEffect(() => {
        DiffBetweenLanguagesAndCvLanguages();
    }, [statusData]);

    const DiffBetweenLanguagesAndCvLanguages = () =>
    {
        let aKeyLanguages = _.keys(
            _.keyBy(cv_languages, 'language_id')
        );

        let dataFiltered = _.filter(languages, (language) => {
            let search = _.indexOf(aKeyLanguages, language.id.toString());

            return search == -1
        });

        setLang(dataFiltered);
        setStatusData(true);
    }; 

    const addLanguage = async(e) =>
    {
        e.preventDefault();

        await dispatch(ProvidersActionsCreators.setLanguage({ language_id : selected }));

        setSelected('');
        updateCvTitle();
    };

    const updateCvTitle = () => setLang(_.filter(lang, (language) => language.id != selected));

    return (
        <form method="POST" onSubmit={addLanguage}>
            <div className="form-group">
                <label className="col-xl-3 col-lg-3 col-form-label">Añadir idioma:</label>
                <Select 
                    text="Seleccionar idioma:"
                    value={lang}
                    name="choose-language"
                    selected={selected}
                    required={true}
                    onChange={(e) => setSelected(e)}
                />
                <Button 
                    classStyles={ { 'btn-success' : true, 'kt-margin-t-20' : 'true' } }
                    name="Añadir Idioma"
                    type="submit"
                />
            </div>
            <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true">
                    <div className="kt-notes__items">
                        {
                            _.map(cv_languages, (language) => (
                                <Language language={language} />
                            ))
                        }
                    </div>
                </div>
        </form>
    )
}

const Language = ({ language }) =>
{
    const dispatch = useDispatch();
    const [speak , setSpeak] = useState(language.speak);
    const [read , setRead] = useState(language.read);
    const [write , setWrite] = useState(language.write);

    const handleChange = async (e) => 
    {
        const { name, value } = e.target;

        let obj = {};

        let modalities = ['speak', 'read', 'write'];

        if(_.startsWith(e.target.name, modalities[0]))
        {
            setSpeak(value);

            obj = { id : language.id ,speak : value }
        }
        else if(_.startsWith(e.target.name, modalities[1]))
        {
            setRead(value);

            obj = { id : language.id, read : value }
        }
        else if(_.startsWith(e.target.name, modalities[2]))
        {
            setWrite(value);

            obj = { id : language.id, write : value }
        }

        await dispatch(ProvidersActionsCreators.setLanguage(obj));
    };

    const deleteLanguage = async () =>
    {
        let providersActionCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

        await providersActionCreators.deleteLanguage(language.id);
    };

    return (
        <div className="kt-notes__item"> 
            <div className="kt-notes__media">
            <span className="kt-notes__icon">
                <i className="fas fa-language kt-font-success"></i>
            </span>                              
            </div>    
            <div className="kt-notes__content"> 
                <div className="kt-notes__section">
                    <div className="kt-notes__info">
                        <a href="#" className="kt-notes__title kt-font-bolder">
                            { language.name }                       
                        </a>
                    </div>

                    <div className="kt-notes__dropdown"> 
                        <CustomButton 
                            classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-danger' : true,'btn-icon' : true }}
                            icon="fa fa-trash-alt"
                            onClick={() => { if(window.confirm("¿Estas seguro de eliminar?")) deleteLanguage() } } />
                    </div>
                </div>
                <div className="kt-notes__body">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4">
                            <label className="col-xl-3 col-lg-3 col-form-label"><strong>Habla</strong></label>
                            <Radio 
                                changed={ handleChange } 
                                id="1" 
                                name={`speak[${language.id}]`}
                                isSelected={ speak === "MUY BIEN" } 
                                label="Muy Bien" 
                                value="MUY BIEN" 
                            />

                            <Radio 
                                changed={ handleChange } 
                                id="2" 
                                name={`speak[${language.id}]`}
                                isSelected={ speak === "BIEN" } 
                                label="Bien" 
                                value="BIEN" 
                            />

                            <Radio 
                                changed={ handleChange } 
                                id="3" 
                                name={`speak[${language.id}]`}
                                isSelected={ speak === "REGULAR" } 
                                label="Regular" 
                                value="REGULAR" 
                            />
                    </div>

                        <div className="col-xl-4 col-lg-4">
                            <label className="col-xl-3 col-lg-3 col-form-label"><strong>Lee</strong></label>
                            <Radio 
                                changed={ handleChange } 
                                id="4" 
                                name={`read[${language.id}]`}
                                isSelected={ read === "MUY BIEN" } 
                                label="Muy Bien" 
                                value="MUY BIEN" 
                            />

                            <Radio 
                                changed={ handleChange } 
                                id="5" 
                                name={`read[${language.id}]`}
                                isSelected={ read === "BIEN" } 
                                label="Bien" 
                                value="BIEN" 
                            />

                            <Radio 
                                changed={ handleChange } 
                                id="6" 
                                name={`read[${language.id}]`}
                                isSelected={ read === "REGULAR" } 
                                label="Regular" 
                                value="REGULAR" 
                            />
                        </div>

                        <div className="col-xl-4 col-lg-4">
                            <label className="col-xl-3 col-lg-3 col-form-label"><strong>Escribe</strong></label>
                            <Radio 
                                changed={ handleChange } 
                                id="7" 
                                name={`write[${language.id}]`}
                                isSelected={ write === "MUY BIEN" } 
                                label="Muy Bien" 
                                value="MUY BIEN" 
                            />

                            <Radio 
                                changed={ handleChange } 
                                id="8" 
                                name={`write[${language.id}]`}
                                isSelected={ write === "BIEN" } 
                                label="Bien" 
                                value="BIEN" 
                            />

                            <Radio 
                                changed={ handleChange } 
                                id="9" 
                                name={`write[${language.id}]`}
                                isSelected={ write === "REGULAR" } 
                                label="Regular" 
                                value="REGULAR" 
                            />
                        </div>
                    </div>
                </div>
            </div>                                             
        </div>
    )
};

export default Languages;