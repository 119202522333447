import React from 'react';

const Observation = ({ informe }) =>
(
    <div class="col-xl-12 col-md-12 col-sm-12">
        <div class="kt-portlet kt-portlet--height-fluid">
            <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-widget kt-widget--project-1">
                        <div class="kt-widget__head">
                            <div class="kt-widget__label">
                                <div class="kt-demo-icon">
                                    <div class="kt-demo-icon__preview">
                                        <i class="fa fa-stop-circle"></i>
                                    </div>
                                </div>
                                <div class="kt-widget__info kt-padding-0">
                                    <a href="#" class="kt-widget__title">
                                        OBSERVACIONES
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="kt-widget__body">
                            <div class="row kt-margin-t-15">
                                <div class="kt-portlet__body">
                                    <div class="form-group row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="alert alert-solid-danger alert-bold" role="alert">
                                                <div class="alert-text">{ informe.observation }</div>
                                            </div>
                                        </div>
                                    </div>           
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
);

export default Observation;