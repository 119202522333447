/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from 'react';
import Loadable from 'react-loadable';

// rct page loader
import Loader from 'Components/Loader';

//Products
const AsyncProductsComponent = Loadable({
   loader : () => import("Views/Products/index"),
   loading : () => <Loader />
});

const AsyncNewProductComponent = Loadable({
    loader : () => import("Views/Products/newProduct"),
    loading : () => <Loader />
 });

 const AsyncEditProductComponent = Loadable({
    loader : () => import("Views/Products/editProduct"),
    loading : () => <Loader />
 });




export {
   AsyncProductsComponent,
   AsyncNewProductComponent,
   AsyncEditProductComponent
};