import React from 'react';
import classNames from 'classnames/bind';

const CustomButton = ({
    classStyles,
    href="javascript:void(0)",
    icon="fa fa-file-alt",
    title='',
    ...props
}) => {
    let styles = classNames({ ...classStyles });

    return (
    <a href={href} className={styles} {...props}>
        <i className={`${icon}`}></i> {title}
    </a>
    );
};

export default CustomButton;