import React from 'react';

const bg = {
    backgroundImage: 'url(./assets/img/bg-3.jpg)',
};

const Login = ({ children }) =>
{
    return (
        <div class="kt-grid kt-grid--ver kt-grid--root" style={{ height : '100%' }}>
            <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login" style={bg}>
                { children }
            </div>
        </div>
    )
};

export default Login;