import { 
    getDeliverablesForSupervisor,
    getInformesByDeliverable,
    updateReviews,
    updateInformeReview,
    getChartStatusReview,
    getChartForExpiration,
    setInformes,
    setIndicadores,
    getIndicadores,
    setActividadesRecientes,
    getActividadesRecientes,
    setIndicadoresPorConvocatoria,
    getIndicadoresPorConvocatoria,
    setEstadosEntregables,
    getEstadosEntregables,
    setProgresoContratos,
    getProgresoContratos,
    setConsultoresSexo,
    getConsultoresSexo,
    setTopIdiomas,
    getTopIdiomas,
    setTopEspecialidades,
    getTopEspecialidades,
    setProgresoCv,
    getProgresoCv,
    setConsultoresGradoAcademico,
    getConsultoresGradoAcademico,
    setContratosResponsable,
    getContratosResponsable,
    setEvaluators,
    getEvaluators,
    getDeliverable,
    saveFormInformeReview,
    removeFormInformeReview,
} from "./actions";

export { 
    getDeliverablesForSupervisor,
    getInformesByDeliverable,
    updateReviews,
    updateInformeReview,
    getChartStatusReview,
    getChartForExpiration,
    setInformes,
    setIndicadores,
    getIndicadores,
    setActividadesRecientes,
    getActividadesRecientes,
    setIndicadoresPorConvocatoria,
    getIndicadoresPorConvocatoria,
    setEstadosEntregables,
    getEstadosEntregables,
    setProgresoContratos,
    getProgresoContratos,
    setConsultoresSexo,
    getConsultoresSexo,
    setTopIdiomas,
    getTopIdiomas,
    setTopEspecialidades,
    getTopEspecialidades,
    setProgresoCv,
    getProgresoCv,
    setConsultoresGradoAcademico,
    getConsultoresGradoAcademico,
    setContratosResponsable,
    getContratosResponsable,
    setEvaluators,
    getEvaluators,
    getDeliverable,
    saveFormInformeReview,
    removeFormInformeReview,
};