import React, {useState} from 'react';
import Aside from 'Views/Layout/aside';
import Header from 'Views/Layout/header';
import SubHeader from 'Views/Layout/subHeader';
import Footer from 'Views/Layout/footer';
import {HeaderMobile} from '../HeaderMobile';
const System = ({ children }) =>
{

    const [showAside , setShowAside] = useState(false);

    return (<React.Fragment>

        <div id="kt_header_mobile" className="kt-header-mobile  kt-header-mobile--fixed" >
            <div className="kt-header-mobile__logo">
                <a href="#">
                    <img alt="Logo" src="/assets/img/logo-small.png"/>
                </a>
            </div>
            <div className="kt-header-mobile__toolbar">
                <button className="kt-header-mobile__toggler kt-header-mobile__toggler--left" onClick={() => {
                    setShowAside(true);
                }}><span></span></button>
                <button className="kt-header-mobile__topbar-toggler" id="kt_header_mobile_topbar_toggler"><i className="flaticon-more"></i></button>
            </div>
        </div>

        <div className="kt-grid kt-grid--hor kt-grid--root">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                { <Aside showAside={showAside} /> }
                {showAside && <div class="kt-aside-overlay" onClick={() => {
                    setShowAside(false);
                }}></div>}
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                    { <Header /> }
                    <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">                        
                        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                            { children }
                        </div>
                    </div>
                    { <Footer /> }
                </div>
            </div>
        </div>
</React.Fragment>)
};

export default System;