import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch, Link } from 'react-router-dom';
import { Input, Radio } from 'Components';
import { routes } from 'Utils/constants';
import { settings } from 'Utils/settings';

const Profile = ({ user }) => 
{
    const handleChange = (e) =>
    {
        const { name, value } = e.target;
    };

    return (
    <div class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
    <button class="kt-app__aside-close" id="kt_user_profile_aside_close">
        <i class="la la-close"></i>
    </button>
    <div class="kt-grid__item kt-app__toggle kt-app__aside" id="kt_user_profile_aside">
<div class="kt-portlet ">
    <div class="kt-portlet__head  kt-portlet__head--noborder">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <a href="#" class="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                <i class="flaticon-more-1"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-fit dropdown-menu-md">
<ul class="kt-nav">
    <li class="kt-nav__head">
        Export Options                                    
        <span data-toggle="kt-tooltip" data-placement="right" title="Click to learn more...">
            <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--brand kt-svg-icon--md1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"/>
        <circle id="Oval-5" fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
        <rect id="Rectangle-9" fill="#000000" x="11" y="10" width="2" height="7" rx="1"/>
        <rect id="Rectangle-9-Copy" fill="#000000" x="11" y="7" width="2" height="2" rx="1"/>
    </g>
</svg>        </span>
   </li>
    <li class="kt-nav__separator"></li>
    <li class="kt-nav__item">
        <a href="#" class="kt-nav__link">
            <i class="kt-nav__link-icon flaticon2-drop"></i>
            <span class="kt-nav__link-text">Activity</span>
        </a>
    </li>
    <li class="kt-nav__item">
        <a href="#" class="kt-nav__link">
            <i class="kt-nav__link-icon flaticon2-calendar-8"></i>
            <span class="kt-nav__link-text">FAQ</span>
        </a>
    </li>
    <li class="kt-nav__item">
        <a href="#" class="kt-nav__link">
            <i class="kt-nav__link-icon flaticon2-telegram-logo"></i>
            <span class="kt-nav__link-text">Settings</span>
        </a>
    </li>
    <li class="kt-nav__item">
        <a href="#" class="kt-nav__link">
            <i class="kt-nav__link-icon flaticon2-new-email"></i>
            <span class="kt-nav__link-text">Support</span>
            <span class="kt-nav__link-badge">
                <span class="kt-badge kt-badge--success kt-badge--rounded">5</span>
            </span>
        </a>
    </li>
    <li class="kt-nav__separator"></li>
    <li class="kt-nav__foot">
        <a class="btn btn-label-danger btn-bold btn-sm" href="#">Upgrade plan</a>                                    
        <a class="btn btn-clean btn-bold btn-sm" href="#" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more...">Learn more</a>
    </li>
</ul>
</div>
        </div>
    </div>
    <div class="kt-portlet__body kt-portlet__body--fit-y">
        <div class="kt-widget kt-widget--user-profile-1">
            <div class="kt-widget__head">
                <div class="kt-widget__media">
                    <img src="./assets/img/100_1.jpg" alt="image" />
                </div>
                <div class="kt-widget__content">
                    <div class="kt-widget__section">
                        <a href="#" class="kt-widget__username">
                            { `${user.userInformation.name} ${user.userInformation.lastname ? user.userInformation.lastname : '' }` }
                            <i class="flaticon2-correct kt-font-success"></i>                       
                        </a>
                        {/*<span class="kt-widget__subtitle">
                            Head of Development               
                        </span>*/}
                    </div>

                    <div class="kt-widget__action">
                        {/*<button type="button" class="btn btn-info btn-sm">chat</button>&nbsp;*/}
                        <button type="button" class="btn btn-success btn-sm">{ settings.ROLES[user.role].label }</button>                         
                    </div>
                </div>
            </div>
            <div class="kt-widget__body">
                <div class="kt-widget__content">
                    <div class="kt-widget__info">
                        <span class="kt-widget__label">Email:</span>
                        <a href="#" class="kt-widget__data">{ user.userInformation.email ? user.userInformation.email : '-' }</a>
                    </div>
                    <div class="kt-widget__info">
                        <span class="kt-widget__label">Teléfono:</span>
                        <a href="#" class="kt-widget__data">{ user.userInformation.phone ? user.userInformation.phone : '-' }</a>
                    </div>
                    <div class="kt-widget__info">
                        <span class="kt-widget__label">Dirección:</span>
                        <span class="kt-widget__data">{ user.userInformation.address ? user.userInformation.address : '-' }</span>
                    </div>
                </div>
                <div class="kt-widget__items">  
                    <a  href="demo1/custom/apps/user/profile-1/personal-information.html" class="kt-widget__item kt-widget__item--active">
                        <span class="kt-widget__section">
                            <span class="kt-widget__icon">
                                <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Shape" points="0 0 24 0 24 24 0 24"/>
        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" id="Mask" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" id="Mask-Copy" fill="#000000" fill-rule="nonzero"/>
    </g>
</svg>                            </span>
                            <span class="kt-widget__desc">
                                Información Personal                                            
                            </span>
                        </span>    
                    </a>  
                </div>
            </div>
        </div>
    </div>
</div>
     </div>
    <div class="kt-grid__item kt-grid__item--fluid kt-app__content">
        <div class="row">
            <div class="col-xl-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">Información personal <small>Actualiza tu información</small></h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <div class="kt-portlet__head-wrapper">
                                <div class="dropdown dropdown-inline">
                                    <button type="button" class="btn btn-label-brand btn-sm btn-icon btn-icon-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="flaticon2-gear"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <ul class="kt-nav">
                                            <li class="kt-nav__section kt-nav__section--first">
                                                <span class="kt-nav__section-text">Export Tools</span>
                                            </li>
                                            <li class="kt-nav__item">
                                                <a href="#" class="kt-nav__link">
                                                    <i class="kt-nav__link-icon la la-print"></i>
                                                    <span class="kt-nav__link-text">Print</span>
                                                </a>
                                            </li>
                                            <li class="kt-nav__item">
                                                <a href="#" class="kt-nav__link">
                                                    <i class="kt-nav__link-icon la la-copy"></i>
                                                    <span class="kt-nav__link-text">Copy</span>
                                                </a>
                                            </li>
                                            <li class="kt-nav__item">
                                                <a href="#" class="kt-nav__link">
                                                    <i class="kt-nav__link-icon la la-file-excel-o"></i>
                                                    <span class="kt-nav__link-text">Excel</span>
                                                </a>
                                            </li>
                                            <li class="kt-nav__item">
                                                <a href="#" class="kt-nav__link">
                                                    <i class="kt-nav__link-icon la la-file-text-o"></i>
                                                    <span class="kt-nav__link-text">CSV</span>
                                                </a>
                                            </li>
                                            <li class="kt-nav__item">
                                                <a href="#" class="kt-nav__link">
                                                    <i class="kt-nav__link-icon la la-file-pdf-o"></i>
                                                    <span class="kt-nav__link-text">PDF</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form class="kt-form kt-form--label-right">
                        <div class="kt-portlet__body">
                            <div class="kt-section kt-section--first">
                                <div class="kt-section__body">
                                    <div class="row">
                                        <label class="col-xl-3"></label>
                                        <div class="col-lg-9 col-xl-6">
                                            <h3 class="kt-section__title kt-section__title-sm">Información del usuario:</h3>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">Avatar</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <div class="kt-avatar kt-avatar--outline" id="kt_user_avatar">
                                                <div class="kt-avatar__holder" style={{ backgroundImage: 'url(&quot;http://keenthemes.com/metronic/preview/default/custom/user/assets/media/users/100_1.jpg&quot;)'}}></div>
                                                <label class="kt-avatar__upload" data-toggle="kt-tooltip" title="" data-original-title="Change avatar">
                                                    <i class="fa fa-pen"></i>
                                                    <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" />
                                                </label>
                                                <span class="kt-avatar__cancel" data-toggle="kt-tooltip" title="" data-original-title="Cancel avatar">
                                                    <i class="fa fa-times"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">Nombres</label>
                                        <div class="col-lg-9 col-xl-6">
                                            { <Input 
                                                classStyles={ {} } 
                                                placeholder="Nombres"
                                                type="text"
                                                name="names"
                                                value=""
                                                onChange={handleChange}
							                /> }
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">Apellidos</label>
                                        <div class="col-lg-9 col-xl-6">
                                            { <Input 
                                                classStyles={ {} } 
                                                placeholder="Apellidos"
                                                type="text"
                                                name="lastnames"
                                                value=""
                                                onChange={handleChange}
							                /> }
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">F. Nacimiento</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <input class="form-control" type="date" />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">Sexo</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <Radio data={[
                                                {
                                                    name : "gender",
                                                    label : "Masculino",
                                                    type : "radio",
                                                    value : "M"
                                                },
                                                {
                                                    name : "gender",
                                                    label : "Femenino",
                                                    type : "radio",
                                                    value : "F"
                                                }
                                            ]} onClick={handleChange} />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">Dirección</label>
                                        <div class="col-lg-9 col-xl-6">
                                            { <Input 
                                                classStyles={ {} } 
                                                placeholder="Dirección"
                                                type="text"
                                                name="address"
                                                value=""
                                                onChange={handleChange}
							                /> }
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">Teléfono</label>
                                        <div class="col-lg-9 col-xl-6">
                                            { <Input 
                                                classStyles={ {} } 
                                                placeholder="Teléfono"
                                                type="text"
                                                name="phone"
                                                value=""
                                                onChange={handleChange}
							                /> }
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions">
                                <div class="row">
                                    <div class="col-lg-3 col-xl-3">
                                    </div>
                                    <div class="col-lg-9 col-xl-9">
                                        <button type="reset" class="btn btn-success">Submit</button>&nbsp;
                                        <Link to={routes.PROVIDER.DELIVERABLES}>
                                            <button type="reset" class="btn btn-secondary">Cancelar</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>  
        </div>           
    </div>
</div>)
};

const mapStateToProps = (state) => ({
    user : state.user
});

export default connect(mapStateToProps, null)(Profile);