import React, { useState } from "react";
import "./index.css";

const CancelButton = ({ onClick }) => {
  return <span onClick={onClick} className="btn-clear" style={{ float: 'right' }} ><i className={"fa fa-trash"}></i></span>
}

const FileObject = ({ object, onRemoveFile }) => {

  const { size } = object;
  const kilobyte = 1024;
  const megabyte = (kilobyte * 1024);
  const gigabyte = (megabyte * 1024);

  let sizetext = `0 KB`;

  if (size && size > 0) {
    sizetext = `${((size / kilobyte)).toFixed(0)} KB`;
    if ((size / megabyte) >= 1) {
      sizetext = `${(size / megabyte).toFixed(0)} MB`;
      if ((size / gigabyte) >= 1) {
        sizetext = `${(size / gigabyte).toFixed(0)} GB`;
      }
    }
  }

  return object && <div className="file-success" style={{paddingTop: '.5rem', paddingBottom: '.5rem'}}>
    <div className="data-file" >
        <a href={object._urltemp} target={'blank'} >
          <strong>{object.name} </strong>
        </a>
        <strong style={{marginRight: '.8rem'}}>({sizetext})</strong>
        <CancelButton onClick={onRemoveFile} />
    </div>
  </div>
}

export default FileObject;