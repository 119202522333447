import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "../../components/Button";
import AddAdenda from "./AddAdenda";
import { Datatable } from "Components";

const AdendasList = ({ contractAdendas, setContractAdendas, contract }) => {
  const [openAddAdendaModal, setOpenAddAdendaModal] = useState(false);

  const removeAdenda = (key) => {
    let _contractAdendas = contractAdendas;
    _contractAdendas.splice(key, 1);
    setContractAdendas([..._contractAdendas]);
  };

  const Adendas = () => {

    let rows = contractAdendas.map((adendas, key) => {
      return {
        ...adendas,
        action_buttons: (
          <Button
            name="Eliminar"
            classStyles={{ 'btn-danger': true }}
            onClick={() => removeAdenda(key)}
           />
        )
      }
    })

    return contractAdendas.length > 0 ? (
      <div>
        <Datatable
          columns={columns}
          rows={rows}
          paginate={false}
          // handlePageClick={(e) => this.handlePageClick(e.selected)}
          // last_page={1}
          // current_page={1}
        />
      </div>
    ) : (
      <div>No hay adendas</div>
    );
  };

  const addAdenda = (adenda) => {
    setContractAdendas([...contractAdendas, { ...adenda }]);
    setOpenAddAdendaModal(false);
  };

  return (
    <div>
      <h5 className="mb-4">Adendas</h5>
      <Button
        name="Agregar"
        classStyles={{ "btn-success": true, "mb-4": true }}
        onClick={() => setOpenAddAdendaModal(true)}
      />
      <Adendas />
      {openAddAdendaModal && (
        <AddAdenda
          contract={contract}
          handleClose={() => setOpenAddAdendaModal(false)}
          handleSubmit={addAdenda}
        />
      )}
    </div>
  );
};

const columns = [
  {
    label: "Número",
    field: "adenda_number",
    sort: "asc",
    width: 150,
  },
  {
    label: "Descripción",
    field: "description",
    sort: "asc",
    width: 300,
  },
  {
    label: "Fecha de suscripción",
    field: "suscription_date",
    sort: "asc",
    width: 150,
  },
  {
    label: "Fecha Fin",
    field: "enddate",
    sort: "asc",
    width: 150,
  },
  {
    label: "Monto",
    field: "amount",
    sort: "asc",
    width: 150,
  },
  {
    label: "Acciones",
    field: "action_buttons",
    width: 150,
  },
];

AdendasList.propTypes = {};

export default AdendasList;
