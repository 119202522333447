import React, { Fragment, useState, useEffect } from 'react';
import { CustomButton, ButtonFile, Radio, Select, InputAutocomplete, Input, Button, File, CustomDatePicker } from 'Components';
import InputFile from "../../components/InputFile";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import { settings } from 'Utils/settings';
import _ from 'lodash';
import { scrollTo } from 'Utils/animateScrollTo';
import moment from 'moment';

const AcademicTitle = ({ cv_titles }) =>
{
    const dispatch = useDispatch();

    const [titles , setTitles] = useState([]);
    const [grade, setGrade] = useState('');
    const [especiality, setEspeciality] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [institution, setInstitution] = useState('');
    const [institutionId, setInstitutionId] = useState('');
    const [institutionName, setInstitutionName] = useState('');
    const [isInternational, setIsInternational] = useState("false");
    const [extensionDate, setExtensionDate] = useState('');
    const [clear, setClear] = useState(false);
    const [file, setFile] = useState('');
    const [files, setFiles] = useState([]);
    const [id, setId] = useState('');
    const [edit, setEdit] = useState(false);

    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        console.log("USE EFFECT ACADEMICTITLE");
        setTitles(cv_titles);
    }, [cv_titles]);

    const onSubmit = async (e) =>
    {
        e.preventDefault();

        e.target.reset();

        let request = {
            grade,
            especiality,
            start_date : startDate,
            end_date : endDate,            
            _filename_certificate : files.length > 0 ? files[0] : null,
            extension_date : extensionDate
        };

        if(isInternational === "true")
        {
            _.assign(request, { country, city, institution_name: institutionName, is_international : isInternational == "true" ? 1:0 });
        }else{
            request.institution_id = institutionId
        }
       
        await dispatch(ProvidersActionsCreators.saveAcademicTitle(edit ? _.assign(request, { id }) : request ));

        setShowForm(false);
        setEdit(false);
    }

    const onEdit = (title) => 
    {
        setShowForm(true)        
        if(_.isEmpty(title))
        {
            setGrade('');
            setEspeciality('');
            setStartDate(null);
            setEndDate(null);
            setCountry('');
            setCity('');
            setInstitution('');
            setInstitutionName('');
            setIsInternational('false');
            setExtensionDate('');
            setFile('');
            setFiles([]);
            setId('');
            setEdit(false);
        }
        else
        {
            setGrade(title.grade);
            setEspeciality(title.especiality);
            setStartDate(title.start_date);
            setEndDate(title.end_date);
            setCountry(title.country);
            setCity(title.city);                       
            setIsInternational(title.is_international ? 'true' : 'false');
            setInstitution(title.institution);
            if(title.is_international){
                setInstitutionName(title.institution_name);
            }else{
                setInstitutionId(title.institution_id);
            }
            setExtensionDate(title.extension_date ? title.extension_date : '');
            if(title.document_certificate)  setFiles([title.document_certificate]);
            setFile('');
            setId(title.id);
            setEdit(true);
        }
    }

    const getUniversities = async (value) =>
    {
        let response = await dispatch(ProvidersActionsCreators.getInstitutions(value));

        return response;
    };

    const handleChangeFile = async (e) =>
    {
        const { name, files } = e.target;

        let urlFile = await dispatch(ProvidersActionsCreators.submitFiles(files[0]));

        setFile(urlFile.filename);
    };

    const getCountries = async (value) =>
    {
        let response = await dispatch(ProvidersActionsCreators.getCountries(value));

        return response;
    };
    

    return (<>

        {!showForm && <div>
            <h2>MI FORMACIÓN ACADÉMICA</h2>
            <p>La información a proporcionar en el siguiente cuadro deberá ser precisa.</p>
            <button onClick={() => { onEdit(null); }} className="btn btn-success btn-sm" >REGISTRAR FORMACIÓN ACADÉMICA</button>

            <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
            <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true">
                <div className="kt-notes__items">

                    {
                        _.chain(cv_titles)
                            .orderBy('end_date', 'desc')
                            .map((title) => <Title title={title} onEdit={onEdit} />)
                            .value()
                    }
                </div>
            </div>        
        </div>}

        {showForm && <form method="POST" onSubmit={onSubmit}>
            {!edit && <h2>NUEVA FORMACIÓN ACADÉMICA</h2>}
            {edit && <h2>EDITAR FORMACIÓN ACADÉMICA</h2>}

            <div>
                <Button 
                    classStyles={ { 'btn' : true, 'btn-default' : isInternational === "true", 'btn-primary' : isInternational === "false" } }
                    onClick={() => {setIsInternational("false") }}
                    type="button"
                    name="PERÚ"
                />&nbsp;
                <Button 
                    classStyles={ { 'btn' : true, 'btn-default' : isInternational === "false", 'btn-primary' : isInternational === "true" } }
                    onClick={() => {setIsInternational("true") }}
                    type="button"
                    name="EXTRANJERO"
                />
            </div>
            <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>

            <div className="row">
                <div className="form-group col-md-6 col-lg-4">
                    <label>Título Académico</label>
                    <Select 
                        text="Seleccionar tipo:"
                        value={settings.TITLE_ACADEMIC}
                        name="grade"
                        selected={grade}
                        required={true}
                        onChange={(e) => setGrade(e)}
                    />            
                </div>

                {isInternational === "true" && <div className="form-group col-md-6 col-lg-4">
                    <label>Institución</label>
                    <Input 
                        classStyles={ {} } 
                        label="Institución"
                        placeholder=""
                        type="text"
                        name="institution_name"
                        required={true}
                        value={institutionName}
                        onChange={(e) => setInstitutionName(e.target.value)}
                    />
                </div>}

                {isInternational === "true" && <div className="form-group col-md-6 col-lg-4">
                    <label>País (Autocompletado)</label>
                    <InputAutocomplete 
                        onCallback={getCountries} 
                        onResult={(country) => setCountry(country.iso_name)}
                        onClear={clear}
                        value={country}
                    />
                </div>}

                {isInternational === "true" && <div className="form-group col-md-6 col-lg-4">
                    <label>Ciudad</label>
                    <Input 
                        classStyles={ {} } 
                        label="Ciudad"
                        placeholder=""
                        type="text"
                        name="city"
                        required={true}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>}

                {isInternational === "false" &&  <div className="form-group col-md-6 col-lg-4">
                    <label>Institución</label>
                    {(edit && institution) && <Input 
                        classStyles={ {} } 
                        label="Institución"
                        placeholder=""
                        type="text"
                        name="institution_name"
                        required={true}
                        value={institution?institution.name:''}
                        onChange={(e) => setInstitution(e.target.value)}
                        disabled
                    />}
                    {(!edit || !institution) && <InputAutocomplete                                     
                        onCallback={getUniversities} 
                        onResult={(suggestion) => { setInstitutionId(suggestion.id); setInstitutionName(suggestion.name) }}
                        onClear={clear}
                        value={institutionName}
                        renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
                    />}
                </div>}

                
                <div className="form-group col-md-6 col-lg-4">
                    <label>Especialidad / Programa</label>
                    <Input 
                        classStyles={ {} } 
                        label="Especialidad"
                        placeholder=""
                        type="text"
                        name="especiality"
                        required={true}
                        maxlength="70"
                        value={especiality}
                        onChange={(e) => setEspeciality(e.target.value)}
                    />
                </div>
                <div className="form-group col-md-6 col-lg-4">
                    <label>Constancia (máx 10 mb)</label>
                    <InputFile name={"_filename_certificate"} objects={files} maxFiles={1} onChange={(objects) => {
                        setFiles(objects);
                    }} />
                    {/*<File 
                        name="_filename_certificate"
                        onChange={handleChangeFile}
                    />*/}
                </div>
                <div className="form-group col-md-6 col-lg-4">
                    <label>Fecha de inicio - Fecha de fin</label>
                    {/*<DateRange start={start_date} end={end_date} onCallback={this.dateRange}  />*/}
                    <div>
                        <CustomDatePicker
                            selected={ startDate? moment(startDate, "YYYY-MM-DD").toDate(): null}
                            onChange={date => { setStartDate(moment(date).format("YYYY-MM-DD")) }}
                            maxDate={moment().toDate()}                           
                        />  
                        &nbsp;
                        <CustomDatePicker
                            selected={ endDate? moment(endDate, "YYYY-MM-DD").toDate(): null}
                            onChange={date => { setEndDate(moment(date).format("YYYY-MM-DD")) }}
                            minDate={startDate? moment(startDate, "YYYY-MM-DD").toDate(): null}                      
                        />       
                    </div> 
                </div>
                
                <div className="form-group col-md-6 col-lg-4">
                    <label>Fecha de extensión del título</label>
                    <div>
                        <CustomDatePicker
                            selected={ extensionDate? moment(extensionDate, "YYYY-MM-DD").toDate(): null}
                            onChange={date => { setExtensionDate(moment(date).format("YYYY-MM-DD")) }}
                        />
                    </div>
                    
                </div>

            </div>
            
           
            <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
            <div className="row">
                
                <div className="col text-rigth">
                    <Button 
                        classStyles={ { 'btn' : true, 'btn-default' : true } }
                        onClick={() => {setShowForm(false)}}
                        type="button"
                        name="CANCELAR"
                    />
                    <Button 
                        classStyles={ { 'btn' : true, 'btn-success' : true } }
                        type="submit"
                        name="GUARDAR"
                    />
                </div>
            </div>
        </form>}

        
    </>)
};

const Title = ({ title, onEdit }) =>
{
    const dispatch = useDispatch();

    const [edit, setEdit] = useState(false);

    const deleteAcademicTitle = async () =>
    {
        let providersActionCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

        let result = await providersActionCreators.deleteStudies(title.id, "ACADEMIC_TITLE");
    };

    useEffect( () => {
        console.log("USEEFFECT");
        setEdit(false);
      }, [ title ]
    );

    return (<div className="kt-notes__item"> 
        <div className="kt-notes__media">
        <span className="kt-notes__icon">
            <i className="fa fa-school kt-font-info"></i>                                    
        </span>                              
        </div>    
        <div className="kt-notes__content"> 
            <div className="kt-notes__section">     
                <div className="kt-notes__info">
                    <a href="#" className="kt-notes__title kt-font-bolder">
                        { title.especiality }                                                    
                    </a>
                    <span className="kt-notes__desc">
                        { title.start_date } - { title.end_date }
                    </span>
                    <span className="kt-badge kt-badge--success kt-badge--inline">{ title.grade }</span>
                </div>
                <div className="kt-notes__dropdown"> 
                    {edit && 
                        <CustomButton 
                            classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-warning' : true,'btn-icon' : true }}
                            icon="fa fa-window-close"
                            style={{ marginRight : '.7em' }}
                            onClick={() => { onEdit({}); setEdit(false) }} />
                    }
                    {! edit &&
                        <CustomButton 
                            classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-success' : true,'btn-icon' : true }}
                            icon="fa fa-edit"
                            style={{ marginRight : '.7em' }}
                            onClick={() => { onEdit(title); setEdit(true); scrollTo('.frm-cv') }} />
                    }
                    <CustomButton 
                        classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-danger' : true,'btn-icon' : true }}
                        icon="fa fa-trash-alt"
                        onClick={() => { if(window.confirm("¿Estas seguro de eliminar?")) deleteAcademicTitle() } } />
                </div>
            </div>
            <span className="kt-notes__body">                                        
                <p>{ `Institución: ${title.institution_name}` }</p>
                {title.extension_date && <p>{ `Fecha de Extensión: ${title.extension_date}` }</p> }
                { title.is_international && 
                    <DetailInternational title={title} />
                }

                { !title.is_international && <DetailNational title={title} /> }
                { title.document_certificate &&
                    <ButtonFile classStyles={{ 'btn-outline-warning' : true }} href={title.document_certificate ? title.document_certificate._url : 'javascript_void(0)'} />
                }
            </span>  
        </div>                                             
    </div>)
};

const DetailInternational = ({ title }) =>
(
    <Fragment>
        <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-flag" />{ title.country }</p>
        <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-city" /> { title.city }</p>
    </Fragment>
);

const DetailNational = ({ title }) =>
(
    <Fragment>
        <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-flag" />{ title.country }</p>
    </Fragment>
);

const mapStateToProps = (state) => ({
    api : state.api,
    user : state.user,
    institutions : state.providers.institutions
});

export default connect(mapStateToProps, null)(AcademicTitle);