import React, { Component, Fragment } from 'react';
import {Doughnut} from 'react-chartjs-2';

class DashboardProvider extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        const data = {
            labels: [
                'Venció',
                'Vencerá',
                'Por vencer'
            ],
            datasets: [{
                data: [1, 4, 3],
                backgroundColor: [
                '#FF6384',
                '#0abb87',
                '#ffb822'
                ],
                hoverBackgroundColor: [
                '#FF6384',
                '#0abb87',
                '#ffb822'
                ]
            }]
        };

        const data2 = {
            labels: [
                'Venció',
                'Vencerá',
                'Por vencer'
            ],
            datasets: [{
                data: [6, 3, 6],
                backgroundColor: [
                '#FF6384',
                '#0abb87',
                '#ffb822'
                ],
                hoverBackgroundColor: [
                '#FF6384',
                '#0abb87',
                '#ffb822'
                ]
            }]
        };

        const data3 = {
            labels: [
                'Venció',
                'Vencerá',
                'Por vencer'
            ],
            datasets: [{
                data: [2, 5, 3],
                backgroundColor: [
                '#FF6384',
                '#0abb87',
                '#ffb822'
                ],
                hoverBackgroundColor: [
                '#FF6384',
                '#0abb87',
                '#ffb822'
                ]
            }]
        };

        return(
            <Fragment>
                <div className="row">
                    <div className="col-xl-4 col-lg-4 order-lg-2 order-xl-1">
                        <div className="kt-portlet kt-portlet--height-fluid">
                            <div className="kt-widget14">
                                <div className="kt-widget14__header">
                                    <h3 className="kt-widget14__title">
                                        Seguimiento de Entregables            
                                    </h3>
                                </div>
                                
                            </div>
                        </div>	
                    </div>
                    <div className="col-xl-4 col-lg-4 order-lg-2 order-xl-1">
                        <div className="kt-portlet kt-portlet--height-fluid">
                            <div className="kt-widget14">
                <div className="kt-widget14__header">
                    <h3 className="kt-widget14__title">
                        Seguimiento de Contratos            
                    </h3>
                </div>	 
                <div className="kt-widget14__content">	
                    
                </div> 
            </div>
                        </div>		
                    </div>
                    <div className="col-xl-4 col-lg-4 order-lg-2 order-xl-1">
                        <div className="kt-portlet kt-portlet--height-fluid">
                            <div className="kt-widget14">
                                <div className="kt-widget14__header">
                                    <h3 className="kt-widget14__title">
                                        Seguimiento de Productos          
                                    </h3>
                                </div>
                                <div className="kt-widget14__content">
                                    
                                </div> 	 
                            </div>
                        </div>	
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default DashboardProvider;