/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from 'react';
import Loadable from 'react-loadable';

// rct page loader
import Loader from 'Components/Loader';

//login
const AsyncLoginComponent = Loadable({
   loader : () => import("Views/Auth/index"),
   loading : () => <Loader />
});


export {
    AsyncLoginComponent
};