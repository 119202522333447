/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from 'react';
import Loadable from 'react-loadable';

// rct page loader
import Loader from 'Components/Loader';

//Contracts
const AsyncContractsComponent = Loadable({
   loader : () => import("Views/Contracts/index"),
   loading : () => <Loader />
});

const AsyncNewContractComponent = Loadable({
    loader : () => import("Views/Contracts/NewContract"),
    loading : () => <Loader />
 });

 const AsyncEditContractComponent = Loadable({
    loader : () => import("Views/Contracts/EditContract"),
    loading : () => <Loader />
 });




export {
    AsyncContractsComponent,
    AsyncNewContractComponent,
    AsyncEditContractComponent
};