import { default as request } from 'Utils/request';
import * as ApiActionsCreators from 'Ducks/api/actions';

export const createRequest = (
    { requestId, requestParams }
  ) => async (dispatch, getState) => {
    const cancelTokenSource = request.getCancelTokenSource();
    //START REQUEST
    dispatch(ApiActionsCreators.requestStartedAction(requestId, cancelTokenSource));
    try {
      const res = await request.send({
        ...requestParams,
        cancelToken: cancelTokenSource.token
      });
      dispatch(ApiActionsCreators.requestSucceededAction(requestId));
      return res;
    } catch (error) {
      if (error.message === 'cancelled') {
        throw error;
      }
      dispatch(ApiActionsCreators.requestFailedAction(requestId, error.response));
      throw error;
    } 
};