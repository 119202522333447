import React from 'react';
import _ from 'lodash';

const Provider = ({ provider, contract }) =>
(
    <div class="kt-portlet">
        <div class="kt-portlet__body kt-portlet__body--fit">
            <div class="row row-no-padding row-col-separator-xl">
                <div class="col-md-12 col-lg-12 col-xl-4">
                    <div class="kt-widget1">
                        <div class="kt-widget1__item justify-content-start">
                            {/*<div className="kt-widget__media">
                                <img src="/assets/img/default.jpg" alt="image" />
                            </div>*/}
                            <div class="kt-widget1__info ml-2">
                                <h3 class="kt-widget1__title font-weight-normal">ACCIÓN </h3>
                                <span class="kt-widget1__desc"><strong>{ contract.name }</strong></span>
                                {contract.contract_type_id == 1 && <span class="kt-widget1__desc kt-margin-t-10 d-block">N° contrato: <strong>{ contract.contract_number }</strong></span>}
                                {contract.contract_type_id == 2 && <span class="kt-widget1__desc kt-margin-t-10 d-block">Orden de servcio: <strong>{ contract.order }</strong></span>}
                            </div> 
                            {/*<span class="kt-widget1__number kt-font-brand">ss</span>*/}
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 col-xl-4">
                    <div class="kt-widget1">
                        <div class="kt-widget1__item">
                            <div class="kt-widget1__info">
                                <h3 class="kt-widget1__title">PROVEEDOR</h3>
                                <span class="kt-widget1__desc kt-margin-t-10 d-block">Nombre: <strong>{ provider.name }</strong></span>
                                <span class="kt-widget1__desc kt-margin-t-10 d-block">Teléfono: <strong>{ provider.phones }</strong></span>
                                <span class="kt-widget1__desc kt-margin-t-10 d-block">Ruc: <strong>{ provider.ruc }</strong></span>
                                {/*<span class="kt-widget1__desc kt-margin-t-10 d-block">Dirección: <strong>{ provider.address }</strong></span>*/}
                                <span class="kt-widget1__desc kt-margin-t-10 d-block">Email: <strong>{ provider.email }</strong></span>
                                
                            </div> 
                            {/*<span class="kt-widget1__number kt-font-success">{ deliverable.contract.contract_number }</span>*/}
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 col-xl-4">
                    <div class="kt-widget1">
                        <div class="kt-widget1__item">
                            <div class="kt-widget1__info">
                                <h3 class="kt-widget1__title">ARCHIVOS</h3>
                                <span class="kt-widget1__desc kt-margin-t-10 d-block">
                                    <strong>TDR:</strong>
                                    <a class="kt-margin-l-10" href={_.isObject(contract.document_tdr) ? contract.document_tdr._url : 'javascript:void(0)'}>
                                        <button title="Descargar TDR" type="button" class={`btn btn-outline-brand btn-elevate btn-icon ${!_.isObject(contract.document_tdr) ? 'disabled' : ''}`}><i class="fa fa-cloud-download-alt"></i></button>
                                    </a>
                                </span>
                                <span class="kt-widget1__desc kt-margin-t-10 d-block">
                                    <strong>CONTRATO:</strong>
                                    <a class="kt-margin-l-10" href={_.isObject(contract.document_contract) ? contract.document_contract._url : 'javascript:void(0)'}>
                                        <button title="Descargar TDR" type="button" class={`btn btn-outline-brand btn-elevate btn-icon ${!_.isObject(contract.document_contract) ? 'disabled' : ''}`}><i class="fa fa-cloud-download-alt"></i></button>
                                    </a>
                                </span>
                            </div> 
                            {/*<span class="kt-widget1__number kt-font-success">ss</span>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Provider;