import { constants } from "./types";
import createReducer  from "Utils/createReducer";

const initialState = {
    contracts : [],
    activities : []
};

const cordinatorReducer = createReducer( initialState )( {
    [ constants.SET_CONTRACTS ]: (state, action) => {
        return  { ...state, contracts : action.contracts };
    },
    [ constants.SET_ACTIVITIES_FOR_EVENT ] : (state, action) => {
        return {  ...state, activities : action.activities }
    }
} );

export default cordinatorReducer;
