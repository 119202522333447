import React, { Component } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';

// router service
import routerService from "./routerService";

class UserRoutes extends Component {

	render() {
        const { match, location } = this.props;
        
		return (
			<React.Fragment>
				{routerService && routerService.map((route,key)=> {
                    return (<Route key={key} path={`${route.path}`} component={route.component} />)
                }
				)}
			</React.Fragment>
		);
	}
}

export default UserRoutes;
