import React, { useState } from "react";
import ResumableUploadFile from "../components/ResumableUploadFile";
import Portlet from "../components/Portlet/portlet";

const Form = () => {

  const [file, setFile] = useState(null)

  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="form-group">
          <ResumableUploadFile label="Archivo" onChange={setFile} />
        </div>        
      </div>
      <div className="col-lg-6">
          <label>Form</label>
        </div>
    </div>
  );
};

const UploadFile = () => {
  return <Portlet title="Formulario" bodyContent={<Form />} />;
};

UploadFile.propTypes = {};

export default UploadFile;
