import React, { Fragment, useState, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FileUpload, Button, Textarea, Select, Alert, buildNotification } from 'Components';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import _ from 'lodash';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import MeansOfVerification from './MeansOfVerification';
import ResumableUploadFile from '../../components/ResumableUploadFile';
import { settings } from 'Utils/settings';
import 'Assets/css/style.css';

class FinalReport extends Component
{
    constructor(props)
    {
        super(props);

        const { dispatch } = props;

        this.state = {
            summary : '',
            observation : '',
            action : '',
            type_informe : null
        }

        this.ProviderActionCreators = bindActionCreators(ProvidersActionsCreators, dispatch);
    }

    componentWillMount()
    {
        
        this.setState({ summary : this.props.informe.summary, observation : this.props.informe.observation });
    }

    processFile = async (props) =>
    {
        const { type_informe } = this.state;

        // let statusInformeresult = await this.ProviderActionCreators.submitFiles(props);

        // await _.map(statusInformeresult, file => this.ProviderActionCreators.createMeanOfVerificationInforme(file.filename, type_informe, this.props.informe?.id));

        await this.ProviderActionCreators.createMeanOfVerificationInforme(props.filename, type_informe, this.props.informe?.id);

        // this.setState({ type_informe : null });
    };

    handleChange = (e) =>
    {
        const { name, value } = e.target;
        this.setState({ [ name ] : value });
    };

    handleSubmit = async (e) =>
    {
        e.preventDefault();

        const { action, summary, observation } = this.state;

        const { verifications } = this.props;

        if(! verifications.length)
        {
            buildNotification('danger', 'Pendiente subir informe...', 'Pendiente');

            return;
        }

        let status;

        if(action == 'SAVE')
        {
            status = settings.STATUS_REVIEW_DELIVERABLES[1].id;
        }
        else if(action == 'FINALIZE')
        {
            status = settings.STATUS_REVIEW_DELIVERABLES[2].id;
        }

        await this.ProviderActionCreators.statusInforme(status, summary, observation);
    };

    render()
    {
        const { verifications, informe, os, ficha_informe } = this.props;

        const { summary, observation, type_informe } = this.state;

        return (
        <Fragment>
            <div class="col-xl-6 col-md-6 col-sm-6">
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-portlet__body kt-portlet__body--fit">
                        <div class="kt-widget kt-widget--project-1">
                            <div class="kt-widget__head">
                                <div class="kt-widget__label">
                                    <div class="kt-demo-icon">
                                        <div class="kt-demo-icon__preview">
                                            <i class="fa fa-check-circle"></i>
                                        </div>
                                    </div>
                                    <div class="kt-widget__info kt-padding-0">
                                        <a href="#" class="kt-widget__title">
                                            INFORME                                          
                                        </a>
                                    </div>
                                </div>
                                <div class="kt-portlet__head-toolbar">
                                    <a href="#" class="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                        <i class="flaticon-more-1"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                        <ul class="kt-nav">
                                            <li class="kt-nav__item">
                                                <a href="#" class="kt-nav__link">
                                                    <i class="kt-nav__link-icon flaticon2-line-chart"></i>
                                                    <span class="kt-nav__link-text">Reports</span>
                                                </a>
                                            </li>
                                            <li class="kt-nav__item">
                                                <a href="#" class="kt-nav__link">
                                                    <i class="kt-nav__link-icon flaticon2-send"></i>
                                                    <span class="kt-nav__link-text">Messages</span>
                                                </a>
                                            </li>
                                            <li class="kt-nav__item">
                                                <a href="#" class="kt-nav__link">
                                                    <i class="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                    <span class="kt-nav__link-text">Charts</span>
                                                </a>
                                            </li>
                                            <li class="kt-nav__item">
                                                <a href="#" class="kt-nav__link">
                                                    <i class="kt-nav__link-icon flaticon2-avatar"></i>
                                                    <span class="kt-nav__link-text">Members</span>
                                                </a>
                                            </li>
                                            <li class="kt-nav__item">
                                                <a href="#" class="kt-nav__link">
                                                    <i class="kt-nav__link-icon flaticon2-settings"></i>
                                                    <span class="kt-nav__link-text">Settings</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="kt-widget__body">
                                <span class="kt-widget__text kt-margin-t-0 kt-padding-t-5">
                                                        
                                </span>
                                <div class="kt-widget__info kt-padding-t-5 kt-margin-t-15" style={{ borderTop : '1px solid #ebedf2' }}>
                                        <a href="#" class="kt-widget__title">
                                            Archivos            
                                        </a>
                                </div>

                                <div class="row kt-margin-t-15">
                                    { _.map(verifications, (verification) => <MeansOfVerification verification={verification} informe={informe} />) }
                                </div>

                                <div class="kt-widget__info kt-padding-t-5 kt-margin-t-15" style={{ borderTop : '1px solid #ebedf2' }}>
                                        <a href="#" class="kt-widget__title">
                                            Subir Archivos            
                                        </a>
                                        <div class="kt-margin-t-15">
                                            <Select 
                                                text="Elegir tipo de archivo a subir..."
                                                value={settings.TYPE_INFORME}
                                                selected={type_informe}
                                                onChange={(e) => this.setState({ type_informe : e })}
                                            />
                                        </div>
                                        <div class="kt-margin-t-15">
                                            {/* { <Previews dispatch={dispatch} product_id={product.id} /> }*/}
                                            {/* <FileUpload isValidate={type_informe} onProcessFiles={this.processFile} /> */}
                                            { this.state.type_informe && <ResumableUploadFile onFileSuccess={this.processFile} /> }
                                        </div>
                                </div>
                            </div>
                            <div class="kt-widget__footer">
                                <div class="kt-widget__wrapper">
                                    <div class="kt-widget__section">
                                        <div class="kt-widget__blog">
                                            <i class="flaticon2-list-1"></i>
                                            <a href="#" class="kt-widget__value kt-font-brand">{ verifications?.length || 0 } Informes</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-6">
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-portlet__body kt-portlet__body--fit">
                        

                    <form class="kt-form kt-form--fit kt-form--label-right" method="POST" onSubmit={this.handleSubmit}>
                        
                        <div class="kt-widget kt-widget--project-1">
                            <div class="kt-widget__head">
                                <div class="kt-widget__label">

                                    <div class="kt-widget__info kt-padding-0">
                                        <a href="#" class="kt-widget__title">
                                            FICHA DE RESUMEN                                          
                                        </a>
                                        <Alert 
                                            message="No olvides finalizar la ficha de resumen.."
                                            color="warning"
                                            style={{ width : '100%' }}
                                            subStyle={{ color : 'white' }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="kt-widget__body">
                                <span class="kt-widget__text kt-margin-t-0 kt-padding-t-5">
                                                        
                                </span>
                                <div class="kt-widget__info kt-padding-t-5 kt-margin-t-15" style={{ borderTop : '1px solid #ebedf2' }}>
                                    <a href="#" class="kt-widget__title">
                                            Ficha de resumen            
                                    </a>
                                    <a class="kt-margin-l-10" href={ficha_informe || 'javascript:void(0)'} target="_blank">
                                        <button title="Ficha de Resumen" type="button" class="btn btn-outline-warning btn-elevate btn-icon"><i class="fa fa-cloud-download-alt"></i></button>
                                    </a>
                                </div>

                                <div class="row kt-margin-t-15">
                                    
                                        <div class="kt-portlet__body">
                                            <div class="form-group row">
                                                <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <label><strong>OS:</strong> { os }</label>
                                                    
                                                </div>
                                            </div>	
                                            <div class="form-group row">
                                                <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <label><strong>Resumen</strong></label>
                                                    {/*<textarea 
                                                        name="summary" 
                                                        class="form-control" 
                                                        data-provide="markdown" 
                                                        rows="6" 
                                                        onChange={this.handleChange}
                                                        required>{ summary }</textarea>*/}
                                                        <div>
                                                            { <Textarea 
                                                                classStyles={ {} } 
                                                                name="summary"
                                                                value={summary}
                                                                processHtml={true}
                                                                onInit={ editor => {
                                                                    console.log( 'Editor is ready to use!', editor );
                                                                } }
                                                                onChange={ ( event, editor ) => {
                                                                    const data = editor.getData();
                                                                    console.log("OBJ: ",{ target : { name : 'summary', data } } );
                                                                    this.handleChange({ target : { name : 'summary', value : data } });
                                                                } }
                                                            /> }
                                                        </div>
                                                        {/*<div>
                                                            <CKEditor
                                                                editor={ ClassicEditor }
                                                                data={ summary }
                                                                onInit={ editor => {
                                                                    console.log( 'Editor is ready to use!', editor );
                                                                } }
                                                                onChange={ ( event, editor ) => {
                                                                    const data = editor.getData();
                                                                    console.log("OBJ: ",{ target : { name : 'summary', data } } );
                                                                    this.handleChange({ target : { name : 'summary', value : data } });
                                                                } }
                                                            />
                                                        </div>*/}
                                                </div>
                                            </div>	   
                                            <div class="form-group row">
                                                <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <label><strong>Observaciones</strong></label>
                                                    {/*<textarea 
                                                        name="observation" 
                                                        class="form-control" 
                                                        data-provide="markdown" 
                                                        rows="6" 
                                                        onChange={this.handleChange}
                                                        required>{ observation }</textarea>*/}
                                                    <div>
                                                        <CKEditor
                                                            editor={ ClassicEditor }
                                                            data={ observation }
                                                            onInit={ editor => {
                                                                console.log( 'Editor is ready to use!', editor );
                                                            } }
                                                            onChange={ ( event, editor ) => {
                                                                const data = editor.getData();
                                                                console.log("OBJ: ",{ target : { name : 'observation', data } } );
                                                                this.handleChange({ target : { name : 'observation', value : data } });
                                                            } }
                                                        />
                                                    </div>
                                                </div>
                                            </div>             
                                        </div>
                                </div>
                            </div>
                            <div class="kt-widget__footer">
                            <div class="kt-widget__wrapper">
                                <Alert 
                                    message="No olvides finalizar la ficha de resumen.."
                                    color="warning"
                                    style={{ width : '100%' }}
                                    subStyle={{ color : 'white' }}
                                />
                            </div>
                                <div class="kt-widget__wrapper">
                                    <div class="kt-widget__section">
                                        <div class="kt-widget__blog">
                                            <Button 
                                                classStyles={ { 'btn-brand' : true, 'btn-success' : true} }
                                                name="FINALIZAR"
                                                loading={false}
                                                type="submit"
                                                onClick={() => this.setState({ action : 'FINALIZE' })}
                                            />
                                        </div>
                                    </div>
                                    <div class="kt-widget__section">
                                        <Button 
                                            classStyles={ { 'btn-brand' : true, 'btn-warning' : true} }
                                            name="GUARDAR SIN TERMINAR"
                                            loading={false}
                                            type="submit"
                                            onClick={() => this.setState({ action : 'SAVE' })}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        </form>

                    </div>
                </div>
            </div>
        </Fragment>
        )
    }
};

export default connect(null, null)(FinalReport);