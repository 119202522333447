import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { settings } from 'Utils/settings';
import { FlatIcon } from '../../components';
import _ from 'lodash';

const Provider = ({ user }) =>
{
    return (
    <ul class="kt-menu__nav ">
        <li class="kt-menu__section ">
            <h4 class="kt-menu__section-text">MÓDULOS</h4>
            <i class="kt-menu__section-icon flaticon-more-v2"></i>
        </li>

        {/*<li class="kt-menu__item  kt-menu__item--active" aria-haspopup="true" >
            <NavLink
                to="/provider/contracts"
                activeClassName="selectedLink"
                className="kt-menu__link ">
                    <FlatIcon color="success" icon="flaticon2-box-1" />                    
                    <span className="kt-menu__link-text"> Contratos</span>                    
            </NavLink>  
    </li>*/}

        <li class="kt-menu__item  kt-menu__item" aria-haspopup="true" >
            <NavLink
                to="/provider/deliverables"
                activeClassName="selectedLink"
                className="kt-menu__link ">
                    <FlatIcon color="success" icon="flaticon2-box-1" />                    
                    <span className="kt-menu__link-text">Mis Entregables</span>                    
            </NavLink>  
        </li>
        {(_.filter(user.userInformation.events, (event) => event.id == settings.EVENTS[1].id)).length > 0 &&
            <li class="kt-menu__item  kt-menu__item" aria-haspopup="true" >
                <NavLink
                    to="/provider/finalReports"
                    activeClassName="selectedLink"
                    className="kt-menu__link ">
                        <FlatIcon color="success" icon="flaticon-layer" />                    
                        <span className="kt-menu__link-text">Mis Informes Finales</span>                    
                </NavLink> 
            </li>
        }

        <li class="kt-menu__item  kt-menu__item" aria-haspopup="true" >
            <NavLink
                to="/provider/curriculum"
                activeClassName="selectedLink"
                className="kt-menu__link ">
                    <FlatIcon color="success" icon="flaticon2-checking" />                    
                    <span className="kt-menu__link-text">Mi Hoja de Vida</span>                    
            </NavLink>
        </li>
    </ul>
    )
};

const mapStateToProps = (state) => ({
    api : state.api,
    user : state.user
});

export default connect(mapStateToProps, null)(Provider);
