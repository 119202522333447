import React, { Component } from 'react';
import Datatable from 'Components/Datatable';
import Activity from 'Views/Contracts/Activity';
import Select from 'Components/Select';
import File from 'Components/File';
import Button from 'Components/Button';
import * as DeliverablesActionsCreators from 'Ducks/deliverables/operations';
import * as ContractsActionsCreators from 'Ducks/contracts/operations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { settings } from 'Utils/settings';
import { Input } from 'Components';

class EditDeliverable extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            description: "",
            start_date: "",
            end_date: "",
            percentaje_amount: "",
            is_penalty: "",
            penalty_amount: "",
            contract_id: ""
        };

        const { dispatch } = props;

        this.contractsActionsCreators = bindActionCreators(ContractsActionsCreators, dispatch);

        this.deliverablesActionsCreators = bindActionCreators(DeliverablesActionsCreators, dispatch);

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount()
    {
        const { pathname, search, hash, state } = this.props.location;

        this.setState({ ...state.deliverable });
    }

    componentDidMount()
    {
        this.contractsActionsCreators.getContracts();
    }

    handleChange = (e) =>
    {
        const { name, value } = e.target;
        this.setState({ [ name ] : value });
    };

    handleSubmit = () =>
    {
        this.deliverablesActionsCreators.editDeliverable(this.state);
    };

    render()
    {
        const { events, contracts, api } = this.props;

        return(
            <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="kt-portlet">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">
                                    Crear Entregable
                                </h3>
                            </div>
                        </div>
                        <div class="kt-form kt-form--label-right">
                            <div class="kt-portlet__body">
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Descripción:</label>
                                        { <Input 
                                            classStyles={ {} } 
                                            label="Descripción"
                                            placeholder="Descripción"
                                            type="text"
                                            name="description"
                                            value={this.state.description}
                                            onChange={this.handleChange}
							            /> }
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Porcentaje de avance:</label>
                                        { <Input 
                                            classStyles={ {} } 
                                            label="Porcentaje de avance"
                                            placeholder="Porcentaje de avance"
                                            type="number"
                                            name="percentaje_amount"
                                            helpText="Ingresar en %"
                                            value={this.state.percentaje_amount}
                                            onChange={this.handleChange}
							            /> }
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Fecha de Inicio:</label>
                                        { <Input 
                                            classStyles={ {} } 
                                            label="Fecha de Inicio"
                                            placeholder="Fecha de Inicio"
                                            type="date"
                                            name="start_date"
                                            helpText="Por favor ingresar la fecha de inicio del entregable"
                                            value={this.state.start_date}
                                            onChange={this.handleChange}
							            /> }
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Fecha de Fin:</label>
                                        { <Input 
                                            classStyles={ {} } 
                                            label="Fecha de Fin"
                                            placeholder="Fecha de Fin"
                                            type="date"
                                            name="end_date"
                                            helpText="Por favor ingresar la fecha de fin del entregable"
                                            value={this.state.end_date}
                                            onChange={this.handleChange}
							            /> }
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Monto de penalización:</label>
                                        { <Input 
                                            classStyles={ {} } 
                                            label="Monto de penalización"
                                            placeholder="Monto de penalización"
                                            type="number"
                                            name="penalty_amount"
                                            value={this.state.penalty_amount}
                                            onChange={this.handleChange}
							            /> }
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Contracto:</label>
                                        {<Select 
                                            text="Contrato"
                                            value={contracts}
                                            selected={this.state.contract_id}
                                            onChange={(e) => this.setState({ contract_id : e })}
                                        /> }
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Penalización:</label>
                                        <Select 
                                            text="Penalización"
                                            value={settings.PENALTY}
                                            selected={this.state.is_penalty}
                                            onChange={(e) => this.setState({ is_penalty : e })}
                                        />
                                    </div>
                                </div>              
                            </div>
                            <div class="kt-portlet__foot kt-portlet__foot--fit-x">
                                <div class="kt-form__actions">
                                    <div class="row">
                                        <div class="col-lg-12 justify-content-md-center row">
                                            {/*<button type="button" class="btn btn-success">Guardar</button>*/}
                                            <Button 
                                                classStyles={ { 'btn-success' : true } }
                                                name="Guardar"
                                                loading={api.loading}
                                                onClick={this.handleSubmit}
                                            />
                                            <Link to="/deliverables">
                                            <button type="button" class="btn btn-secondary ml-3">Cancel</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    contracts : state.contracts.contracts,
    api : state.api
});

export default connect(mapStateToProps, null)(EditDeliverable);