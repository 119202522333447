import React, { Component } from 'react';
import { Badge, Date, FileObject, FileAdjunto } from 'Components';
import * as cordinatorActionsCreators from 'Ducks/cordinator/operations';
import { connect } from 'react-redux';
import { settings } from 'Utils/settings';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { routes } from 'Utils/constants';
import InformeCreateForm from '../../Provider/informes/InformeCreateForm';


const ListInformes = ({informes, deliverable}) => {

	return _.map(informes, (informe, k) => {
		let data = _.pick(informe, ['created_at', 'updated_at', 'status_review']);

		return informe.verifications && informe.verifications.length > 0 && <div key={k} className="kt-portlet" informe_id={informe.id}>
			<div className="kt-portlet__head">
				<div className="kt-portlet__head-label">
					<h3 className="kt-portlet__head-title">
						<div style={{ marginBottom: '.5rem' }}>
							PRODUCTO <span className="kt-font-primary">N°{deliverable.order}</span>
								- CONTRATO <span className="kt-font-primary">{deliverable.contract.contract_number}</span>
								- PROVEEDOR <span className="kt-font-primary">{deliverable.provider?.name}</span>
						</div>

						<div>

							<span style={{ fontWeight: '500' }} >{informe?.created_at}</span>
						</div>
					</h3>
				</div>
			</div>
			<div className="kt-form kt-form--fit kt-form--label-right">
				<div className="kt-portlet__body">

					<div style={{ marginBottom: '1rem' }} dangerouslySetInnerHTML={{ __html: informe?.summary }} />

					<div className="form-group">
						{_.map(informe.verifications, (v, i) => {
							return v.object && <FileObject key={i} object={v.object} type={"ANEXO"} object_type_id={v.object_type_id} />
						})}
					</div>

					{informe.reviews.length > 0 && <h5>Revisión del informe</h5>}
					<div className="form-group">
						{_.map(informe.reviews, (v, i) => {
							return <div key={i} className="card mb-1" >
								<div className="card-body">
									<div>
										<strong>{v.user?.name}</strong> {<Badge label={settings?.STATUS_INFORME[v.status_review].label} name={settings?.STATUS_INFORME[v.status_review].name} />}
									</div>
									<div style={{ marginBottom: '.5rem' }}><small>{v?.created_at}</small></div>

									<div style={{ marginBottom: '.5rem' }} dangerouslySetInnerHTML={{ __html: v?.summary }} />

									{v.document_review && <FileObject object={v.document_review} type={"Documento adjunto"} />}
								</div>
							</div>
						})}
					</div>
				</div>
			</div>
		</div>

	});
}


const ListTramites = ({tramites, deliverable}) => {
	
	return _.map(tramites, (tramite, k) => {

		console.log("tramites", tramite);
		const {documento} = tramite;
		return <div key={k} className="kt-portlet" >
			<div className="kt-portlet__head">
				<div className="kt-portlet__head-label">
					<h3 className="kt-portlet__head-title">
						<div style={{ marginBottom: '.5rem' }}>
							PRODUCTO <span className="kt-font-primary">N°{deliverable.order}</span>
								- CONTRATO <span className="kt-font-primary">{deliverable.contract.contract_number}</span>
								- PROVEEDOR <span className="kt-font-primary">{deliverable.provider?.name}</span>
						</div>

						{/*<div>
							<span style={{ fontWeight: '500' }} >{tramite?.created_at}</span>
						</div>*/}
					</h3>
				</div>
			</div>
			<div className="kt-form kt-form--fit kt-form--label-right">
				<div className="kt-portlet__body">

					<div style={{ marginBottom: '1rem' }} >
					<h5>ASUNTO: {documento?.asunto}</h5>
					</div>
					<div style={{ marginBottom: '1rem' }} >
					<h5>FECHA: {documento?.fecha}</h5>
					</div>

					<h5>ADJUNTO</h5>
					{documento && documento?.adjunto && <div className="form-group" >					
						<FileAdjunto  adjunto={documento.adjunto} />						
					</div>}

					{documento && documento?.anexos && documento?.anexos.length > 0 && <h5>ANEXOS</h5>}
					<div className="form-group">
						{_.map(documento?.anexos, (adjunto, i) => {
							return <FileAdjunto key={i} adjunto={adjunto} />	
						})}
					</div>
				</div>
			</div>
		</div>

	});
}


class DetailDeliverable extends Component
{
   constructor(props)
   {
		super(props);

	   this.state = {
			deliverable : null,
			deliverable_id : null,
			deliverables : []
	   };

	   const { dispatch } = this.props;

	   this.cordinatorActionsCreators = bindActionCreators(cordinatorActionsCreators, dispatch);
   }

   componentWillMount()
    {
		const { pathname, search, hash, state } = this.props.location;
		
		this.setState({ 
			deliverable : state.deliverable, 
			deliverable_id : state.deliverable.id,
			deliverables : state.deliverables });
    }
	
	componentDidMount()
	{
		const { deliverable, deliverable_id } = this.state;

		this.cordinatorActionsCreators.getDetailDeliverable(deliverable_id);
	}

	getDetailDeliverable = (deliverable) =>
	{
		this.cordinatorActionsCreators.getDetailDeliverable(deliverable.id);		
		this.setState({ deliverable, deliverable_id : deliverable.id });
	}

    render()
    {
		const { contracts, user } = this.props;

		const { deliverables, deliverable, deliverable_id } = this.state;

		console.log("getDetailDeliverable",contracts, deliverable);

        return (
			<>
            <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid no-print">
				<div class="row">
					<h3>ENTREGABLES RELACIONADOS</h3>
				</div>
				<div class="row">
					{	_.map(deliverables, (deliver) => {
							return (<div class="col-lg-3">
									<Link to={{ 
										pathname: routes.CORDINATOR.DETAIL_DELIVERABLE,
										search:`?id=${deliver.id}`,
										//hash:'#hash',
										state: { deliverable : deliver, deliverables}
									}}>
										<div onClick={() => this.getDetailDeliverable(deliver)} class={`kt-portlet kt-iconbox kt-iconbox--${deliver.id === deliverable.id ? 'success' : 'brand'} kt-iconbox--animate-slower`}>
											<div class="kt-portlet__body">
												<div class="kt-iconbox__body">
													<div class="kt-iconbox__icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
															<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																<rect id="bound" x="0" y="0" width="24" height="24"></rect>
																<path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
																<path d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z" id="check-path" fill="#000000"></path>
																<path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" id="Combined-Shape" fill="#000000"></path>
															</g>
														</svg>					
													</div>
													<div class="kt-iconbox__desc">
														<h3 class="kt-iconbox__title">
															<a class="kt-link">Entregable N° { deliver.order }</a>
														</h3>
														<div>
															<i received_at={deliver?.received_at} >Vence el: {deliver?.delivery_date}</i>															
														</div>
														<div class="kt-iconbox__content">
															<Badge label={`${settings?.STATUS_INFORME[deliver.status_review].label}`} name={settings?.STATUS_INFORME[deliver.status_review].name} />
														</div>														
													</div>
												</div>
											</div>
										</div>
									</Link>
							</div>)
					}) }
				</div>
		
				{/* <Provider provider={contracts.provider || {}} contract={contracts.contract || {}} /> */}
				{/*<div class="row">
					<div class="col-xl-12">
						<div class="kt-portlet kt-portlet--tabs">
							<div class="kt-portlet__head">
								<div class="kt-portlet__head-label">
									<h3 class="kt-portlet__head-title">
										Entregable N° {deliverable.order}
									</h3>
								</div>
								<div class="kt-portlet__head-toolbar">
										
								</div>
							</div>
							<div class="kt-portlet__body">
								<p>{deliverable.description}</p>
							</div>
						</div>	
					</div>

					<div class="col-xl-12">
						<div class="kt-portlet kt-portlet--tabs">
							<div class="kt-portlet__head">
								<div class="kt-portlet__head-label">
									<h3 class="kt-portlet__head-title">
										Informe enviado el {deliverable.received_at}
									</h3>
								</div>
								<div class="kt-portlet__head-toolbar">
										
								</div>
							</div>
							<div class="kt-portlet__body">

								<div dangerouslySetInnerHTML={{ __html: contracts.informe?.summary }} />
								{JSON.stringify(deliverable.informe)}
								<div class="tab-content">
									<div class="tab-pane active" id="kt_portlet_tab_1_1">										
									<div class="row">
									{ _.map(contracts.verifications, (verification) => <MeansOfVerification verification={verification} />) }
									</div>
									</div>
								</div>
							</div>
						</div>	
					</div>
				</div>	
				*/}
				</div>

			<div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
				
			<h3 className="kt-widget1__title_">
                    CONTRATO <span className="kt-font-primary">{contracts.contract?.contract_number}</span>
                                    &nbsp; PROVEEDOR <span className="kt-font-primary">{contracts.provider?.name}</span>
                </h3>

				<div >
                    <FileObject object={contracts.contract?.document_contract} type="CONTRATO / OS" />
                    <FileObject object={contracts.contract?.document_tdr} type="TDR / ET" />
                </div>

				<div className="kt-portlet">
					<div className="row">
						<div className="col-md-12">
							<div className={`kt-widget1 card-custom-${contracts.status_review}`} style={{ paddingBottom: '1rem', paddingTop: '1rem' }} >
								<h3 className="kt-widget1__title_" style={{ verticalAlign: 'middle' }}>
									PRODUCTO <span className="kt-font-primary">N°{contracts.order}</span> 
								</h3>

								{contracts.description && <div style={{ marginBottom: '.5rem' }}>
									<pre>{contracts.description}</pre>
								</div>}
							</div>
						</div>
					</div>
                </div>

				<ListTramites tramites={contracts.tramites} deliverable={contracts}  />

				<ListInformes  informes={contracts.informes} deliverable={contracts} />

				{user.id === 96 && <div className="col-lg-12">
					{<InformeCreateForm deliverable={deliverable}  maxFiles={20} onChange={(formInforme) => {                           
						
					}} onSuccess={(response) => {
																											
					}} />}
				</div>}

			</div>

			</>
        )
    }
}

const Product = ({ product }) =>
(
	<div class="kt-portlet kt-portlet--tabs">
			<div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						{ product?.name }
					</h3>
				</div>
				<div class="kt-portlet__head-toolbar">
				</div>
			</div>
			<div class="kt-portlet__body">
				<div class="tab-content">
					<div class="tab-pane active" id="kt_portlet_tab_1_1">
						
					<div class="row">
						{ _.map(product.verifications, (verification) => <MeansOfVerification verification={verification} />) }

						</div>
					</div>
				</div>
			</div>
		</div>	
);

const MeansOfVerification = ({ verification }) =>
(
	<div class="col-md-6 kt-margin-t-30">
		<div class="d-flex align-items-center">
			<div class="col-md-2">
				<a href={`${verification.object ? verification.object._url : '-'}`} target="_blank">
					<button title="Descargar medio de verificación" type="button" class="btn btn-outline-success btn-elevate btn-icon"><i class="fa fa-cloud-download-alt"></i></button>
				</a>
			</div>
			<div class="col-md-8">
				<span class="kt-padding-0 d-block">
					<strong>Nombre:</strong> { verification?.name }
				</span>
					<span class="kt-padding-0 d-block">
						<strong>Tipo:</strong> { verification.informe_type_id ? settings?.TYPE_INFORME[verification.informe_type_id].name : '-' }
					</span>
				<span class="kt-padding-0 d-block">
					<strong>Status :</strong> { <Badge label={`${settings.STATUS_INFORME[verification.status_review].label}`} name={settings.STATUS_INFORME[verification.status_review].name} /> }
				</span>
				<span class="kt-margin-t-10 d-flex align-items-center">
					<strong>F. Creación :</strong> 
					<div class="kt-widget__label kt-margin-l-10">
						<Date 
							alert='success'
							date={verification.created_at}
							outputFormat='DD-MM-YYYY h:mm:ss a'
						/>
					</div>
				</span>
				<span class="kt-margin-t-10 d-flex align-items-center">
					<strong>F. Actualización :</strong> 
					<div class="kt-widget__label kt-margin-l-10">
						<Date 
							alert='warning'
							date={verification.updated_at}
							outputFormat='DD-MM-YYYY h:mm:ss a'
						/>
					</div>
				</span>
			</div>
		</div>
	</div>
);

const mapStateToProps = (state) => ({
	api : state.api,
	contracts : state.cordinator.contracts,
	user: state?.user?.userInformation
});

export default connect(mapStateToProps, null)(DetailDeliverable);