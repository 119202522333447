import React, { Fragment, useState, useEffect, Component } from 'react';
import { getIn } from "formik";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FileUpload, Button, Textarea, Select, Radio, Alert, buildNotification, ConfirmationDialog } from 'Components';
import InputFile from "../../components/InputFile";
import UploadFileTemp from '../../components/ResumableUploadFile/UploadFileTemp';
import FileUploadSection from "../../components/FileUploadSection";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import _ from 'lodash';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import { settings } from 'Utils/settings';
import 'Assets/css/style.css';
import Resumablejs from "resumablejs";
import Apigproc from "../../../services/apigproc";

class InformeReviewForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contract_informe_id: null,
            summary: '',
            observation: '',
            _data: null,
            sending: false,
            documents: [],
            document_review: null,
            file: null,
            openConfirm: false
        };

    }    

    resetState() {
        
        this.setState({
            contract_informe_id: null,
            summary: '',
            observation: '',
            _data: null,
            sending: false,
            documents: [],
            document_review: null,
            file: null,
        });
    }

    componentWillReceiveProps(nextProps) {

        const { informe,  formInformeReview } = nextProps;

        if(formInformeReview){
            this.setState(formInformeReview);
        }        
        if(informe) {
            if(!formInformeReview){
                this.setState({
                    contract_informe_id: informe.id,
                    summary: '',
                    observation: '',
                    sending: false,
                    documents: [],
                    document_review: null,
                    file: null,
                });
            }
        }

    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    handleChange = (e) => {
        const { onChange, informe } = this.props;
        const { name, value } = e.target;
        this.setState({ [name]: value, contract_informe_id: informe.id }, () => {
            if(onChange){
                onChange(this.state);
            }
        });

    };

    handleChangeFile = (name, value) => {
        const { onChange, informe } = this.props;
        this.setState({ [name]: value, contract_informe_id: informe.id }, () => {
            if(onChange){
                onChange(this.state);
            }
        });
    };

    handleValidation = async (e) => {
        e.preventDefault();
        this.setState({openConfirm: true});
    }


    handleSubmit = async (e) => {       
        const { summary, documents, status_review } = this.state;

        this.setState({ sending: true });

        const { informe } = this.props;
        let dataSend = {
            contract_informe_id: informe.id,
            summary: summary,
            status_review: status_review,
            document_review: documents.length > 0 ? documents[0] : null,
        };

        Apigproc.informeReview.create(dataSend).then(response => {
            this.setState({ sending: false });
            if (this.props.onSuccess) {
                this.props.onSuccess(response.data);
                this.resetState();
            }
        }).catch(e => {
            this.setState({ sending: false });
        });

    }

    render() {

        const { informe, title = "REVISIÓN", onCancel} = this.props;
        const { summary, documents, status_review } = this.state;

        return <Fragment>

            <ConfirmationDialog open={this.state.openConfirm} message={"Estas Seguro(a) de enviar Revisión"} onConfirm={(result) => {
                this.setState({ openConfirm: false }, () => {
                    if(result){
                        this.handleSubmit();
                    }                                    
                });
            }} />

            <form class="" method="POST" onSubmit={this.handleValidation} >

                <div className="form-group">
                    <h4>Formulario de Revisión</h4>
                </div>

                <div className="form-group">
                    <label><strong>Seleccionar Estado (obligatorio)</strong></label>
                    <div>     
                                        
                        <Button
                            classStyles={{ 'btn-brand btn-sm': true, 'btn-danger': status_review === 4, 'btn-default': status_review != 4 }}
                            name="Cerrar Revisión Como Observado"
                            type="button"
                            onClick={() => this.handleChangeFile('status_review', 4)}
                            style={{ marginRight: '1rem' }}
                        />
                        <Button
                            classStyles={{ 'btn-brand btn-sm': true, 'btn-success': status_review === 3, 'btn-default': status_review != 3 }}
                            name="Cerrar Revisión Como Aprobado"
                            type="button"
                            onClick={() => this.handleChangeFile('status_review', 3)}
                            style={{ marginRight: '1rem' }}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label><strong>Escribir mensaje (obligatorio)</strong> {summary.length} </label>
                    {/*<Textarea
                                classStyles={{}}
                                name="summary"
                                value={summary}
                                processHtml={true}
                                onInit={editor => {
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.handleChange({ target: { name: 'summary', value: data } });
                                }}
                            />*/}

                    {<textarea
                        name="summary"
                        class="form-control"
                        data-provide="markdown"
                        rows="6"
                        //onChange={this.handleChange}
                        onChange={(event) => {
                            const {value} = event.target;
                            this.handleChange(event);
                            /*if(value.length < 256){
                                this.handleChange(event);
                            }else{
                                this.handleChange({ target: { name: 'summary', value: summary } });
                            }*/
                        }}
                        value={summary}
                        required></textarea>}
                    <div>
                        Escribir texto minimo de 64 caracteres.
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label><strong>Adjuntar archivo de revisión</strong> (PDF WORD .zip .rar)</label>
                            <InputFile name={"documents"} objects={documents} maxFiles={1} onChange={(objects) => this.handleChangeFile('documents', objects)} />
                        </div>


                    </div>
                </div>

                <div className="form-group">
                
                    <Button
                        classStyles={{ 'btn-brand': true, 'btn-primary': true }}
                        name="Enviar Revisión"
                        loading={this.state.sending}
                        type="submit"
                        disabled={this.state.sending || summary.length < 64 || !status_review}
                        style={{ marginRight: '1rem' }}
                    />

                    {onCancel && <Button
                        classStyles={{ 'btn-brand': true, 'btn-default': true }}
                        name="Cancelar envío"
                        type="button"
                        onClick={onCancel}
                    />}
                </div>

            </form>
        </Fragment>
    }
}

export default InformeReviewForm;