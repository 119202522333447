import React from 'react';
import _ from 'lodash';
import {  constants } from "./types";
import { createRequest } from "Utils/createRequest";
import { buildNotification } from 'Components';
import history from 'Utils/history';
import { routes } from 'Utils/constants';
import * as ProviderSelectors from 'Ducks/providers/selectors';
import { settings } from '../../utils/settings';
import { getInformationUser } from 'Ducks/user/actions';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export const setProviders = (providers) => 
({
    type: constants.SET_PROVIDERS,
    providers
});

export const setProvidersByUser = (providers) =>
({
  type : constants.SET_PROVIDERS_BY_USER,
  providers
});

export const setInformes = (informes) =>
({
  type : constants.SET_INFORMES,
  informes
});

export const setVerificationsOfInforme = (verifications) =>
({
  type : constants.SET_VERIFICATIONS_OF_INFORME,
  verifications
});

export const setProductsByInforme = (products) =>
({
  type : constants.SET_PRODUCTS_BY_INFORME,
  products
});

export const setContractByInforme = (contract) =>
({
  type : constants.SET_CONTRACT_BY_INFORME,
  contract
});

export const setDeliverableByInforme = (deliverable) =>
({
  type : constants.SET_DELIVERABLE_BY_INFORME,
  deliverable
});

export const setInstitutions = (institutions) => 
({
    type: constants.SET_INSTITUTIONS,
    institutions
});

export const setCountries = (countries) => 
({
    type: constants.SET_COUNTRIES,
    countries
});

export const setFinalReports = (finalReports) =>
({
  type : constants.SET_FINAL_REPORTS,
  finalReports
});

export const saveFormInforme = ({formInforme}) =>
({
  type : constants.SAVE_FORM_INFORME,
  formInforme
});

export const removeFormInforme = ({formInforme}) =>
({
  type : constants.REMOVE_FORM_INFORME,
  formInforme
});


export function getProviders(pagination = true) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/providers${pagination ? '?pagination' : ''}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_PROVIDERS
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        //Redirect to Dashboard
        dispatch(setProviders(data.data));
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function getProvidersByUser()
{
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/auth/providers?search=`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_PROVIDERS_BY_USER
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        dispatch(setProvidersByUser(data.data));
      }

      return data.data;
    }
    catch (error)
    {
      console.log("ERROR: ", error);
    }
  }
}

export function getInformes(id, numberPage = 1)
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/providers/${ProviderSelectors.getFirstProviderId(getState().providers.providersByUser)}/contract_deliveries/${id}?with=products&page=${numberPage}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_INFORMES
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      console.log("RESULT", data);
      if(status === 'Success')
      {
        dispatch(setDeliverableByInforme(data));
        dispatch(setInformes(data));
        
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);
    }
  }
}

export function createMeanOfVerificationInforme(filename, type_informe, contract_informe_id)
{
  return async (dispatch, getState) =>
  {
    const params = {
      requestParams: {
        method: 'POST',
        url: `/providers/${ProviderSelectors.getFirstProviderId(getState().providers.providersByUser)}/contract_verifications`,
        data : {
          _data : filename,
          name : "",
          object_type_id : settings.OBJECT_TYPE[1].id,
          contract_informe_id : contract_informe_id,
          informe_type_id : type_informe
        },
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        //params: query
      },
      requestId: constants.CREATE_MEAN_OF_VERIFICATION_INFORME
    };

    const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Informe subido...', 'Éxito');
        
        dispatch(getProductsAndMeansOfVerifications(contract_informe_id));
      }
  }
}

export function getProductsAndMeansOfVerifications(informe_id)
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/providers/${ProviderSelectors.getFirstProviderId(getState().providers.providersByUser)}/contract_informes/${informe_id}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_PRODUCTS_BY_INFORME
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {        
        dispatch(setContractByInforme(data.contract));
        dispatch(setDeliverableByInforme(data.deliverable));
        dispatch(setProductsByInforme(data.products));
        dispatch(setVerificationsOfInforme(data.verifications));
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);
    }
  }
}

//Change status_review and summary, observacion of informe
export function statusInforme(action, summary, observation)
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'POST',
          url: `/providers/${ProviderSelectors.getFirstProviderId(getState().providers.providersByUser)}/contract_informes`,
          data : {
            id : localStorage.getItem('informe_id'),
            summary,
            observation,
            status_review : action
          },
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.CHANGE_STATUS_INFORME
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        if(action == settings.STATUS_REVIEW_DELIVERABLES[1].id)
        {
          buildNotification('warning', 'Guardado correctamente, no olvides finalizar la ficha.', 'Éxito');
        }
        else
        {
          buildNotification('success', 'Finalizado correctamente :)', 'Éxito');
        }
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);
    }
  }
}

export function submitFiles(files /*, product_id*/) {
  return async dispatch => {
    try {
      let bodyFormData = new FormData();

      if(Array.isArray(files))
      {
        files.forEach(function(file) {
          bodyFormData.append('file[]', file);
        });
      }
      else
      {
        bodyFormData.set('file', files);
      }

      const params = {
        requestParams: {
          method: 'POST',
          url: `/files`,
          data : bodyFormData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type':'multipart/form-data' 
          }
        },
        requestId: constants.SUBMIT_MEANS_OF_VERIFICATIONS
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      return data;

      /*if(status === 'Success')
      {
        _.map(data, file => dispatch(createMeanOfVerification(file.filename, product_id)));
        
        return data;
      }*/

    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

//Create 
export function createMeanOfVerification(_data, id)
{
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'POST',
          url: `/providers/${ProviderSelectors.getFirstProviderId(getState().providers.providersByUser)}/contract_verifications`,
          data : {
            _data,
            object_type_id : 1,
            contract_product_id : id
          },
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.CREATE_MEAN_OF_VERIFICATION
      };
      
      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Se subió correctamente el medio de verificación..', 'Éxito');

        dispatch(getProductsAndMeansOfVerifications(localStorage.getItem("informe_id")));
      }
    }
    catch (error)
    {
      console.log("ERROR: ", error);
    }
  }
}

export function deleteFile(meansOfVerification_id, informe_id) {
  return async (dispatch, getState) => {
    try {
      const params = {
        requestParams: {
          method: 'DELETE',
          url: `/providers/${ProviderSelectors.getFirstProviderId(getState().providers.providersByUser)}/contract_verifications/${meansOfVerification_id}`,
          data : {},
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type':'multipart/form-data' 
          }
        },
        requestId: constants.DELETE_MEAN_OF_VERIFICATION
      };

      const res = await dispatch(createRequest(params));

      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('danger', 'Eliminado...', 'Eliminado');

        dispatch(getProductsAndMeansOfVerifications(informe_id));

        return data;
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function getInstitutions(value) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/institutions?name=${value}&type=UNIVERSIDAD`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_INSTITUTIONS
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        //Redirect to Dashboard
        dispatch(setInstitutions(data.data));

        return data.data;
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function getCountries(value) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/utils/ubigeo_countries?name=${value}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_COUNTRIES
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        //Redirect to Dashboard
        dispatch(setCountries(data.data));

        return data.data;
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function getFinalReports(numberPage = 1, parameters) {
  return async dispatch => {
    try {
      let queryString = "";

      queryString = `&page=${numberPage}
        ${_.has(parameters, 'institution_name') ? `&institution_name=${parameters.institution_name}` : ``}
        ${_.has(parameters, 'provider_name') ? `&provider_name=${parameters.provider_name}` : ``}
        ${_.has(parameters, 'deliverable_number') ? `&deliverable_number=${parameters.deliverable_number}` : ``}
        ${_.has(parameters, 'event_id') ? `&event_id=${parameters.event_id}` : ``}
      `;

      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contract_verifications/informes?with=institutions,deliverable${queryString}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_FINAL_REPORTS
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        //Redirect to Dashboard
        dispatch(setFinalReports(data));

        return data.data;
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function saveAcademicTitle(title) {
  return async dispatch => {
    try {
      console.log("DATA SAVEACADEMICTITLE: ", title);
      const params = {
        requestParams: {
          method: 'POST',
          url: `/profile/cv_titles`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : title
        },
        requestId: constants.SET_ACADEMIC_TITLE
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Estudio agregado...', 'Éxito');
        
        dispatch(getInformationUser());
      }
    } catch (e) 
    {
      const { errors } = e.request.response;

      buildNotification('warning',
      ReactHtmlParser(`<ul>${_.map(errors, error => {console.log("ERROR: ", error[0]); return `<li>${ error[0] }</li>`})}</ul>`)
      , 'Cuidado');
    }
  }
};

export function saveCourse(obj) {
  return async dispatch => {
    try {
      let action = obj.id ? constants.UPDATE_COURSE : constants.SET_COURSE;

      const params = {
        requestParams: {
          method: 'POST',
          url: `/profile/cv_courses`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : obj
        },
        requestId: action
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', `Curso complementario ${action === constants.UPDATE_COURSE ? 'actualizado' : 'agregado'}..`, 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function deleteStudies(id, type) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'DELETE',
          url: type === "ACADEMIC_TITLE" ? `/profile/cv_titles/${id}` : `/profile/cv_courses/${id}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
        },
        requestId: constants.DELETE_ACADEMIC_TITLE
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Estudio general eliminado...', 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());

        return data;
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function languages(obj) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'POST',
          url: `/profile/cv_courses`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : obj
        },
        requestId: constants.SET_COURSE
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Curso complementario agregado...', 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function setLanguage(obj) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'POST',
          url: `/profile/cv_languages`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : obj
        },
        requestId: constants.SET_LANGUAGE
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Idioma agregado...', 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function deleteLanguage(id) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'DELETE',
          url: `/profile/cv_languages/${id}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },

        },
        requestId: constants.DELETE_LANGUAGE
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Lenguaje Eliminado...', 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function setSalary(obj) {
  return async dispatch => {
    try {
      let action = obj.id ? constants.UPDATE_SALARY : constants.SET_SALARY;
      const params = {
        requestParams: {
          method: 'POST',
          url: `/profile/cv_salaries`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : obj
        },
        requestId: action
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', `Salario ${action === constants.UPDATE_SALARY ? 'actualizado' : 'agregado'}..`, 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function deleteSalary(id) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'DELETE',
          url: `/profile/cv_salaries/${id}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },

        },
        requestId: constants.DELETE_SALARY
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Salario Eliminado...', 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function setReference(obj) {
  return async dispatch => {
    try {
      let action = obj.id ? constants.UPDATE_REFERENCE : constants.SET_REFERENCE;

      const params = {
        requestParams: {
          method: 'POST',
          url: `/profile/cv_references`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : obj
        },
        requestId : action
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', `Referencia ${action === constants.UPDATE_SALARY ? 'actualizado' : 'agregado'}..`, 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function deleteReference(id) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'DELETE',
          url: `/profile/cv_references/${id}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },

        },
        requestId: constants.DELETE_REFERENCE
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Referencia laboral eliminada...', 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function setExperience(obj) {
  return async dispatch => {
    try {
      let action = obj.id ? constants.UPDATE_EXPERIENCE : constants.SET_EXPERIENCE;
      
      const params = {
        requestParams: {
          method: 'POST',
          url: `/profile/cv_experiences`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : obj
        },
        requestId: action
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', `Experiencia ${action === constants.UPDATE_EXPERIENCE ? 'actualizado' : 'agregado'}..`, 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function deleteExperience(id) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'DELETE',
          url: `/profile/cv_experiences/${id}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },

        },
        requestId: constants.DELETE_EXPERIENCE
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Experiencia eliminada...', 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function updatePersonalInformation(obj) {
  return async dispatch => {

    try {
      const params = {
        requestParams: {
          method: 'POST',
          url: `/auth/update`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : obj
        },
        requestId: constants.UPDATE_PERSONAL_INFORMATION
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Información actualizada...', 'Éxito');
        //Redirect to Dashboard
        dispatch(getInformationUser());
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function getProfiles() {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/utils/category_specialities?with=specialities`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : {}
        },
        requestId: constants.GET_PROFILES
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        return data.data
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function setProfiles(obj) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'POST',
          url: `/profile/cv_profiles`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : obj
        },
        requestId: constants.SET_PROFILES
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', 'Experiencia específica agregada...', 'Éxito');

        dispatch(getInformationUser());

        return data.data
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function createProvider(provider) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'POST',
          url: `/providers/`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : provider
          //params: query
        },
        requestId: constants.CREATE_PROVIDER
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        buildNotification('success', response.message)
        return response;
      }
    } catch ({response}) 
    {
      return response;
    }
  }
}