import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input, Radio, InputAutocomplete, Select, CustomDatePicker } from 'Components';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import { bindActionCreators } from 'redux';
import { settings } from 'Utils/settings';
import moment from 'moment';

const PersonalInformation = ({ personalInformation }) => 
{
    const dispatch = useDispatch();

    const [firstname , setFirstname] = useState((personalInformation && personalInformation.firstname) || '');
    const [plastname , setPlastname] = useState((personalInformation && personalInformation.plastname) || '');
    const [mlastname , setMlastname] = useState((personalInformation && personalInformation.mlastname) || '');
    const [birthdate , setBirthdate] = useState( (personalInformation && personalInformation.birthdate) || '');
    const [birthplace , setBirthPlace] = useState( (personalInformation && personalInformation.birthplace) || '');
    const [address , setAddress] = useState( (personalInformation && personalInformation.address) || '' );
    const [phone , setPhone] = useState( (personalInformation && personalInformation.phone) || '' );
    const [country , setCountry] = useState( (personalInformation && personalInformation.nacionality) || '' );
    const [city , setCity] = useState( (personalInformation && personalInformation.city) || '' );
    const [cellphone , setCellphone] = useState( (personalInformation && personalInformation.cellphone) || '' );
    const [gender , setGender] = useState( (personalInformation && personalInformation.gender) || 'M' );
    const [maritalStatus, setMaritalStatus] = useState( (personalInformation && personalInformation.civil_status) || '' );
    const [district , setDistrict] = useState( (personalInformation && personalInformation.district) || '' );
    const [documentNumber , setDocumentNumber] = useState( (personalInformation && personalInformation.document_number) || '' );
    const [documentTypeId , setDocumentTypeId] = useState( (personalInformation && personalInformation.document_type_id) || '' );
   

    const onSubmit = async(e) => 
    {
        e.preventDefault();

        let providersActionCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

        await providersActionCreators.updatePersonalInformation({
            firstname,
            plastname,
            mlastname,
            birthdate,
            birthplace,
            gender,
            address,
            phone, 
            nacionality : country,
            civil_status : maritalStatus,
            district,
            document_number : documentNumber,
            document_type_id : documentTypeId,
            city,
            cellphone
        });
    }

    const getCountries = async (value) =>
    {
        let providersActionCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

        return await providersActionCreators.getCountries(value);
    };

    const markImportant = (value) => {
        return <span style={{color: "red"}} >(*)</span>;
        if(!value){
            return <i className="flaticon2-correct kt-font-danger" ></i>
        }else{
            return <i className="flaticon2-correct kt-font-success" ></i>
        }
    }


    return (
        <form className="kt-form kt-form--label-right" action="" onSubmit={onSubmit}>
            <div className="kt-form__body">
                <div className="kt-section kt-section--first">
                    <div className="kt-section__body">

                        <h2>INFORMACIÓN PERSONAL</h2>
                        <p>Todos los campos marcados con <span style={{color: "red"}} >(*)</span> son requeridos.</p>

                        <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>

                        <div className="row">
                        <div className="form-group col-md-6 col-lg-4">
                            <label>Nombres { markImportant(firstname)}</label>
                            
                                <Input 
                                    classStyles={ {} } 
                                    label="Nombres"
                                    placeholder=""
                                    type="text"
                                    name="name"
                                    maxlength="50"                                    
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                />
                            
                        </div>
                        <div className="form-group col-md-6 col-lg-4">
                            <label>Apellido Paterno { markImportant(plastname)}</label>
                            
                                <Input 
                                    classStyles={ {} } 
                                    label="Apellido Paterno"
                                    placeholder=""
                                    type="text"
                                    maxlength="50"
                                    name="plastname"                                    
                                    value={plastname}
                                    onChange={(e) => setPlastname(e.target.value)}
                                />
                            
                        </div>
                        <div className="form-group col-md-6 col-lg-4">
                            <label>Apellido Materno { markImportant(mlastname)}</label>
                            
                                <Input 
                                    classStyles={ {} } 
                                    label="Apellido Materno"
                                    placeholder=""
                                    type="text"
                                    maxlength="50"
                                    name="mlastname"
                                    
                                    value={mlastname}
                                    onChange={(e) => setMlastname(e.target.value)}
                                />
                            
                        </div>

                        <div className="form-group col-md-6 col-lg-4">
                            <label>Nacionalidad (Autocompletado) { markImportant(country)}</label>                            
                                <InputAutocomplete 
                                    onCallback={getCountries} 
                                    onResult={(country) => setCountry(country.gentilicio)}
                                    onClear={false}
                                    value={country}
                                />                            
                        </div>

                        <div className="form-group col-md-6 col-lg-4">
                            <label>Estado Civil { markImportant(maritalStatus)}</label>                            
                            <Select 
                                text="Seleccionar tipo *"
                                value={settings.MARITAL_STATUS}
                                name="civil_status"
                                selected={maritalStatus}
                                
                                onChange={(e) => setMaritalStatus(e)}
                            />                            
                        </div>

                        {/*<div className="form-group col-md-6 col-lg-4">
                            <label>Tipo de documento { markImportant(documentTypeId)}</label>                                                                                  
    </div>*/}
                        <div className="form-group col-md-6 col-lg-4">
                            <label>Documento de identidad { markImportant(documentNumber)}</label>
                            <div className="row">                                
                                <div className="form-group col-5">
                                    <Select 
                                        text="Tipo de documento"
                                        value={settings.DOCUMENT_TYPE}
                                        name="civil_status"
                                        selected={documentTypeId}                                 
                                        onChange={(e) => setDocumentTypeId(e)}
                                    />  
                                </div>
                                <div className="form-group col-7">
                                    <Input 
                                        classStyles={ {} } 
                                        label="DNI"
                                        placeholder="N° de documento"
                                        type="text"
                                        maxlength={documentTypeId == 1 ? 8: 16}
                                        name="document_number"                                        
                                        value={documentNumber}
                                        onChange={(e) => setDocumentNumber(e.target.value)}
                                    />     
                                </div>
                            </div>                            
                                                   
                        </div>
                        <div className="form-group col-md-6 col-lg-4">
                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Fecha de Nacimiento { markImportant(birthdate)}</label>                            
                                    <div>
                                        <CustomDatePicker
                                            selected={ birthdate? moment(birthdate, "YYYY-MM-DD").toDate(): null}
                                            onChange={date => { setBirthdate(moment(date).format("YYYY-MM-DD")) }}
                                            maxDate={moment().subtract(17, 'years').toDate()}   
                                            openToDate={moment().subtract(40, 'years').toDate()}                                 
                                        />        
                                    </div> 
                                </div>
                                <div className="form-group col-sm-6">
                                    <label>Lugar de Nacimiento { markImportant(birthdate)}</label>    
                                    <Input 
                                        classStyles={ {} } 
                                        label="DNI"
                                        placeholder="DEPARTAMENTO - PROVINCIA"
                                        type="text"
                                        name="birthplace"                                        
                                        value={birthplace}
                                        onChange={(e) => setBirthPlace(e.target.value)}
                                    />     
                                </div>
                            </div>
                                                                             
                        </div>

                        <div className="form-group col-md-6 col-lg-4">

                            <label>Dirección { markImportant(address)}</label>                                    
                                        <Input 
                                            classStyles={ {} } 
                                            label="Dirección"
                                            placeholder=""
                                            type="text"
                                            name="address"
                                            maxlength="50"
                                            
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />                            
                            
                        </div>
                        
                        <div className="form-group col-md-6 col-lg-4">
                            <div className="row">                                
                                <div className="form-group col-sm-6">
                                    <label>Distrito { markImportant(district)}</label>                        
                                    <Input 
                                        classStyles={ {} } 
                                        label="Distrito"
                                        placeholder=""
                                        type="text"
                                        name="district"
                                        maxlength="50"
                                        
                                        value={district}
                                        onChange={(e) => setDistrict(e.target.value)}
                                    />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label>Ciudad { markImportant(city)}</label>                            
                                    <Input 
                                        classStyles={ {} } 
                                        label="Ciudad"
                                        placeholder=""
                                        type="text"
                                        name="city"
                                        maxlength="50"
                                        
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </div>
                            </div>
                            
                        </div>

                       

                        

                        

                        <div className="form-group col-md-6 col-lg-4">
                            <label>Teléfono (opcional)</label>                            
                                <Input 
                                    classStyles={ {} } 
                                    label="Teléfono"
                                    placeholder=""
                                    type="text"
                                    name="phone"
                                    maxlength="15"
                                    
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            
                        </div>

                        <div className="form-group col-md-6 col-lg-4">
                            <label>Celular (opcional)</label>
                            
                                <Input 
                                    classStyles={ {} } 
                                    label="Celular"
                                    placeholder=""
                                    type="text"
                                    name="cellphone"
                                    maxlength="15"
                                    
                                    value={cellphone}
                                    onChange={(e) => setCellphone(e.target.value)}
                                />
                            
                        </div>

                        
                        <div className="form-group col-md-6 col-lg-4">
                            <label>Sexo { markImportant(gender)}</label>
                            <div className="kt-radio-inline">
                                <Radio 
                                    changed={ (e) => setGender(e.target.value) } 
                                    id="1" 
                                    name="gender"
                                    isSelected={ gender === "M" } 
                                    label="Masculino" 
                                    value="M" 
                                />

                                <Radio 
                                    changed={ (e) => setGender(e.target.value) } 
                                    id="2" 
                                    name="gender"
                                    isSelected={ gender === "F" } 
                                    label="Femenino" 
                                    value="F" 
                                />
                            </div>                                                            
                        </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid"></div>

            <div className="kt-form__actions">
                <div className="row">
                    <div className="col">
                        <Button 
                            classStyles={ { 'btn' : true, 'btn-success' : true } }
                            type="submit"
                            name="GUARDAR"
                        />
                    </div>
                </div>
            </div>
        </form>
    )
};

export default PersonalInformation;