import React from 'react';

const SplashScreenLoading = ({
    loading = false, 
    position="fixed", 
    mode="card",
    text="Cargando..."
}) => (
    loading && <div className={`splash-screen ${position}`} >
        <div className={`splash-screen--${mode}`}>
            <div className="kt-spinner kt-spinner--lg kt-spinner--brand" style={{ paddingLeft: '30px' }}> {text}</div> 
        </div>        
    </div>
);

export default SplashScreenLoading;