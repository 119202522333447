export const settings = {
    BASE_URL : 'https://gestiona.ue118.gob.pe',
    PROJECT_ID : 1,
    CURRENT_CURRENCY : 'S/',
    FOUNDING_SOURCE : [
        {
            id : 1,
            name : "RO"
        },
        {
            id : 2,
            name : "ROOC"
        },
        {
            id : 3,
            name : "RO/ROOC"
        }
    ],
    CURRENCY : [
        {
            id : "PEN",
            name : "SOLES",
            label : "S/"
        },
        {
            id : "USD",
            name : "DOLARES",
            label : "$"
        }
    ],
    PENALTY : [
        {
            id : 1,
            name : "PENALIZADO"
        },
        {
            id : 0,
            name : "NO PENALIZADO"
        }
    ],
    ROLES : {
        'PROYECT::SYSADMIN' : {
            id : 1,
            name : "PROYECT::SYSADMIN",
            label : 'SYSADMIN'
        },
        "PROYECT::ADMIN" : {
            id : 2,
            name : "PROYECT::ADMIN",
            label : 'ADMIN'
        },
        "PROYECT::SUPERVISOR" : {
            id : 3,
            name : "PROYECT::SUPERVISOR",
            label : 'SUPERVISOR'
        },
        "PROYECT::PROVEEDOR" : {
            id : 4,
            name : "PROYECT::PROVEEDOR",
            label : 'CONSULTOR/PROVEEDOR'
        },
        "PROYECT::CORDINATOR" : {
            id : 5,
            name : "PROYECT::CORDINATOR",
            label : 'COORDINADOR'
        }
    },
    STATUS_INFORME : {
        1 : {
            id : 1,
            name : 'PENDIENTE',
            label : 'info'
        },
        2 : {
            id : 2,
            name : 'EN REVISIÓN',
            label : 'warning'
        },
        3 : {
            id : 3,
            name : 'APROBADO',
            label : 'success'
        },
        4 : {
            id : 4,
            name : 'OBSERVADO',
            label : 'danger'
        }
    },
    OBJECT_TYPE : {
        1 : {
            id : 1,
            name : 'Subir medios de verificación digitales'
        }
    },
    STATUS_REVIEW_DELIVERABLES : {
        1 : {
            id : 1,
            name : 'PENDIENTE'
        },
        2 : {
            id : 2,
            name : 'EN REVISIÓN'
        }
    },
    TYPE_INFORME : {
        1 : {
            id : 1,
            name : 'COMPROBANTE DE PAGO',
        },
        2 : {
            id : 2,
            name : 'CONSULTA RUC',
        },
        3 : {
            id : 3,
            name : 'INFORME',
        },
        4 : {
            id : 4,
            name : 'EXONERACIÓN',
        },
        5 : {
            id : 5,
            name : 'CONFORMIDAD DE SERVICIO',
        },
        6 : {
            id : 6,
            name : 'FACTURA',
        },
        7 : {
            id : 7,
            name : 'ANEXO',
        },
        8 : {
            id : 8,
            name : 'EXCEL CAPACITACIÓN',
        },
        9 : {
            id : 9,
            name : 'INFORME EDITABLE',
        }
    },
    TYPE_CONTRACT : {
        1 : {
            id : 1,
            name : 'CONTRATO'
        },
        2 : {
            id : 2,
            name : 'ORDEN DE SERVICIO'
        }
    },
    DOCUMENT_TYPE : {
        1 : {
            id : 1,
            name : 'DNI'
        },
        2 : {
            id : 2,
            name : 'C.E'
        },
        3 : {
            id : 3,
            name : 'PASAPORTE'
        },
        4 : {
            id : 4,
            name : 'OTROS'
        }
    },
    TITLE_ACADEMIC : {
        1 : {
            id : 'DOCTORADO',
            name : 'DOCTORADO'
        },
        2  : {
            id : 'MAESTRIA',
            name : 'MAESTRÍA'
        },
        3 : {
            id : 'DIPLOMADO',
            name : 'DIPLOMADO'
        },
        4 : {
            id : 'LICENCIADO',
            name : 'LICENCIADO'
        },
        5 : {
            id : 'BACHILLER',
            name : 'BACHILLER'
        },
        6 : {
            id : 'SEGUNDA ESPECIALIDAD',
            name : 'SEGUNDA ESPECIALIDAD'
        }
    },
    LANGUAGES : {
        1 : {
            id : 1,
            name : 'MUY BIEN'
        },
        2  : {
            id : 2,
            name : 'BIEN'
        },
        3 : {
            id : 3,
            name : 'REGULAR'
        }
    },
    EVENTS : {
        1 : {
            id : 1,
            name : 'FC-01-2019-IESU-IDi : MEJORA DE LA GESTIÓN DE LA INVESTIGACIÓN, DESARROLLO E INNOVACIÓN EN UNIVERSIDADES PÚBLICAS'
        },
        2  : {
            id : 2,
            name : 'FB-03-2019-IESU-SISTEMAS : MEJORAMIENTO DE LA GESTIÓN DE LOS SISTEMAS ADMINISTRATIVOS Y DE INFORMACIÓN DE LAS UNIVERSIDADES, A TRAVÉS DE LA IMPLEMENTACIÓN DEL SISTEMA INTEGRADO DE GESTIÓN ACADÉMICA UNIVERSITARIA'
        },
        3 : {
            id : 3,
            name : 'FC-02-2019-IEST-GA : MEJORA O EL IMPULSO DE REFORMAS ACADÉMICAS EN INSTITUTOS DE EDUCACIÓN SUPERIOR TECNOLÓGICOS PÚBLICOS'
        },
        4 : {
            id : 4,
            name : 'FC-04-2O19-IEST-SISTEMAS RC : REGISTRA Y CONECTA'
        },
        7 : {
            id : 7,
            name : 'FC-05-2O20 MEJORES CONDICIONES DE CALIDAD DE LA GESTIÓN ACADÉMICA EN LOS IEST PÚBLICOS'
        },
        6 : {
            id : 6,
            name : 'FC-06-2O20 MEJORA DE LA GESTIÓN ACADÉMICA Y PEDAGÓGICA DE LAS UNIVERSIDADES PÚBLICAS'
        },
        8 : {
            id : 8,
            name : 'FC-07-2O20 PROGRAMA DE CAPACITACIÓN PARA EL FORTALECIMIENTO DE CAPACIDADES ACADÉMICAS Y PEDAGÓGICAS DE LOS IEST/IES'
        },
        9 : {
            id : 9,
            name : 'FC-08-2O20 PROGRAMA DE APOYO AL DISEÑO E IMPLEMENTACIÓN DE ESTRATEGIAS PARA LA CONTINUIDAD DEL SERVICIO EDUCATIVO SUPERIOR DE LAS UNIVERSIDADES PÚBLICAS'
        },
        10 : {
            id : 10,
            name : 'FC-07-2O20 PROGRAMA DE APOYO PARA EL DESARROLLO DE CAPACIDADES DEL SERVICIO EDUCATIVO NO PRESENCIAL EN LA EDUCACIÓN SUPERIOR TECNOLÓGICA PÚBLICA'
        },
        5 : {
            id : 5,
            name : 'ADMINISTRATIVO'
        },
    },
    RESPONSABLES : {
        72 : {
            id : 72,
            name : 'RONY'
        },
        71  : {
            id : 71,
            name : 'ROCIO'
        },
        109 : {
            id : 109,
            name : 'JULIO'
        },
        977 : {
            id : 977,
            name : 'LEONCIO'
        },
        976 : {
            id : 976,
            name : 'ELENA'
        }
    },
    MARITAL_STATUS : {
        'S' : {
            id : 'S',
            name : 'SOLTERO'
        },
        'C'  : {
            id : 'C',
            name : 'CASADO'
        },
        'D' : {
            id : 'D',
            name : 'DIVORCIADO'
        },
        'V' : {
            id : 'V',
            name : 'VIUDO'
        }
    },
    CONTRACT_STATUS : [
        {
            id: '300100',
            name: 'Contrato en Elaboración'
        },
        {
            id: '300200',
            name: 'Contrato en Ejecución'
        },
        {
            id: '300300',
            name: 'Contrato Finalizado'
        },
        {
            id: '300400',
            name: 'Contrato Cancelado'
        },
    ]
};