export const routes = {
    LOGIN: '/auth/login',
    REGISTER : '/auth/register',
    FORGOT_PASSWORD: '/auth/forgot-password',
    FORGOT_PASSWORD_SUCCESS: '/auth/forgot-password-success',
    RESET_PASSWORD: '/auth/reset-password',
    PROFILE: '/profile',
    DASHBOARD : '/dashboard',    
    USERS : '/users',
    TEST : '/test',
    CONTRACTS : '/contracts',
    DELIVERABLES : '/deliverables',
    PRODUCTS : '/products',
    INSTITUTIONS : '/institutions',
    UNAUTHORIZED : '/unauthorized',
    CHANGE_PASWORD: '/change-password',
   /* PROVIDER */
    PROVIDER : {
        DASHBOARD : '/provider/dashboard',
        DELIVERABLES : '/provider/deliverables',
        CV : '/provider/curriculum',
        FINAL_REPORTS : '/provider/finalReports',
        CONTRACTS: '/provider/contracts'
    },

    /* EVALUATOR */
    SUPERVISOR : {
        DASHBOARD : '/supervisor/dashboard',
        NEWDASHBOARD: '/supervisor/newdashboard',
        DELIVERABLES : '/supervisor/deliverables',
        SHOW_INFORMES_AND_OBSERVATIONS : '/supervisor/show-informes-and-observations'
    },

   CORDINATOR : {
        USERS : '/cordinator/users',
        CONTRACTS : '/cordinator/contracts',
        DETAIL_DELIVERABLE : '/cordinator/detail-deliverable'
   }
};