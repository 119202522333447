import React, { Component } from 'react';
import _ from 'lodash';

class Select extends Component
{
    constructor(props)
    {
        super(props);
    }

    handleChange = (event) => {
        this.props.onChange(event.target.value);
    };

    render()
    {
        const props = this.props;

        const hasError = this.props.hasErrors || false;

        return (
            <select 
                className={`form-control kt-selectpicker ${ hasError ? 'is-invalid' : '' }`}
                data-size="7" 
                data-live-search="true" 
                onChange={this.handleChange.bind(this)} 
                required={this.props.required ? this.props.required : false}
            >
                <option value="">{this.props.text}</option>
                { _.map(this.props.value, (props, index) => <Option key={index} {...props} selected={this.props.selected} />) }
            </select>
        )
    }
}

const Option = (props) => <option value={props.id} selected={props.id == props.selected} >{props.name}</option>;

export default Select;