import React, { useState } from "react";
import PropTypes from "prop-types";

import ReactResumableJs from "./ReactResumableJs";
import "./index.css";
import { settings } from "../../../utils/settings";

// Doc: http://resumablejs.com/
// Doc: https://github.com/Artear/ReactResumableJS
const ResumableUploadFile = ({
  maxFileSizeGB = 1,
  chunkSizeMB = 1,
  simultaneousUploads = 1,
  apiURL = `${settings.BASE_URL}/files/uploadChunk`,
  id = "file",
  textLabel="",
  onFileSuccess
}) => {
  const maxFileSizeMB = maxFileSizeGB * 1024 + "mb";
  const chunkSizeBytes = chunkSizeMB * 1024 * 1024;

  return (
    <div className="d-flex align-items-center">
      <ReactResumableJs
        chunkSize={chunkSizeBytes}
        simultaneousUploads={simultaneousUploads}
        testChunks={false}
        service={apiURL}
        headerObject={{
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }}
        uploaderID={id}
        maxFileSize={maxFileSizeMB}
        fileAddedMessage="Started!"
        completedMessage="Complete!"
        onFileAdded={() => {}}
        maxFiles={10}
        startButton={true}
        pauseButton={true}
        cancelButton={true}
        onStartUpload={() => {}}
        onCancelUpload={() => {}}
        onPauseUpload={() => {}}
        onResumeUpload={() => {}}
        onFileSuccess={(file, fileServer) => {
          onFileSuccess(fileServer)
        }}
        label={textLabel}
      />
    </div>
  );
};

ResumableUploadFile.propTypes = {
  label: PropTypes.string.isRequired,
};

export default ResumableUploadFile;
