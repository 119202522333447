import React, { useState } from "react";

import * as ContractsActionsCreators from "Ducks/contracts/operations";
import Modal from "../../components/Modal/Modal";
import Input from "../../components/Input";
import FileIcon from "../../components/FileIcon/FileIcon";
import File from "../../components/File";
import { useDispatch } from "react-redux";

const AddAdenda = ({ handleClose, handleSubmit, contract }) => {
  const [adenda, setAdenda] = useState({
    adenda_number: "",
    description: "",
    suscription_date: "",
    enddate: "",
    amount: "",
    _filename_documento_adenda: ''
  });

  const dispatch = useDispatch();

  const onHandleChange = (e) => {
    const { name, value } = e.target;

    setAdenda({
      ...adenda,
      [name]: value,
    });
  };

  const onHandleChangeFile = async (e) => {
    const { name, files } = e.target;
    let urlFile = await dispatch(ContractsActionsCreators.submitFile(files[0]));
    setAdenda({ ...adenda, [name]: urlFile });
  };

  const RequiredFieldsNotFilled =
    adenda.adenda_number === "" ||
    adenda.description === "" ||
    adenda.suscription_date === "" ||
    adenda.amount === "";

  return (
    <Modal
      show
      title="Agregar Adenda"
      handleClose={handleClose}
      handleSubmit={() => handleSubmit(adenda)}
      disabledSubmitButton={RequiredFieldsNotFilled}
    >
      <div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Número</label>
              <Input
                type="number"
                min="1"
                name="adenda_number"
                value={adenda.adenda_number}
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group">
              <label>Descripción</label>
              <textarea
                name={`description`}
                className="form-control"
                rows="6"
                value={adenda.description}
                onChange={onHandleChange}
              ></textarea>
            </div>
            <div className="form-group">
              <label>Fecha de suscripción</label>
              <Input
                type="date"
                name="suscription_date"
                value={adenda.suscription_date}
                onChange={onHandleChange}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Monto</label>
              <Input
                type="number"
                name="amount"
                value={adenda.amount}
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group">
              <label>Fecha Fin</label>
              <Input
                type="date"
                name="enddate"
                value={adenda.enddate}
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group">
              <label>Documento</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    {adenda.document_adenda ? (
                      <FileIcon
                        href={adenda.document_adenda.url}
                        text="DOC"
                        icon="pdf"
                      />
                    ) : (
                      "DOC"
                    )}
                  </span>
                </div>
                <File
                  name="_filename_documento_adenda"
                  onChange={onHandleChangeFile}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddAdenda;
