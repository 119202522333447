import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import _ from 'lodash';
const queryString = require('query-string');


class ForgotPasswordSuccess extends Component
{
	query = {};
	constructor(props) 
	{
		super(props);
		this.state = {	
			message: ''	
		};
		this.query = queryString.parse(window.location.search);  
	}

	componentDidMount()
	{
		console.log(this.props);
		const { state } = this.props.location;
		console.log(state);
        this.setState({message: state.message});
	}

    render()
    {
		const {message} = this.state;
        const bg = {
            backgroundImage: 'url(/assets/img/bg-3.jpg)',
		};

		
        return (
			<div class="kt-grid kt-grid--ver kt-grid--root" style={{ height : '100%' }}>
            <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login" style={bg}>
				<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"    >
					<div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
						<div class="kt-login__container">
							<div class="kt-login__logo">
								<a href="javascript:void(0)">
									<img src="/assets/img/logo.png" />  	
								</a>
							</div>
							<div class="kt-login__signin">
								<div class="kt-login__head">
									<h3 class="kt-login__title">{message}</h3>
								</div>
								<form class="kt-form">
									<div class="kt-login__actions">										
										<a href="/" type="button" className={'btn btn-brand btn-success btn-elevate'}>Salir</a>									
									</div>
								</form>
							</div>														
						</div>	
					</div>
				</div>
			</div>
		</div>
        )
    }
}

const mapStateToProps = (state) => ({
    user : state.user,
    api : state.api
});


export default connect(mapStateToProps, null)(ForgotPasswordSuccess);