import { constants } from "./types";
import createReducer  from "Utils/createReducer";

const initialState = {
    dashboard : {
        statusReview : [],
        statusExpiration : []
    }
};

const dashboardReducer = createReducer( initialState )( {
    [ constants.SET_STATUS_REVIEW ]: (state, action) => {
        return  { ...state, dashboard : { ...state.dashboard, statusReview : action.data } };
    },
    [ constants.SET_STATUS_EXPIRATION ]: (state, action) => {
        return  { ...state, dashboard : { ...state.dashboard, statusExpiration : action.data } };
    }
} );

export default dashboardReducer;
