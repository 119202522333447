import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import FormError from "../../Layout/errors/FormError";
import InformacionGeneral from "../Shared/InformacionGeneral";
import Entregables from "../Shared/Entregables";
import Detalle from "../Shared/Detalle";
import AgregarProveedor from "../Shared/AgregarProveedor";
import * as EventsActionsCreators from "Ducks/events/operations";
import * as ProvidersActionsCreators from "Ducks/providers/operations";
import * as ContractsActionsCreators from "Ducks/contracts/operations";
import * as SupervisorActionsCreators from "Ducks/supervisors/operations";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import _ from "lodash";
import Tabs from "../../components/Tabs/Tabs";

const EditContract = () => {
  const location = useLocation();

  const [formLoaded, setFormLoaded] = useState(false);
  const [editContract, setEditContract] = useState({});
  const [formErrorContract, setFormErrorContract] = useState({});
  const dispatch = useDispatch();

  // Render Inicial
  useEffect(() => {
    const { contract } = location.state;
    let { evaluadores, ..._contract } = contract;
    let deliverables = [];

    if (_contract.deliverables) {
      deliverables = _contract.deliverables.map((deliverable) => ({
        ...deliverable,
        percentaje_amount: deliverable.percentaje_amount * 100,
      }));
      _contract.deliverables = deliverables;
    }

    _contract = {
      ..._contract,
      evaluators: contract.evaluadores.map((evaluador) => evaluador.id),
    };
    setEditContract(_contract);

    dispatch(EventsActionsCreators.getEvents());
    dispatch(ProvidersActionsCreators.getProviders(false));
    dispatch(SupervisorActionsCreators.getEvaluators());

    setFormLoaded(true);
  }, []);

  // Cuando cambia los valores del monto del contrato
  useEffect(() => {
    if (!editContract.deliverables) return;

    const { deliverables } = editContract;

    let deliverablesWithNewAmount = deliverables.map((deliverable) => ({
      ...deliverable,
      amount: (deliverable.percentaje_amount / 100) * editContract.amount,
    }));

    setEditContract({
      ...editContract,
      deliverables: deliverablesWithNewAmount,
    });
  }, [editContract.amount]);

  // Cuando cambia los valores de la fecha de firma
  useEffect(() => {
    if (!editContract.deliverables) return;

    const { deliverables } = editContract;

    let deliverablesWithNewDeliveryDate = deliverables.map((deliverable) => ({
      ...deliverable,
      delivery_date: moment(editContract.signature_date, "YYYY-MM-DD")
        .add(deliverable.delivery_day, "days")
        .format("YYYY-MM-DD"),
    }));

    setEditContract({
      ...editContract,
      deliverables: deliverablesWithNewDeliveryDate,
    });
  }, [editContract.signature_date]);

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    const contract = _.omit(editContract, ["provider"]);
    const response = await dispatch(
      ContractsActionsCreators.editContract(contract)
    );
    if (response) {
      setFormErrorContract(response.data.errors);
    }
  };

  const inputHasErrors = (name) => {
    const errors = { ...formErrorContract };
    return Object.keys(errors).includes(name);
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setEditContract({
      ...editContract,
      [name]: value,
    });
  };

  const onHandleChangeProviders = (e) =>
    setEditContract({ ...editContract, provider_id: e.value });

  const onHandleChangeEvaluators = (e) => {
    const evaluators = e;
    const evaluatorsIds = evaluators.map((evaluator) => evaluator.value);
    setEditContract({ ...editContract, evaluators: evaluatorsIds });
  };

  const onHandleChangeFile = async (e) => {
    const { name, files } = e.target;
    let urlFile = await dispatch(ContractsActionsCreators.submitFile(files[0]));
    setEditContract({ ...editContract, [name]: urlFile });
  };

  const errorMessageFormByInput = (name) => {
    if (!inputHasErrors(name)) return;
    const errors = { ...formErrorContract };
    return errors[name][0];
  };

  const onAddDeliverable = () => {
    let deliverables = editContract.deliverables;
    deliverables.push({
      order: deliverables.length + 1,
      name: `Entregable ${deliverables.length + 1}`,
      description: "",
      delivery_day: 1,
      delivery_date: moment(editContract.signature_date, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD"),
      percentaje_amount: 0,
      amount: 0,
      is_penalty: 0,
      penalty_amount: 0,
    });
    setEditContract({ ...editContract, deliverables: deliverables });
  };

  const onRemoveDeliverable = (index) => {
    let deliverables = editContract.deliverables;
    deliverables.splice(index, 1);
    setEditContract({ ...editContract, deliverables: deliverables });
  };

  const onHandleChangeDeliverables = (e) => {
    const { name, value } = e.target;
    const property = name.split(".")[0];
    const index = name.split(".")[1];
    let deliverables = editContract.deliverables;
    let deliverable = { ...deliverables[index], [property]: value };
    deliverable.name = `Entregable ${deliverable.order}`;
    deliverable.delivery_date = moment(
      editContract.signature_date,
      "YYYY-MM-DD"
    )
      .add(deliverable.delivery_day, "days")
      .format("YYYY-MM-DD");
    deliverable.amount =
      (deliverable.percentaje_amount / 100) * editContract.amount;
    deliverables[index] = deliverable;
    setEditContract({ ...editContract, deliverables: deliverables });
  };

  const formHasErrors = () => {
    const errors = formErrorContract;
    return Object.values(errors).length > 0;
  };

  const updateNewProvider = (provider_id) => {
    setEditContract({
      ...editContract,
      provider_id: provider_id,
    });
  };

  if (!formLoaded) return <div>Loading ...</div>;

  return (
    <React.Fragment>
      <form class="kt-form kt-form--label-right" onSubmit={onHandleSubmit}>
        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">Editar Contrato</h3>
            </div>
            <Button
              type="submit"
              name="Editar"
              classStyles={{ "btn-primary": true, "my-3": true }}
            />
          </div>
          <div class="kt-portlet__body">
            <FormError
              show={formHasErrors()}
              errors={Object.values(formErrorContract)}
            />
            <Tabs>
              <div name="Información General">
                <InformacionGeneral
                  {...editContract}
                  onHandleChange={onHandleChange}
                  onHandleChangeProviders={onHandleChangeProviders}
                  inputHasErrors={inputHasErrors}
                  updateNewProvider={updateNewProvider}
                />
              </div>
              <div name="Detalle">
                <Detalle
                  {...editContract}
                  onHandleChange={onHandleChange}
                  onHandleChangeEvaluators={onHandleChangeEvaluators}
                  onHandleChangeFile={onHandleChangeFile}
                  inputHasErrors={inputHasErrors}
                  errorMessageFormByInput={errorMessageFormByInput}
                />
              </div>
              <div name="Entregables">
                <Entregables
                  {...editContract}
                  onAddDeliverable={onAddDeliverable}
                  onRemoveDeliverable={onRemoveDeliverable}
                  onHandleChangeDeliverables={onHandleChangeDeliverables}
                />
              </div>
            </Tabs>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

EditContract.propTypes = {};

export default EditContract;
