import {  constants } from "./types";
import { createRequest } from "Utils/createRequest";
import history from 'Utils/history';
import { routes } from 'Utils/constants';

export const setInstitutions = (institutions) => 
({
    type: constants.SET_INSTITUTIONS,
    institutions
});

export function getInstitutions() {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'GET',
          url: `/institutions`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_INSTITUTIONS
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        //Redirect to Dashboard
        dispatch(setInstitutions(data.data));
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};