import React, {Component} from 'react';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import Product from './Product';
import FinalReport from './FinalReport';
import Observation from './Observation';
import { settings } from 'Utils/settings';
//import Progress from './Progress';

class EditMeansOfVerification extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            progress : 0,
            informe : {},
            os : 0,
            ficha_informe : ''
        };

        const { dispatch } = props;

        this.providersActionsCreators = bindActionCreators(ProvidersActionsCreators, dispatch);
    }

    componentWillMount()
    {
        const { pathname, search, hash, state } = this.props.location;

        //this.setState({ ...state.deliverable });
        this.setState({ informe : {...state.informe }, os : state.os, ficha_informe : state.ficha_informe});
    }

    componentDidMount()
    {
        const { match } = this.props;

        //localStorage.setItem("informe_id", match.params.id);
        this.providersActionsCreators.getProductsAndMeansOfVerifications(match.params.id);
    }

    render()
    {
        const { products, verifications, deliverable, contract, api } = this.props;

        const { informe, os, ficha_informe } = this.state;

        console.log("INFORME: ", informe, deliverable);

        return(
            <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div class="col-xl-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__body kt-portlet__body--fit">
                        <div class="row row-no-padding row-col-separator-xl">
                            <div class="col-md-12">
                                <div class="kt-widget1">
                                    <div class="kt-widget1__item">
                                        <div class="kt-widget1__info">
                                            <h3 class="kt-widget1__title">CONTRATO</h3>
                                            <span class="kt-widget1__desc">{ contract?.concept }</span>
                                            {contract?.contract_type_id == 1 && <span class="kt-widget1__desc kt-margin-t-10 d-block">N° contrato: <strong>{ contract.contract_number }</strong></span>}
                                            {contract?.contract_type_id == 2 && <span class="kt-widget1__desc kt-margin-t-10 d-block">Orden de servcio: <strong>{ contract.order }</strong></span>}
                                        </div>
                                        {/*<span class="kt-widget1__number kt-font-success">{ deliverable.contract.contract_number }</span>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                    <div class="kt-portlet kt-portlet--tabs">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">
                                    Entregable N° {deliverable?.order}
                                </h3>
                            </div>
                            <div class="kt-portlet__head-toolbar">
                                    
                            </div>
                        </div>
                        <div class="kt-portlet__body">
                            <p>{deliverable?.description}</p>
                        </div>
                    </div>	
				</div>

                <div class="row">
                    <div class="col-lg-12">
                        {/*{ <Progress /> }*/}
                        <div class="row">
                            { _.includes([ settings.STATUS_INFORME[3].id, settings.STATUS_INFORME[4].id ], informe.status_review) && informe.observation && <Observation informe={informe} /> }
                            <FinalReport verifications={verifications} informe={informe} os={os} ficha_informe={ficha_informe} />
                            {_.map(products, (product) => <Product key={product.id} product={product} /> )}
                        </div>
                    </div>
                </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    contract: state.providers.contract,
    deliverable : state.providers.deliverable,
    products : state.providers.products,
    verifications : state.providers.verifications,
    api : state.api
});

export default connect(mapStateToProps, null)(EditMeansOfVerification);