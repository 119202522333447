import React, { Component } from 'react';
import { Input } from 'Components';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as UserActionsCreators from 'Ducks/user/operations';
import classNames from 'classnames/bind';
import 'react-notifications/lib/notifications.css';
import { default as LayoutLogin } from 'Views/Layout/types/Login';
import { configureStore } from 'Utils/createStore';
import Apigproc from '../../services/apigproc';
import {getIn} from 'formik';
import _ from 'lodash';
import {SplashScreenLoading} from 'Components';
import buildNotification from 'Components/Notification';

const { store, persistor } = configureStore();
const queryString = require('query-string');
class ResetPassword extends Component {
	query = {};
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			password_confirmation: '',
			result: null,
			errors: null,
			loading: false
		};

		const { dispatch } = props;
		this.query = queryString.parse(window.location.search);
		this.userActionsCreators = bindActionCreators(UserActionsCreators, dispatch);

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		const { dispatch } = this.props;

		persistor.purge();

		this.userActionsCreators.resetLogout();
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	keyPressed = (event) => {
		if (event.key === "Enter") {
			this.handleSubmit();
		}
	};

	handleSubmit = () => {
		this.setState({loading: true});
		const data = {
			token: this.query.token,
			password: this.state.password,
			password_confirmation: this.state.password_confirmation
		};

		Apigproc.auth.passwordReset(data).then((response) => {

			buildNotification('success', getIn(response, 'data.message'), 'Éxito');
			console.log(response);
			this.props.history.push('/auth/login');
			
		}).catch((error) => {
			
			const errors = getIn(error, 'response.data.errors');

			if(errors){
				buildNotification('warning', getIn(error, 'response.data.message'), 'Alert');
				this.setState({errors: errors});
			}else{
				buildNotification('error', getIn(error, 'response.data.message'), 'Error');
				this.props.history.push('/auth/login');
			}

		}).finally(() => {
			this.setState({loading: false});
		});

	};
	//this.userActionsCreators.auth(this.state, this.query);

	render() {
		const bg = {
			backgroundImage: 'url(/assets/img/bg-3.jpg)',
		};

		const { api } = this.props;

		let styles = classNames(
			'btn', 'btn-brand', 'btn-success', 'btn-elevate', api.loading ? 'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light' : ''
		);

		return (
			<div class="kt-grid kt-grid--ver kt-grid--root" style={{ height: '100%' }}>
				<SplashScreenLoading loading={this.state.loading} />
				<div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login" style={bg}>
					<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"    >
						<div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
							<div class="kt-login__container">
								<div class="kt-login__logo">
									<a href="javascript:void(0)">
										<img src="/assets/img/logo.png" />
									</a>
								</div>
								<div class="kt-login__signin">
									<div class="kt-login__head">
										<h3 class="kt-login__title">Registra tu nueva contraseña?</h3>
									</div>

									{this.state.result?.errors && <div className="alert alert-danger" role="alert">
										{this.state.result.message}
									</div>}

									<form class="kt-form">
										<div class="input-group">
											{<Input
												classStyles={{ 'dixonet': true }}
												label="DIXONET"
												placeholder="Nueva contraseña"
												type="password"
												name="password"
												onKeyPress={this.keyPressed}
												value={this.state.password}
												onChange={this.handleChange}
											/>}
											
											<div className="error">
												<ul>
												{_.map(getIn(this.state.errors, 'password', []), (error, i) => <li key={i} >{error}</li>)}
												</ul>
											</div>
										</div>
										

										<div class="input-group">
											{<Input
												classStyles={{ 'dixonet': true }}
												label="DIXONET"
												placeholder="Repetir nueva contraseña"
												type="password"
												name="password_confirmation"
												onKeyPress={this.keyPressed}
												value={this.state.password_confirmation}
												onChange={this.handleChange}
											/>}
										</div>
										<div class="kt-login__actions">
											<a href="javascript:void(0)">
												<button onClick={this.handleSubmit} type="button" className={styles}>Gurdar nueva contraseña</button>&nbsp;
										</a>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	api: state.api
});


export default connect(mapStateToProps, null)(ResetPassword);