import _ from 'lodash';
import {  constants } from "./types";
import { createRequest } from "Utils/createRequest";
import history from 'Utils/history';
import { routes } from 'Utils/constants';
import { settings } from 'Utils/settings';
import * as ProvidersOperations from 'Ducks/providers/operations';

export const setDeliverables = (deliverables) => 
({
    type: constants.SET_DELIVERABLES,
    deliverables
});

export function getDeliverables(numberPage = 1, parameters) {
  return async dispatch => {
    try {
      let queryString = "";

      queryString = `&page=${numberPage}
        ${_.has(parameters, 'responsable') ? `&responsable_name=${parameters.responsable}` : ``}
        ${_.has(parameters, 'provider') ? `&provider_name=${parameters.provider}` : ``}
        ${_.has(parameters, 'type_contract') ? `&provider_name=${parameters.provider}` : ``}
        ${_.has(parameters, 'order') ? `&order=${parameters.order}` : ``}
        ${_.has(parameters, 'contract_number') ? `&contract_number=${parameters.contract_number}` : ``}
      `;

      const params = {
        requestParams: {
          method: 'GET',
          url: `/proyects/${settings.PROJECT_ID}/contract_deliveries?with=documentCompliance,contract,contract.contractType,contract.documentContract,provider,evaluadores${queryString}`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
          //params: query
        },
        requestId: constants.GET_DELIVERABLES
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        //Redirect to Dashboard
        dispatch(setDeliverables(data));
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function createDeliverable(deliverable) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'POST',
          url: `/proyects/${settings.PROJECT_ID}/contract_deliveries`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : deliverable
          //params: query
        },
        requestId: constants.CREATE_DELIVERABLE
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        history.push(routes.DELIVERABLES);
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};

export function editDeliverable(deliverable) {
  return async dispatch => {
    try {
      const params = {
        requestParams: {
          method: 'PUT',
          url: `/proyects/${settings.PROJECT_ID}/contract_deliveries`,
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data : deliverable
          //params: query
        },
        requestId: constants.CREATE_DELIVERABLE
      };

      const res = await dispatch(createRequest(params));
      
      const response = res.data;

      const { status, data } = response;

      if(status === 'Success')
      {
        history.push(routes.DELIVERABLES);
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};


/* ACTIONS FOR PROVIDER */
export function getDeliverablesForProviders(numberPage = 1, statusReview = null) {
  return async dispatch => {
    try {

      let providersByUser = await dispatch(ProvidersOperations.getProvidersByUser());

      if((providersByUser.length > 0)){
        let provider_id =  _.head(providersByUser).id;

        const params = {
          requestParams: {
            method: 'GET',
            url: `/providers/${provider_id}/contract_deliveries?with=products,contract.documentContract,contract.documentTdr,event&page=${numberPage}${statusReview ? `&status_review=${statusReview}` : ''}`,
            headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
            //params: query
          },
          requestId: constants.GET_DELIVERABLES_FOR_PROVIDER
        };

        const res = await dispatch(createRequest(params));
        
        const response = res.data;

        const { status, data } = response;

        if(status === 'Success')
        {
          //Redirect to Dashboard
          dispatch(setDeliverables(data));
        }
      }else{
        dispatch(setDeliverables({
          data: [],
          current_page: 0,
          from: 0,
          last_page: 0,
          per_page: 0,
          prev_page_url: null,
          to: 0,
          total: 0,
        }));
      }
    } catch (error) 
    {
      console.log("ERROR: ", error);
    }
  }
};