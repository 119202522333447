import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Router, Route, Switch, Link } from 'react-router-dom';
import history from 'Utils/history';
import Layout from 'Views/Layout';
import Dashboard from 'Views/Dashboard';
import PrivateRoute from 'Routes/PrivateRoute';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { settings } from 'Utils/settings';

//import UserRoutes from 'Ducks/user/routes';
//import UsersRoutes from 'Ducks/users/routes';
import ContractsRoutes from 'Ducks/contracts/routes';
import DeliverablesRoutes from 'Ducks/deliverables/routes';
import ProductsRoutes from 'Ducks/products/routes';
import Login from 'Views/Auth/index';
import ForgotPassword from 'Views/Auth/forgotPassword';
import ForgotPasswordSuccess from 'Views/Auth/forgotPasswordSuccess';
import ResetPassword from 'Views/Auth/resetPassword';
import Register from 'Views/Register/index';
import Profile from 'Views/Layout/profile';
import Users from 'Views/Users/index';
import Institutions from 'Views/Institutions/index';
// import NewContract from '../views/Contracts/NewContract';
import CreateContract from '../views/Contracts/CreateContract';
import EditContract from '../views/Contracts/EditContract';
import EditDeliverable from 'Views/Deliverables/editDeliverable';
import NewDeliverable from 'Views/Deliverables/newDeliverable';
import NewProduct from 'Views/Products/newProduct';
import EditProduct from 'Views/Products/editProduct';
import { routes } from 'Utils/constants';

import SagaPage from 'Views/Sepa'
/* PROVIDER */
import DashboardProvider from 'Views/Provider/dashboard';
import DeliverablesProvider from 'Views/Provider/deliverables';
import EditDeliverablesProvider from 'Views/Provider/deliverables/edit';
import CreateMeansOfVerificationProvider from 'Views/Provider/meansOfVerification/createMeansOfVerification';
import EditMeansOfVerificationProvider from 'Views/Provider/meansOfVerification/editMeansOfVerification';
import Cv from 'Views/Provider/cv';
import FinalReports from 'Views/Provider/finalReports';
import ContractsProvider from 'Views/Provider/contracts/ContractsProvider';
import ChangePassword from 'Views/Provider/cv/ResetPassword';

/* SUPERVISOR */
import DeliverablesSupervisor from 'Views/Supervisor/deliverables';
import InformesSupervisor from 'Views/Supervisor/deliverables/informes';
import EditDeliverableSupervisor from 'Views/Supervisor/deliverables/edit';
import DashboardSupervisor from 'Views/Supervisor/dashboard';
import NewDashboardSupervisor from 'Views/Supervisor/newdashboard';

/* CORDINATOR */
import ContractsCordinator from 'Views/Cordinator/contracts';
import DetailDeliverable from 'Views/Cordinator/detailDeliverable';


import WorkJobs from 'Views/RegistroConsultores/workJobs'
//import WorkJob from 'Views/RegistroConsultores/workJob'
//import WorkJobPostulation from 'Views/RegistroConsultores/workJobPostulation'
import { default as roles } from 'Routes/roles/';
import { hasRole, isAllowed } from "Utils/auth";

import NotFound from 'Views/Layout/errors/404';
import NewUser from '../views/Users/newUser';
import UploadFile from '../views/UploadFile';

class Routes extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {

        const { authorities, isAuthenticated } = this.props;
        console.log("Routes this.props", this.props);
        return (
            <Fragment>
                <Router history={history}>
                  <Switch>
                        {/*{ _.includes(authorities, 'PROYECT::SYSADMIN') && <SysadminRoutes /> }*/}
                        
                        <Route exact path={routes.LOGIN} component={Login} />
                        <Route exact path={routes.REGISTER} component={Register} />
                        <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
                        <Route exact path={routes.FORGOT_PASSWORD_SUCCESS} component={ForgotPasswordSuccess} />                        
                        <Route exact path={routes.RESET_PASSWORD} component={ResetPassword} />
                        {!isAuthenticated && <Redirect from="/" to={routes.LOGIN} />}

                        <Layout>
                          { (hasRole(roles.admin, authorities) || true) &&                            
                              <>
                              <Route path="/sepa" component={SagaPage} />
                              <PrivateRoute exact path={routes.PROFILE} component={Profile} />
                              
                              <PrivateRoute path={routes.DASHBOARD} component={Dashboard} />                              

                              <PrivateRoute path={routes.USERS} component={Users} />
                              <PrivateRoute path="/new-user" component={NewUser} />

                              <PrivateRoute path={routes.INSTITUTIONS} component={Institutions} />

                              <PrivateRoute path={routes.CONTRACTS} component={ContractsRoutes} />
                              <PrivateRoute path="/new-contract" component={CreateContract} />
                              <PrivateRoute path="/edit-contract" component={EditContract} />

                              <PrivateRoute path={routes.DELIVERABLES} component={DeliverablesRoutes} />
                              <PrivateRoute path="/new-deliverable" component={NewDeliverable} />
                              <PrivateRoute path="/edit-deliverable" component={EditDeliverable} />

                              <PrivateRoute path={routes.PRODUCTS} component={ProductsRoutes} />
                              <PrivateRoute path="/new-product" component={NewProduct} />
                              <PrivateRoute path="/edit-product" component={EditProduct} />
                              <PrivateRoute path="/upload-file" component={UploadFile} />                       
                            </>
                          }                          

                          { hasRole(roles.supervisor, authorities) &&
                            <>

                              <PrivateRoute path={routes.CORDINATOR.USERS} component={Users} />

                              <PrivateRoute path={routes.SUPERVISOR.NEWDASHBOARD} component={NewDashboardSupervisor} />

                              <PrivateRoute exact path={routes.PROFILE} component={Profile} />

                              <PrivateRoute path={routes.SUPERVISOR.DASHBOARD} component={DashboardSupervisor} />

                              <PrivateRoute exact path={routes.CORDINATOR.CONTRACTS} component={ContractsCordinator} />
                              <PrivateRoute path={routes.CORDINATOR.DETAIL_DELIVERABLE} component={DetailDeliverable} />


                              <PrivateRoute path={routes.SUPERVISOR.DELIVERABLES} component={DeliverablesSupervisor} />
                              
                              <PrivateRoute path={routes.SUPERVISOR.SHOW_INFORMES_AND_OBSERVATIONS} component={EditDeliverableSupervisor} />

                            </>
                          }

                          { hasRole(roles.cordinator, authorities) &&
                            <>

                              <PrivateRoute path={routes.CORDINATOR.USERS} component={Users} />

                              <PrivateRoute path={routes.SUPERVISOR.NEWDASHBOARD} component={NewDashboardSupervisor} />

                              <PrivateRoute exact path={routes.CORDINATOR.CONTRACTS} component={ContractsCordinator} />

                              <PrivateRoute path={routes.SUPERVISOR.DELIVERABLES} component={DeliverablesSupervisor} />

                              <PrivateRoute path={routes.SUPERVISOR.SHOW_INFORMES_AND_OBSERVATIONS} component={InformesSupervisor} />

                              <PrivateRoute path={routes.CORDINATOR.DETAIL_DELIVERABLE} component={DetailDeliverable} />

                            </>
                          }

                          
                            <>
                              {/*<PrivateRoute exact path={'/puestos-de-tabajo'} component={WorkJobs} />
                              <PrivateRoute exact path={'/puestos-de-tabajo/:id'} component={WorkJob} />
                              <PrivateRoute exact path={'/puestos-de-tabajo/:id/postulacion'} component=  {WorkJobPostulation} />*/}
                              {/*<Redirect
                                exact={true}
                                from="/"
                                to={routes.PROVIDER.CONTRACTS}
                              />*/}
                              
                              <PrivateRoute exact path={routes.PROFILE} component={Profile} />

                              <PrivateRoute path={routes.PROVIDER.DASHBOARD} component={DashboardProvider} />

                              <PrivateRoute path={routes.PROVIDER.DELIVERABLES} component={DeliverablesProvider} />

                              <PrivateRoute path="/provider/edit-deliverables-provider" component={EditDeliverablesProvider} />

                              <PrivateRoute path="/provider/create-means-of-verification" component={CreateMeansOfVerificationProvider} />

                              <PrivateRoute path="/provider/edit-means-of-verification/:id" component={EditMeansOfVerificationProvider} />

                              <PrivateRoute path={routes.PROVIDER.CONTRACTS} component={ContractsProvider} />

                              <PrivateRoute path={routes.PROVIDER.FINAL_REPORTS} component={FinalReports} />

                              <PrivateRoute path={routes.PROVIDER.CV} component={Cv} />

                              <PrivateRoute path={routes.CHANGE_PASWORD} component={ChangePassword} />
                              
                            </>
                          

                          </Layout>

                          <Route component={NotFound} />

                          {/*<Route path={routes.LOGIN} component={Login} />*/}
                      
                  </Switch>
                </Router>
                <NotificationContainer/>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user,
  authorities : state.user.authorities,
});

export default connect(mapStateToProps, null)(Routes);
