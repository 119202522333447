import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import Button from 'Components/Button';
import * as UserActionsCreators from 'Ducks/user/operations';
import { configureStore } from 'Utils/createStore';
import { settings } from 'Utils/settings';
import { routes } from 'Utils/constants';

const {store, persistor } = configureStore();

const Header = ({ user, api, dispatch }) => {

    const [dropdownUser, setDropdownUser] = useState(false);

    const [close, setClose] = useState(false);

    const logout = () =>
    {   
        setDropdownUser(false);
        
        dispatch(UserActionsCreators.logout());

        persistor.purge();

        setClose(true);
    };

    return (<div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed " >
        {close && <Redirect to="/" /> }
            <button className="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i className="la la-close"></i></button>
            <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
            </div>
        <div className="kt-header__topbar">
            <div className="kt-header__topbar-item dropdown">
                <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
                    <form>
                        <div className="tab-content">
                            <div className="tab-pane active show" id="topbar_notifications_notifications" role="tabpanel">
                            
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        <div className="kt-header__topbar-item kt-header__topbar-item--user" style={{ position : 'relative' }}>    
                <div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px" onClick={() => setDropdownUser(! dropdownUser)}>
                    <div className="kt-header__topbar-user">
                        <span className="kt-header__topbar-welcome kt-hidden-mobile">Hola,</span>
                        <span className="kt-header__topbar-username kt-hidden-mobile">{ user.userInformation.name || "" }</span>
                        <img className="kt-hidden" alt="Pic" src="./assets/img/300_25.jpg" />
                        <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">{ user.userInformation.name.charAt(0).toUpperCase() || ""}</span>
                    </div>
                </div>

                <div className={`dropdown-menu dropdown-menu-fit dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl ${dropdownUser ? 'show' : ''}`} style={{ left : 'auto', right: 0 }}>
                    <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x" style={{ backgroundImage: 'url(/assets/img/bg-1.jpg)' }}>
                        <div className="kt-user-card__avatar">
                            <img className="kt-hidden" alt="Pic" src="./assets/img/300_25.jpg" />
                            <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">{user.userInformation.name.charAt(0).toUpperCase() || ""}</span>
                        </div>
                        <div className="kt-user-card__name">
                            { user.userInformation.name }
                        </div>
                        {/*<div className="kt-user-card__badge">
                            <span className="btn btn-success btn-sm btn-bold btn-font-md">23 messages</span>
                        </div>*/}
                    </div>
                    <div className="kt-notification">
                        {/*<Link to={routes.PROFILE}>
                            <a href="javascript:void(0)" className="kt-notification__item">
                                <div className="kt-notification__item-icon">
                                    <i className="flaticon2-calendar-3 kt-font-success"></i>
                                </div>
                                <div className="kt-notification__item-details">
                                    <div className="kt-notification__item-title kt-font-bold">
                                        Mi Perfil
                                    </div>
                                    <div className="kt-notification__item-time">
                                        Configuración de la cuenta y más
                                    </div>
                                </div>
                            </a>
                        </Link>*/}

                        {/*<a href="demo1/custom/apps/user/profile-3.html" className="kt-notification__item">
                            <div className="kt-notification__item-icon">
                                <i className="flaticon2-mail kt-font-warning"></i>
                            </div>
                            <div className="kt-notification__item-details">
                                <div className="kt-notification__item-title kt-font-bold">
                                    My Messages
                                </div>
                                <div className="kt-notification__item-time">
                                    Inbox and tasks
                                </div>
                            </div>
                        </a>
                        <a href="demo1/custom/apps/user/profile-2.html" className="kt-notification__item">
                            <div className="kt-notification__item-icon">
                                <i className="flaticon2-rocket-1 kt-font-danger"></i>
                            </div>
                            <div className="kt-notification__item-details">
                                <div className="kt-notification__item-title kt-font-bold">
                                    My Activities
                                </div>
                                <div className="kt-notification__item-time">
                                    Logs and notifications
                                </div>
                            </div>
                        </a>
                        <a href="demo1/custom/apps/user/profile-3.html" className="kt-notification__item">
                            <div className="kt-notification__item-icon">
                                <i className="flaticon2-hourglass kt-font-brand"></i>
                            </div>
                            <div className="kt-notification__item-details">
                                <div className="kt-notification__item-title kt-font-bold">
                                    My Tasks
                                </div>
                                <div className="kt-notification__item-time">
                                    latest tasks and projects
                                </div>

                            </div>
                        </a>*/}
                        
                        {/*{user.role == settings.ROLES["PROYECT::PROVEEDOR"].name &&*/}
                            <Link to={routes.PROVIDER.CV} onClick={() => setDropdownUser(false)} className="kt-notification__item">
                                <div className="kt-notification__item-icon">
                                    <i className="flaticon2-checking kt-font-warning"></i>
                                </div>
                                <div className="kt-notification__item-details">
                                    <div className="kt-notification__item-title kt-font-bold">
                                        CV
                                    </div>
                                    <div className="kt-notification__item-time">
                                        Hoja de vida {/*<span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded">2 pending</span>*/}
                                    </div>
                                </div>
                            </Link>
                        {/*}*/}
                        <div className="kt-notification__custom kt-space-between">
                        <Button 
                            classStyles={ { 'btn-clean' : true, 'btn-outline-brand' : true} }
                            name="Cerrar Sesión"
                            loading={api.loading}
                            onClick={ () => logout()}
                        />

                            {/*<a href="demo1/custom/user/login-v2.html" target="_blank" className="btn btn-clean btn-sm btn-bold">Upgrade Plan</a>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
};

const mapStateToProps = (state) => ({
    api : state.api,
    user : state.user
});

export default connect(mapStateToProps, null)(Header);
