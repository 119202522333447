import React, { useState, useEffect } from 'react';
import { Button, Input, CustomButton, DateRange } from 'Components';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActionsCreators from 'Ducks/user/operations';
import _ from 'lodash';

const ResetPassword = () => {
    const dispatch = useDispatch();

    const [password, setPassword] = useState('');
    const [c_password, setConfirmPassword] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault();

        console.log("object resetpassword: ", { password, c_password });

        await dispatch(UserActionsCreators.resetPassword({ password, c_password }));

        setPassword('');
        setConfirmPassword('');
    }

    return (
        <>
            <div className={"offset-lg-4 col-lg-4"}>
                <div className={'card'}>
                    <form className={'col-lg-12'} method="POST" onSubmit={onSubmit}>
                        <br/>
                        <div className="form-group text-center">
                            <h4>CAMBIAR CONTRASEÑA</h4>
                        </div>
                        <div className="form-group">
                            <label className="col-xl-12 col-form-label">Password</label>
                            <Input
                                classStyles={{}}
                                label="Password"
                                placeholder=""
                                type="password"
                                name="password"
                                required={true}
                                value={password}
                                maxlength="20"
                                required={true}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label className="col-xl-12 col-form-label">Confirmar password</label>
                            <Input
                                classStyles={{}}
                                label="Confirmar Password"
                                placeholder=""
                                type="password"
                                name="c_password"
                                required={true}
                                value={c_password}
                                maxlength="20"
                                required={true}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        
                        <div className="form-group">
                                <Button
                                    classStyles={{ 'btn': true, 'btn-success': true }}
                                    type="submit"
                                    name="Enviar instrucción"
                                />
                        </div>
                        <br/>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;