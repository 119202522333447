import React, { Component, Fragment, useState, useEffect } from 'react';
import Button from 'Components/Button';
import Badge from 'Components/Badge';
import Date from 'Components/Date';
import Select from 'Components/Select';
import * as SupervisorsActionsCreators from 'Ducks/supervisors/operations';
import * as SupervisorsSelectors from 'Ducks/supervisors/selectors';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { settings } from 'Utils/settings';
import  * as utils from 'Utils/utils';
import Provider from './Provider';

class InformesSupervisor extends Component
{
    constructor(props)
    {
        super(props);

        const { dispatch } = props;

        this.state = {
            deliverable : {}
        };

        this.supervisorsActionsCreators = bindActionCreators(SupervisorsActionsCreators, dispatch);
    }

    componentWillMount()
    {
        const { pathname, search, hash, state } = this.props.location;

        this.setState({ deliverable : state.deliverable });
    }

    componentDidMount()
    {
        const { deliverable } = this.state;

        this.supervisorsActionsCreators.getInformesByDeliverable(deliverable.id);
    }

    componentWillUnmount()
    {
    }

    render()
    {
        const { informes, dispatch } = this.props;

        const { deliverable } = this.state;

        return(
            <div>
                { <Provider provider={deliverable.provider} contract={deliverable.contract} /> }
                <div class="row">
                
					<div class="col-xl-12">
						<div class="kt-portlet kt-portlet--tabs">
							<div class="kt-portlet__head">
								<div class="kt-portlet__head-label">
									<h3 class="kt-portlet__head-title">
										Entregable N° {deliverable.order}
									</h3>
								</div>
								<div class="kt-portlet__head-toolbar">
										
								</div>
							</div>
							<div class="kt-portlet__body">
								<p>{deliverable.description}</p>
							</div>
						</div>	
					</div>

                    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    { _.map(informes, (informe) => <Informe informe={informe} dispatch={dispatch} />) }
                    </div> 
                </div>        
            </div>
        )
    }
}

const Informe = ({ informe, dispatch }) =>
{
    const [statusReview , setStatusReview] = useState(informe.reviews.length ? _.head(informe.reviews).status_review : null);

    const [observation , setObservation] = useState(informe.reviews.length ? _.head(informe.reviews).observation : null);

    const handleSubmit = async (e) =>
    {
        e.preventDefault();

        let amountVerificationsPending = SupervisorsSelectors.getAmountMeansOfVerificationsPending(informe.products, informe.verifications);

        if(amountVerificationsPending)
        {
            alert("Debes revisar todos los medios para poder finalizar..");
            return false;
        }
        else
        {
            await dispatch(SupervisorsActionsCreators.updateInformeReview({ 
                id : _.head(informe.reviews).id,
                observation,
                status_review : statusReview,
                contract_informe_id : informe.id
             }));
        }

        //let result = await dispatch(SupervisorsActionsCreators.submitFiles(props /*, product.id*/));
    };

    /*useEffect( () => {
        console.log("REVIEWS: ", informe);
        console.log("useeffect status_reviews: ", informe.reviews.length ? _.head(informe.reviews).status_review : null);
        console.log("useeffect observation: ", informe.reviews.length ? _.head(informe.reviews).observation : null);
        setStatusReview(informe.reviews.length ? _.head(informe.reviews).status_review : '');
        setObservation(informe.reviews.length  ? _.head(informe.reviews).observation : '');
    } );*/

    useEffect( () => async () => {
        //setStatusReview(null);
        //setObservation(null);
        await dispatch(SupervisorsActionsCreators.setInformes([]));
    }, [] );

    return (
        <div class="row">
            <div class="kt-portlet">
                { statusReview &&
                    <div class="kt-portlet__head kt-portlet__head--lg">
                        <div class="kt-portlet__head-label">
                            <span class="kt-portlet__head-icon">
                                <i class="kt-font-success flaticon2-line-chart"></i>
                            </span>
                            <h3 class="kt-portlet__head-title">
                                INFORME { <Badge label={`${settings.STATUS_INFORME[statusReview].label}`} name={settings.STATUS_INFORME[statusReview].name} /> }
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <div class="kt-portlet__head-wrapper">
                                <div class="kt-portlet__head-actions">
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
                { statusReview &&
                    <div class="kt-portlet__head kt-portlet__head--lg">
                        <div class="kt-portlet__body" style={{ width : '100%' }}>
                            <form class="kt-form kt-form--fit kt-form--label-right" style={{ width : '100%' }} method="POST" onSubmit={handleSubmit}>
                                <div class="form-group">
                                    <textarea 
                                        name="observation" 
                                        class="form-control" 
                                        data-provide="markdown" 
                                        rows="3" 
                                        onChange={(e) => setObservation(e.target.value)}
                                        required>{ observation }</textarea>
                                </div>
                                <div class="form-group">
                                    <Select 
                                        text="Estados"
                                        value={_.pick(settings.STATUS_INFORME, [settings.STATUS_INFORME[3].id, settings.STATUS_INFORME[4].id])}
                                        name="status_review"
                                        onChange={(value) => setStatusReview(value)}
                                        selected={statusReview}
                                    />
                                </div>
                                <div class="form-group text-center">
                                    <Button 
                                        classStyles={ { 'btn-success' : true } }
                                        name="FINALIZAR"
                                        type="submit"
                                        //onClick={this.updateStatusReview}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                }

                <div class="kt-portlet__body">
                    { <InformeVerification 
                        informes={informe.verifications}
                        informe={informe}
                        observation={informe.observation} 
                        summary={informe.summary} dispatch={dispatch} /> }
                    { _.map(informe.products, product => 
                        <Product 
                            product={product} 
                            informe={informe}
                            dispatch={dispatch} />) }
                </div>
            </div>
        </div>
    )
};

const Product = ({ product, dispatch, informe }) =>
(
    <Fragment>
        <h5 class="kt-demo-panel__title kt-margin-t-10">{ product.name }</h5>
        <div class="row row-no-padding ">
            { _.map(product.verifications, verification => 
                <MeanOfVerification 
                    verification={verification} 
                    dispatch={dispatch}
                    informe={informe} />) }
        </div>
    </Fragment>
);

const InformeVerification = ({ informes, dispatch, observation, summary, informe }) =>
(
    <Fragment>
        <h5 class="kt-demo-panel__title">INFORMES</h5>
        <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="alert alert-solid-warning" role="alert">
                        <div class="alert-text" dangerouslySetInnerHTML={{__html: observation }}></div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="alert alert-solid-success" role="alert">
                        <div class="alert-text" dangerouslySetInnerHTML={{__html: summary }}></div>
                    </div>
                </div>
            </div>
        <div class="row row-no-padding ">            
            { _.map(informes, verification => 
                <MeanOfVerification 
                    informe={informe} 
                    verification={verification} 
                    dispatch={dispatch} 
                />) }
        </div>
    </Fragment>
);

class MeanOfVerification extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
        };

        const { dispatch } = this.props;

        this.supervisorsActionsCreators = bindActionCreators(SupervisorsActionsCreators, dispatch);
    }

    componentWillMount()
    {
        let obj = {};

        if(this.props.verification.reviews.length)
        {
            obj.observation = _.head(this.props.verification.reviews).observation;
            obj.status_review = _.head(this.props.verification.reviews).status_review;
        }
        else
        {
            obj.observation = '';
            obj.status_review = settings.STATUS_INFORME[1].id;
        }

        this.setState({ ...obj });
    }

    handleChange = (e) =>
    {
        const { name, value } = e.target;
        this.setState({ [ name ] : value });
    };
 
    updateStatusReview = async (props) =>
    {
        this.supervisorsActionsCreators.updateReviews({ 
            ...this.state, 
            contract_verification_id : this.props.verification.id, 
            contract_informe_review_id : (this.props.informe.reviews.length ? _.head(this.props.informe.reviews).id : '' )  });
    };

    render()
    {
        const { verification } = this.props;

        const { observation, status_review } = this.state;

        return (
        <div class="col-xl-6 col-md-6 col-sm-12">
        <div class="col-md-12 kt-margin-t-10 kt-margin-b-10">
            <div class="d-flex align-items-center">
                <div class="col-md-2 col-sm-6">
                    <a href={verification.object._url} target="_blank">
                        <button title="Descargar medio de verificación" type="button" class="btn btn-outline-success btn-elevate btn-icon"><i class="fa fa-cloud-download-alt"></i></button>
                    </a>
                </div>
                <div class="col-md-5 col-sm-6">
                    <span class="kt-padding-0 d-block">
                        <strong>Nombre:</strong>
                        <p>{ verification.name }</p>
                    </span>
                    <span class="kt-padding-0 d-block">
                        <strong>Tipo :</strong> 
                        <p>{ verification.informe_type_id ? settings.TYPE_INFORME[verification.informe_type_id].name : '-' }</p>
                    </span>
                    <span class="kt-padding-0 d-block">
                        <strong>Status :</strong> 
                        <p>{ <Badge label={`${settings.STATUS_INFORME[verification.status_review].label}`} name={settings.STATUS_INFORME[verification.status_review].name} /> }</p>
                    </span>
                    <span class="kt-margin-t-10 d-block">
                        <strong>F. Creación :</strong> 
                        <div>
                            <Date 
                                alert='success'
                                date={verification.created_at}
                                outputFormat='DD-MM-YYYY h:mm:ss a'
                            />
                        </div>
                    </span>
                </div>
                <div class="col-md-5 col-sm-12">
                    <div class="form-group">
                        <div class="kt-checkbox-list">
                            <label class="kt-checkbox kt-checkbox--solid kt-checkbox--success pl-0">
                                {/*<input type="checkbox" name="status_review" onChange={this.handleInputChange} /> Aprobado
                                <span></span>*/}
                                <Select 
                                    text="Estado"
                                    value={_.pick(settings.STATUS_INFORME, [settings.STATUS_INFORME[3].id,settings.STATUS_INFORME[4].id])}
                                    name="status_review"
                                    onChange={(value) => this.setState({ status_review : value })}
                                    selected={status_review}
                                />
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Observaciones</label>
                        <textarea name="observation" class="form-control" data-provide="markdown" rows="3" onChange={this.handleChange}>{ observation }</textarea>
                    </div>
                    <div class="form-group">
                        <Button 
                            classStyles={ { 'btn-success' : true } }
                            name="Actualizar"
                            //loading={api.loading}
                            onClick={this.updateStatusReview}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>)
    }
}

const mapStateToProps = (state) => ({
    informes : state.supervisors.informes
});

export default connect(mapStateToProps, null)(InformesSupervisor);