import { constants } from "./types";
import createReducer  from "Utils/createReducer";
import _ from "lodash";

const initialState = {
    deliverables : {},
    informes : [],
    dashboard : {
        statusReview : [],
        statusExpiration : [],
        indicadores: [],
        actividadesRecientes: [],
        indicadoresPorConvocatoria: [],
        estadosEntregables: [],
        progresoContratos: [],
        consultoresSexo: [],
        topIdiomas: [],
        topEspecialidades: [],
        progresoCv: [],
        consultoresGradoAcademico: [],
        contratosResponsable: [],        
    },
    evaluators: [],
    listFormInformeReview: [],
};

const supervisorsReducer = createReducer( initialState )( {
    [ constants.SET_DELIVERABLES ]: (state, action) => {
        return  { ...state, deliverables : action.deliverables };
    },
    [ constants.SET_DELIVERABLE_DETAIL ]: (state, action) => {
        return  { ...state, deliverable : action.deliverable };
    },
    [ constants.SET_INFORMES ]: (state, action) => {
        return  { ...state, informes : action.informes };
    },
    [ constants.SET_STATUS_REVIEW ]: (state, action) => {
        return  { ...state, dashboard : { ...state.dashboard, statusReview : action.data } };
    },
    [ constants.SET_STATUS_EXPIRATION ]: (state, action) => {
        return  { ...state, dashboard : { ...state.dashboard, statusExpiration : action.data } };
    },
    [ constants.SET_INDICADORES ]: (state, action) => {
        return  { ...state, dashboard: { ...state.dashboard, indicadores: action.data}};
    },
    [ constants.SET_ACTIVIDADES_RECIENTES ]: (state, action) => {
        return  { ...state, dashboard: { ...state.dashboard, actividadesRecientes: action.data}};
    },
    [ constants.SET_INDICADORES_POR_CONVOCATORIA ]: (state, action) => {
        return  { ...state, dashboard: { ...state.dashboard, indicadoresPorConvocatoria: action.data}};
    },
    [ constants.SET_ESTADOS_ENTREGABLES ]: (state, action) => {
        return  { ...state, dashboard: { ...state.dashboard, estadosEntregables: action.data}};
    },
    [ constants.SET_PROGRESO_CONTRATOS ]: (state, action) => {
        return  { ...state, dashboard: { ...state.dashboard, progresoContratos: action.data}};
    },
    [ constants.SET_CONSULTORES_SEXO ]: (state, action) => {
        return  { ...state, dashboard: { ...state.dashboard, consultoresSexo: action.data}};
    },
    [ constants.SET_TOP_IDIOMAS ]: (state, action) => {
        return  { ...state, dashboard: { ...state.dashboard, topIdiomas: action.data}};
    },
    [ constants.SET_TOP_ESPECIALIDADES ]: (state, action) => {
        return  { ...state, dashboard: { ...state.dashboard, topEspecialidades: action.data}};
    },
    [ constants.SET_PROGRESO_CV ]: (state, action) => {
        return  { ...state, dashboard: { ...state.dashboard, progresoCv: action.data}};
    },
    [ constants.SET_CONSULTORES_GRADO_ACADEMICO ]: (state, action) => {
        return  { ...state, dashboard: { ...state.dashboard, consultoresGradoAcademico: action.data}};
    },
    [ constants.SET_CONTRATOS_RESPONSABLE ]: (state, action) => {
        return  { ...state, dashboard: { ...state.dashboard, contratosResponsable: action.data}};
    },
    [ constants.SET_EVALUATORS ]: (state, action) => {
        return  { ...state, evaluators : action.data };
    },
    [ constants.SAVE_FORM_INFORME_REVIEW ]: (state, action) => {

        let {listFormInformeReview} = state;
        const {formInformeReview} = action;
        if(formInformeReview){
            const index = _.findIndex(listFormInformeReview, i => i.contract_informe_id === formInformeReview?.contract_informe_id);            
            if(index !== -1){
                listFormInformeReview[index] = formInformeReview;
            }else{
                listFormInformeReview.push(formInformeReview);            
            }
            return  { ...state, listFormInformeReview: listFormInformeReview };
        }
        return state;
    },
    [ constants.REMOVE_FORM_INFORME_REVIEW ]: (state, action) => {

        let {listFormInformeReview} = state;
        const {formInformeReview} = action;
        const index = _.findIndex(listFormInformeReview, i => i.contract_informe_id === formInformeReview?.contract_informe_id);
        if(index !== -1){
            listFormInformeReview.splice(index, 1);
        }
        return  { ...state, listFormInformeReview : listFormInformeReview };
    }
} );

export default supervisorsReducer;
