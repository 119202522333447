/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from 'react';
import Loadable from 'react-loadable';

// rct page loader
import Loader from 'Components/Loader';

//Contracts
const AsyncDeliverablesComponent = Loadable({
   loader : () => import("Views/Deliverables/index"),
   loading : () => <Loader />
});

const AsyncNewDeliverableComponent = Loadable({
    loader : () => import("Views/Deliverables/newDeliverable"),
    loading : () => <Loader />
 });

 const AsyncEditDeliverableComponent = Loadable({
    loader : () => import("Views/Deliverables/editDeliverable"),
    loading : () => <Loader />
 });




export {
   AsyncDeliverablesComponent,
   AsyncNewDeliverableComponent,
   AsyncEditDeliverableComponent
};