import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import { routes } from 'Utils/constants';

import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) =>
{
  console.log("PRIVATEROUTE: ", isAuthenticated);
    return (
      <Route
        {...rest}
        render={props =>
            isAuthenticated ? <Component {...props} />
            : (
            <Redirect
              to={{
                pathname: `${routes.LOGIN}`,
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }

const mapStateToProps = (state, ownProps) => ({
    isAuthenticated : state.user.isAuthenticated,
});

export default connect(mapStateToProps, null)(PrivateRoute);
