import React, { useState, useEffect } from 'react';
import { Button, Input, CustomButton, Radio, Select } from 'Components';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import _ from 'lodash';
import animateScrollTo from 'animated-scroll-to';

const Salaries = ({ cv_salaries }) =>
{
    console.log("CV_SALARIES: ", cv_salaries);
    const dispatch = useDispatch();

    const [salaries , setSalaries] = useState([]);
    const [company, setCompany] = useState('');
    const [salary, setSalary] = useState('');
    const [months, setMonth] = useState('');
    const [amount, setAmount] = useState('');
    const [money, setMoney] = useState('');
    
    const [id, setId] = useState('');
    const [edit, setEdit] = useState(false);

    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        console.log("USE EFFECT");
        setSalaries(cv_salaries);
    }, [cv_salaries]);

    const onSubmit = async (e) =>
    {
        e.preventDefault();

        await dispatch(ProvidersActionsCreators.setSalary(edit ? { company, amount, months, money, id } : { company, amount, months, money }));

        setShowForm(false);
        setEdit(false);
    }

    const onEdit = (salary) => 
    {
        setShowForm(true);
        if(_.isEmpty(salary))
        {
            setCompany('');
            setAmount('');
            setMoney('');
            setMonth('');
            setId('');
            setEdit(false);
        }
        else
        {
            setCompany(salary.company);
            setAmount(salary.amount);
            setMoney(salary.money);
            setMonth(salary.months);
            setId(salary.id);
            setEdit(true);
        }
    }

    const MONEY_LIST = [
        {
            id : "PEN",
            name : "SOLES"
        },
        {
            id : "USA",
            name : "DOLARES"
        }
    ]

    return (
        <>
            {!showForm && <div>
                <h2>SALARIOS PERCIBIDOS</h2>
                <p>Detalle del monto de honorarios y/o remuneraciones mensuales percibidas en promedio durante los tres últimos años.</p>
                <button onClick={() => { onEdit(null) }} className="btn btn-success btn-sm" >REGISTRAR SALARIO</button>                

                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true">
                    <div className="kt-notes__items">
                        {
                            _.map(salaries, (salary) => (
                                <Salary salary={salary} onEdit={onEdit} />
                            ))
                        }
                    </div>
                </div>

            </div>} 
            {showForm && <form method="POST" onSubmit={onSubmit}>

                {!edit && <h2>NUEVO SALARIO</h2>}
                {edit && <h2>EDITAR SALARIO</h2>}
                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>

                <div className="row">

                <div className="form-group col-md-6 col-lg-4">
                    <label>Empresa o Institución</label>
                    <Input 
                        classStyles={ {} } 
                        label="Compañía"
                        placeholder=""
                        type="text"
                        name="company"
                        required={true}
                        value={company}
                        maxlength="128"
                        required={true}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                </div>
                <div className="form-group col-md-6 col-lg-4">
                    <div className="row">
                        <div className="form-group col-6">
                            <label>Moneda</label>
                            <div>
                                <Select 
                                     text="Monedad"
                                     value={MONEY_LIST}
                                     name="money"
                                     selected={money}                                     
                                     onChange={(e) => setMoney(e)}
                                />
                            </div>
                        </div>
                        <div className="form-group col-6">
                            <label>Monto Total del Contrato</label>
                            <Input 
                                classStyles={ {} } 
                                label="Salario"
                                placeholder=""
                                type="text"
                                name="amount"
                                required={true}
                                value={amount}
                                maxlength="6"
                                required={true}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>                        
                    </div>                    
                </div>
                <div className="form-group col-md-6 col-lg-4">

                    <div className="row">
                        <div className="form-group col-6">
                            <label>Periodo (meses)</label>
                            <Input 
                                classStyles={ {} } 
                                label="Meses"
                                placeholder=""
                                type="text"
                                name="months"
                                required={true}
                                value={months}
                                maxlength="5"
                                required={true}
                                onChange={(e) => setMonth(e.target.value)}
                            />
                        </div>
                        <div className="form-group col-6">
                            <label>Promedio Mensual de Honorarios</label>
                            <Input 
                                classStyles={ {} } 
                                label="Meses"
                                placeholder=""
                                type="text"
                                name="salary"
                                required={true}
                                value={(amount > 0 && months > 0 ? (amount/months).toFixed(2) : 0)}
                                disabled
                            />
                        </div>                        
                    </div>  
                    
                </div>
               

                </div>
               
                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                <div className="row">
                    <div className="col">
                        <Button 
                            classStyles={ { 'btn' : true, 'btn-default' : true } }
                            onClick={() => {setShowForm(false)}}
                            type="button"
                            name="CANCELAR"
                        />&nbsp;
                        <Button 
                            classStyles={ { 'btn' : true, 'btn-success' : true } }
                            type="submit"
                            name="GUARDAR"
                        />
                    </div>
                </div>
                
            </form>}
           
        </>
    )
}

const Salary = ({ salary, onEdit }) =>
{
    const dispatch = useDispatch();

    const [edit, setEdit] = useState(false);

    const deleteSalary = async () =>
    {
        let providersActionCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

        let result = await providersActionCreators.deleteSalary(salary.id);
    };

    useEffect( () => {
        console.log("USEEFFECT");
        setEdit(false);
      }, [ salary ]
     );

    return (
        <div className="kt-notes__item"> 
            <div className="kt-notes__media">
            <span className="kt-notes__icon">
                <i className="fa fa-money-bill kt-font-warning"></i>
            </span>                              
            </div>    
            <div className="kt-notes__content"> 
                <div className="kt-notes__section">     
                    <div className="kt-notes__info">
                        <a href="#" className="kt-notes__title kt-font-bolder">
                        { salary.salary } { salary.money }                 
                        </a>
                        <span className="kt-notes__desc">
                            
                        </span>
                    </div>
                    <div className="kt-notes__dropdown"> 
                    {edit &&
                        <CustomButton 
                            classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-warning' : true,'btn-icon' : true }}
                            icon="fa fa-window-close"
                            style={{ marginRight : '.7em' }}
                            onClick={() => { onEdit({}); setEdit(false) }} />
                    }
                    {! edit &&
                        <CustomButton 
                            classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-success' : true,'btn-icon' : true }}
                            icon="fa fa-edit"
                            style={{ marginRight : '.7em' }}
                            onClick={() => { onEdit(salary); setEdit(true); animateScrollTo(document.querySelector('.frm-cv')); }} />
                    }
                        <CustomButton 
                            classStyles={{ 'btn' : true, 'btn-elevate' : true, 'btn-outline-danger' : true,'btn-icon' : true }}
                            icon="fa fa-trash-alt"
                            onClick={() => { if(window.confirm("¿Estas seguro de eliminar?")) deleteSalary() } } />
                    </div>
                </div>
                <span className="kt-notes__body">                                        
                    <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-building" /> { salary.company }</p>
                    {/*<p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-money-check-alt" />{ salary.salary } { salary.money }</p>*/}
                    <p><CustomButton style={{ marginRight : '.8em' }} icon="fa fa-lg fa-calendar-alt" />{ salary.months } meses</p>
                </span>  
            </div>                                             
        </div>
    )
}

export default Salaries;