import React, {useEffect, useState, Component, useMemo} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import MeansOfVerification from './MeansOfVerification';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import FileUpload from 'Components/FileUpload';
import ResumableUploadFile from '../../components/ResumableUploadFile';

const Product = ({ product, dispatch }) =>
{
    const processFile = async (props) =>
    {
        let result = await dispatch(ProvidersActionsCreators.submitFiles(props /*, product.id*/));

        await _.map(result, file => dispatch(ProvidersActionsCreators.createMeanOfVerification(file.filename, product.id)));
    };

    return (
    <div class="col-xl-6">
        <div class="kt-portlet kt-portlet--height-fluid">            
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-widget kt-widget--project-1">
                    <div class="kt-widget__head d-flex">
                        <div class="kt-widget__label">
                            <div class="kt-demo-icon">
                                <div class="kt-demo-icon__preview">
                                    <i class="fab fa-product-hunt"></i>
                                </div>
                            </div>
                            <div class="kt-widget__info kt-padding-0">
                                <a href="#" class="kt-widget__title">
                                    { product.name }                                              
                                </a>
                            </div>
                        </div>
                        <div class="kt-widget__toolbar">
                            <a href="#" class="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                <i class="flaticon-more-1"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                <ul class="kt-nav">
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-line-chart"></i>
                                            <span class="kt-nav__link-text">Reports</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-send"></i>
                                            <span class="kt-nav__link-text">Messages</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                            <span class="kt-nav__link-text">Charts</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-avatar"></i>
                                            <span class="kt-nav__link-text">Members</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="#" class="kt-nav__link">
                                            <i class="kt-nav__link-icon flaticon2-settings"></i>
                                            <span class="kt-nav__link-text">Settings</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget__body">
                        <span class="kt-widget__text kt-margin-t-0 kt-padding-t-5">
                            { product.description }                            
                        </span>
                        <div class="kt-widget__info kt-padding-t-5 kt-margin-t-15" style={{ borderTop : '1px solid #ebedf2' }}>
                                <a href="#" class="kt-widget__title">
                                    Archivos            
                                </a>
                        </div>

                        <div class="row kt-margin-t-15">
                            { _.map(product.verifications, (verification) => <MeansOfVerification verification={verification} />) }
                        </div>

                        <div class="kt-widget__info kt-padding-t-5 kt-margin-t-15" style={{ borderTop : '1px solid #ebedf2' }}>
                                <a href="#" class="kt-widget__title">
                                    Subir Archivos            
                                </a>
                                <div class="kt-margin-t-15">
                                    {/* { <Previews dispatch={dispatch} product_id={product.id} /> }*/}
                                    <FileUpload isValidate={true} onProcessFiles={processFile} />
                                </div>
                        </div>
                        
                        {/*<div class="kt-widget__stats kt-margin-t-20">
                            <div class="kt-widget__item d-flex align-items-center kt-margin-r-30">
                                <span class="kt-widget__date kt-padding-0 kt-margin-r-10">
                                    Nombre Archivo
                                </span>
                                <div class="kt-widget__label">
                                    <button type="button" class="btn btn-outline-brand btn-elevate btn-icon"><i class="fa fa-cloud-download-alt"></i></button>
                                </div>
                            </div>
                            <div class="kt-widget__item d-flex align-items-center kt-padding-l-0">
                                <span class="kt-widget__date kt-padding-0 kt-margin-r-10 ">
                                    Due
                                </span>
                                <div class="kt-widget__label">
                                    <span class="btn btn-label-danger btn-sm btn-bold btn-upper">07 0ct, 18</span>
                                </div>
                            </div>
                        </div>*/}


                        {/*<div class="kt-widget__container">
                            <span class="kt-widget__subtitel">Progress</span>
                            <div class="kt-widget__progress d-flex align-items-center flex-fill">
                                <div class="progress" style={{ height: '5px' ,width: '100%' }}>
                                    <div class="progress-bar kt-bg-success" role="progressbar" style={{ width: '78%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <span class="kt-widget__stat">
                                    78%
                                </span>
                            </div>
                            </div>*/}
                    </div>
                    <div class="kt-widget__footer">
                        <div class="kt-widget__wrapper">
                            <div class="kt-widget__section">
                                <div class="kt-widget__blog">
                                    <i class="flaticon2-list-1"></i>
                                    <a href="#" class="kt-widget__value kt-font-brand">{ product.verifications.length || 0 } Medios de verificación</a>
                                </div>
                                {/*<div class="kt-widget__blog">
                                    <i class="flaticon2-talk"></i>
                                    <a href="#" class="kt-widget__value kt-font-brand">654 Comments</a>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
};

export default connect(null, null)(Product);
