import React from "react";
import PropTypes from "prop-types";

const Modal = ({
  show = false,
  handleClose,
  title,
  submitButtonMessage = 'Guardar',
  handleSubmit,
  disabledSubmitButton = false,
  children,
}) => {
  const showHideClassName = show ? "modal fade show" : "modal fade";
  const showHideStyles = show
    ? {
        display: "block",
        paddingRight: 17 + "px",
        background: "rgba(0, 0, 0, 0.3)",
      }
    : { display: "none" };

  return (
    <div
      className={showHideClassName}
      id="kt_modal_4"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style={showHideStyles}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClose}
            >
              Cerrar
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={disabledSubmitButton}>
              {submitButtonMessage}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default Modal;
