import React, { Component, Fragment } from 'react';
import { Input, 
    Select, 
    InputAutocomplete, 
    Radio, 
    Button,
    File,
    Progress } from 'Components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProvidersActionsCreators from 'Ducks/providers/operations';
import AcademicTitle from 'Views/Provider/cv/AcademicTitle';
import References from 'Views/Provider/cv/References';
import Experiences from 'Views/Provider/cv/Experiences';
import PersonalInformation from 'Views/Provider/cv/PersonalInformation';
import Salaries from 'Views/Provider/cv/Salaries';
import Languages from 'Views/Provider/cv/Languages';
import ComplementaryStudies from 'Views/Provider/cv/ComplementaryStudies';
import ResetPassword from 'Views/Provider/cv/ResetPassword';
import Profiles from 'Views/Provider/cv/Profiles';
import { settings } from 'Utils/settings';
import _ from 'lodash';
import moment from 'moment';
import { scrollTo } from 'Utils/animateScrollTo';

    class Cv extends Component
    {
        constructor(props)
        {
            super(props);

            const { dispatch } = props;

            this.state = {
                show_export : false,
                grade : '',
                especiality : '',
                university : '',
                start_date : null,
                end_date : null,
                suggestion : null,
                clear : false,
                is_international : "false",
                tab : 'personal_information',
                country : '',
                city : '',
                institution : '',
                institution_inei : '',
                concept : '',
                languages : [],
                _filename_certificate : '',
                fillingFeed : 0
            };

            this.providersActionCreators = bindActionCreators(ProvidersActionsCreators, dispatch);

            this.handleChange = this.handleChange.bind(this);
        }

        cleanState = {
            show_export : false,
            grade : '',
            especiality : '',
            university : '',
            start_date : '',
            end_date : '',
            suggestion : null,
            clear : true,
            is_international : "false",
            tab : 'academic_title',
            country : '',
            city : '',
            institution : '',
            institution_inei : '',
            concept : '',
            _filename_certificate : ''
        };

        getUniversities = async (value) =>
        {
            let response = await this.providersActionCreators.getInstitutions(value);

            return response;
        };

        getCountries = async (value) =>
        {
            let response = await this.providersActionCreators.getCountries(value);

            return response;
        };
 
        dateRange = (/*event, picker*/e, type) =>
        {
            let dateFormat = moment(e).format('YYYY-MM-DD');

            console.log("TYPE: ", type);

            if(type == "start_date")
            {
                this.setState({
                    start_date : dateFormat
                });
            }
            else
            {
                this.setState({
                    end_date : dateFormat
                });
            }
        };

        handleChange = (e) =>
        {
            const { name, value } = e.target;

            if(name === "is_international") this.setState(this.cleanState);

            this.setState({ [ name ] : value });
        };

        handleChangeFile = async (e) =>
        {
            const { name, files } = e.target;

            let urlFile = await this.providersActionCreators.submitFiles(files[0]);

            this.setState({ [ name ] : urlFile.filename || '' });
        };

        onSubmit = (type) => async(e) => 
        {
            e.preventDefault();

            e.target.reset();

            this.setState({ clear : false });

            const { grade, 
                especiality, 
                start_date, 
                end_date, 
                institution_inei, 
                is_international,
                city,
                country,
                institution,
                concept,
                _filename_certificate
                 } = this.state;

            let obj = {
                grade,
                especiality,
                start_date,
                end_date,
                institution_inei,
                //institution,
            };

            if(type === "ACADEMIC_TITLE")
            {
                if(is_international === "true")
                {
                    delete obj.institution_inei;

                    _.assign(obj, { country, city, institution, is_international : true });
                }

                _.assign(obj, { _filename_certificate });

                await this.providersActionCreators.saveAcademicTitle(obj);
            } 
            else if(type === "COMPLEMENTARY_STUDIES")
            {
                delete obj.institution_inei;
                delete obj.grade;

                _.assign(obj, { concept, city, country, institution, _filename_certificate });

                console.log("OBJ COMPLEMENTARY_STUDIES: ", obj);

                await this.providersActionCreators.saveCourse(obj);
            }

            console.log("CLEANSTATE: ", this.cleanState);

            this.setState(this.cleanState);
        };

        componentDidMount()
        {
            this.setState({languages : [
                {
                    "id": 4,
                    "code": "de",
                    "name": "ALEMÁN"
                },
                {
                    "id": 1,
                    "code": "es",
                    "name": "ESPAÑOL"
                },
                {
                    "id": 2,
                    "code": "en",
                    "name": "INGLÉS"
                },
                {
                    "id": 3,
                    "code": "fr",
                    "name": "FRANCÉS"
                },
                {
                    "id": 5,
                    "code": "pt",
                    "name": "PORTUGUÉS"
                },
                {
                    "id": 8,
                    "code": "ja",
                    "name": "JAPONÉS"
                },
                {
                    "id": 13,
                    "code": "th",
                    "name": "TAILANDÉS"
                },
                {
                    "id": 14,
                    "code": "qu",
                    "name": "QUECHUA"
                }
            ]});

        }

        fillingFeed = () =>
        {
            let { cv_titles, cv_courses, cv_languages, cv_experiences, cv_references, cv_salaries } = this.props.user.userInformation;

            console.log("CV_TITLES: ", cv_titles);

            var sum = 0;

            if(cv_titles.length) sum = sum + 16.6666;
            if(cv_courses.length) sum = sum + 16.6666;
            if(cv_languages.length) sum = sum + 16.6666;
            if(cv_experiences.length) sum = sum + 16.6666;
            if(cv_references.length) sum = sum + 16.6666;
            if(cv_salaries.length) sum = sum + 16.6666;

            this.setState({ fillingFeed : sum > 99 ? 100 : sum });
        }

        componentDidUpdate(oldProps, prevState) {
            const newProps = this.props;

            if(oldProps !== newProps)
            {
                this.fillingFeed();
            }

            if(oldProps.tab !== newProps.tab) 
            {
                scrollTo('.frm-cv');
            }

            if(prevState.tab !== this.state.tab)
            {
                scrollTo('.frm-cv');
            }
        }

        badgeStatusCount = (name) => {
            let userInformation = this.props.user.userInformation;
            let count = 0;
            if(userInformation[name]){
                let data = userInformation[name];
                if(Array.isArray(data) && data.length > 0){
                    count = data.length;
                }
            }
            if(count > 0){
                return <span className="kt-badge kt-badge--unified-success kt-badge--sm kt-badge--rounded kt-badge--bolder">{count}</span>
            }
            return <span className="kt-badge kt-badge--unified-danger kt-badge--sm kt-badge--rounded kt-badge--bolder">{count}</span>
        }

        badgeCompletePersonalData = (userInformation) => {
            let count = 0;
            
            const personalInformation = userInformation;                
            if(personalInformation){
                count += personalInformation.firstname ? 1:0;
                count += personalInformation.plastname ? 1:0;
                count += personalInformation.mlastname ? 1:0;
                count += personalInformation.birthdate ? 1:0;

                count += personalInformation.address ? 1:0;
                count += personalInformation.nacionality ? 1:0;
                count += personalInformation.city ? 1:0;
                count += personalInformation.gender ? 1:0;
                count += personalInformation.civil_status ? 1:0;
                count += personalInformation.district ? 1:0;
                count += personalInformation.document_number ? 1:0;
                count += personalInformation.document_type_id ? 1:0;
            }
            
            //count += personalInformation.phone ? 1:0;
            //count += personalInformation.cellphone ? 1:0;
            
            if(count >= 12){
                return <span className="kt-badge kt-badge--unified-success kt-badge--sm kt-badge--rounded kt-badge--bolder">{count}</span>
            }
            return <span className="kt-badge kt-badge--unified-danger kt-badge--sm kt-badge--rounded kt-badge--bolder">{count}</span>
        }

        render ()
        {
            const { 
                user,
                institutions } = this.props;

            const { show_export, 
                grade,
                especiality,
                university,
                clear,
                tab,
                is_international,
                country,
                city,
                start_date,
                end_date,
                institution,
                concept,
                languages,
                _filename_certificate,
                fillingFeed } = this.state;

            return (
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" className="frm-cv">
                    <div className="kt-portlet">
    <div className="kt-portlet__body">
        <div className="kt-widget kt-widget--user-profile-3">
            <div className="kt-widget__top">
                <div className="kt-widget__media">
                    <img src={user.userInformation.avatar ? user.userInformation.avatar._url : "/assets/img/default.jpg"} alt="image" />
                </div>
                <div className="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-bolder kt-font-light kt-hidden">
                    JM
                </div>
                <div className="kt-widget__content">
                    <div className="kt-widget__head">
                        <div className="kt-widget__user">
                            <a href="#" className="kt-widget__username">
                                { user.userInformation.name }                   
                            </a>
                            <span className="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success">{ settings.ROLES[user.role].label }</span>
                        </div>

                        <div className="kt-widget__action">
                            <div className="dropdown dropdown-inline">
                                <a 
                                    href="#" 
                                    className="btn btn-brand btn-sm btn-upper dropdown-toggle" data-toggle="dropdown"
                                    onClick={() => this.setState({ show_export : !show_export })}>
                                    Exportar
                                </a>
                                <div className={`dropdown-menu dropdown-menu-fit dropdown-menu-md dropdown-menu-right ${show_export ? 'show show-export' : ''}`}>
                                    <ul className=" kt-nav">
                                        <li className="kt-nav__head">
                                            Opciones para exportar                                
                                            <span data-toggle="kt-tooltip" data-placement="right" title="Click to learn more...">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand kt-svg-icon--md1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect id="bound" x="0" y="0" width="24" height="24"/>
                                                        <circle id="Oval-5" fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                                                        <rect id="Rectangle-9" fill="#000000" x="11" y="10" width="2" height="7" rx="1"/>
                                                        <rect id="Rectangle-9-Copy" fill="#000000" x="11" y="7" width="2" height="2" rx="1"/>
                                                    </g>
                                                </svg>        
                                            </span>
                                        </li>
                                        <li className="kt-nav__separator"></li>
                                        <li className="kt-nav__item">
                                            <a href={`${settings.BASE_URL}/profile/cv_formato_bid?token=${localStorage.getItem('token')}`} target="_blank" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-document"></i>
                                                <span className="kt-nav__link-text">.PDF</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="kt-widget__subhead">
                        <a href="#"><i className="flaticon2-new-email"></i>{ user.userInformation.email ? user.userInformation.email : '-' }</a>
                                    <a href="#"><i className="flaticon2-calendar"></i>{ user.userInformation.birthdate ? user.userInformation.birthdate : '-' } </a>
                        <a href="#"><i className="flaticon2-placeholder"></i>{ user.userInformation.address ? user.userInformation.address : '-' }</a>
                    </div>

                    <div className="kt-widget__info">
                        <div className="kt-widget__progress">
                            <div className="kt-widget__text">
                                Progreso
                            </div>
                            <Progress 
                                styles={{ width : '300px'}}
                                progress={fillingFeed} 
                                valuemax="100"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="kt-widget__bottom kt-hidden">
                <div className="kt-widget__item">
                    <div className="kt-widget__icon">
                        <i className="flaticon-piggy-bank"></i>
                    </div>
                    <div className="kt-widget__details">
                        <span className="kt-widget__title">Earnings</span>
                        <span className="kt-widget__value"><span>$</span>249,500</span>
                    </div>
                </div>

                <div className="kt-widget__item">
                    <div className="kt-widget__icon">
                        <i className="flaticon-confetti"></i>
                    </div>
                    <div className="kt-widget__details">
                        <span className="kt-widget__title">Expances</span>
                        <span className="kt-widget__value"><span>$</span>164,700</span>
                    </div>
                </div>

                <div className="kt-widget__item">
                    <div className="kt-widget__icon">
                        <i className="flaticon-pie-chart"></i>
                    </div>
                    <div className="kt-widget__details">
                        <span className="kt-widget__title">Net</span>
                        <span className="kt-widget__value"><span>$</span>782,300</span>
                    </div>
                </div>

                <div className="kt-widget__item">
                    <div className="kt-widget__icon">
                        <i className="flaticon-file-2"></i>
                    </div>
                    <div className="kt-widget__details">
                        <span className="kt-widget__title">73 Tasks</span>
                        <a href="#" className="kt-widget__value kt-font-brand">View</a>
                    </div>
                </div>

                <div className="kt-widget__item">
                    <div className="kt-widget__icon">
                        <i className="flaticon-chat-1"></i>
                    </div>
                    <div className="kt-widget__details">
                        <span className="kt-widget__title">648 Comments</span>
                        <a href="#" className="kt-widget__value kt-font-brand">View</a>
                    </div>
                </div>

                <div className="kt-widget__item">
                    <div className="kt-widget__icon">
                        <i className="flaticon-network"></i>
                    </div>
                    <div className="kt-widget__details">
                        <div className="kt-section__content kt-section__content--solid">
                            <div className="kt-badge kt-badge__pics">
                                <a href="#" className="kt-badge__pic" data-toggle="kt-tooltip" data-skin="brand" data-placement="top" title="" data-original-title="John Myer">
                                    <img src="./assets/img/100_7.jpg" alt="image" />
                                </a>
                                <a href="#" className="kt-badge__pic" data-toggle="kt-tooltip" data-skin="brand" data-placement="top" title="" data-original-title="Alison Brandy">
                                    <img src="./assets/img/100_3.jpg" alt="image" />
                                </a>
                                <a href="#" className="kt-badge__pic" data-toggle="kt-tooltip" data-skin="brand" data-placement="top" title="" data-original-title="Selina Cranson">
                                    <img src="./assets/img/100_2.jpg" alt="image" />
                                </a>
                                <a href="#" className="kt-badge__pic" data-toggle="kt-tooltip" data-skin="brand" data-placement="top" title="" data-original-title="Luke Walls">
                                    <img src="./assets/img/100_13.jpg" alt="image" />
                                </a>
                                <a href="#" className="kt-badge__pic" data-toggle="kt-tooltip" data-skin="brand" data-placement="top" title="" data-original-title="Micheal York">
                                    <img src="./assets/img/100_4.jpg" alt="image" />
                                </a>
                                <a href="#" className="kt-badge__pic kt-badge__pic--last kt-font-brand">
                                    +7
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    <div className="row d-flex justify-content-center">
        <div className="col-xl-12">
            <div className="kt-portlet kt-portlet--tabs">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-toolbar">
                        <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand" role="tablist">
                            <li className="nav-item">
                                <a onClick={() => this.setState({ tab : 'personal_information' })} className={`nav-link ${tab === 'personal_information' ? 'active' : ''}`}  data-toggle="tab" href="#personal_information" role="tab">
                                    <i className="flaticon2-calendar-3"></i> Datos Personales 
                                    &nbsp;{this.badgeCompletePersonalData(user.userInformation)}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => this.setState({ tab : 'academic_title' })} className={`nav-link ${tab === 'academic_title' ? 'active' : ''}`} data-toggle="tab" href="#academic_title" role="tab">
                                    <i className="flaticon2-book"></i> Formación Académica
                                    &nbsp;{this.badgeStatusCount('cv_titles')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => this.setState({ tab : 'complementary_studies' })} className={`nav-link ${tab === 'complementary_studies' ? 'active' : ''}`} data-toggle="tab" href="#complementary_studies" role="tab">
                                    <i className="flaticon2-time"></i> Estudios Complementarios
                                    &nbsp;{this.badgeStatusCount('cv_courses')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => this.setState({ tab : 'languages' })} className={`nav-link ${tab === 'languages' ? 'active' : ''}`}  data-toggle="tab" href="#languages" role="tab">
                                    <i className="flaticon2-calendar-3"></i> Idiomas
                                    &nbsp;{this.badgeStatusCount('cv_languages')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => this.setState({ tab : 'experience' })} className={`nav-link ${tab === 'experience' ? 'active' : ''}`}  data-toggle="tab" href="#experience" role="tab">
                                    <i className="flaticon2-calendar-3"></i> Experiencia Laboral
                                    &nbsp;{this.badgeStatusCount('cv_experiences')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => this.setState({ tab : 'references' })} className={`nav-link ${tab === 'references' ? 'active' : ''}`}  data-toggle="tab" href="#references" role="tab">
                                    <i className="flaticon2-calendar-3"></i> Referencias
                                    &nbsp;{this.badgeStatusCount('cv_references')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => this.setState({ tab : 'salaries' })} className={`nav-link ${tab === 'salaries' ? 'active' : ''}`}  data-toggle="tab" href="#salaries" role="tab">
                                    <i className="flaticon2-calendar-3"></i> Salarios
                                    &nbsp;{this.badgeStatusCount('cv_salaries')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => this.setState({ tab : 'reset-password' })} className={`nav-link ${tab === 'reset-password' ? 'active' : ''}`}  data-toggle="tab" href="#reset-password" role="tab">
                                    <i className="flaticon2-calendar-3"></i> Cambiar password
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => this.setState({ tab : 'profile' })} className={`nav-link ${tab === 'profile' ? 'active' : ''}`}  data-toggle="tab" href="#profile" role="tab">
                                    <i className="flaticon2-calendar-3"></i> Perfiles
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    <div className="tab-content kt-margin-t-10">
                        <div className={`tab-pane ${tab === 'academic_title' ? 'active' : ''}`} id="academic_title" role="tabpanel">
                            
                            <AcademicTitle cv_titles={user.userInformation.cv_titles} />

                        </div>
                        <div className={`tab-pane ${tab === 'complementary_studies' ? 'active' : ''}`}  id="complementary_studies" role="tabpanel">

                            <ComplementaryStudies cv_courses={user.userInformation.cv_courses} />
                            
                        </div>
                        <div className={`tab-pane ${tab === 'languages' ? 'active' : ''}`}  id="languages" role="tabpanel">
                            
                            <Languages languages={languages} cv_languages={user.userInformation.cv_languages} />

                        </div>
                        <div className={`tab-pane ${tab === 'personal_information' ? 'active' : ''}`}  id="personal_information" role="tabpanel">

                            <PersonalInformation personalInformation={user.userInformation} />
                        </div>
                        

                        <div className={`tab-pane ${tab === 'salaries' ? 'active' : ''}`} id="salaries" role="tabpanel">
                            
                            <Salaries cv_salaries={user.userInformation.cv_salaries} />

                        </div>

                        <div className={`tab-pane ${tab === 'references' ? 'active' : ''}`} id="references" role="tabpanel">
                            
                            <References cv_references={user.userInformation.cv_references} />

                        </div>

                        <div className={`tab-pane ${tab === 'experience' ? 'active' : ''}`} id="experience" role="tabpanel">
                            
                            <Experiences cv_experiences={user.userInformation.cv_experiences} />

                        </div>

                        <div className={`tab-pane ${tab === 'reset-password' ? 'active' : ''}`} id="reset-password" role="tabpanel">
                            
                            <ResetPassword />

                        </div>

                        <div className={`tab-pane ${tab === 'profile' ? 'active' : ''}`} id="reset-password" role="tabpanel">
                            
                            <Profiles cv_profiles={user.userInformation.cv_profiles} cv_experiences={user.userInformation.cv_experiences} />

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>	
</div>  
            )
        }
    }

const mapStateToProps = (state) => ({
    api : state.api,
    user : state.user,
    institutions : state.providers.institutions
});

export default connect(mapStateToProps, null)(Cv);