import React from 'react';
import classNames from 'classnames/bind';

const Input = ({
    classStyles,
    label="Label",
    required=false,
    hasErrors=false,
    ...props
}) => {
    let styles = classNames({
        'form-control': true,
        'is-invalid': hasErrors,
        ...classStyles
    });
    return (
        <React.Fragment>
            { props.label ? <label>{props.label}</label> : '' }
            <input className={styles} {...props} required={required ? required : false}></input>
            { props.helpText ? <span class="form-text text-muted">{props.helpText}</span> : '' }
        </React.Fragment>
    );
};

export default Input;

