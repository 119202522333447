import React, { Fragment } from 'react';
import { MDBDataTable, MDBPagination, MDBPageItem, MDBPageNav, MDBCol, MDBRow } from "mdbreact";

import ReactPaginate from 'react-paginate';

const Datatable = ({ columns, rows, last_page, current_page, handlePageClick, paginate = true }) => {
  const data = {
    columns,
    rows
  };

  const handlePage = (e) => handlePageClick(e);

  return (
    <Fragment>
      
      <MDBDataTable
          className=""
          striped
          bordered
          hover
          className="dixonet"
          searching={false}
          searchLabel="Buscar:"
          entriesLabel="Mostrar entradas"
          infoLabel={["Mostrar", "a", "de", "entradas"]}
          paginationLabel={["Atrás", "Adelante"]}
          data={data}
          exportToCSV={true}
          paging={false}
          theadColor="thead-dark"
          theadTextWhite
      />

      {paginate && <ReactPaginate
          previousLabel={'Atrás'}
          nextLabel={'Adelante'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={last_page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePage}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          forcePage={current_page}
        />
      }
      
    </Fragment>
  );
}

export default Datatable;
