import React from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';

const Date = ({
    alert='success',
    outputFormat='DD-MM-YYYY h:mm:ss a',
    date,
    ...props
}) => {
    return (
        <React.Fragment>
            <span class={`btn btn-label-${alert} btn-sm btn-bold btn-upper`}>
                { moment(date).format(outputFormat) }
            </span>
        </React.Fragment>
    );
};

export default Date;