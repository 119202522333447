import React, { Component } from 'react';
import { Input } from 'Components';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as UserActionsCreators from 'Ducks/user/operations';
import classNames from 'classnames/bind';
import 'react-notifications/lib/notifications.css';
import { default as LayoutLogin } from 'Views/Layout/types/Login';
import { configureStore } from 'Utils/createStore';
import { routes } from 'Utils/constants';

const {store, persistor } = configureStore();
const queryString = require('query-string');
class Login extends Component
{
	query = {};
	constructor(props) 
	{
		super(props);

		this.state = {
			email : '',
			password: ''
		};

		const { dispatch } = props;	  
		this.query = queryString.parse(window.location.search);  
		this.userActionsCreators = bindActionCreators(UserActionsCreators, dispatch);

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount()
	{
		const { dispatch } = this.props;
		
		persistor.purge();

		this.userActionsCreators.resetLogout();
	}

	handleChange = (e) =>
    {
        const { name, value } = e.target;
        this.setState({ [ name ] : value });
	}
	
	keyPressed = (event) => {
        if (event.key === "Enter") {
          this.handleSubmit();
        }
    };

	handleSubmit = () => this.userActionsCreators.auth(this.state, this.query);

    render()
    {
        const bg = {
            backgroundImage: 'url(/assets/img/bg-3.jpg)',
		};

		const { api } = this.props;

		let styles = classNames(
			'btn', 'btn-brand', 'btn-success', 'btn-elevate', api.loading ? 'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light' : ''
		);
		
        return (
			<div class="kt-grid kt-grid--ver kt-grid--root" style={{ height : '100%' }}>
            <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login" style={bg}>
				<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"    >
					<div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
						<div class="kt-login__container">
							<div class="kt-login__logo">
								<a href="javascript:void(0)">
									<img src="/assets/img/logo.png" />  	
								</a>
							</div>
							<div class="kt-login__signin">
							<div class="kt-login__head">
								<h3 class="kt-login__title">Bienvenido al Sistema de Seguimiento y Monitoreo de la Oficina de Fortalecimiento de la Gestión Institucional</h3>
							</div>
							<form class="kt-form">
							<div class="input-group">
								{ <Input 
									classStyles={ {} } 
									label="DIXONET"
									placeholder="Email"
									type="text"
									name="email"
									value={this.state.email}
									onChange={this.handleChange}
								/> }
							</div>
							<div class="input-group">
								{ <Input 
									classStyles={ { 'dixonet' : true } } 
									label="DIXONET"
									placeholder="Password"
									type="password"
									name="password"
									onKeyPress={this.keyPressed}
									value={this.state.password}
									onChange={this.handleChange}
								/> }
							</div>
							<div class="row kt-login__extra">
								{/*<div class="col">
									kabel class="kt-checkbox">
										<input type="checkbox" name="remember" /> Recordarme
										<span></span>
									</label>
								</div>*/}
								<div class="col kt-align-right">
									<Link to={routes.FORGOT_PASSWORD}>
										<a href="javascript:;" id="kt_login_forgot" class="kt-login__link">¿ Olvidaste tu contraseña ?</a>
									</Link>
								</div>
							</div>
							<div class="kt-login__actions">
								<a href="javascript:void(0)">
									<button onClick={this.handleSubmit} type="button" className={styles}>Ingresar</button>&nbsp;
								</a>
							</div>
						</form>
							</div>
							<div class="kt-login__signup">
						<div class="kt-login__head">
							<h3 class="kt-login__title">Sign Up</h3>
							<div class="kt-login__desc">Enter your details to create your account:</div>
						</div>
						<form class="kt-form">
						<div class="input-group">
							<input class="form-control" type="text" placeholder="Fullname" name="fullname" />
						</div>
						<div class="input-group">
							<input class="form-control" type="text" placeholder="Email" name="email" autocomplete="off" />
						</div>
						<div class="input-group">
							<input class="form-control" type="password" placeholder="Password" name="password" />
						</div>
						<div class="input-group">
							<input class="form-control" type="password" placeholder="Confirm Password" name="rpassword" />
						</div>
						<div class="row kt-login__extra">
							<div class="col kt-align-left">
								<label class="kt-checkbox">
									<input type="checkbox" name="agree" />I Agree the <a href="#" class="kt-link kt-login__link kt-font-bold">terms and conditions</a>.
									<span></span>
								</label>
								<span class="form-text text-muted"></span>
							</div>
						</div>
						<div class="kt-login__actions">
							<button id="kt_login_signup_submit" class="btn btn-brand btn-elevate kt-login__btn-primary">Sign Up</button>&nbsp;&nbsp;
							<button id="kt_login_signup_cancel" class="btn btn-light btn-elevate kt-login__btn-secondary">Cancel</button>
						</div>
					</form>
					</div>
							<div class="kt-login__forgot">
								<div class="kt-login__head">
									<h3 class="kt-login__title">¿ Olvidaste tu contraseña ?</h3>
									<div class="kt-login__desc">Ingresa tu email para reestablecer la contraseña..</div>
								</div>
								<form class="kt-form" action="">
									<div class="input-group">
										<input class="form-control" type="text" placeholder="Email" name="email" id="kt_email" autocomplete="off" />
									</div>
									<div class="kt-login__actions">
										<button id="kt_login_forgot_submit" class="btn btn-brand btn-elevate kt-login__btn-primary btn-success">Enviar</button>&nbsp;&nbsp;
										<button id="kt_login_forgot_cancel" class="btn btn-light btn-elevate kt-login__btn-secondary">Cancelar</button>
									</div>
								</form>
							</div>
							{/*<div class="kt-login__account">
								<span class="kt-login__account-msg">
									¿ Todavía no tienes una cuenta ?
								</span>
								&nbsp;&nbsp;
								<a href="javascript:;" id="kt_login_signup" class="kt-login__account-link">¡Regístrate Aquí!</a>
							</div>*/}
						</div>	
					</div>
				</div>
				</div>
				</div>
        )
    }
}

const mapStateToProps = (state) => ({
    user : state.user,
    api : state.api
});


export default connect(mapStateToProps, null)(Login);